import styled from 'styled-components';

export const PositionDiv = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  text-align: center;
`;
export const PercentageText = styled.p`
  position: absolute;
  top: 30%;
  left: 45%;
  font-size: 15px;
`;
export const TagEditDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 41px;
`;
