import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { AddCommentForm } from '../forms';
import { Loading, Title, DeleteButtonSmall } from '../styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddCommentModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    isLoading,
    loadData,
    isEditModeReply,
    setIsEditModeReply,
    comment,
    handleAddReply,
    handleEditReply,
  } = props;
  const handleSubmit = (values) => {
    if (isEditModeReply) {
      const editPromise = handleEditReply(values);
      editPromise.then(() => {
        setIsModalOpen(false);
        setIsEditModeReply(false);
        loadData();
      });
    } else {
      const addPromise = handleAddReply(values);
      addPromise.then(() => {
        setIsModalOpen(false);
        setIsEditModeReply(false);
        loadData();
      });
      return addPromise;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModeReply(false);
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">
            {isEditModeReply ? 'Editează Răspuns' : 'Adaugă Răspuns'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <AddCommentForm
          onSubmit={handleSubmit}
          comment={comment && comment.comment}
          isEditModeComment={isEditModeReply}
        />
      </Modal.Body>

      <Loading isLoading={isLoading} />
    </Modal>
  );
};

const mapDispatchToProps = {};

export default withRouter(
  connect(undefined, mapDispatchToProps)(AddCommentModal)
);
