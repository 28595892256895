import React from 'react';
import { Switch, Route } from 'react-router';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import store from './store';
import { theme } from './core/styles';
import history from './core/services/history';

import AppRouter from './AppRouter';

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route component={AppRouter} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>
);

export default App;
