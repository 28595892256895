import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadExperimentDetail } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import ExperimentDetailPage from './ExperimentDetailPage';

const ExperimentDetailPageResolver = (props) => {
  const {
    loadExperimentDetail,
    match: {
      params: { detailId },
    },
  } = props;
  const loadDependencies = () =>
    detailId ? loadExperimentDetail(detailId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Detaliu Experiment</DocumentTitle>
      <Resolver
        successComponent={ExperimentDetailPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

ExperimentDetailPageResolver.propTypes = {
  loadExperimentDetail: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadExperimentDetail };

export default withRouter(
  connect(undefined, mapDispatchToProps)(ExperimentDetailPageResolver)
);
