import update from 'immutability-helper';

import {
  loadUsers as doLoadUsers,
  deleteUser as doDeleteUser,
  deleteUserProfile as doDeleteUserProfile,
} from '../services/users';

// Actions
const START_LOAD = 'users/START_LOAD';
const COMPLETE_LOAD = 'users/COMPLETE_LOAD';
const FAIL_LOAD = 'users/FAIL_LOAD';

const START_DELETE = 'users/START_DELETE';
const COMPLETE_DELETE = 'users/COMPLETE_DELETE';
const FAIL_DELETE = 'users/FAIL_DELETE';

const RESET = 'users/RESET';

// Initial state
const initialState = {
  isDeleting: false,
  isLoading: false,
  users: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          users: action.users,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          users: undefined,
        },
      });
    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (users) => ({
  type: COMPLETE_LOAD,
  users,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadUsers = () => (dispatch) => {
  dispatch(startLoad());
  return doLoadUsers()
    .then((res) => dispatch(completeLoad(res)))
    .catch(() => dispatch(failLoad()));
};

export const deleteUser = (userId) => (dispatch) => {
  dispatch(startDelete());
  return doDeleteUser(userId)
    .then(() => dispatch(completeDelete()))
    .catch(() => dispatch(failDelete()));
};

export const deleteUserProfile = (userProfileId) => (dispatch) => {
  dispatch(startDelete());
  return doDeleteUserProfile(userProfileId)
    .then(() => dispatch(completeDelete()))
    .catch(() => dispatch(failDelete()));
};
