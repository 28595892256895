import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadTeams } from '../../../teams/ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import CohortTeamsPage from './CohortTeamsPage';

const CohortTeamsPageResolver = (props) => {
  const {
    loadTeams,
    match: {
      params: { cohortId },
    },
  } = props;
  const loadDependencies = () =>
    cohortId ? loadTeams(cohortId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Echipe</DocumentTitle>
      <Resolver
        successComponent={CohortTeamsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

CohortTeamsPageResolver.propTypes = {
  loadTeams: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadTeams };

export default withRouter(
  connect(undefined, mapDispatchToProps)(CohortTeamsPageResolver)
);
