import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { get } from 'lodash-es';
import {
  AddResolutionFormExperiment,
  AddResolutionFormHypothesis,
} from '../forms';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddResolutionModal = (props) => {
  const {
    isLoading,
    isModalOpen,
    setIsModalOpen,
    entityId,
    onSubmitResolution,
    isHypothesis,
    isExperiment,
    parent,
    resolution,
    loadData,
    teamId,
    firstName,
    lastName,
    isEditingResolution,
  } = props;

  const handleSubmitResolution = (values) => {
    const personResponsible = `${firstName} ${lastName} `;
    const registerValues = {
      ...values,
      outcome: get(values.outcome, 'value'),
      personResponsible,
      hypothesisId: isEditingResolution
        ? resolution.hypothesisId[0]._id
        : entityId,
    };
    const promise = onSubmitResolution(registerValues);
    promise.then(() => {
      setIsModalOpen(false);
      loadData(teamId || entityId);
    });
  };

  const handleSubmit = async (values) => {
    const personResponsible = `${firstName} ${lastName} `;

    const promise = onSubmitResolution({
      ...values.resolution,
      experimentId: isEditingResolution
        ? resolution.experimentId[0]._id
        : entityId,
      personResponsible,
      actionRequired: get(values.resolution.actionRequired, 'value'),
      dataReliability: get(values.resolution.dataReliability, 'value'),
      outcome: values.resolution.outcome || 'inProgress',
    });
    promise.then(() => {
      setIsModalOpen(false);
      loadData(teamId || entityId);
    });

    return promise;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const experimentText = isEditingResolution
    ? 'Editează concluzia experimentului'
    : 'Concluzie experiment';

  const hypothesisText = isEditingResolution
    ? 'Editează rezoluția ipotezei'
    : 'Adaugă o rezoluție la ipoteză';
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="modal-responsive scrollbar-modal">
        <TitleButtonsDiv borderBottom marginBottom="30px">
          <Title lineHeight="28px" fontSize="24px">
            {' '}
            {isExperiment ? experimentText : hypothesisText}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        {isExperiment ? (
          <AddResolutionFormExperiment
            onSubmit={handleSubmit}
            resolution={parent ? parent.resolution : resolution}
          />
        ) : (
          isHypothesis && (
            <AddResolutionFormHypothesis
              onSubmit={handleSubmitResolution}
              resolution={parent ? parent.resolution : resolution}
            />
          )
        )}
      </Modal.Body>

      <Loading isLoading={isLoading} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddResolutionModal)
);
