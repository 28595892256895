import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadUserTeams } from '../../../common/ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import MyTeamsPage from './MyTeamsPage';

const MyTeamsPageResolver = (props) => {
  const { loadUserTeams, userId } = props;
  const loadDependencies = () => loadUserTeams(userId);

  return (
    <>
      <DocumentTitle>Ideile Mele</DocumentTitle>
      <Resolver
        successComponent={MyTeamsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

MyTeamsPageResolver.propTypes = {
  loadUserTeams: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
});

const mapDispatchToProps = { loadUserTeams };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyTeamsPageResolver)
);
