import { css } from 'styled-components';
import { rotate } from './animations';

export const loading = css`
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMjQyODMzIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCIvPjwvZz48L3N2Zz4=')
    no-repeat center;
  background-size: 40px;
  animation: ${rotate} 0.4s linear infinite;
`;

export const loadingOverlay = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: ${(props) => props.theme.gray};
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    margin: -40px 0 0 -40px;
    ${loading};
  }
`;

export const loadingOverlay2 = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.5);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    margin: -40px 0 0 -40px;
    ${loading};
  }
`;
