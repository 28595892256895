import styled from 'styled-components';
import { Row, Button } from 'react-bootstrap';

export const TeamMembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 10px;
`;

export const AddButton = styled(Button)`
  position: absolute;
  top: -58px;
  right: 0;
  float: right;
`;
export const MembersDiv = styled.div`
  max-height: 195px;
  overflow-y: auto;
  padding-right: 4px;
`;
export const IconLabelDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
