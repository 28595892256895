import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavigationBarContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 999999;
  justify-content: space-between;
  width: 150px;
  height: 100vh;
  color: #2ad4c3;
  background-color: #2f3333;
  font-size: 16px;
`;

export const NavigationBarLinks = styled.div`
  display: flex;
  width: 100%;
  align-items: left;
  flex-direction: column;
`;

export const NavigationToggleDiv = styled.div`
  width: ${(props) => (props.open ? '250px' : '80px')};
`;
export const ArrowButton = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 80px;
  right: -14px;
  width: 24px;
  z-index: 11;
  height: 24px;
  text-align: center;
  background-color: grey;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg > path {
    fill: ${(props) => props.theme.white};
  }

  &:hover {
    background-color: ${(props) => props.theme.accentHover};
  }
`;

export const NavigationBarLinkContainer = styled.div`
  height: 50px;
  padding: 10px;
  line-height: 30px;
  ${(props) =>
    props.alignBottom &&
    css`
      margin-top: auto;
      margin-bottom: 50px;
    `}
  &:hover,
  &:focus {
    a {
      text-decoration: none;
      color: #000;
    }
    cursor: pointer;
    background-color: #3faea3;
  }
`;

export const NavigationBarLink = styled(NavLink)`
  ${(props) =>
    props.titleapp &&
    css`
      padding: 8px 24px !important;
    `}

  ${(props) =>
    props.imageitem &&
    css`
      padding: 8px 14px !important;
      &:hover {
        span {
          font-weight: bold !important;
        }
      }
    `}
`;

export const StyledLink = styled.a`
  ${(props) =>
    props.close &&
    css`
      padding: 8px 16px !important;
    `}
`;
export const PositionText = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.text};
  white-space: nowrap;
  position: absolute;
  top: 12px;
  left: ${(props) => (props.open ? '44px' : '-500px')};

  ${(props) =>
    props.name &&
    css`
      left: ${(props) => (props.open ? '80px' : '-500px')};
    `}

  ${(props) =>
    props.isTitle &&
    css`
      font-family: Dosis;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: 0.1em;
      left: ${(props) => (props.open ? '60px' : '-500px')};
      top: 5px;
    `}
`;
export const PositionBadge = styled.div`
  position: absolute;
  top: 32px;
  left: ${(props) => (props.open ? '80px' : '-500px')};
`;
export const PositionMenu = styled.div`
  width: 100%;
`;

export const NavigationDropdownContainer = styled.div`
  margin: 15px 5px;
  svg {
    fill: #3faea3;
  }
`;
