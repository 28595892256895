import React from 'react';
import Select, { components } from 'react-select';
import { get } from 'lodash-es';
import { theme } from '../styles';
import dropdownControl from '../../common/assets/icons/dropdownControl.svg';

const dropdownStyles = {
  menu: (baseStyle) => ({
    ...baseStyle,
    zIndex: 9999999999,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: theme.textInactive,
  }),
  control: (baseStyle, state) => ({
    width: '100%',
    minHeight: '40px',
    padding: '5px 26px 5px 5px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    outline: 'none',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: get(state.selectProps, 'invalid')
      ? '#fa5c7c'
      : theme.textInactive,
    lineHeight: '19px',
    fontSize: '14px',
    color: theme.textInactive,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '0px 6px 2px 0',
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    top: '2px',
    transform: 'none',
    fontSize: '14px',
    fontWeight: 400,
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    fontSize: '14px',
    fontFamily: ' Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: state.isFocused ? theme.text : theme.textInactive,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    top: '0px',
    right: '0px',
  }),
  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '12px',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    ':active': theme.text,
    paddingRight: '30px',
    backgroundColor:
      (state.isSelected && 'white') ||
      (state.isFocused && theme.bodyBackgroundColor) ||
      'transparent',
    fontSize: '16px',
    color: theme.text,
    fontFamily: ' Open Sans',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : 'normal',
    cursor: 'pointer',
  }),
};

const SingleValueOption = (props) => {
  const {
    data: { label, icon, value },
  } = props;
  return (
    <components.SingleValue {...props}>
      {!!icon && (
        <img
          src={icon}
          style={{
            width: value === 'validatedWithoutMVP' ? '18px' : '20px',
            height: value === 'validatedWithoutMVP' ? '18px' : '20px',
            marginRight: '5px',
            marginLeft: value === 'validatedWithoutMVP' ? '2px' : '0px',
          }}
          alt={label}
        />
      )}
      {label}
    </components.SingleValue>
  );
};

const IconOption = (props) => {
  const {
    data: { label, icon, value },
  } = props;
  return (
    <components.Option {...props}>
      {!!icon && (
        <img
          src={icon}
          style={{
            width: value === 'validatedWithoutMVP' ? '18px' : '20px',
            height: value === 'validatedWithoutMVP' ? '18px' : '20px',
            marginRight: '5px',
            marginLeft: value === 'validatedWithoutMVP' ? '2px' : '0px',
          }}
          alt={label}
        />
      )}
      {label}
    </components.Option>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <img
      src={dropdownControl}
      alt={dropdownControl}
      style={{
        marginTop: '2px',
        width: '15px',
        height: '10px',
        cursor: 'pointer',
      }}
    />
  </components.DropdownIndicator>
);

const Dropdown = ({
  options,
  value,
  onChange,
  defaultValue,
  isInvalid,
  placeholder,
  styles = dropdownStyles,
  isMulti,
  disabled,
  fieldRef,
  showCustomControl = false,
  showIconOption = false,
  onBlur = () => {},
}) => {
  const handleChange = (selectedOption) => {
    if (onChange) onChange(selectedOption);
  };
  const customComponents = showCustomControl ? { DropdownIndicator } : {};

  const customComponentsIcon = showIconOption
    ? { Option: IconOption, SingleValue: SingleValueOption }
    : {};

  return (
    <Select
      value={value && value.value ? value : undefined}
      placeholder={placeholder}
      onChange={handleChange}
      isMulti={isMulti}
      onBlur={onBlur}
      isClearable={false}
      ref={fieldRef}
      options={options}
      styles={styles}
      isDisabled={disabled}
      defaultValue={defaultValue}
      invalid={isInvalid}
      components={showIconOption ? customComponentsIcon : customComponents}
    />
  );
};
export default Dropdown;
