import React from 'react';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import {
  ButtonDiv,
  Input,
  Heading2,
  SubmitButton,
} from '../../../common/components/styled';

const RenderNameField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({ handleSubmit, handleChange, values }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderNameField
        placeholder="Cod Idee"
        name="teamCode"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Cod Idee"
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">Alătură-te</SubmitButton>
    </ButtonDiv>
  </Form>
);

const JoinTeamForm = (props) => {
  const { onSubmit } = props;
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values.teamCode);
  };

  const schema = Yup.object({
    teamCode: Yup.string().required('Câmp obligatoriu'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        teamCode: '',
      }}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};

export default JoinTeamForm;
