export const BMC_OPTIONS = [
  {
    value: 'customerSegments',
    label: 'Customer Segments',
  },
  {
    value: 'customerRelationships',
    label: 'Customer Relationships',
  },
  {
    value: 'valuePropositions',
    label: 'Value Propositions',
  },
  {
    value: 'revenueStreams',
    label: 'Revenue Streams',
  },
  {
    value: 'channels',
    label: 'Channels',
  },
  {
    value: 'costStructure',
    label: 'Cost Structure',
  },
  {
    value: 'keyResources',
    label: 'Key Resources',
  },
  {
    value: 'keyActivities',
    label: 'Key Activities',
  },
  {
    value: 'keyPartners',
    label: 'Key Partners',
  },
];

export const BMC_LABELS = [
  {
    value: 'customerSegments',
    label: 'CS',
  },
  {
    value: 'customerRelationships',
    label: 'CR',
  },
  {
    value: 'valuePropositions',
    label: 'VP',
  },
  {
    value: 'revenueStreams',
    label: 'RS',
  },
  {
    value: 'channels',
    label: 'CH',
  },
  {
    value: 'costStructure',
    label: 'CS',
  },
  {
    value: 'keyResources',
    label: 'KR',
  },
  {
    value: 'keyActivities',
    label: 'KA',
  },
  {
    value: 'keyPartners',
    label: 'KP',
  },
];
