import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadInvitations } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import MentorsInvitationsPage from './MentorsInvitationsPage';

const MentorsInvitationsPageResolver = (props) => {
  const { loadInvitations, userId } = props;
  const loadDependencies = async () => {
    await loadInvitations(userId);
  };

  return (
    <>
      <DocumentTitle>Invitations</DocumentTitle>
      <Resolver
        successComponent={MentorsInvitationsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

MentorsInvitationsPageResolver.propTypes = {
  loadInvitations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.account.login.user._id,
});

const mapDispatchToProps = { loadInvitations };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentorsInvitationsPageResolver)
);
