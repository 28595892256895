import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadExperiment } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import ExperimentDetailsPage from './ExperimentDetailsPage';

const ExperimentDetailsPageResolver = (props) => {
  const {
    loadExperiment,
    match: {
      params: { experimentId },
    },
  } = props;
  const loadDependencies = () =>
    experimentId ? loadExperiment(experimentId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Experiment</DocumentTitle>
      <Resolver
        successComponent={ExperimentDetailsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

ExperimentDetailsPageResolver.propTypes = {
  loadExperiment: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadExperiment };

export default withRouter(
  connect(undefined, mapDispatchToProps)(ExperimentDetailsPageResolver)
);
