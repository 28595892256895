import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AvatarContainer = styled.div`
  margin-bottom: 10px;
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  padding: 18px 8px;
`;

export const IconsDiv = styled.div`
  display: flex;
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${(props) => props.theme.brandPrimary};
  margin-left: -6px;
  margin-top: -11px;
`;

export const StyledP = styled.p`
  cursor: ${(props) => props.permision && 'pointer'};
`;
