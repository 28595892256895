import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  loadUserTeams,
  setCurrentUserTeam,
  resetUserTeams,
  handleMenu,
} from '../ducks';
import { ReactComponent as RocketIcon } from '../assets/icons/Navigation_Logo.svg';
import { ReactComponent as ExitIcon } from '../assets/icons/Navigation_Logout.svg';
import { ReactComponent as HelpIcon } from '../assets/icons/Navigation_Resurse-1.svg';

import {
  NavigationToggleDiv,
  ArrowButton,
  NavigationBarLink,
  StyledLink,
  PositionText,
} from './styled';

import {
  FounderNavigationMenu,
  MentorNavigationMenu,
  AdminNavigationMenu,
  AcceleratorNavigationMenu,
} from './partials';

const NavigationBar = ({ role, isMenuOpen, handleMenu }) => {
  const handleHoverIcon = useCallback(() => handleMenu(true), [handleMenu]);

  const getMenuByRole = useCallback(() => {
    switch (role) {
      case 'FOUNDER':
        return <FounderNavigationMenu handleHoverIcon={handleHoverIcon} />;
      case 'MENTOR':
        return <MentorNavigationMenu handleHoverIcon={handleHoverIcon} />;
      case 'ADMIN':
        return <AdminNavigationMenu handleHoverIcon={handleHoverIcon} />;
      case 'ACCELERATOR':
        return <AcceleratorNavigationMenu handleHoverIcon={handleHoverIcon} />;

      default:
        return <div />;
    }
  }, [role, handleHoverIcon]);

  return (
    <NavigationToggleDiv open={isMenuOpen} className="left-side-menu">
      <ArrowButton onClick={() => handleMenu(!isMenuOpen)}>
        <FontAwesomeIcon icon={isMenuOpen ? faChevronLeft : faChevronRight} />
      </ArrowButton>
      <div style={{ overflow: 'hidden' }}>
        <ul
          className="metismenu side-nav"
          style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        >
          <li
            className="side-nav-item"
            style={{ marginBottom: '20px', marginTop: '30px' }}
          >
            <NavigationBarLink
              titleapp="true"
              to="/"
              className="side-sub-nav-link side-nav-link"
              style={{
                fontSize: '20px',
                padding: isMenuOpen ? '8px 16px !important' : '8px 30px',
              }}
            >
              <RocketIcon onMouseEnter={handleHoverIcon} />
              <PositionText isTitle open={isMenuOpen}>
                LAUNCHPAD
              </PositionText>
            </NavigationBarLink>
          </li>
          {getMenuByRole(isMenuOpen)}{' '}
          <li className="side-nav-item">
            <StyledLink
              href="https://ptic.ro/help/"
              target="_blank"
              rel="noopener noreferrer"
              className="side-sub-nav-link side-nav-link"
              style={{
                padding: isMenuOpen ? '8px 16px' : '8px 30px',
              }}
            >
              <HelpIcon onMouseEnter={handleHoverIcon} />
              <PositionText open={isMenuOpen}>Ajutor</PositionText>
            </StyledLink>
          </li>
          <li className="side-nav-item" style={{ marginBottom: '50px' }}>
            <NavigationBarLink
              activeClassName="activeNavItem"
              to="/account/logout"
              className="side-sub-nav-link side-nav-link"
              style={{
                padding: isMenuOpen ? '8px 16px' : '8px 30px',
              }}
            >
              <ExitIcon onMouseEnter={handleHoverIcon} />
              <PositionText open={isMenuOpen}>Ieșire / Logout</PositionText>
            </NavigationBarLink>
          </li>
        </ul>
      </div>
    </NavigationToggleDiv>
  );
};

const mapStateToProps = (state) => ({
  role: get(state.account.login, 'user.role'),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  loadUserTeams,
  setCurrentUserTeam,
  resetUserTeams,
  handleMenu,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
);
