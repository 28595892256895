import { intersection, castArray, get } from 'lodash-es';
import { http } from '../../core/services/http';
import { createSession, getSessionValue, destroySession } from './session';
import transformLogin from './transformLogin';

export const isEmailRegistered = (email) =>
  http.get(`account/is-email-registered?email=${email}`);

export const register = async (data) => {
  const registerData = {
    ...data,
    role: get(data.role, 'value'),
  };
  const response = await http.post('account/sign-up', { ...registerData });
  const user = transformLogin(response.data);
  return user;
};

export const login = async (email, password) => {
  const response = await http.post('account/login', { email, password });
  const user = transformLogin(response.data);
  createSession(response.headers.authtoken, user);
  return user;
};

export const getAuthToken = () => getSessionValue('authToken');
export const getUser = () => getSessionValue('user');
export const hasRole = (roles) =>
  !!intersection(getSessionValue('user.roles'), castArray(roles)).length;

export const logout = () => {
  destroySession();
};
