import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypotheses } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { currentTeamIdSelector } from '../../../common/ducks';
import HypothesesPage from './HypothesesPage';

const HypothesesPageResolver = (props) => {
  const { loadHypotheses, teamId } = props;
  const loadDependencies = () =>
    teamId ? loadHypotheses(teamId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Ipoteze</DocumentTitle>
      <Resolver
        successComponent={HypothesesPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

HypothesesPageResolver.propTypes = {
  loadHypotheses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { loadHypotheses };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HypothesesPageResolver)
);
