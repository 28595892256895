/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { sortBy, reverse } from 'lodash-es';

import { TableHead } from './styled';

const SortTableHead = ({
  children,
  array,
  setArray,
  cellSortKeys,
  defaultSortKey,
}) => {
  const [sortIndicator, setSortIndicator] = useState(null);
  const [childrenWithProps, setChildren] = useState(children);

  useEffect(() => {
    if (defaultSortKey) setArray(sortBy(array, defaultSortKey));
  }, []);

  const handlerSortIndicator = (key) => {
    if (sortIndicator && sortIndicator.key === key) {
      setSortIndicator({ key, reverse: !sortIndicator.reverse });
    } else {
      setSortIndicator({ key, reverse: false });
    }
  };

  const handlerOnClick = (key) => {
    if (cellSortKeys && cellSortKeys.length > key && cellSortKeys[key]) {
      if (
        sortIndicator &&
        sortIndicator.key === key &&
        !sortIndicator.reverse
      ) {
        setArray(reverse(sortBy(array, cellSortKeys[key])));
      } else {
        setArray(sortBy(array, cellSortKeys[key]));
      }
      handlerSortIndicator(key);
    }
  };

  useEffect(() => {
    setChildren(
      React.Children.map(children, (child, index) => {
        const props = { onClick: () => handlerOnClick(index) };
        if (sortIndicator && sortIndicator.key === index) {
          props.indicator = true;
          props.reverse = sortIndicator.reverse;
        }
        if (React.isValidElement(child)) {
          return React.cloneElement(child, props);
        }
        return child;
      })
    );
  }, [sortIndicator, array]);

  return <TableHead>{childrenWithProps}</TableHead>;
};

export default SortTableHead;
