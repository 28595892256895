import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { AccountPanelLink } from '../styled';
import {
  Input,
  SubmitButton,
  ButtonDiv,
} from '../../../common/components/styled';

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationFormik01">
        <Input
          type="text"
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          isInvalid={submitCount > 0 && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {submitCount > 0 && errors.email}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
    <Form.Row>
      <Form.Group as={Col} md="12">
        <AccountPanelLink to="/account/login">Inapoi la Login</AccountPanelLink>
      </Form.Group>
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">Resetează parola</SubmitButton>
    </ButtonDiv>
  </Form>
);

const RequestPasswordResetForm = (props) => {
  const { handleSubmit, isLoginFailed } = props;
  const onSubmit = (values, { resetForm }) => {
    handleSubmit(values);
  };

  const schema = Yup.object({
    email: Yup.string().required('Câmp obligatoriu'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
    >
      {(props) => <RenderForm {...props} isLoginFailed={isLoginFailed} />}
    </Formik>
  );
};

RequestPasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default RequestPasswordResetForm;
