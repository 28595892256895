import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Doughnut } from 'react-chartjs-2';
import { ProfileModal } from '../modals';
import {
  Loading,
  Heading2,
  Heading4Regular,
  Link,
  Title,
  TitleCounterDiv,
  RoleBadge,
  EditButton,
  EditButtonHover,
} from '../../../common/components/styled';
import { ProfileImageUploader } from '../../../common/components';
import { uploadProfilePicture } from '../../ducks';
import { theme } from '../../../core/styles';
import { PositionDiv, PercentageText, TagEditDiv } from '../styled';
import {
  IdeeaDescription,
  LabelFieldDiv,
  ImageFieldsDiv,
  FieldsDiv,
  WrapContainer,
  DivBorderBottom,
} from '../../../mentors/components/styled';

const ProfilePage = (props) => {
  const {
    userProfile,
    isLoading,
    role,
    hypothesesPercentage,
    experimentsPercentage,
    uploadProfilePicture,
  } = props;

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverState = () => setIsHovered(!isHovered);

  const doughnutDataHypothesis = {
    datasets: [
      {
        data: [hypothesesPercentage, 100 - hypothesesPercentage],
        backgroundColor: [theme.success, theme.white],
      },
    ],
  };

  const doughnutDataExperiments = {
    datasets: [
      {
        data: [experimentsPercentage, 100 - experimentsPercentage],
        backgroundColor: [theme.success, theme.white],
      },
    ],
  };

  const doughnutOptions = {
    cutoutPercentage: 70,
    tooltips: false,
  };

  const onFilesSelected = (files) => {
    uploadProfilePicture(files[0]);
  };
  const handleRoleText = (role) => {
    switch (role) {
      case 'FOUNDER':
        return 'Fondator';
      case 'MENTOR':
        return 'Mentor';

      case 'ACCELERATOR':
        return 'Accelerator/Incubator';

      default:
        return 'Fondator';
    }
  };

  const handleRoleColor = (role) => {
    switch (role) {
      case 'FOUNDER':
        return '#15BB89';
      case 'MENTOR':
        return '#FB791C';
      case 'ACCELERATOR':
        return '#74A3FE';

      default:
        return '#15BB89';
    }
  };

  return (
    <>
      <TitleCounterDiv smallMargin>
        <Title>Profilul Meu</Title>
      </TitleCounterDiv>
      <TagEditDiv>
        <RoleBadge backgroundColor={handleRoleColor(role)}>
          <Heading4Regular white>{handleRoleText(role)}</Heading4Regular>
        </RoleBadge>

        {isHovered ? (
          <EditButtonHover
            onMouseEnter={handleHoverState}
            onMouseLeave={handleHoverState}
            onClick={() => setIsProfileModalOpen(true)}
          />
        ) : (
          <EditButton
            onMouseEnter={handleHoverState}
            onMouseLeave={handleHoverState}
            onClick={() => setIsProfileModalOpen(true)}
          />
        )}
      </TagEditDiv>

      <DivBorderBottom>
        <ImageFieldsDiv>
          <ProfileImageUploader
            upload
            label="Imagine de profil"
            onFilesSelected={onFilesSelected}
            imageUrl={userProfile.profilePicture}
          />
          <FieldsDiv>
            <LabelFieldDiv>
              <Heading2>Nume</Heading2>
              <Heading4Regular>{userProfile.firstName}</Heading4Regular>
            </LabelFieldDiv>
            <LabelFieldDiv>
              <Heading2>Prenume</Heading2>
              <Heading4Regular>{userProfile.lastName}</Heading4Regular>
            </LabelFieldDiv>
            <LabelFieldDiv>
              <Heading2>Locatie</Heading2>
              <Heading4Regular> {userProfile.address}</Heading4Regular>
            </LabelFieldDiv>
            <LabelFieldDiv>
              <Heading2>Telefon</Heading2>
              <Heading4Regular>{userProfile.phone}</Heading4Regular>
            </LabelFieldDiv>
            <LabelFieldDiv>
              <Heading2>Email</Heading2>
              <Heading4Regular>{userProfile.email}</Heading4Regular>
            </LabelFieldDiv>
            <LabelFieldDiv>
              <Heading2>Profil LinkedIn</Heading2>
              <Link href={userProfile.linkedIn} target="_blank">
                {userProfile.linkedIn}
              </Link>
            </LabelFieldDiv>
          </FieldsDiv>
        </ImageFieldsDiv>
        <LabelFieldDiv>
          <Heading2>Despre tine</Heading2>
        </LabelFieldDiv>
        <IdeeaDescription className="scrollbar">
          <Heading4Regular noEllipsis>{userProfile.about}</Heading4Regular>
        </IdeeaDescription>
      </DivBorderBottom>
      {role === 'FOUNDER' && (
        <WrapContainer>
          <div style={{ width: '50%' }}>
            <PositionDiv>
              <Heading2>Ipoteze</Heading2>
              <PercentageText>
                {!hypothesesPercentage
                  ? 0
                  : Math.round(hypothesesPercentage * 100) / 100}
                %
              </PercentageText>
              <Doughnut
                data={doughnutDataHypothesis}
                options={doughnutOptions}
              />
            </PositionDiv>
          </div>

          <div style={{ width: '50%' }}>
            <PositionDiv>
              <Heading2>Experimente</Heading2>
              <PercentageText>
                {!experimentsPercentage
                  ? 0
                  : Math.round(experimentsPercentage * 100) / 100}
                %
              </PercentageText>
              <Doughnut
                data={doughnutDataExperiments}
                options={doughnutOptions}
              />
            </PositionDiv>
          </div>
        </WrapContainer>
      )}

      <ProfileModal
        isModalOpen={isProfileModalOpen}
        setIsModalOpen={setIsProfileModalOpen}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  hypothesesPercentage: state.userProfile.hypothesesPercentage,
  experimentsPercentage: state.userProfile.experimentsPercentage,
  userProfile: state.userProfile.userProfile,
  isLoading: state.userProfile.isLoading,
  role: state.account.login.user.role,
});

const mapDispatchToProps = {
  uploadProfilePicture,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
);
