import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router';
import { AdminRoute } from '../../account/components';
import Page from '../../common/components/Page';

import { UsersPageResolver, ResourcesPageResolver } from './pages';

const AdminRouter = () => (
  <Page>
    <Switch>
      <AdminRoute exact path="/admin/users" component={UsersPageResolver} />
      <AdminRoute
        exact
        path="/admin/resources"
        component={ResourcesPageResolver}
      />
      <Redirect path="/admin" to={{ pathname: '/teams' }} />
    </Switch>
  </Page>
);
export default withRouter(AdminRouter);
