/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { get, find, map } from 'lodash-es';
import { CohortFilterForm } from '../forms';
import {
  setCurrentUserCohort,
  resetUserCohorts,
  currentCohortIdSelector,
} from '../../../accelerator/ducks';
import { ReactComponent as MyIdeeasIcon } from '../../assets/icons/Navigation_Idei.svg';
import { ReactComponent as CohortIcon } from '../../assets/icons/Avatar_Cohorta-2.svg';
import { ReactComponent as LeaderBoardIcon } from '../../assets/icons/Navigation_Clasament.svg';
import MemberIcon from '../../assets/icons/Avatar_Membru.svg';

import { ImageIconRound } from '../../../mentors/components/styled';

import {
  NavigationBarLink,
  PositionText,
  PositionBadge,
  PositionMenu,
  Title,
  Heading2,
} from '../styled';

const AcceleratorNavigationMenu = (props) => {
  const {
    cohortId,
    userCohorts,
    push,
    resetUserCohorts,
    setCurrentUserCohort,
    userId,
    userProfile,
    role,
    isMenuOpen,
    handleHoverIcon,
  } = props;

  useEffect(() => {
    if (!cohortId && !userCohorts) push('/cohorts');
    if (cohortId) push(`/accelerator/${cohortId}/leaderboard`);
    return () => resetUserCohorts();
  }, []);

  const getCohortOptions = useCallback(
    () =>
      map(userCohorts, (cohort) => ({
        label: cohort.name,
        value: cohort._id,
      })),
    [userCohorts]
  );

  const getCohortValue = useCallback(() => {
    const value = find(userCohorts, (cohort) => !!cohort.selected);
    return { label: get(value, 'name'), value: get(value, '_id') };
  }, [userCohorts]);

  const handleChangeCohort = (cohort) => {
    setCurrentUserCohort(userId, cohort.value);
  };

  return (
    <>
      <li className="side-nav-item" style={{ marginBottom: '30px' }}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          imageitem="true"
          to="/profile"
          className="side-sub-nav-link side-nav-link my-1"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
        >
          {!!userProfile && (
            <p style={{ wordBreak: 'break-word' }} className="mb-0">
              <ImageIconRound
                onMouseEnter={handleHoverIcon}
                style={{ marginRight: '5px' }}
                src={
                  userProfile.profilePicture
                    ? userProfile.profilePicture
                    : MemberIcon
                }
              />
              <PositionText fontWeight="600" name="true" open={isMenuOpen}>
                {`${userProfile.firstName} ${userProfile.lastName}`}
              </PositionText>
            </p>
          )}
          <PositionBadge open={isMenuOpen} className="mb-0">
            <Heading2 color="#74A3FE">
              {role === 'ACCELERATOR' && 'Incubator/Accelerator'}
            </Heading2>
          </PositionBadge>
        </NavigationBarLink>
      </li>
      <div>
        <PositionMenu
          onMouseEnter={handleHoverIcon}
          open={isMenuOpen}
          style={{ marginBottom: '10px' }}
        >
          {!!userCohorts && (
            <CohortFilterForm
              userCohorts={getCohortOptions()}
              userCohortValue={getCohortValue()}
              handleChangeCohort={handleChangeCohort}
            />
          )}
        </PositionMenu>
      </div>
      <li className="side-nav-item" style={{ marginBottom: '30px' }}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          to="/cohorts"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
        >
          <CohortIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Cohorte</PositionText>
        </NavigationBarLink>
      </li>
      <Title
        navigationTitle
        marginLeft={isMenuOpen ? '16px' : '13px'}
        fontSize="16px"
      >
        {isMenuOpen ? 'ADMINISTRARE' : 'ADMIN'}
      </Title>
      <li className="side-nav-item">
        <NavigationBarLink
          activeClassName="activeNavItem"
          to={`/accelerator/${cohortId}/teams`}
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
        >
          <MyIdeeasIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Echipe</PositionText>
        </NavigationBarLink>
      </li>
      <li className="side-nav-item">
        <NavigationBarLink
          activeClassName="activeNavItem"
          to={`/accelerator/${cohortId}/leaderboard`}
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
        >
          <LeaderBoardIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Clasament</PositionText>
        </NavigationBarLink>
      </li>
    </>
  );
};

const mapStateToProps = (state) => ({
  cohortId: currentCohortIdSelector(state.cohorts),
  userCohorts: state.cohorts.cohorts,
  userProfile: get(state.userProfile, 'userProfile'),
  role: get(state.account.login, 'user.role'),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  push,
  setCurrentUserCohort,
  resetUserCohorts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceleratorNavigationMenu);
