import React, { useMemo, useState } from 'react';
import { map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';

import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  LinkTable,
} from '../../../common/components/styled';
import { Export, SortTableHead } from '../../../common/components';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const LeaderboardPage = (props) => {
  const { teams, isAccelerator = false, cohortId, push } = props;
  const [sortedTeams, setSortedTeams] = useState(teams);

  const getDownloadData = useMemo(
    () =>
      map(sortedTeams, (team, index) => ({
        '#': index + 1,
        Nume: team.name,
        IRL: team.irl,
        Ipoteze: team.numberOfHypotheses,
        'Ipoteze validate': team.validatedHypotheses,
        'Ipoteze invalidate': team.invalidatedHypotheses,
        Experimente: team.numberOfExperiments,
        'Poziție în platformă': team.platformPosition,
      })),
    [sortedTeams]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Clasament echipe platformă</Title>
          <CounterDiv>
            <CounterText>{size(sortedTeams)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <Export data={getDownloadData} filename="clasament.csv" />
      </TitleButtonsDiv>

      <SortTableHead
        array={sortedTeams}
        setArray={setSortedTeams}
        cellSortKeys={[
          null,
          ['name'],
          ['irl'],
          ['numberOfHypotheses'],
          ['validatedHypotheses'],
          ['invalidatedHypotheses'],
          ['numberOfExperiments'],
          ['cohortPosition'],
          ['platformPosition'],
        ]}
      >
        <TableHeadCell alignLeft cellWidth="4%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="30%">
          <Heading2>Nume</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="6%">
          <Heading2>IRL</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2>Ipoteze</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>
            Ipoteze
            <br />
            validate
          </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>
            Ipoteze <br /> invalidate
          </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>Experimente</Heading2>
        </TableHeadCell>
        {isAccelerator && (
          <TableHeadCell cellWidth="10%">
            <Heading2 center>Poziție în cohortă</Heading2>
          </TableHeadCell>
        )}
        <TableHeadCell cellWidth="10%">
          <Heading2 center>Poziție în platformă</Heading2>
        </TableHeadCell>
      </SortTableHead>
      {map(sortedTeams, (team, index) => (
        <TableRow key={index}>
          <TableBodyCell alignLeft cellWidth="4%">
            <Heading4Regular>{index + 1}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignLeft cellWidth="30%">
            {!isAccelerator ? (
              <Heading4Regular twoRows>{team.name}</Heading4Regular>
            ) : (
              <LinkTable
                onClick={() =>
                  push(`/accelerator/${cohortId}/teams/${team._id}/canvas`)
                }
              >
                {team.name}
              </LinkTable>
            )}
          </TableBodyCell>
          <TableBodyCell cellWidth="6%">
            <Heading4Regular>{team.irl}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="10%">
            <Heading4Regular>{team.numberOfHypotheses}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="10%">
            <Heading4Regular> {team.validatedHypotheses}</Heading4Regular>
          </TableBodyCell>

          <TableBodyCell cellWidth="10%">
            <Heading4Regular>{team.invalidatedHypotheses}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="10%">
            <Heading4Regular>{team.numberOfExperiments}</Heading4Regular>
          </TableBodyCell>
          {isAccelerator && (
            <TableBodyCell cellWidth="10%">
              <Heading4Regular>{team.cohortPosition}</Heading4Regular>
            </TableBodyCell>
          )}
          <TableBodyCell cellWidth="10%">
            <Heading4Regular> {team.platformPosition}</Heading4Regular>
          </TableBodyCell>
        </TableRow>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  teams: state.teams.teams,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeaderboardPage)
);
