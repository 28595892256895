/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import { map, get } from 'lodash-es';
import { Popover } from 'react-bootstrap';
import { CircleIrl, WhiteCard, Heading1, Heading4Regular } from './styled';

import irl12image from '../assets/img/irl12.png';
import irl11image from '../assets/img/irl11.png';
import irl10image from '../assets/img/irl10.png';
import irl9image from '../assets/img/irl9.png';
import irl7image from '../assets/img/irl7.png';
import irl6image from '../assets/img/irl6.png';
import irl4image from '../assets/img/irl4.png';
import irl3image from '../assets/img/irl3.png';
import irl2image from '../assets/img/irl2.png';
import irl1image from '../assets/img/irl1.png';

export const thermometerLabels = [
  {
    label: 'Pitch deck',
    number: 14,
    tooltipTitle: 'IRL 15. Prezentarea afacerii pentru potențiali investitori',
    tooltipContents: `    Fie că dorești să obții o finanțare privată sau nu, prezentarea afacerii este un exercițiu bun pentru a spune povestea ideii voastre, cât mai succint și convingător posibil. Lăsați-vă ghidați de structura BMC pentru a crea această prezentare și încorporați toate ipotezele, descoperirile, concluziile și deciziile pe care le-ați luat. Încercați să va încadrați în 10 minute dacă ar trebui să o prezentați în fata unui grup de investitori. Un alt termen sub care mai regăsiți acest concept este cel de "pitch deck".\n
    Treceți la următorul nivel IRL dacă există un link (Google Drive, One Drive etc) către o prezentare a ideii voastre de afaceri.\n
    Descoperă mai multe informații despre acest subiect de lucru în secțiunile Ajutor și Resurse.`,
    tooltipImage: undefined,
  },
  {
    label: 'Strategie intrare pe piață',
    number: 13,
    tooltipTitle: 'IRL 14. Strategia de intrare pe piață-(CS+VP+CH+CR+RS)',
    tooltipContents: `    Demonstrați că aveți un plan detaliat și bine articulat de intrare pe piață din care să rezulte modul eficient de alocare al resurselor pentru câștigarea rapidă a cotelor de piață. Arătați modul în care împachetați soluția voastră pentru a răspunde cât mai bine nevoilor și așteptărilor specifice fiecărui segment de piață. Alți termeni sub care mai regăsiți acest concept sunt: plan de marketing, mix de marketing sau "go to market strategy".\n
    Treceți la următorul nivel IRL dacă există un link (Google Drive, One Drive etc) către un document în care descrieți concret, succint și foarte specific planul de marketing al afacerii.\n
    Descoperă mai multe informații despre acest subiect de lucru în secțiunile Ajutor și Resurse.`,
    tooltipImage: undefined,
  },
  {
    label: 'Indicatorii ideii',
    number: 12,
    tooltipTitle: 'IRL 13. Indicatorii cei mai importanți ai ideii',
    tooltipContents: `   Echipa voastră trebuie să demonstreze că înțelege foarte bine afacerea pe care urmează să o dezvolte și indicatorii care evidențiază într-o manieră obiectivă performanța deciziilor luate. Asigurați-vă că evitați monitorizarea indicatorilor superficiali, care vă pot da un sentiment fals de siguranță.\n
    Treceți la următorul nivel IRL dacă aveți menționat cel puțin un indicator pentru mai mult de 50% din ipotezele validate și un link (Google Drive, One Drive etc) către un document (Excel sau alt format) în care argumentați succint alegerea indicatorilor respectivi, valorile țintă și modul de calcul. Găsiți toți indicatorii în meniul principal secțiunea "Indicatori"\n
    Descoperă mai multe informații despre acest subiect de lucru în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl12image,
  },
  {
    label: 'Fezabilitatea ideii',
    number: 11,
    tooltipTitle: 'IRL 12. Fezabilitatea ideii -(CS)',
    tooltipContents: `    Capacitatea echipei și ideii voastre de a genera profituri demonstrează cu adevărat că veți construi o afacere durabilă. Acest lucru presupune să anticipați cele mai importate cheltuieli ale afacerii voastre. Ia în calcul faptul că aceasta este secțiunea de are cei mai mult investitori sunt interesați când decid ideile de afaceri în care investesc. Ca regulă generală e mai bine să fiți pesimiști și să vă depășiți așteptările, decât foarte optimiști și să vă treziți în pericol de blocaj financiar.\n
    Prezentați dovezi, date și informații concrete că ideea voastră este fezabilă și poate genera venituri mai mari decât cheltuielile. Încarcă cash flow-ul estimat al ideii voastre.\n
    Treceți la următorul nivel IRL dacă există un link (Google Drive, One Drive etc) către un cash flow (CF) sau buget de venituri și cheltuieli (BVC) care demonstrează că ideea voastră este profitabilă.\n
    Descoperă mai multe informații despre acest subiect și șabloane de lucru în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl11image,
  },
  {
    label: 'Viabilitatea ideii',
    number: 10,
    tooltipTitle: 'IRL 11. Viabilitatea ideii -(KA+KR+KP)',
    tooltipContents: `    Până în acest moment ați demonstrat că există o piață relevantă interesată de soluția voastră, iar acum trebuie să vă gândiți la detaliile logistice din "bucătăria internă" care sunt esențiale pentru livrarea soluției: activitățile recurente cele mai importante, resursele cele mai importante necesare pentru desfășurarea activităților și partenerii strategici cu care vă puteți asocia pentru accelerarea intrării pe piață. \n
    Testează ipotezele din partea dreaptă a BMC: Key Activities" (KA), "Key Resources" (KR) și "Key Partners" (KP).\n
    Treceți la următorul nivel IRL dacă cel puțin 50% din ipotezele KP, KR, KA sunt validate și demonstrați că ideea voastră poate să fie implementată.\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl10image,
  },
  {
    label: 'Modelul de business',
    number: 9,
    tooltipTitle: 'IRL 10. Validarea modelului de business -(CS+VP+RS)',
    tooltipContents: `    Modelul de business reprezintă strategia principală de a genera profituri din vânzarea soluției voastre către segmentele de clienți identificate. Modelul de business este un concept dinamic care poate evolua de mai multe ori pe durata existenței afacerii.\n
    Prezentați dovezi, date și informații concrete culese prin unul sau mai multe “experimente” că modelul de business (beneficiile oferite clienților pot să genereze tipurile de venituri dorite) este acceptat de clienți. Demonstrează că aveți clienți potențiali (CS) interesați să cumpere soluția voastră (VP) în una din formele stabilite (RS). Testează ipotezele din "Revenue Streams" (RS).\n
    Treceți la următorul nivel IRL dacă cel puțin o ipoteză din "Revenue Streams" (RS) este validată de unul sau mai multe experimente și există cel puțin un detaliu legat de interesul unui client potențial pentru soluție asociat experiment.\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl9image,
  },
  {
    label: 'Prototipul rafinat',
    number: 8,
    tooltipTitle: 'IRL 9. Prototip rafinat (VP)³',
    tooltipContents: `    Rafinează prototipul soluției voastre incorporând datele și informațiile care l-ați obținut până acum direct de la clienți. Aceasta ar trebui să fie varianta soluției cu care vei ieși în piață. Nu vă faceți griji dacă nu este perfectă, va fi timp mai târziu pentru îmbunătățiri suplimentare. Referire la prototip rafinat se mai face și prin termenul de "High Fidelity MVP". Încarcă linkul către prezentarea sau video prototipului îmbunătățit al soluției tale.\n
    Treceți la următorul nivel IRL dacă există un link (Google Drive, One Drive etc) către varianta rafinată a prototipului tău cu care cel mai probabil vei ieși în piață.\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: undefined,
  },
  {
    label: 'Achiziția clienților',
    number: 7,
    tooltipTitle:
      'IRL 8. Canalele de achiziție și comunicare cu clienții -(CH+CR)',
    tooltipContents: `    Alege care sunt canalele (CH) prin care veți asigura schimbul dintre soluția voastră și banii clienților. Acestea pot să fie canale de distribuție fizice sau virtuale, în funcție de tipul soluției voastre. Apoi decideți care sunt canalele de comunicare (CR) și metodele folosite pentru a atrage clienții în aceste canale, păstrarea acestora cât mai mult timp alături de afacere și dezvoltarea relației cu aceștia pentru achiziții repetate și mai valoroase. Nu fiți surprinși dacă constatați că pentru fiecare segment de piață va trebui să aveți un alt mix de tactici și activități.\n
    Prezentați dovezi, date și informații concrete culese prin unul sau mai multe “experimente” că ipotezele voastre legate de canalele de distribuție și comunicare sunt potrivite segmentelor de clienți identificate. Testați ipotezele din "Cahnnels" (CH) si "Customer relationships" (CR).\n
    Treceți la următorul nivel IRL dacă cel puțin 50% din ipotezele secțiunilor "Channels" și "Customer Relationships" sunt validate prin unul sau mai multe "experimente".\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl7image,
  },
  {
    label: 'Product market fit',
    number: 6,
    tooltipTitle: 'IRL 7. Validarea prototipului în piață-(CS+VP)²',
    tooltipContents: `   Este momentul să puneți în mâinile clienților prototipul vostru. Până în această etapă ați descoperit că pentru fiecare segment de piață identificat trebuie să aveți un set specific de beneficii (VP). Acum e momentul să validați cu ajutorul prototipului creat în pasul anterior că satisfaceți nevoile segmentului de piață vizat și că există interes pentru cumpărarea soluției voastre. Validarea satisfacerii nevoilor segmentelor de piață cu ajutorul unui prototip se mai numește și "product market fit".\n
    Prezentați dovezi, date și informații concrete culese prin unul sau mai multe “experimente” că funcționalitățile prototipului vostru satisfac nevoile segmentului de piață vizat.\n
    Treceți la următorul nivel IRL dacă există cel puțin o ipoteză VP validată prin unul sau mai multe experimente care au avut la bază un prototip (MVP).\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl6image,
  },
  {
    label: 'Prototipul brut',
    number: 5,
    tooltipTitle: 'IRL 6. Construirea unui prototip brut -(VP)²',
    tooltipContents: `    Prototipul brut reprezintă cea mai ieftină, rapidă și directă metodă de a testa gradul în care soluția voastră satisface așteptările și nevoile segmentelor de piață vizate. Realizează un prototip brut al soluției voastre. Referire la prototip brut se mai face și prin termenul de "Low Fidelity MVP".\n
    Treceți la următorul nivel IRL dacă există un link (Google Drive, One Drive etc) către prezentarea prototipului tău (schițe, desene, "story boards", "mock-ups", "wireframes", materiale video, broșuri, instrucțiuni de utilizare etc).\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: undefined,
  },
  {
    label: 'Problem to solution fit',
    number: 4,
    tooltipTitle: 'IRL 5. Validarea probleme în piață-(CS+VP)¹',
    tooltipContents: `    Unul din principalele motive pentru care startupurile falimentează se datorează faptului că aduc în piață o soluție pe care nu o vrea nimeni sau foarte puțini clienți (piață este prea mică). Asigurați-vă că ați identificat cel puțin o nevoie a unui segment de piață (CS) pe care o puteți satisface cu una sau mai multe din beneficiile soluției tale (VP). Validarea nevoilor clienților (CS) și realizarea unei corespondențe a acestora cu beneficiile soluției voastre (VP) se mai numește și "problem solution fit".\n
    Prezentați dovezi, date și informații concrete, culese în urma derulării mai multor "experimente", că există cel puțin o nevoie comună în segmentul de piață vizat pe care o puteți să le satisfaceți cu soluția voastră.\n
    Treceți la următorul nivel IRL dacă există cel puțin o ipoteză VP validată prin unul sau mai multe experimente desfășurate fără sau cu prototip (MVP).\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl4image,
  },
  {
    label: 'Dimensiunea pieței țintă',
    number: 3,
    tooltipTitle: 'IRL 4. Dimensiunea estimată a pieței țintă-(CS)',
    tooltipContents: `    Dacă soluția voastră se adresează unei piețe prea mici, atunci sunt șanse mari să nu rezistați foarte mult în piață. Este esențial să actualizați informațiile și să căutați continuu cele mai bune piețe pentru soluția voastră. Prezentați și descrieți sintetizat și cât mai concret segmentele/micro-segmentele vizate de soluția voastră. În descrierea segmentelor pune accentul pe aspecte de natură geografică, demografică, "comportamentală. Estimați o valoare pentru fiecare segment și explicați modul în care ați ajuns la acea valoare.\n
    Treceți la următorul nivel IRL dacă există un link (Google Drive, One Drive etc) către o analiză de piață.\n
    Descoperă mai multe informații despre acest subiect în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl3image,
  },
  {
    label: 'Analiza de oportunitate',
    number: 2,
    tooltipTitle: 'IRL 3. Analiza de oportunitate',
    tooltipContents: `   În jurul ideii voastre există o multitudine de factori în afara controlului care pot să influențeze decisiv idea. Explicați și analizați ideea în raport cu o serie de factori, precum trendurile pieței (1), industria (2) și competitorii (3). Completați integral cele trei secțiuni ale analizei de oportunitate (AO). Recomandăm să folosești cele "5 forțe ale lui Porter" pentru completarea acestei secțiuni.\n
    Treceți la următorul nivel IRL dacă există informații relevante în fiecare din cele 3 secțiuni ale AO.\n
    Vezi recomandările noastre legate de acest nivel IRL și instrument în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl2image,
  },
  {
    label: 'BMC v1.0',
    number: 1,
    tooltipTitle: 'IRL 2. BMC v1.0',
    tooltipContents: `     Business Model Canvas (BMC) este un instrument de lucru vizual și dinamic dezvoltat de Alexander Osterwalder care ajută la dezvoltarea ideilor de afaceri sau descoperirea modelului optim de business. BMC este un instrument care este recomandat a fi utilizat înaintea completării planului de afaceri și nu în locul acestuia.\n
    Completați toate secțiunile Business Model Canvas (BMC) cu cel puțin câte o ipoteză în următoarea ordine: Customer Segments (CS), Value Propositions (VP), Channels (CH), Customer Relationships (CR), Revenue Streams (RS), Key Activities (KA), Key Resources (KR), Key Partners (KP), Cost Structure (CS).\n
    Treceți la următorul nivel IRL dacă există cel puțin o ipoteză relevantă în fiecare secțiune din BMC.\n
    Vezi recomandările noastre legate de acest nivel IRL și instrument în secțiunile Ajutor și Resurse.`,
    tooltipImage: irl1image,
  },
  {
    label: 'Echipa',
    number: 0,
    tooltipTitle: 'IRL 1. Echipa din spatele ideii',
    tooltipContents: `   Echipa este cel mai important criteriu care determină succesul unei inițiative antreprenoriale. Experiență noastră ne spune că echipa ideala este formată din 3 membrii: inginerul, finanțistul și vânzătorul. Completează profilul tău personal cu datele solicitate și încarcă link-ul spre un video de prezentare a întregii echipe de maxim 5 minute în secțiunea "Video prezentare echipă". Explică de ce aceasta este echipa ideală pentru dezvoltarea ideii, ce abilități și experiențe valoroase pentru proiect pe care le aveți fiecare dintre voi.\n
    Treceți la următorul nivel IRL dacă există un link (YouTube, Google Drive, One Drive etc) către prezentarea echipei.\n
    Vezi recomandările noastre legate de acest nivel IRL în secțiunile Ajutor și Resurse.\n`,
    tooltipImage: undefined,
  },
];

const RenderPopover = ({ tooltipTitle, tooltipContents, clientWidth }) => (
  <Popover id="popover-basic" style={{ left: `calc(${50}% + 30px)` }}>
    <WhiteCard
      width={`${
        window.innerWidth > 1440
          ? 374
          : Math.min(374, Math.round((clientWidth - 30) / 2))
      }px`}
      height="650px"
    >
      <Heading1 marginBottom="15px">{tooltipTitle}</Heading1>
      <Heading4Regular style={{ whiteSpace: 'pre-wrap' }} noEllipsis>
        {tooltipContents}
      </Heading4Regular>
    </WhiteCard>
  </Popover>
);

const IRLThermometer = ({ irlLevels }) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tooltipData, setTooltipData] = useState({
    tooltipTitle: thermometerLabels[0].tooltipTitle,
    tooltipContents: thermometerLabels[0].tooltipContents,
  });

  const meterRef = useRef(null);
  const tgThermometerRef = useRef(null);

  const handleClick = (event, index, tooltipTitle, tooltipContents) => {
    event.stopPropagation();
    setSelectedIndex(index);
    setTooltipData({
      tooltipTitle,
      tooltipContents,
    });
  };

  return (
    <div
      className="tg-thermometer"
      style={{ height: '100%' }}
      ref={tgThermometerRef}
    >
      <RenderPopover
        {...tooltipData}
        width={get(tgThermometerRef, 'current.innerWidth')}
      />

      <div className="meter" ref={meterRef}>
        <div className="statistics">
          {map(
            thermometerLabels,
            (
              { label, tooltipTitle, tooltipContents, tooltipImage, number },
              index
            ) => (
              <div key={index}>
                <div
                  className={`percent percent-${index + 1} ${
                    get(irlLevels, `irl_${number}.isActive`, false) && 'passed'
                  } `}
                  onClick={(e) =>
                    handleClick(
                      e,
                      index,
                      tooltipTitle,
                      tooltipContents,
                      tooltipImage
                    )
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      top: '-7px',
                      left: '-205px',
                      width: '200px',
                      justifyContent: 'space-between',
                    }}
                  >
                    {`${number + 1}. ${label}`}
                    <CircleIrl
                      passed={get(irlLevels, `irl_${number}.isActive`, false)}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default IRLThermometer;
