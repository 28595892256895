import React, { useEffect, useState } from 'react';
import { get, map, size, find, filter, reverse, some, uniq } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { getBMCData, loadHypotheses } from '../../hypotheses/ducks';
import { openModal, currentTeamIdSelector } from '../../common/ducks';

import { isMentorSelector, isAcceleratorSelector } from '../../account/ducks';
import {
  AddCompetitionModal,
  AddIndustryModal,
  AddTrendsModal,
} from '../../opportunityAnalysis/components/modals';
import {
  TitleButtonsDiv,
  DivBorderBottom,
  IdeeaDescription,
} from '../../mentors/components/styled';

import { CommentRenderComponent } from '../../common/components';
import { AddCommentModal } from '../../common/components/modals';
import {
  deleteOpportunityAnalysisComment,
  loadOpportunityAnalysis,
  editOpportunityAnalysisComment,
  addOpportunityAnalysisComment,
  editOpportunityAnalysis,
  addOpportunityAnalysisReply,
  editOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply,
} from '../../opportunityAnalysis/ducks';
import HypothesisCard from './HypothesisCard';

import {
  BusinessModelCanvasContainer,
  BusinessModelCanvasRow,
  BusinessModelCanvasColumn,
  NoResultsFound,
  HypothesisCardsContainer,
} from './styled';
import {
  Heading2,
  Heading1,
  CounterDiv,
  CounterText,
  Heading4Regular,
  Heading3Semibold,
  ColDiv,
  Title,
  Loading,
  AddCommentButton,
  RowDiv,
  WhiteCard,
  EditButtonSmall,
  TitleCounterDiv,
  TextInactive,
  Link,
} from '../../common/components/styled';
import { sizeComments } from '../../common/functions';

const BusinessModelCanvasPage = (props) => {
  const {
    isLoading,
    isEditing,
    isCreating,
    canvasData,
    openModal,
    isMentor,
    teamId,
    loadHypotheses,
    isLoadingOpportunity,
    opportunityAnalysis,
    deleteOpportunityAnalysisComment,
    editOpportunityAnalysisComment,
    addOpportunityAnalysisComment,
    loadOpportunityAnalysis,
    isAccelerator,
  } = props;

  const [isTrendsModalOpen, setIsTrendsModalOpen] = useState(false);
  const [isCompetitionModalOpen, setIsCompetitionModalOpen] = useState(false);
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const [isEditModeComment, setIsEditModeComment] = useState(false);
  const [comment, setComment] = useState(undefined);

  const handleHookSet = (type) => {
    switch (type) {
      case 'trends':
        setIsTrendsModalOpen(true);
        break;
      case 'competition':
        setIsCompetitionModalOpen(true);
        break;

      case 'industry':
        setIsIndustryModalOpen(true);
        break;
      case 'comment':
        setIsCommentModalOpen(true);
        break;

      default:
        setIsCompetitionModalOpen(false);
        setIsIndustryModalOpen(false);
        setIsTrendsModalOpen(false);
        setIsCommentModalOpen(false);
    }
  };

  const handleOpenEditCommentModal = (type, commentId) => {
    handleHookSet(type);
    setComment(commentId);
    setIsEditModeComment(true);
  };

  const handleDeleteComment = async (commentId) => {
    await deleteOpportunityAnalysisComment(commentId);
    await loadOpportunityAnalysis(teamId);
  };

  const sortedHypotheses = (array) => {
    const sortedArray = [];
    let intermediateArray = [];

    const inProgress = reverse(
      filter(array, (el) => !size(el.resolutions) > 0),
      'createdAt'
    );

    intermediateArray.push(inProgress);
    intermediateArray = intermediateArray.flat();

    const invalidated = filter(
      array,
      (el) =>
        some(
          el.resolutions,
          (resolution) => resolution.outcome === 'invalidatedWithoutMVP'
        ) && !find(intermediateArray, { _id: el._id })
    );

    intermediateArray.push(invalidated);
    intermediateArray = intermediateArray.flat();

    const invalidatedWithMVP = filter(
      array,
      (el) =>
        some(
          el.resolutions,
          (resolution) => resolution.outcome === 'invalidatedWithMVP'
        ) && !find(intermediateArray, { _id: el._id })
    );

    intermediateArray.push(invalidatedWithMVP);
    intermediateArray = intermediateArray.flat();

    const validatedWithMVP = filter(
      array,
      (el) =>
        some(
          el.resolutions,
          (resolution) => resolution.outcome === 'validatedWithMVP'
        ) && !find(intermediateArray, { _id: el._id })
    );
    intermediateArray.push(validatedWithMVP);
    intermediateArray = intermediateArray.flat();

    const validated = filter(
      array,
      (el) =>
        some(
          el.resolutions,
          (resolution) => resolution.outcome === 'validatedWithoutMVP'
        ) && !find(intermediateArray, { _id: el._id })
    );

    sortedArray.push(inProgress);
    sortedArray.push(validated);
    sortedArray.push(validatedWithMVP);
    sortedArray.push(invalidated);
    sortedArray.push(invalidatedWithMVP);
    return uniq(sortedArray.flat());
  };

  useEffect(() => {
    if (teamId) {
      Promise.all([loadHypotheses(teamId), loadOpportunityAnalysis(teamId)]);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses, loadOpportunityAnalysis]);

  const getColor = (segmentId) =>
    map(segmentId, (segment) =>
      get(find(get(canvasData, 'customerSegments'), { _id: segment }), 'color')
    );

  const openHypothesisModal = (details) => {
    openModal('hypothesis', details);
  };

  return (
    <>
      <TitleButtonsDiv marginBottom="38px">
        <Title>Business Model Canvas</Title>
      </TitleButtonsDiv>
      <DivBorderBottom paddingBottom="15px" marginBottom="30px">
        <BusinessModelCanvasContainer>
          <BusinessModelCanvasRow borderBottom>
            <BusinessModelCanvasColumn firstRow>
              <TitleButtonsDiv marginBottom="15px">
                <Heading2>Key Partners</Heading2>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton
                    onClick={() =>
                      openHypothesisModal({
                        initialValues: {
                          columnBMC: 'keyPartners',
                        },
                      })
                    }
                  />
                )}
              </TitleButtonsDiv>

              {!size(get(canvasData, 'keyPartners')) ? (
                <NoResultsFound>Nu există ipoteze</NoResultsFound>
              ) : (
                <HypothesisCardsContainer className="scrollbar">
                  {map(
                    sortedHypotheses(get(canvasData, 'keyPartners')),
                    (hypothesis) => (
                      <HypothesisCard
                        key={hypothesis._id}
                        hypothesis={hypothesis}
                        canvasData={canvasData}
                        backgroundColor={getColor(get(hypothesis, 'segment'))}
                      />
                    )
                  )}
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn borderLeft firstRow>
              <BusinessModelCanvasColumn
                borderBottom
                noPadding
                multipleChildren
              >
                <TitleButtonsDiv marginBottom="15px">
                  <Heading2>Key Activities</Heading2>
                  {!isMentor && !isAccelerator && (
                    <AddCommentButton
                      onClick={() =>
                        openHypothesisModal({
                          initialValues: {
                            columnBMC: 'keyActivities',
                          },
                        })
                      }
                    />
                  )}
                </TitleButtonsDiv>
                {!size(get(canvasData, 'keyActivities')) ? (
                  <NoResultsFound>Nu există ipoteze</NoResultsFound>
                ) : (
                  <HypothesisCardsContainer className="scrollbar">
                    {map(
                      sortedHypotheses(get(canvasData, 'keyActivities')),
                      (hypothesis) => (
                        <HypothesisCard
                          key={hypothesis._id}
                          hypothesis={hypothesis}
                          canvasData={canvasData}
                          backgroundColor={getColor(get(hypothesis, 'segment'))}
                        />
                      )
                    )}
                  </HypothesisCardsContainer>
                )}
              </BusinessModelCanvasColumn>

              <BusinessModelCanvasColumn noPadding multipleChildren>
                <TitleButtonsDiv marginBottom="15px">
                  <Heading2>Key Resources</Heading2>
                  {!isMentor && !isAccelerator && (
                    <AddCommentButton
                      onClick={() =>
                        openHypothesisModal({
                          initialValues: {
                            columnBMC: 'keyResources',
                          },
                        })
                      }
                    />
                  )}
                </TitleButtonsDiv>
                {!size(get(canvasData, 'keyResources')) ? (
                  <NoResultsFound>Nu există ipoteze</NoResultsFound>
                ) : (
                  <HypothesisCardsContainer className="scrollbar">
                    {map(
                      sortedHypotheses(get(canvasData, 'keyResources')),
                      (hypothesis) => (
                        <HypothesisCard
                          key={hypothesis._id}
                          hypothesis={hypothesis}
                          canvasData={canvasData}
                          backgroundColor={getColor(get(hypothesis, 'segment'))}
                        />
                      )
                    )}
                  </HypothesisCardsContainer>
                )}
              </BusinessModelCanvasColumn>
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn borderLeft firstRow>
              <TitleButtonsDiv marginBottom="15px">
                <Heading2>Value Propositions</Heading2>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton
                    onClick={() =>
                      openHypothesisModal({
                        initialValues: {
                          columnBMC: 'valuePropositions',
                        },
                      })
                    }
                  />
                )}
              </TitleButtonsDiv>
              {!size(get(canvasData, 'valuePropositions')) ? (
                <NoResultsFound>Nu există ipoteze</NoResultsFound>
              ) : (
                <HypothesisCardsContainer className="scrollbar">
                  {map(
                    sortedHypotheses(get(canvasData, 'valuePropositions')),
                    (hypothesis) => (
                      <HypothesisCard
                        key={hypothesis._id}
                        hypothesis={hypothesis}
                        canvasData={canvasData}
                        backgroundColor={getColor(get(hypothesis, 'segment'))}
                      />
                    )
                  )}
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn borderLeft firstRow>
              <BusinessModelCanvasColumn
                borderBottom
                noPadding
                multipleChildren
              >
                <TitleButtonsDiv marginBottom="15px">
                  <Heading2>Customer Relationships</Heading2>
                  {!isMentor && !isAccelerator && (
                    <AddCommentButton
                      onClick={() =>
                        openHypothesisModal({
                          initialValues: {
                            columnBMC: 'customerRelationships',
                          },
                        })
                      }
                    />
                  )}
                </TitleButtonsDiv>
                {!size(get(canvasData, 'customerRelationships')) ? (
                  <NoResultsFound>Nu există ipoteze</NoResultsFound>
                ) : (
                  <HypothesisCardsContainer className="scrollbar">
                    {map(
                      sortedHypotheses(
                        get(canvasData, 'customerRelationships')
                      ),
                      (hypothesis) => (
                        <HypothesisCard
                          key={hypothesis._id}
                          hypothesis={hypothesis}
                          canvasData={canvasData}
                          backgroundColor={getColor(get(hypothesis, 'segment'))}
                        />
                      )
                    )}
                  </HypothesisCardsContainer>
                )}
              </BusinessModelCanvasColumn>

              <BusinessModelCanvasColumn noPadding multipleChildren>
                <TitleButtonsDiv marginBottom="15px">
                  <Heading2>Channels</Heading2>
                  {!isMentor && !isAccelerator && (
                    <AddCommentButton
                      onClick={() =>
                        openHypothesisModal({
                          initialValues: {
                            columnBMC: 'channels',
                          },
                        })
                      }
                    />
                  )}
                </TitleButtonsDiv>
                {!size(get(canvasData, 'channels')) ? (
                  <NoResultsFound>Nu există ipoteze</NoResultsFound>
                ) : (
                  <HypothesisCardsContainer className="scrollbar">
                    {map(
                      sortedHypotheses(get(canvasData, 'channels')),
                      (hypothesis) => (
                        <HypothesisCard
                          key={hypothesis._id}
                          hypothesis={hypothesis}
                          canvasData={canvasData}
                          backgroundColor={getColor(get(hypothesis, 'segment'))}
                        />
                      )
                    )}
                  </HypothesisCardsContainer>
                )}
              </BusinessModelCanvasColumn>
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn borderLeft firstRow>
              <TitleButtonsDiv marginBottom="15px">
                <Heading2>Customer Segments</Heading2>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton
                    onClick={() =>
                      openHypothesisModal({
                        initialValues: {
                          columnBMC: 'customerSegments',
                        },
                      })
                    }
                  />
                )}
              </TitleButtonsDiv>
              {!size(get(canvasData, 'customerSegments')) ? (
                <NoResultsFound>Nu există ipoteze</NoResultsFound>
              ) : (
                <HypothesisCardsContainer className="scrollbar">
                  {map(
                    sortedHypotheses(get(canvasData, 'customerSegments')),
                    (hypothesis) => (
                      <HypothesisCard
                        customerSegment
                        key={hypothesis._id}
                        hypothesis={hypothesis}
                        canvasData={canvasData}
                        backgroundColor={hypothesis.color}
                      />
                    )
                  )}
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
          </BusinessModelCanvasRow>
          <BusinessModelCanvasRow>
            <BusinessModelCanvasColumn lastRow>
              <TitleButtonsDiv marginBottom="15px">
                <Heading2>Cost Structure</Heading2>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton
                    onClick={() =>
                      openHypothesisModal({
                        initialValues: {
                          columnBMC: 'costStructure',
                        },
                      })
                    }
                  />
                )}
              </TitleButtonsDiv>
              {!size(get(canvasData, 'costStructure')) ? (
                <NoResultsFound>Nu există ipoteze</NoResultsFound>
              ) : (
                <HypothesisCardsContainer className="scrollbar">
                  <Row>
                    <Col md="6">
                      {map(
                        sortedHypotheses(get(canvasData, 'costStructure')),
                        (hypothesis, index) =>
                          index % 2 === 0 && (
                            <HypothesisCard
                              key={index}
                              hypothesis={hypothesis}
                              canvasData={canvasData}
                              backgroundColor={getColor(
                                get(hypothesis, 'segment')
                              )}
                            />
                          )
                      )}
                    </Col>
                    <Col md="6">
                      {map(
                        sortedHypotheses(get(canvasData, 'costStructure')),
                        (hypothesis, index) =>
                          Math.abs(index % 2) === 1 && (
                            <HypothesisCard
                              floatRight
                              key={index}
                              hypothesis={hypothesis}
                              canvasData={canvasData}
                              backgroundColor={getColor(
                                get(hypothesis, 'segment')
                              )}
                            />
                          )
                      )}
                    </Col>
                  </Row>
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn lastRow borderLeft>
              <TitleButtonsDiv marginBottom="15px">
                <Heading2>Revenue Streams</Heading2>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton
                    onClick={() =>
                      openHypothesisModal({
                        initialValues: {
                          columnBMC: 'revenueStreams',
                        },
                      })
                    }
                  />
                )}
              </TitleButtonsDiv>
              {!size(get(canvasData, 'revenueStreams')) ? (
                <NoResultsFound>Nu există ipoteze</NoResultsFound>
              ) : (
                <HypothesisCardsContainer className="scrollbar">
                  <Row>
                    <Col md="6">
                      {map(
                        sortedHypotheses(get(canvasData, 'revenueStreams')),
                        (hypothesis, index) =>
                          index % 2 === 0 && (
                            <HypothesisCard
                              key={index}
                              hypothesis={hypothesis}
                              canvasData={canvasData}
                              backgroundColor={getColor(
                                get(hypothesis, 'segment')
                              )}
                            />
                          )
                      )}
                    </Col>
                    <Col md="6">
                      {map(
                        sortedHypotheses(get(canvasData, 'revenueStreams')),
                        (hypothesis, index) =>
                          Math.abs(index % 2) === 1 && (
                            <HypothesisCard
                              floatRight
                              key={index}
                              hypothesis={hypothesis}
                              canvasData={canvasData}
                              backgroundColor={getColor(
                                get(hypothesis, 'segment')
                              )}
                            />
                          )
                      )}
                    </Col>
                  </Row>
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
          </BusinessModelCanvasRow>
        </BusinessModelCanvasContainer>
      </DivBorderBottom>
      <DivBorderBottom paddingBottom="30px" marginBottom="30px">
        <TitleButtonsDiv marginBottom="25px">
          <Title>Analiza de oportunitate</Title>
        </TitleButtonsDiv>
        <RowDiv>
          <ColDiv width="32%">
            <TitleButtonsDiv marginBottom="13px">
              <Heading2>Trenduri</Heading2>
              {!isMentor && !isAccelerator && (
                <EditButtonSmall onClick={() => handleHookSet('trends')} />
              )}
            </TitleButtonsDiv>

            <WhiteCard height="420px">
              <Heading3Semibold>Concluzie</Heading3Semibold>
              <Heading4Regular noEllipsis marginBottom="10px">
                {get(opportunityAnalysis, 'trends.conclusion')}
              </Heading4Regular>

              <Heading3Semibold>Descriere</Heading3Semibold>
              <IdeeaDescription height="193px" className="scrollbar-modal">
                <Heading4Regular noEllipsis>
                  {get(opportunityAnalysis, 'trends.description')}
                </Heading4Regular>
              </IdeeaDescription>
              <Heading3Semibold>Link-uri</Heading3Semibold>
              {map(
                get(opportunityAnalysis, 'trends.resources'),
                (link, index) => (
                  <Link
                    style={{ display: 'block' }}
                    key={index}
                    href={link}
                    target="_blank"
                  >
                    {link}
                  </Link>
                )
              )}
            </WhiteCard>
          </ColDiv>

          <ColDiv width="32%">
            <TitleButtonsDiv marginBottom="13px">
              <Heading2>Competiție</Heading2>
              {!isMentor && !isAccelerator && (
                <EditButtonSmall onClick={() => handleHookSet('competition')} />
              )}
            </TitleButtonsDiv>
            <WhiteCard height="420px">
              <Heading3Semibold>Concluzie</Heading3Semibold>
              <Heading4Regular noEllipsis marginBottom="10px">
                {get(opportunityAnalysis, 'competition.conclusion')}
              </Heading4Regular>
              <Heading3Semibold>Descriere</Heading3Semibold>
              <IdeeaDescription height="193px" className="scrollbar-modal">
                <Heading4Regular noEllipsis>
                  {get(opportunityAnalysis, 'competition.description')}
                </Heading4Regular>
              </IdeeaDescription>
              <Heading3Semibold>Link-uri</Heading3Semibold>
              {map(
                get(opportunityAnalysis, 'competition.resources'),
                (link, index) => (
                  <Link
                    style={{ display: 'block' }}
                    key={index}
                    href={link}
                    target="_blank"
                    marginBottom="10px"
                  >
                    {link}
                  </Link>
                )
              )}
            </WhiteCard>
          </ColDiv>
          <ColDiv width="32%">
            <TitleButtonsDiv marginBottom="13px">
              <Heading2>Industrie</Heading2>
              {!isMentor && !isAccelerator && (
                <EditButtonSmall onClick={() => handleHookSet('industry')} />
              )}
            </TitleButtonsDiv>
            <WhiteCard height="420px">
              <Heading3Semibold>Concluzie</Heading3Semibold>
              <Heading4Regular noEllipsis marginBottom="10px">
                {get(opportunityAnalysis, 'industry.conclusion')}
              </Heading4Regular>
              <Heading3Semibold>Descriere</Heading3Semibold>
              <IdeeaDescription height="193px" className="scrollbar-modal">
                <Heading4Regular noEllipsis>
                  {get(opportunityAnalysis, 'industry.description')}
                </Heading4Regular>
              </IdeeaDescription>
              <Heading3Semibold>Link-uri</Heading3Semibold>
              {map(
                get(opportunityAnalysis, 'industry.resources'),
                (link, index) => (
                  <Link
                    style={{ display: 'block' }}
                    key={index}
                    href={link}
                    target="_blank"
                  >
                    {link}
                  </Link>
                )
              )}
            </WhiteCard>
          </ColDiv>
        </RowDiv>
      </DivBorderBottom>
      {!!get(opportunityAnalysis, '_id') && (
        <>
          <TitleButtonsDiv marginBottom="22px">
            <TitleCounterDiv noMargin>
              <Heading1 style={{ marginRight: '0' }}>Comentarii</Heading1>
              <CounterDiv>
                <CounterText>
                  {sizeComments(opportunityAnalysis.comments)}
                </CounterText>
              </CounterDiv>
            </TitleCounterDiv>
            <AddCommentButton onClick={() => handleHookSet('comment')} />
          </TitleButtonsDiv>
          {!size(opportunityAnalysis.comments) > 0 && (
            <TextInactive>
              Momentan nu există niciun comentariu pentru această secțiune. Poți
              adăuga un comentariu făcând click pe butonul „+” din partea
              dreaptă a secțiunii.
            </TextInactive>
          )}
          {map(opportunityAnalysis.comments, (comment, index) => (
            <CommentRenderComponent
              key={index}
              comment={comment}
              handleDeleteComment={handleDeleteComment}
              handleEdit={() => handleOpenEditCommentModal('comment', comment)}
              handleAddReply={props.addOpportunityAnalysisReply}
              handleEditReply={(values, replyId) =>
                props.editOpportunityAnalysisReply(values, comment._id, replyId)
              }
              handleDeleteReply={(replyId) =>
                props.deleteOpportunityAnalysisReply(comment._id, replyId)
              }
              loadData={() => loadOpportunityAnalysis(teamId)}
              isLoading={isEditModeComment ? isEditing : isCreating}
            />
          ))}
        </>
      )}
      <Loading isLoading={isLoadingOpportunity || isLoading} />
      <AddCompetitionModal
        isModalOpen={isCompetitionModalOpen}
        setIsModalOpen={setIsCompetitionModalOpen}
      />
      <AddTrendsModal
        isModalOpen={isTrendsModalOpen}
        setIsModalOpen={setIsTrendsModalOpen}
      />
      <AddIndustryModal
        isModalOpen={isIndustryModalOpen}
        setIsModalOpen={setIsIndustryModalOpen}
      />

      <AddCommentModal
        isModalOpen={isCommentModalOpen}
        setIsModalOpen={setIsCommentModalOpen}
        parentId={get(opportunityAnalysis, '_id')}
        onSubmit={addOpportunityAnalysisComment}
        isLoading={isEditModeComment ? isEditing : isCreating}
        loadData={loadOpportunityAnalysis}
        isEditModeComment={isEditModeComment}
        comment={isEditModeComment ? comment : undefined}
        setIsEditModeComment={setIsEditModeComment}
        editComment={editOpportunityAnalysisComment}
        teamId={teamId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoadingOpportunity: state.opportunityAnalysis.isLoading,
  isEditing: state.opportunityAnalysis.isEditing,
  isCreating: state.opportunityAnalysis.isCreating,
  opportunityAnalysis: state.opportunityAnalysis.opportunityAnalysis,
  isLoading: state.hypotheses.isLoadingHypotheses,
  canvasData: getBMCData(state.hypotheses),
  teamId: currentTeamIdSelector(state.common.userTeams),
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});

const mapDispatchToProps = {
  openModal,
  loadHypotheses,
  deleteOpportunityAnalysisComment,
  editOpportunityAnalysisComment,
  loadOpportunityAnalysis,
  addOpportunityAnalysisComment,
  editOpportunityAnalysis,
  addOpportunityAnalysisReply,
  editOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessModelCanvasPage)
);
