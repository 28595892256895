/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useCallback } from 'react';
import {
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Popover,
  Overlay,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { map, filter, size, get } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { push } from 'connected-react-router';
import CohortIcon from '../../../common/assets/icons/Avatar_Cohorta.svg';
import { ReactComponent as VideoIcon } from '../../../common/assets/icons/Video.svg';
import { ReactComponent as LowIcon } from '../../../common/assets/icons/low-mvp.svg';
import { ReactComponent as HighIcon } from '../../../common/assets/icons/hi-mvp.svg';
import { ReactComponent as AimIcon } from '../../../common/assets/icons/tinta.svg';
import { ReactComponent as ProjectionsIcon } from '../../../common/assets/icons/projections.svg';
import { ReactComponent as PresentationIcon } from '../../../common/assets/icons/prezentare.svg';
import { ReactComponent as RulerIcon } from '../../../common/assets/icons/ruler.svg';
import { ReactComponent as StrategyIcon } from '../../../common/assets/icons/strategy.svg';
import { ReactComponent as MoneyIcon } from '../../../common/assets/icons/money.svg';
import { ReactComponent as RoundIcon } from '../../../common/assets/icons/round.svg';
import { ReactComponent as CalendarIcon } from '../../../common/assets/icons/calendar.svg';
import { ReactComponent as PartIcon } from '../../../common/assets/icons/part.svg';
import { ReactComponent as LinkIcon } from '../../../common/assets/icons/Link.svg';
import { ReactComponent as EuroIcon } from '../../../common/assets/icons/Suma.svg';
import { ReactComponent as PercentIcon } from '../../../common/assets/icons/Procent.svg';
import { ReactComponent as ToDoIcon } from '../../../common/assets/icons/to-do.svg';

import { MembersDiv, IconLabelDiv } from '../styled';
import {
  AvatarMember,
  IRLThermometer,
  ProfileImageUploader,
} from '../../../common/components';
import { loadUserTeams } from '../../../common/ducks';
import {
  Loading,
  AddCommentButton,
  CounterDiv,
  CounterText,
  PlaceHolderInput,
  Heading2,
  Heading4Regular,
  Heading3Semibold,
  Link,
  Title,
  TitleCounterDiv,
  TagButton,
  EditButton,
  EditButtonHover,
  RowDiv,
  ColDiv,
  Heading1,
  AvatarContainer,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import {
  editTeam,
  getTeam,
  uploadTeamImage,
  removeMember,
  editEquityMember,
  leaveCohort,
  mentorLeave,
} from '../../ducks';
import {
  isMentorSelector,
  isAcceleratorSelector,
} from '../../../account/ducks';
import {
  AddTeamDetailsModal,
  CreateTeamModal,
  JoinCohortModal,
} from '../modals';

import {
  LabelFieldDiv,
  DivBorderBottom,
  TitleButtonsDiv,
  ImageIconRound,
  ImageIconRoundDiv,
} from '../../../mentors/components/styled';

const TeamProfilePage = (props) => {
  const {
    isLoading,
    team,
    push,
    getTeam,
    isMentor,
    isAccelerator,
    isEditing,
    uploadTeamImage,
    removeMember,
    userId,
    editEquityMember,
    leaveCohort,
    loadUserTeams,
    mentorLeave,
  } = props;

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [joinCohortModalOpen, setJoinCohortModalOpen] = useState(false);

  const [copySuccess, setCopySuccess] = useState('Copy');
  const teamCodeRef = useRef(null);
  const thermometherContainerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showIRLTooltips, setShowIRLTooltips] = useState(true);
  const addUserPopoverRef = useRef(null);
  const [section, setSection] = useState(undefined);
  const [isHoveredTeam, setIsHoveredTeam] = useState(false);
  const [isHoveredIRL, setIsHoveredIRL] = useState(false);
  const [isHoveredInvestment, setIsHoveredInvestment] = useState(false);

  const handleInviteMembers = (event) => {
    event.stopPropagation();
    setShow(!show);
  };

  const copyToClipBoard = (e) => {
    teamCodeRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setTimeout(() => setShow(false), 1000);
  };
  const members = useCallback(
    (type) =>
      filter(
        team.members,
        (member) =>
          member.profile &&
          member.profile.userId &&
          member.profile.userId.role === type
      ),
    [team.members]
  );

  const handleHookSet = (type, section) => {
    switch (type) {
      case 'details':
        setIsDetailsModalOpen(true);
        setSection(section);
        break;

      case 'create':
        setIsCreateTeamModalOpen(true);
        break;
      case 'cohort':
        setJoinCohortModalOpen(true);
        break;
      default:
        setIsCreateTeamModalOpen(false);
        setIsDetailsModalOpen(false);
    }
  };

  const handleMentorButton = () => push('/mentors');
  const handleLeaveCohort = async () => {
    await leaveCohort(team._id);
    await loadUserTeams(userId);
    await getTeam(team._id);
  };

  const onFilesSelected = (files) => {
    uploadTeamImage(team._id, files[0]);
  };

  const handleRemoveMember = async (userId) => {
    await removeMember(userId, team._id);
    await getTeam(team._id);
  };

  const handleMentorLeave = async (userId) => {
    await mentorLeave(userId, team._id);
    await loadUserTeams(userId);
  };

  const handleEditEquityMember = async (userId, values) => {
    await editEquityMember(userId, team._id, values);
    await getTeam(team._id);
  };

  const handleResetTooltips = () => {
    setShow(false);
    setShowIRLTooltips(false);
  };

  return (
    <div onClick={handleResetTooltips}>
      <DivBorderBottom paddingBottom="50px" marginBottom="50px">
        <TitleButtonsDiv marginBottom="15px">
          <Title>{team.name}</Title>
          {!isMentor &&
            (isHoveredTeam ? (
              <EditButtonHover
                style={{ marginRight: '42px' }}
                onMouseLeave={() => setIsHoveredTeam(false)}
                onClick={() => handleHookSet('create')}
              />
            ) : (
              <EditButton
                style={{ marginRight: '42px' }}
                onMouseEnter={() => setIsHoveredTeam(true)}
                onClick={() => handleHookSet('create')}
              />
            ))}
        </TitleButtonsDiv>

        <TitleButtonsDiv marginBottom="50px">
          <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            {map(team.ideaCategory, (category, index) => (
              <TagButton key={index} width="120px">
                <Heading4Regular white fontSize="12px">
                  {category}
                </Heading4Regular>
              </TagButton>
            ))}
          </div>
        </TitleButtonsDiv>
        <RowDiv>
          <ColDiv flex width="50%">
            <div>
              <Heading1 marginBottom="12px">Logo</Heading1>
              <ProfileImageUploader
                upload
                onFilesSelected={onFilesSelected}
                imageUrl={team.logo}
                isTeamProfile
                disabled={isMentor || isAccelerator}
              />
            </div>

            <div>
              <Heading1 marginBottom="20px">Detalii</Heading1>
              <LabelFieldDiv flexStart>
                <Link href={team.doamain} target="_blank">
                  {team.domain}
                </Link>
              </LabelFieldDiv>
              <LabelFieldDiv flexStart>
                <Heading2>Stadiu</Heading2>
                <Heading4Regular>{team.stage}</Heading4Regular>
              </LabelFieldDiv>
              <LabelFieldDiv flexStart>
                <Heading2> Data Infiintare</Heading2>
                <Heading4Regular>
                  {team.dateOfEstablishment &&
                    moment(team.dateOfEstablishment).format('DD/MM/YYYY')}
                </Heading4Regular>
              </LabelFieldDiv>
              <LabelFieldDiv flexStart>
                <Heading2>Locatie</Heading2>
                <Heading4Regular noEllipsis>
                  {team.headquarters}
                </Heading4Regular>
              </LabelFieldDiv>
              <LabelFieldDiv flexStart>
                <Heading2>CUI</Heading2>
                <Heading4Regular>{team.cui}</Heading4Regular>
              </LabelFieldDiv>
            </div>
          </ColDiv>
          <ColDiv width="50%">
            <Heading1 marginBottom="20px">Descriere</Heading1>
            <div
              className="scrollbar"
              style={{ height: '148px', overflowY: 'auto' }}
            >
              <Heading4Regular noEllipsis>{team.description}</Heading4Regular>
            </div>
          </ColDiv>
        </RowDiv>
      </DivBorderBottom>
      <DivBorderBottom paddingBottom="40px" marginBottom="40px">
        <RowDiv>
          <ColDiv width="30%">
            <TitleButtonsDiv
              marginBottom="13px"
              style={{ position: 'relative' }}
              ref={addUserPopoverRef}
            >
              <TitleCounterDiv noMargin>
                <Heading2>Membrii</Heading2>
                <CounterDiv>
                  <CounterText>{size(members('FOUNDER'))}</CounterText>
                </CounterDiv>
              </TitleCounterDiv>
              {!isMentor && !isAccelerator && (
                <AddCommentButton onClick={handleInviteMembers} />
              )}
              <Overlay
                show={show}
                target={addUserPopoverRef.current}
                placement="bottom"
                container={addUserPopoverRef.current}
              >
                <Popover
                  id="popover-basic"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Popover.Content>
                    <p>
                      Copiază și trimite codul ideii persoanelor (fondatori,
                      parteneri sau mentori) pe care dorești să le inviți în
                      echipa de lucru pentru ideea ta.
                    </p>
                    <Row>
                      <Col>
                        <div style={{ display: 'flex', maxWidth: '200px' }}>
                          <Form.Control
                            ref={teamCodeRef}
                            value={team.teamCode}
                            readOnly
                            style={{ textOverflow: 'ellipsis' }}
                          />
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>{copySuccess}</Tooltip>}
                          >
                            <Button
                              style={{ marginLeft: '5px', padding: '8px' }}
                              onClick={(e) => copyToClipBoard(e)}
                              variant="outline-primary"
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </Col>
                    </Row>
                  </Popover.Content>
                </Popover>
              </Overlay>
            </TitleButtonsDiv>
            <MembersDiv className="scrollbar">
              {map(members('FOUNDER'), (member, index) => (
                <AvatarMember
                  user={member}
                  key={`member${index}`}
                  handleRemoveMember={handleRemoveMember}
                  userId={userId}
                  createdBy={team.createdBy}
                  handleEditEquityMember={handleEditEquityMember}
                  color="#15BB89"
                />
              ))}
            </MembersDiv>
          </ColDiv>
          <ColDiv width="30%">
            <TitleButtonsDiv marginBottom="13px">
              <TitleCounterDiv noMargin>
                <Heading2>Mentori</Heading2>
                <CounterDiv>
                  <CounterText>{size(members('MENTOR'))}</CounterText>
                </CounterDiv>
              </TitleCounterDiv>
              {!isMentor && !isAccelerator && (
                <AddCommentButton onClick={handleMentorButton} />
              )}
            </TitleButtonsDiv>
            <MembersDiv className="scrollbar">
              {map(members('MENTOR'), (mentor, index) => (
                <AvatarMember
                  key={`mentor${index}`}
                  handleRemoveMember={handleRemoveMember}
                  handleMentorLeave={handleMentorLeave}
                  user={mentor}
                  userId={userId}
                  createdBy={team.createdBy}
                  isMentor
                  color="#FB791C"
                />
              ))}
            </MembersDiv>
          </ColDiv>
          <ColDiv width="30%">
            <TitleButtonsDiv marginBottom="22px">
              <Heading2>Cohorta</Heading2>
              {!isMentor && !isAccelerator && !team.cohortId && (
                <AddCommentButton onClick={() => handleHookSet('cohort')} />
              )}
            </TitleButtonsDiv>
            <AvatarContainer>
              <RowDiv>
                <ColDiv width="15%">
                  <ImageIconRoundDiv>
                    <ImageIconRound src={CohortIcon} />
                  </ImageIconRoundDiv>
                </ColDiv>
                <ColDiv width="80%">
                  <TitleButtonsDiv>
                    <Heading3Semibold>
                      {team.cohortId === null
                        ? 'Cohorta publica'
                        : `${get(team, 'cohortId.name')}`}
                    </Heading3Semibold>

                    {userId === team.createdBy && !isMentor && (
                      <DeleteButtonSmall onClick={handleLeaveCohort} />
                    )}
                  </TitleButtonsDiv>
                  <div style={{ display: 'flex', alignItems: 'center' }} />
                </ColDiv>
              </RowDiv>
            </AvatarContainer>
          </ColDiv>
        </RowDiv>
      </DivBorderBottom>
      <DivBorderBottom paddingBottom="40px" marginBottom="40px">
        <RowDiv>
          <ColDiv width="30%">
            <Heading1 style={{ marginBottom: '35px' }}>
              Investment Readiness Level - IRL{' '}
            </Heading1>
            <IconLabelDiv>
              <VideoIcon />
              <Heading2 marginLeft="12px">1. Video prezentare echipă​</Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.linkVideo ? (
                <Link href={team.linkVideo} target="_blank">
                  {team.linkVideo}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
            <IconLabelDiv>
              <AimIcon />
              <Heading2 marginLeft="12px">4. Dimensiune piață țintă</Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.marketSize ? (
                <Link href={team.marketSize} target="_blank">
                  {team.marketSize}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
            <IconLabelDiv>
              <LowIcon />
              <Heading2 marginLeft="12px">6. Prototipul brut</Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.LVP ? (
                <Link href={team.LVP} target="_blank">
                  {team.LVP}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
            <IconLabelDiv>
              <HighIcon />
              <Heading2 marginLeft="12px">9. Prototipul rafinat</Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.HVP ? (
                <Link href={team.HVP} target="_blank">
                  {team.HVP}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
            <IconLabelDiv>
              <ProjectionsIcon />
              <Heading2 marginLeft="12px">12. Proiecții financiare​</Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.financialProjections ? (
                <Link href={team.financialProjections} target="_blank">
                  {team.financialProjections}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
            <IconLabelDiv>
              <RulerIcon />
              <Heading2 marginLeft="12px">13. Indicatori</Heading2>
            </IconLabelDiv>
            {size(team.KPIs) !== 0 ? (
              map(team.KPIs, (elem, index) => (
                <PlaceHolderInput spaceBetween marginBottom="11px">
                  <Link key={index} href={elem} target="_blank">
                    {elem}
                  </Link>
                  <LinkIcon />
                </PlaceHolderInput>
              ))
            ) : (
              <PlaceHolderInput spaceBetween marginBottom="11px">
                <Heading4Regular inactive>https://</Heading4Regular>
                <LinkIcon />
              </PlaceHolderInput>
            )}
            <IconLabelDiv>
              <StrategyIcon />
              <Heading2 marginLeft="12px">
                14. Strategie intrare pe piață
              </Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.market ? (
                <Link href={team.market} target="_blank">
                  {team.market}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
            <IconLabelDiv>
              <PresentationIcon />
              <Heading2 marginLeft="12px">15. Prezentarea ideii​</Heading2>
            </IconLabelDiv>
            <PlaceHolderInput spaceBetween marginBottom="11px">
              {team.pitchDeck ? (
                <Link href={team.pitchDeck} target="_blank">
                  {team.pitchDeck}
                </Link>
              ) : (
                <Heading4Regular inactive>https://</Heading4Regular>
              )}
              <LinkIcon />
            </PlaceHolderInput>
          </ColDiv>
          <ColDiv width="66%">
            <Heading1
              style={{
                marginBottom: '56px',
                display: 'flex',
                justifyContent: 'center',
                marginRight: '4%',
              }}
            />
            <div ref={thermometherContainerRef} style={{ width: '100%' }}>
              <IRLThermometer
                thermometherRef={thermometherContainerRef}
                irl={team.irl}
                irlLevels={team.irlLevels}
                showIRLTooltips={showIRLTooltips}
                setShowIRLTooltips={setShowIRLTooltips}
              />
            </div>
          </ColDiv>
          <ColDiv width="4%">
            <TitleButtonsDiv marginBottom="35px">
              {!isMentor &&
                (isHoveredIRL ? (
                  <EditButtonHover
                    onMouseLeave={() => setIsHoveredIRL(false)}
                    onClick={() => handleHookSet('details', 'IRL')}
                  />
                ) : (
                  <EditButton
                    onMouseEnter={() => setIsHoveredIRL(true)}
                    onClick={() => handleHookSet('details', 'IRL')}
                  />
                ))}
            </TitleButtonsDiv>
          </ColDiv>
        </RowDiv>
      </DivBorderBottom>
      <TitleButtonsDiv marginBottom="35px">
        <Heading1>Investiție căutată​</Heading1>

        {!isMentor &&
          (isHoveredInvestment ? (
            <EditButtonHover
              onMouseLeave={() => setIsHoveredInvestment(false)}
              onClick={() => handleHookSet('details', 'investment')}
            />
          ) : (
            <EditButton
              onMouseEnter={() => setIsHoveredInvestment(true)}
              onClick={() => handleHookSet('details', 'investment')}
            />
          ))}
      </TitleButtonsDiv>
      <RowDiv marginBottom="30px">
        <ColDiv width="24%">
          <IconLabelDiv>
            <MoneyIcon />
            <Heading2 marginLeft="12px">Investiție căutată​</Heading2>
          </IconLabelDiv>
          <PlaceHolderInput spaceBetween>
            <Heading4Regular inactive>
              {team.investmentSought ? team.investmentSought : 'Suma'}
            </Heading4Regular>
            <EuroIcon />
          </PlaceHolderInput>
        </ColDiv>
        <ColDiv width="24%">
          <IconLabelDiv>
            <PartIcon />
            <Heading2 marginLeft="12px">Acțiuni oferite</Heading2>
          </IconLabelDiv>
          <PlaceHolderInput spaceBetween>
            <Heading4Regular inactive>
              {team.equity ? team.equity : 'Procent'}
            </Heading4Regular>
            <PercentIcon />
          </PlaceHolderInput>
        </ColDiv>
        <ColDiv width="24%">
          <IconLabelDiv>
            <RoundIcon />
            <Heading2 marginLeft="12px">Rundă finanțare​</Heading2>
          </IconLabelDiv>
          <PlaceHolderInput>
            <Heading4Regular inactive>
              {team.round ? team.round : 'Numarul rundei'}
            </Heading4Regular>
          </PlaceHolderInput>
        </ColDiv>
        <ColDiv width="24%">
          <IconLabelDiv>
            <CalendarIcon />
            <Heading2 marginLeft="12px">Termen</Heading2>
          </IconLabelDiv>
          <PlaceHolderInput>
            <Heading4Regular inactive>
              {team.deadline
                ? moment(team.deadline).format('DD/MM/YYYY')
                : 'zz/ll/aaaa'}
            </Heading4Regular>
          </PlaceHolderInput>
        </ColDiv>
      </RowDiv>
      <RowDiv marginBottom="50px">
        <ColDiv width="100%">
          <IconLabelDiv>
            <ToDoIcon />
            <Heading2 marginLeft="12px">Destinația fondurilor​</Heading2>
          </IconLabelDiv>
          {size(team.foundsDestination) !== 0 ? (
            map(team.foundsDestination, (elem, index) => (
              <PlaceHolderInput spaceBetween marginBottom="10px">
                <Heading4Regular key={index}>{elem}</Heading4Regular>
              </PlaceHolderInput>
            ))
          ) : (
            <PlaceHolderInput spaceBetween marginBottom="10px">
              <Heading4Regular inactive>Destinația fondurilor​</Heading4Regular>
            </PlaceHolderInput>
          )}
        </ColDiv>
      </RowDiv>

      <AddTeamDetailsModal
        isModalOpen={isDetailsModalOpen}
        setIsModalOpen={setIsDetailsModalOpen}
        section={section}
        setSection={setSection}
      />
      <CreateTeamModal
        isModalOpen={isCreateTeamModalOpen}
        setIsModalOpen={setIsCreateTeamModalOpen}
        isLoading={isEditing}
        isEditMode
        team={team}
      />
      <JoinCohortModal
        isModalOpen={joinCohortModalOpen}
        setIsModalOpen={setJoinCohortModalOpen}
        isLoading={isEditing}
        userId={userId}
      />
      <Loading isLoading={isEditing || isLoading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  team: state.team.team,
  isLoading: state.team.isLoading,
  isEditing: state.team.isEditing,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
  userId: get(state.account.login, 'user._id'),
});

const mapDispatchToProps = {
  push,
  editTeam,
  getTeam,
  uploadTeamImage,
  removeMember,
  editEquityMember,
  leaveCohort,
  loadUserTeams,
  mentorLeave,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamProfilePage)
);
