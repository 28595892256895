import React, { useMemo, useState } from 'react';
import { map, filter, find, size, some, get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { ReactComponent as HypothesisInvalidIcon } from '../../../common/assets/icons/Ipoteza-Invalida_S.svg';
import { ReactComponent as HypothesisValidIcon } from '../../../common/assets/icons/Ipoteza-Valida_S.svg';
import { ReactComponent as HypothesisInProgressIcon } from '../../../common/assets/icons/Ipoteza-Neconcludent_S.svg';
import { ReactComponent as ValidHypothesisMVP } from '../../../common/assets/icons/Ipoteza-valida-w-MVP.svg';
import { ReactComponent as InvalidHypothesisMVP } from '../../../common/assets/icons/Ipoteza-Invalida-MVP.svg';

import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  LinkTable,
} from '../../../common/components/styled';
import { BMC_OPTIONS } from '../../../canvas/constants';
import { Export, SortTableHead } from '../../../common/components';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const HypothesesPage = (props) => {
  const { hypotheses, push } = props;

  const [sortedHypotheses, setSortedHypotheses] = useState(hypotheses);

  const handleExperimentsResults = (array, type) => {
    const result = filter(
      array,
      (item) => get(item, 'resolution.outcome') === type
    );
    return result.length;
  };

  const handleColumnBmcTitle = (title) => {
    const result = find(BMC_OPTIONS, { value: title });
    return !!result && result.label;
  };

  const getHypothesisIcon = (resolutions) => {
    if (!size(resolutions) > 0) {
      return <HypothesisInProgressIcon />;
    }
    const outcomes = map(resolutions, (resolution) => resolution.outcome);

    if (some(outcomes, (outcome) => outcome === 'invalidatedWithMVP')) {
      return <HypothesisInvalidIcon />;
    }
    if (some(outcomes, (outcome) => outcome === 'invalidatedWithoutMVP')) {
      return <InvalidHypothesisMVP style={{ width: '24px', height: '24px' }} />;
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithMVP')) {
      return <HypothesisValidIcon />;
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithoutMVP')) {
      return <ValidHypothesisMVP style={{ width: '22px', height: '22px' }} />;
    }
  };

  const getHypothesisIconText = (resolutions) => {
    if (!size(resolutions) > 0) {
      return 'In Progress';
    }
    const outcomes = map(resolutions, (resolution) => resolution.outcome);

    if (some(outcomes, (outcome) => outcome === 'invalidatedWithMVP')) {
      return 'Invalidată';
    }
    if (some(outcomes, (outcome) => outcome === 'invalidatedWithoutMVP')) {
      return 'Invalidată cu MVP';
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithMVP')) {
      return 'Validată';
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithoutMVP')) {
      return 'Validată cu MVP';
    }
  };

  const getDownloadData = useMemo(
    () =>
      map(sortedHypotheses, (hypothesis, index) => ({
        '#': index + 1,
        Titlu: hypothesis.title,
        'Secțiune BMC': handleColumnBmcTitle(hypothesis.columnBMC),
        Rezoluție: getHypothesisIconText(hypothesis.resolutions),
        Importanță: hypothesis.importance,
        Tip: hypothesis.hypothesisCategory,
        'Exp. de validare': handleExperimentsResults(
          hypothesis.experiments,
          'like'
        ),
        'Exp. de invalidare': handleExperimentsResults(
          hypothesis.experiments,
          'dislike'
        ),
        'Exp. în curs': handleExperimentsResults(
          hypothesis.experiments,
          'inProgress'
        ),
      })),
    [sortedHypotheses]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Ipoteze</Title>
          <CounterDiv>
            <CounterText>{size(sortedHypotheses)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <Export data={getDownloadData} filename="ipoteze.csv" />
      </TitleButtonsDiv>

      <SortTableHead
        array={sortedHypotheses}
        setArray={setSortedHypotheses}
        cellSortKeys={[
          null,
          ['title'],
          (el) => el.columnBMC && handleColumnBmcTitle(el.columnBMC),
          ['resolution.outcome'],
          ['importance'],
          ['hypothesisCategory'],
          (el) =>
            el.experiments && handleExperimentsResults(el.experiments, 'like'),
          (el) =>
            el.experiments &&
            handleExperimentsResults(el.experiments, 'dislike'),
          (el) =>
            el.experiments &&
            handleExperimentsResults(el.experiments, 'inProgress'),
        ]}
        defaultSortKey={['title']}
      >
        <TableHeadCell alignLeft cellWidth="2%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="34%">
          <Heading2 center>Titlu</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>Secțiune BMC</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>Rezoluție</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>Importanță</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2 center>Tip </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="8%">
          <Heading2 center>Exp. de validare </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="8%">
          <Heading2 center>Exp. de invalidare</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="8%">
          <Heading2 center>Exp. în curs</Heading2>
        </TableHeadCell>
      </SortTableHead>
      {map(sortedHypotheses, (hypothesis, index) => (
        <TableRow key={index}>
          <TableBodyCell alignLeft cellWidth="2%">
            <Heading4Regular>{index + 1}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignLeft cellWidth="34%">
            <LinkTable
              maxWidth="100%"
              onClick={() => push(`/hypothesis/${hypothesis._id}`)}
            >
              {hypothesis.title}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="10%">
            <Heading4Regular noEllipsis>
              {' '}
              {hypothesis.columnBMC &&
                handleColumnBmcTitle(hypothesis.columnBMC)}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="10%">
            <Heading4Regular noEllipsis>
              {getHypothesisIcon(hypothesis.resolutions)}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="10%">
            <Heading4Regular noEllipsis>
              {hypothesis.importance}
            </Heading4Regular>
          </TableBodyCell>

          <TableBodyCell cellWidth="10%">
            <Heading4Regular noEllipsis>
              {hypothesis.hypothesisCategory}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="8%">
            <Heading4Regular noEllipsis>
              {hypothesis.experiments &&
                handleExperimentsResults(hypothesis.experiments, 'like')}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="8%">
            <Heading4Regular noEllipsis>
              {hypothesis.experiments &&
                handleExperimentsResults(hypothesis.experiments, 'dislike')}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="8%">
            <Heading4Regular noEllipsis>
              {hypothesis.experiments &&
                handleExperimentsResults(hypothesis.experiments, 'inProgress')}
            </Heading4Regular>
          </TableBodyCell>
        </TableRow>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HypothesesPage)
);
