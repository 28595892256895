import React, { useState } from 'react';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Form, Col } from 'react-bootstrap';

import {
  Input,
  Heading2,
  SubmitButton,
  SaveIcon,
  ValidHypothesisButton,
  InvalidHypothesisButton,
} from '../../../common/components/styled';

const RenderTitleField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  setOutcome,
  formLabel,
  iconsFormLabel,
  pressedHypothesisInvalid,
  pressedHypothesisValid,
  setPressedHypothesisInvalid,
  setPressedHypothesisValid,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderTitleField
        name="title"
        label={formLabel}
        values={values}
        handleChange={handleChange}
      />
    </Form.Row>
    <div style={{ marginBottom: '10px' }}>
      <Heading2 marginBottom="10px">{iconsFormLabel}</Heading2>
      <div>
        <ValidHypothesisButton
          pressed={pressedHypothesisValid}
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => {
            setOutcome('validated');
            setPressedHypothesisValid(true);
            setPressedHypothesisInvalid(false);
          }}
        />

        <InvalidHypothesisButton
          pressed={pressedHypothesisInvalid}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOutcome('invalidated');
            setPressedHypothesisInvalid(true);
            setPressedHypothesisValid(false);
          }}
        />
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <SubmitButton type="submit">
        {' '}
        <SaveIcon />
        Salvează
      </SubmitButton>
    </div>
  </Form>
);

const EmpathyMapForm = (props) => {
  const { onSubmit, name, item, formLabel, iconsFormLabel } = props;
  const [outcome, setOutcome] = useState(item ? item.outcome : '');
  const [pressedHypothesisValid, setPressedHypothesisValid] = useState(
    item.outcome === 'validated'
  );
  const [pressedHypothesisInvalid, setPressedHypothesisInvalid] = useState(
    item.outcome === 'invalidated'
  );

  const handleSubmit = (values, { resetForm }) => {
    const registerValues = {
      ...values,
      outcome,
    };
    onSubmit(outcome !== '' ? registerValues : values);
  };

  const defaultValues = {
    title: '',
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={item || defaultValues}>
      {(props) => (
        <RenderForm
          {...props}
          formLabel={formLabel}
          outcome={outcome}
          setOutcome={setOutcome}
          pressedHypothesisInvalid={pressedHypothesisInvalid}
          pressedHypothesisValid={pressedHypothesisValid}
          setPressedHypothesisInvalid={setPressedHypothesisInvalid}
          setPressedHypothesisValid={setPressedHypothesisValid}
          name={name}
          iconsFormLabel={iconsFormLabel}
        />
      )}
    </Formik>
  );
};

export default EmpathyMapForm;
