import React, { useState } from 'react';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Input,
  Heading2,
  SubmitButton,
  Link,
  Heading4Regular,
  ButtonDiv,
} from '../../../common/components/styled';
import { checkEmailRegistered } from '../../ducks';
import { Dropdown } from '../../../core/components';
import { AccountPanelLink } from '../styled';

const roleOptions = [
  {
    value: 'FOUNDER',
    label: 'Fondator sau Partener',
  },
  {
    value: 'MENTOR',
    label: 'Mentor',
  },

  {
    value: 'ACCELERATOR',
    label: 'Incubator sau Accelerator',
  },
];

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    md,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md={md || '6'} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderEmailField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    setEmailChanged,
    initialValues,
    isEmailRegistered,
    emailChanged,
    checkEmailRegistered,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(event) => {
          setEmailChanged(initialValues.email !== event.target.value);
          helpers.setTouched();
          return handleChange(event);
        }}
        onBlur={(e) => {
          checkEmailRegistered(e.target.value);
          helpers.setTouched();
        }}
        isInvalid={
          (meta.touched && !!meta.error) || (isEmailRegistered && emailChanged)
        }
      />
      <Form.Control.Feedback type="invalid">
        {(meta.touched && meta.error) ||
          (isEmailRegistered &&
            emailChanged &&
            'Acest email este folosit deja!')}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderRoleDropdown = (props) => {
  const { values, setFieldValue, controlId, placeholder } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, 'role');

  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">Rol</Heading2>
      <Input
        as={Dropdown}
        name="role"
        options={roleOptions}
        value={fieldValue}
        placeholder={placeholder}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('role', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderReCaptchaField = (props) => {
  const { values, setFieldValue, name, controlId } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, '');
    }
  };
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <ReCAPTCHA
        sitekey="6LeOgMQZAAAAADJXXTWOrFWTwryEcH5eKxzX2-jF"
        name={name}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
          handleNullValue(v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderGDPRCheckbox = (props) => {
  const { values, setFieldValue, controlId } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, 'role');

  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Form.Check
        type="checkbox"
        label={
          <Heading4Regular>
            {`Accept `}
            <Link
              href="https://ptic.ro/confidentialitate/"
              target="_blank"
              rel="noopener noreferrer"
            >
              termenii, condițiile și GDPR
            </Link>
          </Heading4Regular>
        }
        name="gdpr"
        value={fieldValue}
        onChange={(e) => setFieldValue('gdpr', e.target.checked)}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  checkEmailRegistered,
  isEmailRegistered,
  initialValues,
  setFieldValue,
  isValid,
  isSubmitting,
  facebookLogin,
  ...rest
}) => {
  const [emailChanged, setEmailChanged] = useState(false);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Row>
        <RenderTextField
          placeholder="Nume"
          name="firstName"
          values={values}
          handleChange={handleChange}
          controlId="validationFormik01"
          label="Nume"
        />
        <RenderTextField
          placeholder="Prenume"
          name="lastName"
          values={values}
          handleChange={handleChange}
          controlId="validationFormik02"
          label="Prenume"
        />
      </Form.Row>
      <Form.Row>
        <RenderTextField
          placeholder="Parolă"
          name="password"
          values={values}
          handleChange={handleChange}
          controlId="validationFormik03"
          label="Parolă"
          type="password"
        />
        <RenderTextField
          placeholder="Confirmă parola"
          name="confirmPassword"
          values={values}
          handleChange={handleChange}
          controlId="validationFormik04"
          label="Confirmă parola"
          type="password"
        />
      </Form.Row>
      <Form.Row>
        <RenderEmailField
          placeholder="Email"
          label="Email"
          name="email"
          valuse={values}
          handleChange={handleChange}
          controlId="validationFormik05"
          setEmailChanged={setEmailChanged}
          initialValues={initialValues}
          isEmailRegistered={isEmailRegistered}
          emailChanged={emailChanged}
          checkEmailRegistered={checkEmailRegistered}
        />
      </Form.Row>
      <Form.Row>
        <RenderRoleDropdown
          values={values}
          setFieldValue={setFieldValue}
          name="role"
          placeholder="Alege tipul de cont pe care dorești să-l activezi"
          controlId="validationFormik06"
        />
      </Form.Row>
      <Form.Row>
        <RenderTextField
          placeholder="Google search, Facebook, Presa scrisă, Laviniu Chiș"
          name="howFindUs"
          values={values}
          handleChange={handleChange}
          controlId="validationFormik07"
          label="Cum ai aflat de noi?*"
          md="12"
        />
      </Form.Row>
      <Form.Row>
        <RenderGDPRCheckbox
          values={values}
          setFieldValue={setFieldValue}
          name="gdpr"
          controlId="validationFormik08"
        />
      </Form.Row>
      {/* <Form.Row>
        <RenderReCaptchaField
          name="recaptcha"
          setFieldValue={setFieldValue}
          values={values}
          controlId="validationFormik09"
        />
      </Form.Row> */}
      <Form.Row>
        <Form.Group as={Col} md="12">
          <AccountPanelLink to="/account/login">
            Ai deja un cont creat?
          </AccountPanelLink>
        </Form.Group>
      </Form.Row>
      <ButtonDiv>
        <SubmitButton type="submit" disabled={isSubmitting}>
          Crează Cont
        </SubmitButton>
      </ButtonDiv>
    </Form>
  );
};

const RegisterForm = ({
  onSubmit,
  checkEmailRegistered,
  isEmailRegistered,
}) => {
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    email: Yup.string()
      .required('Câmp obligatoriu')
      .email('Adresa de email invalida'),
    password: Yup.string().required('Câmp obligatoriu'),
    confirmPassword: Yup.string()
      .required('Câmp obligatoriu')
      .oneOf([Yup.ref('password'), null], 'Parolele nu coincid'),
    firstName: Yup.string().required('Câmp obligatoriu'),
    lastName: Yup.string().required('Câmp obligatoriu'),
    role: Yup.string().required('Câmp obligatoriu'),
    howFindUs: Yup.string().required('Câmp obligatoriu'),
    gdpr: Yup.bool().oneOf([true], 'Câmp obligatoriu'),
    // recaptcha: Yup.string().required('Câmp obligatoriu'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
        howFindUs: '',
        gdpr: false,
        // recaptcha: '',
      }}
    >
      {(props) => (
        <RenderForm
          {...props}
          checkEmailRegistered={checkEmailRegistered}
          isEmailRegistered={isEmailRegistered}
        />
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  isEmailRegistered: state.account.register.isEmailRegistered,
});

const mapDispatchToProps = { checkEmailRegistered };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
