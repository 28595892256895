import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { get, map, find, filter, includes, isEmpty, isDate } from 'lodash-es';
import { Formik, useField, useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { Col, Form } from 'react-bootstrap';
import { Dropdown } from '../../../core/components';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  Heading2,
  SubmitButton,
  DatePicker,
  ButtonDiv,
  SaveIcon,
} from '../../../common/components/styled';
import { handleBmcLabels } from '../../../hypotheses/components/pages/HypothesisDetailsPage';

export const handleAutomaticStatus = (values) => {
  const today = new Date();
  const newValues = values;

  if (
    !isEmpty(values.title) &&
    !isEmpty(values.forValidation) &&
    !isEmpty(values.measure) &&
    !isEmpty(values.weConsider) &&
    isDate(values.deadline) &&
    isDate(values.startDate) &&
    today <= values.deadline &&
    today >= values.startDate
  ) {
    newValues.status = 'În derulare';
  } else if (today >= values.deadline) {
    newValues.status = 'Finalizat';
  } else {
    newValues.status = 'În constructie';
  }

  return newValues;
};

const statusOptions = [
  {
    value: 'În asteptare',
    label: 'În așteptare',
  },
  {
    value: 'În constructie',
    label: 'În construcție',
  },
  {
    value: 'În derulare',
    label: 'În derulare',
  },
  {
    value: 'Finalizat',
    label: 'Finalizat',
  },
];

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderDeadlineField = (props) => {
  const {
    values,
    name,
    placeholder,
    controlId,
    label,
    setFieldValue,
    minDate,
    maxDate,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, '');
    }
  };
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px" ref={fieldRef}>
        {label}
      </Heading2>
      <Input
        component={DatePicker}
        as={DatePicker}
        name={name}
        placeholderText={placeholder}
        selected={fieldValue}
        value={fieldValue}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="dd/MM/yyyy"
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
          handleNullValue(v);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderCostField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderTitleField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderValidateField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type,
    rows,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="75px"
        ref={fieldRef}
        as={type}
        rows={rows}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderMeasureField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type,
    rows,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="75px"
        ref={fieldRef}
        as={type}
        rows={rows}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderWeConsiderField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type,
    rows,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="75px"
        ref={fieldRef}
        as={type}
        rows={rows}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderHypothesesField = (props) => {
  const { values, setFieldValue, controlId, name, label, hypothesesOptions } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue('hypothesisId', []);
    }
  };

  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });

  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        isMulti
        name={name}
        options={hypothesesOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('hypothesisId', v);
          handleNullValue(v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  hypothesesOptions,
  setFieldValue,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderTitleField
        name="title"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Titlul experimentului*"
        placeholder="Titlul experimentului"
      />
    </Form.Row>
    <Form.Row>
      <RenderValidateField
        name="forValidation"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Pentru a valida experimentul vom:*"
        type="textarea"
        rows={3}
        placeholder="Descrie pe scurt cum vei valida experimentul."
      />
    </Form.Row>
    <Form.Row>
      <RenderWeConsiderField
        name="weConsider"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik03"
        label="Avem dreptate dacă:*"
        placeholder="Descrie pe scurt cum vei stii ca ai avut dreptate."
        type="textarea"
        rows={3}
      />
    </Form.Row>
    <Form.Row>
      <RenderMeasureField
        name="measure"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik04"
        label="Vom măsura rezultatele prin:*"
        placeholder="Descrie pe scurt cum vei măsura rezultatele."
        type="textarea"
        rows={3}
      />
    </Form.Row>
    <Form.Row>
      <RenderDeadlineField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik05"
        name="startDate"
        maxDate={values.deadline}
        label="Dată începere"
        placeholder="dd/mm/yy"
      />
      <RenderDeadlineField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik06"
        name="deadline"
        minDate={values.startDate}
        label="Dată finalizare"
        placeholder="dd/mm/yy"
      />
    </Form.Row>
    <Form.Row>
      <RenderCostField
        name="estimatedPrice"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik07"
        label="Estimăm că ne va costa (€):*"
        placeholder="0.00"
        type="number"
      />
    </Form.Row>

    <Form.Row>
      <RenderHypothesesField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik07"
        name="hypothesisId"
        label="Acest experiment urmărește validarea ipotezelor*"
        hypothesesOptions={hypothesesOptions}
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddExperimentForm = (props) => {
  const { onSubmit, hypotheses, hypothesis, experiment, isEditMode } = props;

  const hypothesesOptions = map(hypotheses, (el) => ({
    label: `${handleBmcLabels(el.columnBMC)}: ${el.title}`,
    value: `${el._id}`,
  }));

  const defaultHypothesis =
    hypothesis &&
    find(hypothesesOptions, {
      value: hypothesis._id,
    });

  const hypothesisIdsToSearch =
    experiment && map(experiment.hypothesisId, (el) => el._id);

  const defaultExperiment = {
    ...experiment,
    hypothesisId:
      experiment &&
      filter(hypothesesOptions, (elem) =>
        includes(map(hypothesisIdsToSearch), elem.value)
      ),
    startDate:
      experiment && experiment.startDate && new Date(experiment.startDate),
    deadline:
      experiment && experiment.deadline && new Date(experiment.deadline),
    status:
      experiment &&
      find(statusOptions, {
        value: experiment.status,
      }),
  };

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(handleAutomaticStatus(values));
  };

  const schema = Yup.object({
    title: Yup.string().required('Câmp obligatoriu'),
    status: Yup.string(),
    estimatedPrice: Yup.number(),
    hypothesisId: Yup.string().required('Câmp obligatoriu'),
    forValidation: Yup.string().required('Câmp obligatoriu'),
    measure: Yup.string().required('Câmp obligatoriu'),
    weConsider: Yup.string().required('Câmp obligatoriu'),
    startDate: Yup.string().nullable(true),
    deadline: Yup.string().nullable(true),
  });

  const defaultValues = {
    title: '',
    status: '',
    estimatedPrice: 0,
    hypothesisId: defaultHypothesis || '',
    forValidation: '',
    measure: '',
    weConsider: '',
    startDate: '',
    deadline: '',
  };

  const initialValues = isEditMode ? defaultExperiment : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          hypothesesOptions={hypothesesOptions}
          isEditMode={isEditMode}
        />
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddExperimentForm);
