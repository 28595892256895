import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { dropdownStyles } from '../../constants';
import { Dropdown } from '../../../core/components';

const CohortFilterForm = ({
  userCohorts,
  userCohortValue,
  handleChangeCohort,
}) => (
  <Formik onSubmit={() => {}} initialValues={{ team: userCohortValue }}>
    {(props) => (
      <Form>
        <Form.Group style={{ padding: '8px 22px' }}>
          <Form.Control
            as={Dropdown}
            placeholder="Selectează echipă"
            options={userCohorts}
            styles={dropdownStyles}
            name="team"
            label="Echipa"
            showCustomControl
            value={props.values.team}
            onChange={(e) => {
              handleChangeCohort(e);
              props.setFieldValue('team', e);
            }}
          />
        </Form.Group>
      </Form>
    )}
  </Formik>
);

export default CohortFilterForm;
