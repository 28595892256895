import styled, { css } from 'styled-components';

export const BusinessModelCanvasContainer = styled.div`
  width: 100%;
`;

export const BusinessModelCanvasRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.borderBottom &&
    css`
      margin-bottom: 15px;
      border-bottom: 1px solid #cccccc;
    `}

  padding-bottom: 15px;
`;

export const BusinessModelCanvasColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  height: ${(props) => (props.firstRow ? '410px' : '205px')};
  height: ${(props) => props.lastRow && '195px'};
  max-width: ${(props) => (props.firstRow ? '20%' : '50%')};
  ${(props) =>
    props.borderLeft &&
    css`
      border-left: 1px solid #cccccc;
    `}
  ${(props) =>
    !!props.empathy &&
    css`
      width: 33%;
      height: 540px;
    `}
  ${(props) =>
    !!props.noPadding &&
    css`
      padding: 0;
    `}
  ${(props) =>
    !!props.multipleChildren &&
    css`
      max-width: 100%;
      display: flex;
      flex-direction: column;
      &:last-of-type {
        padding-top: 15px;
      }
      &:first-of-type {
        padding-bottom: 15px;
      }
    `};

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #cccccc;
    `}
`;

export const BMCColumnTitle = styled.h3`
  font-size: 20px;
  padding-right: 20px;
  position: relative;
  color: #0acf97;
  font-weight: 400;
`;

export const BMCColumnTitleIcon = styled.div`
  position: absolute;
  right: 0px;
  top: 2px;
  cursor: pointer;
  > svg > path {
    fill: #6c757d;
  }
  :hover {
    > svg > path {
      fill: #0acf97;
    }
  }
`;

export const NoResultsFound = styled.div`
  font-size: 14px;
  padding-right: 20px;
  color: #777;
  font-style: italic;
`;

export const HypothesisCardsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
  margin-right: -4px;
`;
