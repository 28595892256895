import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { get, size } from 'lodash-es';
import { FieldArray, Formik, useField } from 'formik';
import { connect } from 'react-redux';
import { Col, Form } from 'react-bootstrap';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonDiv,
  Input,
  Heading2,
  SubmitButton,
  SaveIcon,
  LabelButtonContainer,
  AddCommentButton,
  PositionParent,
  LinkIcon,
  MinusButton,
} from '../../../common/components/styled';
import { currentTeamIdSelector } from '../../../common/ducks';

const RenderTitleField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDetailField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="100px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderResourcesField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,

    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const [showPlusButton, setShowPlusButton] = useState(false);
  useEffect(() => {
    if (size(values.trends.resources) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.trends.resources, setShowPlusButton]);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <LabelButtonContainer>
            <Heading2>Link-uri</Heading2>
            {showPlusButton && (
              <AddCommentButton onClick={() => arrayHelpers.push('')} />
            )}
          </LabelButtonContainer>
          {values.trends.resources.map((resource, index) => (
            <Form.Row
              key={index}
              style={{ width: '100%', margin: '0 0 10px 0' }}
            >
              <PositionParent width="93%">
                <LinkIcon icon={faLink} />
                <Input
                  formlink
                  type={type}
                  placeholder={placeholder}
                  name={`trends.resources[${index}]`}
                  onChange={(e) => {
                    helpers.setTouched();
                    handleChange(e);
                  }}
                  value={values.trends.resources[index]}
                  isInvalid={
                    meta.touched &&
                    meta.error &&
                    meta.error[index] &&
                    meta.error[index]
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: 'inherit' }}
                >
                  {meta.touched &&
                    meta.error &&
                    meta.error[index] &&
                    meta.error[index]}
                </Form.Control.Feedback>
              </PositionParent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '7%',
                }}
              >
                {values.trends.resources.length > 1 && (
                  <MinusButton
                    marginTop="10px"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                )}
              </div>
            </Form.Row>
          ))}
        </>
      )}
    />
  );
};

const RenderForm = ({ handleSubmit, handleChange, values }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderTitleField
        name="trends.conclusion"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Concluziile analizei trendurilor pieței*"
        placeholder="Care este principala concluzie la care ai ajuns în urma analizei pieței?"
        type="text"
      />

      <RenderDetailField
        name="trends.description"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Detalii*"
        placeholder=" Descrie principalele descoperiri pe care le-ai făcut în urma analizei trendurilor pieței. Piață este în creștere, stagnare sau declin? Cum s-au schimbat comportamentele consumatorilor? În ce direcție evoluează? Ce factori influențează comportamentul clienților?"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <Col md="12">
        <RenderResourcesField
          name="trends.resources"
          values={values}
          handleChange={handleChange}
          type="text"
          label="Linkuri"
          placeholder="https://"
        />
      </Col>
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddTrendsForm = (props) => {
  const { onSubmit, opportunityAnalysis, teamId } = props;

  const handleSubmit = (values, { resetForm }) => {
    const registerValues = {
      teamId,
      trends: {
        ...values.trends,
      },
    };
    onSubmit(registerValues);
  };
  const schema = Yup.object({
    trends: Yup.object({
      conclusion: Yup.string().required('Câmp obligatoriu'),
      description: Yup.string().required('Câmp obligatoriu'),
      resources: Yup.array().of(
        Yup.string().url(
          'URL Invalid (linkul trebuie sa contina http:// sau https://)'
        )
      ),
    }),
  });

  const defaultValues = {
    trends: {
      conclusion: '',
      description: '',
      resources: [''],
    },
  };

  const editValues = {
    trends: {
      ...(opportunityAnalysis && opportunityAnalysis.trends),
      resources:
        opportunityAnalysis.trends &&
        opportunityAnalysis.trends.resources &&
        size(opportunityAnalysis.trends.resources) !== 0
          ? opportunityAnalysis.trends.resources
          : [''],
    },
  };
  const initialValues = opportunityAnalysis ? editValues : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  opportunityAnalysis: state.opportunityAnalysis.opportunityAnalysis,
  teamId: currentTeamIdSelector(state.common.userTeams),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddTrendsForm);
