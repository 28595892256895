import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadExperiments } from '../../../experiments/ducks';
import { loadHypotheses } from '../../../hypotheses/ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../styled';
import AddDetailModal from './AddDetailModal';
import { addHypothesisDetail } from '../../../hypothesisDetails/ducks';
import { addExperimentDetail } from '../../../experimentDetails/ducks';
import { currentTeamIdSelector } from '../../ducks';

const AddDetailModalResolver = (props) => {
  const {
    loadExperiments,
    loadHypotheses,
    isHypothesis,
    isExperiment,
    isHypothesisTitle,
    isExperimentTitle,
    isModalOpenGlobal,
    addHypothesisDetail,
    addExperimentDetail,
    isLoadingExperimentDetail,
    isLoadingHypothesisDetail,
    closeModal,
    teamId,
    global,
  } = props;

  const loadDependencies = async () => {
    if (isHypothesis) {
      await loadHypotheses(teamId);
    } else if (isExperiment) {
      await loadExperiments(teamId);
    }
  };

  const addDetailGlobal = isHypothesis
    ? addHypothesisDetail
    : addExperimentDetail;

  const isLoadingGlobal = isHypothesis
    ? isLoadingHypothesisDetail
    : isLoadingExperimentDetail;
  return (
    <>
      <Resolver
        successComponent={AddDetailModal}
        loadingComponent={Loading}
        resolve={loadDependencies}
        successProps={{
          addDetailGlobal,
          global,
          isModalOpenGlobal,
          closeModal,
          isHypothesis,
          isExperimentTitle,
          isHypothesisTitle,
          isExperiment,
          isLoadingGlobal,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  isLoadingHypothesisDetail: state.hypothesisDetail.isCreating,
  isLoadingExperimentDetail: state.experimentDetail.isCreating,
});

const mapDispatchToProps = {
  loadExperiments,
  loadHypotheses,
  addHypothesisDetail,
  addExperimentDetail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDetailModalResolver)
);
