import React from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { JoinTeamForm } from '../forms';
import { joinTeam } from '../../../team/ducks';
import { loadUserTeams } from '../../../common/ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const JoinTeaModal = (props) => {
  const { joinTeam, isJoining, isOpen, setIsModalOpen, loadUserTeams, userId } =
    props;
  const onSubmit = async (teamCode) => {
    await joinTeam(teamCode);
    setIsModalOpen(false);
    await loadUserTeams(userId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal onHide={handleCloseModal} show={isOpen} size="lg" backdrop="static">
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">Alătură-te unei idei</Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <JoinTeamForm onSubmit={onSubmit} />
      </Modal.Body>

      <Loading isLoading={isJoining} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  isJoining: state.team.isJoining,
});

const mapDispatchToProps = {
  loadUserTeams,
  joinTeam,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinTeaModal)
);
