import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TitleButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 25px;
  cursor: pointer;
`;
export const Title = styled.h1`
  margin: 0;
  margin-right: 15px;
`;

export const TitleIconDiv = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.detailPage &&
    css`
      padding-left: 10px;
    `}
`;

export const EmpathyMapButton = styled.div`
  border: 1.5px solid ${(props) => props.theme.blueAccent};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.blueAccent};
  height: 32px;
  cursor: pointer;
  :hover {
    border: 1.5px solid ${(props) => props.theme.accentHover};
  }

  > h4 {
    color: ${(props) => props.theme.blueAccent};
  }
  :hover > h4 {
    color: ${(props) => props.theme.accentHover};
  }
  :hover > svg > path {
    fill: ${(props) => props.theme.accentHover};
  }
`;

export const OutComeIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

export const Label = styled.h4`
  margin-bottom: 10px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
`;
export const Container = styled.div`
  padding: 30px;
`;

export const BorderDiv = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.borderGray};
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;
export const EditIconDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  svg {
    cursor: pointer;
    :hover {
      > path {
        fill: #727cf5;
      }
    }
  }
`;
export const CommentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
