import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Modal } from 'react-bootstrap';
import { map, size, get, find } from 'lodash-es';
import moment from 'moment';
import { push } from 'connected-react-router';
import {
  isMentorSelector,
  isAcceleratorSelector,
} from '../../../account/ducks';
import { ReactComponent as ExperimentDetailIcon } from '../../../common/assets/icons/Menu_Detaliu_Experiment.svg';
import { ReactComponent as ReplyIconMessage } from '../../../common/assets/icons/Reply.svg';
import EditIconLarge from '../../../common/assets/icons/Button_Edit_Large.svg';
import EditIconLargeHover from '../../../common/assets/icons/Button_Edit_Large_Hover.svg';

import {
  loadExperimentDetail,
  editExperimentDetail,
  deleteExperimentDetail,
  addExperimentDetailComment,
  editExperimentDetailComment,
  deleteExperimentDetailComment,
  addExperimentDetailCommentReply,
  editExperimentDetailCommentReply,
  deleteExperimentDetailCommentReply,
} from '../../ducks';
import {
  AddCommentModal,
  AddDetailModal,
} from '../../../common/components/modals';

import { CommentRenderComponent } from '../../../common/components';

import {
  AcceptButton,
  TitleButtonsDiv,
  DivBorderBottom,
  ButtonText,
} from '../../../mentors/components/styled';

import {
  Heading2,
  Heading4Regular,
  Link,
  Title,
  CounterDiv,
  CounterText,
  Loading,
  PlaceHolderInput,
  DeleteButton,
  AddCommentButton,
  DeleteCommentButton,
  TextInactive,
  WhiteCard,
  EditButtonLarge,
  LeftSection,
  RightSection,
  RowDiv,
} from '../../../common/components/styled';

import {
  BUYING_SIGNALS_OPTIONS,
  CAN_CONTACT_LATER_OPTIONS,
  CONTACT_TYPE_OPTIONS,
  DETAIL_CATEGORY_OPTIONS,
  ENGAGEMENT_LEVEL_OPTIONS,
  IS_EARLY_ADOPTER_OPTIONS,
} from '../../../common/constants';
import { TitleIconDiv } from '../../../hypotheses/components/styled/HypothesisDetailsPage';
import { handleBmcLabels } from '../../../hypotheses/components/pages/HypothesisDetailsPage';
import { sizeComments } from '../../../common/functions';

const AlertMessage = ({ show, setShow, onSubmit }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit();
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere detaliu</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea detaliului?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ExperimentDetailPage = (props) => {
  const {
    push,
    experimentDetail,
    loadExperimentDetail,
    isEditing,
    isDeleting,
    isLoading,
    isCreating,
    editExperimentDetail,
    addExperimentDetailComment,
    editExperimentDetailComment,
    deleteExperimentDetailComment,
    deleteExperimentDetail,
    isMentor,
    isAccelerator,
    match: {
      params: { hypothesisId, experimentId },
    },
  } = props;

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const [isEditModeComment, setIsEditModeComment] = useState(false);
  const [comment, setComment] = useState(undefined);

  const [hovered, setHovered] = useState(false);

  const handleHookSet = (type) => {
    switch (type) {
      case 'detail':
        setIsDetailModalOpen(true);
        break;

      case 'comment':
        setIsCommentModalOpen(true);
        break;
      default:
        setIsDetailModalOpen(false);
        setIsCommentModalOpen(false);
    }
  };

  const handleOpenEditCommentModal = (type, commentId) => {
    handleHookSet(type);
    setComment(commentId);
    setIsEditModeComment(true);
  };

  const handleDeleteExperimentDetail = async () => {
    await deleteExperimentDetail(experimentDetail._id);
    push(`/hypothesis/${hypothesisId}/experiment/${experimentId}`);
  };

  const handleDeleteComment = async (commentId) => {
    await deleteExperimentDetailComment(commentId);
    await loadExperimentDetail(experimentDetail._id);
  };

  const handleCardClick = (url) => push(url);

  const detailCategory = find(DETAIL_CATEGORY_OPTIONS, {
    value: experimentDetail.detailCategory,
  });

  const contactType = find(CONTACT_TYPE_OPTIONS, {
    value: experimentDetail.contactType,
  });
  const engagementLevel = find(ENGAGEMENT_LEVEL_OPTIONS, {
    value: experimentDetail.engagementLevel,
  });
  const canContactLater = find(CAN_CONTACT_LATER_OPTIONS, {
    value: experimentDetail.canContactLater,
  });
  const buyingSignals = find(BUYING_SIGNALS_OPTIONS, {
    value: experimentDetail.buyingSignals,
  });
  const isEarlyAdopter = find(IS_EARLY_ADOPTER_OPTIONS, {
    value: experimentDetail.isEarlyAdopter,
  });

  console.log(experimentDetail);
  return (
    <>
      <div>
        <div>
          <TitleButtonsDiv paddingRight marginBottom="15px">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ExperimentDetailIcon />
              <Title>{experimentDetail.title} </Title>
            </div>
            <div>
              {!isMentor && !isAccelerator && (
                <>
                  <EditButtonLarge
                    src={hovered ? EditIconLargeHover : EditIconLarge}
                    onClick={() => handleHookSet('detail')}
                    onMouseEnter={() => setHovered(!hovered)}
                    onMouseLeave={() => setHovered(!hovered)}
                  />
                  <DeleteButton onClick={() => setOpenAlertMessage(true)} />
                </>
              )}
            </div>
          </TitleButtonsDiv>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', width: '70%' }}
            >
              <Heading2>Ipoteze:</Heading2>
              {map(experimentDetail.experimentId.hypothesisId, (hypothesis) => (
                <PlaceHolderInput
                  key={hypothesis._id}
                  hypothesisCard
                  maxWidth="30%"
                  onClick={() =>
                    handleCardClick(`/hypothesis/${hypothesis._id}`)
                  }
                >
                  <Heading4Regular>{`${handleBmcLabels(
                    hypothesis.columnBMC
                  )}: ${hypothesis.title}`}</Heading4Regular>
                </PlaceHolderInput>
              ))}
            </div>
            <Heading4Regular inactive>{`${experimentDetail.createdBy} ${moment(
              experimentDetail.createdAt
            ).format('DD/MM/YYYY  H:mm')}`}</Heading4Regular>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '30px',
              paddingLeft: '30px',
            }}
          >
            <ReplyIconMessage />
            <Heading2 marginLeft="15px">Experiment:</Heading2>
            <AcceptButton
              dashed
              onClick={() =>
                handleCardClick(
                  `/hypothesis/${hypothesisId}/experiment/${experimentDetail.experimentId._id}`
                )
              }
            >
              <Heading4Regular>
                {experimentDetail.experimentId.title}
              </Heading4Regular>
            </AcceptButton>
          </div>
        </div>

        <RowDiv>
          <LeftSection>
            <WhiteCard height="auto" borderRadius="3px" padding="20px">
              <Title marginBottom="20px" fontSize="24px" lineHeight="28px">
                Detaliu experiment
              </Title>
              <Heading2 marginBottom="20px">Descriere detaliu</Heading2>

              <Heading4Regular textArea marginBottom="30px" noEllipsis>
                {experimentDetail.detail}
              </Heading4Regular>
              <Row>
                <Col md={6}>
                  <Heading2 marginBottom="20px">Categorie detaliu</Heading2>
                  <TitleIconDiv detailPage>
                    {get(detailCategory, 'renderIcon', () => {})()}
                    <Heading4Regular marginLeft="5px">
                      {get(detailCategory, 'label', '-')}
                    </Heading4Regular>
                  </TitleIconDiv>
                </Col>
                <Col md={6}>
                  <Heading2 marginBottom="20px">
                    Are profilul unui “early-adopter”?
                  </Heading2>
                  <TitleIconDiv detailPage>
                    {get(isEarlyAdopter, 'renderIcon', () => {})()}
                    <Heading4Regular marginLeft="5px">
                      {get(isEarlyAdopter, 'label', '-')}
                    </Heading4Regular>
                  </TitleIconDiv>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Tip contact
                  </Heading2>
                  <TitleIconDiv detailPage>
                    {get(contactType, 'renderIcon', () => {})()}
                    <Heading4Regular marginLeft="5px">
                      {get(contactType, 'label', '-')}
                    </Heading4Regular>
                  </TitleIconDiv>
                </Col>
                <Col md={6}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Există disponibilitate de contact ulterior?
                  </Heading2>
                  <TitleIconDiv detailPage>
                    {get(canContactLater, 'renderIcon', () => {})()}
                    <Heading4Regular marginLeft="5px">
                      {get(canContactLater, 'label', '-')}
                    </Heading4Regular>
                  </TitleIconDiv>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Cât de interesat părea interlocutorul?
                  </Heading2>
                  <TitleIconDiv detailPage>
                    {get(engagementLevel, 'renderIcon', () => {})()}
                    <Heading4Regular marginLeft="5px">
                      {get(engagementLevel, 'label', '-')}
                    </Heading4Regular>
                  </TitleIconDiv>
                </Col>
                <Col md={6}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Au existat semnale de cumpărare?
                  </Heading2>
                  <TitleIconDiv detailPage>
                    {get(buyingSignals, 'renderIcon', () => {})()}
                    <Heading4Regular marginLeft="5px">
                      {get(buyingSignals, 'label', '-')}
                    </Heading4Regular>
                  </TitleIconDiv>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Link-uri
                  </Heading2>

                  {size(experimentDetail.resources) !== 0 ? (
                    map(experimentDetail.resources, (resource, index) => (
                      <Link
                        style={{ display: 'block', marginBottom: '10px' }}
                        key={index}
                        maxWidth="100%"
                        href={resource}
                        target="_blank"
                      >
                        {resource}
                      </Link>
                    ))
                  ) : (
                    <Heading4Regular inactive>https://</Heading4Regular>
                  )}
                </Col>
              </Row>
            </WhiteCard>
          </LeftSection>
          <RightSection>
            <WhiteCard
              noOverflow
              height="100%"
              borderRadius="3px"
              padding="20px"
              style={{ height: '413px' }}
            >
              <TitleButtonsDiv marginBottom="39px">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title fontSize="24px" lineHeight="28px">
                    Comentarii
                  </Title>
                  <CounterDiv>
                    <CounterText>
                      {sizeComments(experimentDetail.comments)}
                    </CounterText>
                  </CounterDiv>
                </div>
                <AddCommentButton onClick={() => handleHookSet('comment')} />
              </TitleButtonsDiv>
              {!size(experimentDetail.comments) > 0 && (
                <TextInactive>
                  Momentan nu există niciun comentariu pentru această secțiune.
                  Poți adăuga un comentariu făcând click pe butonul „+” din
                  partea dreaptă a secțiunii.
                </TextInactive>
              )}
              <div
                style={{
                  maxHeight: '85%',
                  overflow: 'auto',
                  paddingRight: '4px',
                }}
                className="scrollbar-modal"
              >
                {map(experimentDetail.comments, (comment, index) => (
                  <CommentRenderComponent
                    key={index}
                    comment={comment}
                    handleDeleteComment={handleDeleteComment}
                    handleEdit={() =>
                      handleOpenEditCommentModal('comment', comment)
                    }
                    handleAddReply={props.addExperimentDetailCommentReply}
                    handleEditReply={(values, replyId) =>
                      props.editExperimentDetailCommentReply(
                        values,
                        comment._id,
                        replyId
                      )
                    }
                    handleDeleteReply={(replyId) =>
                      props.deleteExperimentDetailCommentReply(
                        comment._id,
                        replyId
                      )
                    }
                    loadData={() => loadExperimentDetail(experimentDetail._id)}
                    isLoading={isEditModeComment ? isEditing : isCreating}
                  />
                ))}
              </div>
            </WhiteCard>
          </RightSection>
        </RowDiv>
      </div>

      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteExperimentDetail}
      />
      <AddDetailModal
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        isEditMode
        entityId={experimentDetail._id}
        loadData={loadExperimentDetail}
        detail={experimentDetail}
        editDetail={editExperimentDetail}
        isLoading={isEditing}
      />

      <AddCommentModal
        isModalOpen={isCommentModalOpen}
        setIsModalOpen={setIsCommentModalOpen}
        parentId={experimentDetail._id}
        onSubmit={addExperimentDetailComment}
        isLoading={isEditModeComment ? isEditing : isCreating}
        loadData={loadExperimentDetail}
        isEditModeComment={isEditModeComment}
        comment={isEditModeComment ? comment : undefined}
        setIsEditModeComment={setIsEditModeComment}
        editComment={editExperimentDetailComment}
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  experimentDetail: state.experimentDetail.experimentDetail,
  isCreating: state.experimentDetail.isCreating,
  isEditing: state.experimentDetail.isEditing,
  isLoading: state.experimentDetail.isLoading,
  isDeleting: state.experimentDetail.isDeleting,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});

const mapDispatchToProps = {
  loadExperimentDetail,
  editExperimentDetail,
  deleteExperimentDetail,
  addExperimentDetailComment,
  editExperimentDetailComment,
  deleteExperimentDetailComment,
  addExperimentDetailCommentReply,
  editExperimentDetailCommentReply,
  deleteExperimentDetailCommentReply,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExperimentDetailPage)
);
