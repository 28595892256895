import styled, { css } from 'styled-components';

export const GridUnresponsive = styled.div`
  width: 100%;

  ${(props) =>
    props.padded &&
    css`
      padding: 0 15px;
    `}

  .row {
    margin-left: 0;
    margin-right: 0;
  }
`;
