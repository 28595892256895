import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  height: calc(100% - 70px);
  padding: 10px;
  overflow-y: auto;
`;

export const LinkIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledRow = styled(Row)`
  height: calc(100vh - 128px);
`;
