import { createGlobalStyle } from 'styled-components';
import fontLight from '../fonts/assets/OpenSans-Light.ttf';
import fontSemiBold from '../fonts/assets/OpenSans-SemiBold.ttf';
import fontRegular from '../fonts/assets/OpenSans-Regular.ttf';
import fontBold from '../fonts/assets/OpenSans-Bold.ttf';
import fontRaleway from '../fonts/assets/Raleway-Regular.ttf';
import fontDosis from '../fonts/assets/Dosis-Regular.ttf';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Open Sans';
  src: url(${fontLight});
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url(${fontRegular});
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis';
  src:
    url(${fontDosis});
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(${fontSemiBold});
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:  url(${fontBold});
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src:  url(${fontRaleway});
  font-weight: 500;
  font-style: normal;
}

body { 
  font-family: "Open Sans", sans-serif;
 

}

.activeNavItem {
  background: #EFEFF4;
  border-left:  2px solid #2A5AB7;
}

.disabledNavItem  {
  cursor: not-allowed !important;
}
.disabledNavItem > a > svg  {
  cursor: not-allowed !important;
}

.disabledNavItem > a   {
  cursor: not-allowed !important;
}

.disabledNavItem > a > span {
  color: #8E8EB5 !important;
  cursor: not-allowed !important;
}

.disabledNavItem:hover > a > span {
font-family: Open Sans;
font-style: normal;
font-weight: normal !important;
font-size: 14px;
line-height: 19px;
color: #8E8EB5 !important;

}

.disabledNavItem > a > svg > path {
  fill: #8E8EB5 !important;
}



.activeNavItem > span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #131336;
}

  .dropdown-toggle::after {
    display: none;
}

  .scrollbar::-webkit-scrollbar {
  width: 10px;           
}
  .scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
  .scrollbar::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;    
  border-radius: 8px;
  background-clip: padding-box;
  border: 3px solid #EFEFF4;
  
}

 .scrollbar-modal::-webkit-scrollbar {
  width: 10px;           
}
  .scrollbar-modal::-webkit-scrollbar-track {
  background-color: transparent;
}
  .scrollbar-modal::-webkit-scrollbar-thumb {
  background-color: #8E8EB5;    
  border-radius: 8px;
  background-clip: padding-box;
  border: 3px solid #FFFFFF;
}


 



  * {
    box-sizing: border-box;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  textarea:focus {
    outline: none !important;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
  }

  /* Toastify Styles */
  .toastify {
    padding: .75rem 1.25rem !important;
    margin-bottom: 1rem !important;
    border: 1px solid transparent !important;
    border-radius: .25rem !important;

    .toastify-body {
      padding: 0 !important;
      margin: 0 !important;
      align-self: center;
    }

    &.toastify-type-success {
      color: #155724 !important;
      background-color: #d4edda !important;
      border-color: #c3e6cb !important;

      .toastify-progress {
        background-color: #809379 !important;
      }
    }

    &.toastify-type-warning  {
      color: #856404 !important;
      background-color: #fff3cd !important;
      border-color: #ffeeba !important;

      .toastify-progress {
        background-color: #c09f74 !important;
      }
    }

    &.toastify-type-error {
      color: #721c24 !important;
      background-color: #f8d7da !important;
      border-color: #f5c6cb !important;

      .toastify-progress {
        background-color: #b5787c !important;
      }
    }
  }
  

  /* Bootstrap Theme */
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #8190a0;
  }

  /* Overwrite modals width */
  .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
    padding-left: 200px;
    padding-right: 50px;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .modal-header .close {
    display:none;
  
  


}

  textarea {
    resize: none;
  }
`;

export default GlobalStyle;
