import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { map, size } from 'lodash-es';
import moment from 'moment';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { joinTeam } from '../../../team/ducks';
import { deleteInvitation, loadInvitations } from '../../ducks';
import { ReactComponent as AddIcon } from '../../../common/assets/icons/Button_Add-2.svg';
import {
  loadUserTeams,
  currentTeamIdSelector,
} from '../../../common/ducks/userTeams';

import {
  IdeeaDescription,
  InvitationContainer,
  AcceptButton,
  ButtonText,
  DenyButton,
  ButtonsDiv,
  LabelFieldDiv,
  TitleCategoriesDiv,
  IndustryText,
  ImageFieldsDiv,
  FieldsDiv,
  WrapContainer,
  DivBorderBottom,
} from '../styled';
import AvatarImage from '../../../common/components/AvatarImage';
import {
  Heading1,
  Heading2,
  Heading4Regular,
  Link,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  TagButton,
  Loading,
} from '../../../common/components/styled';

const MentorsInvitationsPage = (props) => {
  const {
    invitations,
    joinTeam,
    deleteInvitation,
    loadInvitations,
    loadUserTeams,
    userId,
    isLoading,
    push,
    teamId,
  } = props;

  const handleAcceptInvitation = async (teamCode, invitationId, type) => {
    if (type === 'accept') {
      await joinTeam(teamCode);
      await deleteInvitation(invitationId);
      await loadInvitations(userId);
      await loadUserTeams(userId);
      if (!teamId) push('/teams');
    } else if (type === 'decline') {
      await deleteInvitation(invitationId);
      await loadInvitations(userId);
    }
  };

  return (
    <>
      <TitleCounterDiv>
        <Title>Invitațiile mele</Title>
        <CounterDiv>
          <CounterText>{size(invitations)}</CounterText>
        </CounterDiv>
      </TitleCounterDiv>

      <WrapContainer>
        {map(invitations, (invitation, index) => (
          <InvitationContainer key={index}>
            <DivBorderBottom>
              <TitleCategoriesDiv>
                <Heading1>{invitation.teamId.name}</Heading1>
              </TitleCategoriesDiv>

              <div style={{ display: 'flex', marginBottom: '10px' }}>
                {map(invitation.teamId.ideaCategory, (category, index) => (
                  <TagButton key={index}>
                    <IndustryText>{category}</IndustryText>
                  </TagButton>
                ))}
              </div>

              {invitation.createdBy && (
                <Heading4Regular>{`${invitation.createdBy.firstName} ${invitation.createdBy.lastName} te invită să îl ajuți în dezvoltarea ideii pe care o are`}</Heading4Regular>
              )}
            </DivBorderBottom>
            <DivBorderBottom>
              <ImageFieldsDiv>
                <AvatarImage
                  src={invitation.teamId.logo}
                  icon={faUsers}
                  medium
                />
                <FieldsDiv>
                  <LabelFieldDiv>
                    <Link href={invitation.teamId.domain} target="_blank">
                      {invitation.teamId.domain}
                    </Link>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Stadiu</Heading2>
                    <Heading4Regular>
                      {' '}
                      {invitation.teamId.stage}
                    </Heading4Regular>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Data Infintare</Heading2>
                    <Heading4Regular>
                      {invitation.teamId.dateOfEstablishment &&
                        moment(invitation.teamId.dateOfEstablishment).format(
                          'DD/MM/YYYY'
                        )}
                    </Heading4Regular>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Locatie</Heading2>
                    <Heading4Regular>
                      {' '}
                      {invitation.teamId.headquarters}
                    </Heading4Regular>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Cui</Heading2>
                    <Heading4Regular>{invitation.teamId.cui}</Heading4Regular>
                  </LabelFieldDiv>
                </FieldsDiv>
              </ImageFieldsDiv>
              <LabelFieldDiv>
                <Heading2>Descriere</Heading2>
              </LabelFieldDiv>
              <IdeeaDescription className="scrollbar-modal">
                <Heading4Regular noEllipsis>
                  {invitation.teamId.description}
                </Heading4Regular>
              </IdeeaDescription>
              <LabelFieldDiv noMargin>
                <Heading2>IRL</Heading2>
                <Heading4Regular>{`${invitation.teamId.irl}/14 `}</Heading4Regular>
              </LabelFieldDiv>
            </DivBorderBottom>
            <ButtonsDiv>
              <AcceptButton
                invitationButton
                onClick={() =>
                  handleAcceptInvitation(
                    invitation.teamId.teamCode,
                    invitation._id,
                    'accept'
                  )
                }
              >
                <AddIcon />
                <ButtonText color="#FFFFFF">Acceptă</ButtonText>
              </AcceptButton>
              <DenyButton
                onClick={() =>
                  handleAcceptInvitation(
                    invitation.teamId.teamCode,
                    invitation._id,
                    'decline'
                  )
                }
              >
                <ButtonText noMargin color="#BA3232">
                  Refuză
                </ButtonText>
              </DenyButton>
            </ButtonsDiv>
          </InvitationContainer>
        ))}
      </WrapContainer>

      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.mentors.isLoading,
  invitations: state.mentors.invitations,
  userId: state.account.login.user._id,
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  joinTeam,
  push,
  loadInvitations,
  deleteInvitation,
  loadUserTeams,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentorsInvitationsPage)
);
