import { get, map, camelCase, isArray, filter } from 'lodash-es';
import { http } from '../../core/services/http';
import mapper from '../../utils/styles/mapper';

export const loadHypotheses = async (teamId) => {
  const res = await http.get(`/hypothesis/get-all/${teamId}`);
  const data = map(res.data, (hypothesis) => ({
    ...hypothesis,
    outcome: mapper(
      camelCase(hypothesis.outcome),
      {
        inProgress: 'inProgress',
        validated: 'validated',
        invalidated: 'invalidated',
      },
      'inProgress'
    ),
    importance: mapper(
      camelCase(hypothesis.importance),
      { high: 'Mare', low: 'Mică', medium: 'Medie' },
      'medie'
    ),
    hypothesisCategory: mapper(
      camelCase(hypothesis.hypothesisCategory),
      { dezirabila: 'dezirabila', fezabila: 'fezabila', viabila: 'viabila' },
      'viabila'
    ),
  }));
  return data;
};

export const addHypothesis = (values, teamId, createdBy) => {
  const registerData = {
    ...values,
    hypothesisCategory: get(values.hypothesisCategory, 'value'),
    segment: isArray(values.segment)
      ? map(values.segment, (el) => get(el, 'value'))
      : get(values.segment, 'value'),
    importance: get(values.importance, 'value'),
    columnBMC: get(values.columnBMC, 'value'),
    teamId,
    createdBy,
    KPIIndicators: filter(
      values.KPIIndicators,
      (elem) => elem.title !== undefined || elem.targetValue !== undefined
    ),
  };
  return http.post('/hypothesis/add', registerData);
};

export const editHypothesis = (values, entityId, parent, personResponsible) => {
  const registerDataHypothesis = {
    ...values,
    columnBMC: get(values.columnBMC, 'value'),
    importance: get(values.importance, 'value'),
    hypothesisCategory: get(values.hypothesisCategory, 'value'),
    segment: isArray(values.segment)
      ? map(values.segment, (el) => get(el, 'value'))
      : get(values.segment, 'value'),
    KPIIndicators: filter(
      values.KPIIndicators,
      (elem) => elem.title !== undefined || elem.targetValue !== undefined
    ),
  };
  const registerDataResolution = {
    ...parent,
    resolution: {
      ...values.resolution,
      personResponsible,
    },
  };
  if (values.outcome) {
    registerDataResolution.resolution.outcome = values.outcome;
  }

  // when updating resolution, 'parent' parameter is sent manually
  const registerData =
    parent === undefined ? registerDataHypothesis : registerDataResolution;
  return http.put(`/hypothesis/edit/${entityId}`, registerData);
};

export const addHypothesisComment = (values, hypothesisId) => {
  const registerData = {
    ...values,
    hypothesisId,
  };
  return http.post('/hypothesis-comment/add', registerData);
};

export const editHypothesisComment = (values, hypothesisCommentId) =>
  http.put(`/hypothesis-comment/edit/${hypothesisCommentId}`, values);

export const deleteHypothesisComment = (hypothesisCommentId) =>
  http.delete(`/hypothesis-comment/delete/${hypothesisCommentId}`);

export const loadHypothesis = (hypothesisId) =>
  http.get(`/hypothesis/get/${hypothesisId}`);

export const deleteHypothesis = (hypothesisId) =>
  http.delete(`/hypothesis/delete/${hypothesisId}`);

export const editHypothesisEmpathyMap = (hypothesisId, empathyMap, name) =>
  http.put(`/hypothesis/empathy-map/${hypothesisId}/${name}`, empathyMap);

export const editHypothesisEmpathyMapItem = (hypothesisId, item, name) =>
  http.put(`/hypothesis/edit-empathy-map/${hypothesisId}/${name}`, item);

export const addHypothesisCommentReply = (values, hypothesisCommentId) =>
  http.post(`/hypothesis-comment/reply/add/${hypothesisCommentId}`, values);

export const editHypothesisCommentReply = (
  values,
  hypothesisCommentId,
  replyId
) =>
  http.put(
    `/hypothesis-comment/reply/edit/${hypothesisCommentId}/${replyId}`,
    values
  );

export const deleteHypothesisCommentReply = (hypothesisCommentId, replyId) =>
  http.delete(
    `/hypothesis-comment/reply/delete/${hypothesisCommentId}/${replyId}`
  );
