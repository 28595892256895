/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const SwitchButtonContainer = styled.div`
  height: ${(props) => (props.size ? `${props.size}px` : '30px')};
  width: ${(props) => (props.size ? `${props.size * 1.7}px` : '51px')};
  background-color: ${(props) => (props.color ? props.color : 'lightgray')};
  border-radius: ${(props) => (props.size ? `${props.size}px` : '30px')};
  position: relative;
`;
export const SwitchButtonCircle = styled.div`
  transition: all 300ms linear;
  height: ${(props) => (props.size ? `${props.size * 0.9}px` : '26px')};
  width: ${(props) => (props.size ? `${props.size * 0.9}px` : '26px')};
  background-color: ${(props) =>
    props.color ? props.color : props.theme.brandPrimary};
  border-radius: ${(props) => (props.size ? `${props.size * 0.9}px` : '26px')};
  position: absolute;
  top: ${(props) => (props.size ? `${props.size * 0.05}px` : '2px')};
  left: ${(props) =>
    props.checked
      ? props.size
        ? `${props.size * 0.7}px`
        : '22px'
      : props.size
      ? `${props.size * 0.05}px`
      : '2px'};
`;
