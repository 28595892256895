import ValidHypothesis from '../../common/assets/icons/Ipoteza-Valida_S.svg';
import InvalidHypothesis from '../../common/assets/icons/Ipoteza-Invalida_S.svg';
import ValidHypothesisMVP from '../../common/assets/icons/Ipoteza-valida-w-MVP.svg';
import InvalidHypothesisMVP from '../../common/assets/icons/Ipoteza-Invalida-MVP.svg';

export const actionOptions = [
  {
    value: 'action1',
    label: 'acțiune hotărâtă',
  },
  {
    value: 'action2',
    label: 'necesită aprofundare',
  },
  {
    value: 'action3',
    label: 'nicio acțiune',
  },
];

export const dataOptions = [
  {
    value: 'data1',
    label: 'foarte concludente',
  },
  {
    value: 'data2',
    label: 'concludente',
  },
  {
    value: 'data3',
    label: 'neconcludente',
  },
];

export const outcomeOptions = [
  {
    value: 'validatedWithoutMVP',
    label: 'Validată fără MVP',
    icon: ValidHypothesisMVP,
  },
  {
    value: 'invalidatedWithoutMVP',
    label: 'Invalidată fără MVP',
    icon: InvalidHypothesisMVP,
  },
  {
    value: 'validatedWithMVP',
    label: 'Validată cu MVP',
    icon: ValidHypothesis,
  },
  {
    value: 'invalidatedWithMVP',
    label: 'Invalidată cu MVP',
    icon: InvalidHypothesis,
  },
];
