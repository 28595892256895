import { combineReducers } from 'redux';

// Import Reducers
import { reducer as userTeamsReducer } from './userTeams';
import { reducer as modalReducer } from './modal';
import { reducer as menuReducer } from './navigation';

// Export Action Creators
export {
  loadUserTeams,
  setCurrentUserTeam,
  resetUserTeams,
  currentTeamIdSelector,
  currentTeamCohortIdSelector,
} from './userTeams';

export { openModal, closeModal, getModalOpenStateSelector } from './modal';
export { handleMenu } from './navigation';

export const reducer = combineReducers({
  userTeams: userTeamsReducer,
  modal: modalReducer,
  menu: menuReducer,
});
