/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useCallback, useState } from 'react';
import { get, map, find } from 'lodash-es';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { ReactComponent as TeamProfileIcon } from '../../assets/icons/Navigation_Detalii.svg';
import { ReactComponent as BmcIcon } from '../../assets/icons/Navigation_BMC.svg';
import { ReactComponent as ExperimentsIcon } from '../../assets/icons/Navigation_Experiment.svg';
import { ReactComponent as HypothesisIcon } from '../../assets/icons/Navigation_Hypothesis.svg';
import { ReactComponent as MyIdeeasIcon } from '../../assets/icons/Navigation_Idei.svg';
import { ReactComponent as LeaderBoardIcon } from '../../assets/icons/Navigation_Clasament.svg';
import { ReactComponent as DetailIcon } from '../../assets/icons/Menu_Detaliu_IpotezaMenu_Detaliu_Ipoteza_Small_Hover.svg';
import { ReactComponent as KPIIcon } from '../../assets/icons/KPI.svg';
import MemberIcon from '../../assets/icons/Avatar_Membru.svg';

import { ImageIconRound } from '../../../mentors/components/styled';

import {
  NavigationBarLink,
  PositionText,
  PositionBadge,
  PositionMenu,
  Title,
  Heading2,
} from '../styled';
import {
  setCurrentUserTeam,
  currentTeamIdSelector,
  resetUserTeams,
} from '../../ducks';
import { TeamFilterForm } from '../forms';

import { getUserRole } from '../../services/roleBadgeColors';

const FounderNavigationMenu = (props) => {
  const {
    teamId,
    userTeams,
    userId,
    setCurrentUserTeam,
    resetUserTeams,
    push,
    location,
    userProfile,
    role,
    isMenuOpen,
    handleHoverIcon,
  } = props;

  const [selectedTeam, setSelectedTeam] = useState(undefined);

  useEffect(() => {
    if (!teamId && !userTeams) push('/profile');
    if (!!teamId && location.pathname === '/home') push('/canvas');
    setSelectedTeam(find(userTeams, (team) => !!team.selected));
    return () => resetUserTeams();
  }, []);

  const getTeamOptions = useCallback(
    () =>
      map(userTeams, (team) => ({
        label: team.name,
        value: team._id,
      })),
    [userTeams]
  );

  const getTeamValue = useCallback(() => {
    const team = find(userTeams, (team) => !!team.selected);
    return { label: get(team, 'name'), value: get(team, '_id') };
  }, [userTeams]);

  const getSelectedTeamCohortId = useCallback(
    () =>
      get(
        find(userTeams, (team) => !!team.selected),
        'cohortId'
      ),
    [userTeams]
  );

  const handleChangeTeam = (team) => {
    setCurrentUserTeam(userId, team.value);
    setSelectedTeam(find(userTeams, { _id: team.value }));
  };

  return (
    <>
      <li className="side-nav-item" style={{ marginBottom: '30px' }}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          imageitem="true"
          to="/profile"
          className="side-sub-nav-link side-nav-link my-1"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
        >
          {!!userProfile && (
            <p style={{ wordBreak: 'break-word' }} className="mb-0">
              <ImageIconRound
                onMouseEnter={handleHoverIcon}
                style={{ marginRight: '5px' }}
                src={
                  userProfile.profilePicture
                    ? userProfile.profilePicture
                    : MemberIcon
                }
              />
              <PositionText fontWeight="600" name="true" open={isMenuOpen}>
                {`${userProfile.firstName} ${userProfile.lastName}`}
              </PositionText>
            </p>
          )}
          <PositionBadge open={isMenuOpen} className="mb-1">
            <Heading2 color="#15BB89">
              {getUserRole(
                role,
                userId,
                selectedTeam && get(selectedTeam, 'createdBy'),
                selectedTeam &&
                  get(find(selectedTeam.members, { userId }), 'equity')
              )}
            </Heading2>
          </PositionBadge>
        </NavigationBarLink>
      </li>
      <PositionMenu onMouseEnter={handleHoverIcon} open={isMenuOpen}>
        <TeamFilterForm
          userTeams={getTeamOptions()}
          userTeamValue={getTeamValue()}
          handleChangeTeam={handleChangeTeam}
          cohortId={getSelectedTeamCohortId()}
        />
      </PositionMenu>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/team-profile"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <TeamProfileIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Detalii idee</PositionText>
        </NavigationBarLink>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/canvas"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <BmcIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>BMC</PositionText>
        </NavigationBarLink>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/hypotheses"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <HypothesisIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Ipoteze</PositionText>
        </NavigationBarLink>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/experiments"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <ExperimentsIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Experimente</PositionText>
        </NavigationBarLink>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/details"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <DetailIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Detalii</PositionText>
        </NavigationBarLink>
      </li>
      <li
        className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}
        style={{ marginBottom: '40px' }}
      >
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/kpis"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <KPIIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Indicatori</PositionText>
        </NavigationBarLink>
      </li>
      <Title
        navigationTitle
        marginLeft={isMenuOpen ? '16px' : '13px'}
        fontSize="16px"
      >
        {isMenuOpen ? 'ADMINISTRARE' : 'ADMIN'}
      </Title>

      <li className="side-nav-item">
        <NavigationBarLink
          activeClassName="activeNavItem"
          className={`side-sub-nav-link side-nav-link `}
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/teams"
        >
          <MyIdeeasIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Ideile mele</PositionText>
        </NavigationBarLink>
      </li>

      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: isMenuOpen ? '8px 16px' : '8px 30px',
          }}
          to="/leaderboard"
          onClick={(e) => !teamId && e.preventDefault()}
        >
          <LeaderBoardIcon onMouseEnter={handleHoverIcon} />
          <PositionText open={isMenuOpen}>Clasament echipe</PositionText>
        </NavigationBarLink>
      </li>
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  userProfile: get(state.userProfile, 'userProfile'),
  userTeams: state.common.userTeams.userTeams,
  teamId: currentTeamIdSelector(state.common.userTeams),
  role: get(state.account.login, 'user.role'),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  setCurrentUserTeam,
  resetUserTeams,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FounderNavigationMenu)
);
