import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { AddResourceForm } from '../forms';
import { loadResources, editResource, addResource } from '../../ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddResourceModal = (props) => {
  const {
    isCreating,
    isEditing,
    addResource,
    editResource,
    loadResources,
    isModalOpen,
    setIsModalOpen,
    resource,
    resetResource,
    resources,
  } = props;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetResource();
  };

  const isEditMode = useMemo(() => !!resource, [resource]);

  const onSubmit = (values) => {
    const promise = isEditMode ? editResource(values) : addResource(values);

    promise.then(() => {
      handleCloseModal();
      loadResources();
    });
    return promise;
  };

  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="modal-responsive scrollbar-modal">
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">
            {isEditMode ? 'Editează resursa' : 'Adaugă o resursă nouă'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <AddResourceForm
          onSubmit={onSubmit}
          isEditMode={isEditMode}
          resource={resource}
          resources={resources}
        />
      </Modal.Body>

      <Loading isLoading={isEditMode ? isEditing : isCreating} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  resources: state.admin.resources.resources,
  isEditing: state.admin.resources.isEditing,
  isCreating: state.admin.resources.isCreating,
});

const mapDispatchToProps = {
  addResource,
  editResource,
  loadResources,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceModal);
