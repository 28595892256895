import update from 'immutability-helper';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';
import {
  loadMentors as doLoadMentors,
  addMentorInvitation as doAddMentorInvitations,
  loadInvitations as doLoadInvitations,
  deleteInvitation as doDeleteInvitation,
} from '../services/mentors';

// Actions
const START_LOAD = 'mentors/START_LOAD';
const COMPLETE_LOAD = 'mentors/COMPLETE_LOAD';
const FAIL_LOAD = 'mentors/FAIL_LOAD';

const START_LOAD_INVITATIONS = 'mentors-invitations/START_LOAD_INVITATIONS';
const COMPLETE_LOAD_INVITATIONS =
  'mentors-invitations/COMPLETE_LOAD_INVITATIONS';
const FAIL_LOAD_INVITATIONS = 'mentors-invitations/FAIL_LOAD_INVITATIONS';

const START_ADD = 'mentors-invitations/START_ADD';
const COMPLETE_ADD = 'mentors-invitations/COMPLETE_ADD';
const FAIL_ADD = 'mentors-invitations/FAIL_ADD';

const START_DELETE = 'mentors-invitations/START_DELETE';
const COMPLETE_DELETE = 'mentors-invitations/COMPLETE_DELETE';
const FAIL_DELETE = 'mentors-invitations/FAIL_DELETE';

const RESET = 'mentors/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isDeleting: false,
  isCreating: false,
  mentors: undefined,
  invitations: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mentors: action.mentors,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mentors: undefined,
        },
      });

    case START_ADD:
      return update(state, {
        $merge: {
          isCreating: true,
        },
      });

    case COMPLETE_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case FAIL_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case START_LOAD_INVITATIONS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_INVITATIONS:
      return update(state, {
        $merge: {
          isLoading: false,
          invitations: action.invitations,
        },
      });

    case FAIL_LOAD_INVITATIONS:
      return update(state, {
        $merge: {
          isLoading: false,
          invitations: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (mentors) => ({
  type: COMPLETE_LOAD,
  mentors,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startLoadInvitations = () => ({
  type: START_LOAD_INVITATIONS,
});

const completeLoadInvitations = (invitations) => ({
  type: COMPLETE_LOAD_INVITATIONS,
  invitations,
});

const failLoadInvitations = () => ({
  type: FAIL_LOAD_INVITATIONS,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

export const loadMentors = (teamId) => (dispatch) => {
  dispatch(startLoad());
  const loadMentorsPromise = doLoadMentors(teamId);

  loadMentorsPromise
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
    });
  return loadMentorsPromise;
};

export const loadInvitations = (mentorId) => (dispatch) => {
  dispatch(startLoadInvitations());
  const loadInvitationsPromise = doLoadInvitations(mentorId);

  loadInvitationsPromise
    .then((res) => {
      dispatch(completeLoadInvitations(res.data));
    })
    .catch((error) => {
      dispatch(failLoadInvitations());
      dispatch(notifyError(error.response.data));
    });
  return loadInvitationsPromise;
};

export const deleteInvitation = (invitationId) => (dispatch) => {
  dispatch(startDelete());
  const deleteInvitationPromise = doDeleteInvitation(invitationId);

  deleteInvitationPromise
    .then(() => {
      dispatch(completeDelete());
    })
    .catch((error) => {
      dispatch(failDelete());
      dispatch(notifyError(error.response.data));
    });
  return deleteInvitationPromise;
};

export const addMentorInvitation = (mentorId, teamId) => (dispatch) => {
  dispatch(startAdd());
  const addMentorInvitationPromise = doAddMentorInvitations(mentorId, teamId);

  addMentorInvitationPromise
    .then(() => {
      dispatch(completeAdd());
      dispatch(notifySuccess('Invitatia a fost trimisa cu succes'));
    })
    .catch((error) => {
      dispatch(failAdd());
      dispatch(notifyError(error.response.data));
    });
  return addMentorInvitationPromise;
};
