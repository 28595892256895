import update from 'immutability-helper';
import { push } from 'react-router-redux';

import {
  loadExperimentDetails as doLoadExperimentDetails,
  loadHypothesisDetails as doLoadHypothesisDetails,
} from '../services';
import { notifyError } from '../../core/ducks/notifications';

// Actions
const START_LOAD = 'details/START_LOAD';
const COMPLETE_LOAD_EXPERIMENT_DETAILS =
  'details/COMPLETE_LOAD_EXPERIMENT_DETAILS';
const COMPLETE_LOAD_HYPOTHESIS_DETAILS =
  'details/COMPLETE_LOAD_HYPOTHESIS_DETAILS';
const FAIL_LOAD = 'details/FAIL_LOAD';
const RESET = 'details/RESET';

// Initial state
const initialState = {
  isLoading: false,
  hypothesisDetails: [],
  experimentDetails: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_EXPERIMENT_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          experimentDetails: action.experimentDetails,
        },
      });

    case COMPLETE_LOAD_HYPOTHESIS_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          hypothesisDetails: action.hypothesisDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadExperimentDetails = (experimentDetails) => ({
  type: COMPLETE_LOAD_EXPERIMENT_DETAILS,
  experimentDetails,
});

const completeLoadHypothesisDetails = (hypothesisDetails) => ({
  type: COMPLETE_LOAD_HYPOTHESIS_DETAILS,
  hypothesisDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadExperimentDetails = (teamId) => (dispatch) => {
  dispatch(startLoad());
  const loadExperimentDetailsPromise = doLoadExperimentDetails(teamId);

  loadExperimentDetailsPromise
    .then((res) => {
      dispatch(completeLoadExperimentDetails(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
      if (error.response.status === 404) dispatch(push('/canvas'));
    });

  return loadExperimentDetailsPromise;
};

export const loadHypothesisDetails = (teamId) => (dispatch) => {
  dispatch(startLoad());
  const loadHypothesisDetailsPromise = doLoadHypothesisDetails(teamId);

  loadHypothesisDetailsPromise
    .then((res) => {
      dispatch(completeLoadHypothesisDetails(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
      if (error.response.status === 404) dispatch(push('/canvas'));
    });

  return loadHypothesisDetailsPromise;
};
