import { theme } from '../../core/styles';

export const dropdownStyles = {
  menu: (baseStyle) => ({
    ...baseStyle,
    zIndex: 3000,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: theme.textInactive,
  }),
  control: () => ({
    width: '100%',
    minHeight: '40px',
    padding: '5px 26px 5px 5px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    outline: 'none',
    borderColor: theme.textInactive,
    lineHeight: '18px',
    fontSize: '14px',
    color: theme.textInactive,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '0px 6px 2px 0',
    fontFamily: 'Raleway',
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    top: '2px',
    transform: 'none',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Raleway',
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    fontSize: '14px',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: state.isFocused ? theme.text : theme.textInactive,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    top: '0px',
    right: '0px',
  }),
  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '12px',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    ':active': theme.text,
    paddingRight: '30px',
    backgroundColor:
      (state.isSelected && 'white') ||
      (state.isFocused && theme.bodyBackgroundColor) ||
      'transparent',
    fontSize: '16px',
    color: theme.text,
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : 'normal',
    cursor: 'pointer',
  }),
};
