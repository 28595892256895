/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';

const Resolver = ({
  resolve,
  redirect,
  onSuccess,
  onError,
  redirectOnError,
  successComponent: SuccessComponent,
  loadingComponent: LoadingComponent,
  successProps,
}) => {
  const [resolved, setResolved] = useState(false);

  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;
    resolve()
      .then((result) => {
        if (!isComponentMounted.current) return;
        if (onSuccess) onSuccess(result);
        setResolved('true');
      })
      .catch((error) => {
        if (!isComponentMounted.current) return;
        if (onError) onError(error);
        if (redirectOnError) redirect(redirectOnError);
      });
    return () => (isComponentMounted.current = false);
  }, []);

  return resolved ? (
    <SuccessComponent {...successProps} />
  ) : (
    <LoadingComponent isLoading />
  );
};

Resolver.propTypes = {
  resolve: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  redirectOnError: PropTypes.string,
  successComponent: PropTypes.func.isRequired,
  loadingComponent: PropTypes.object.isRequired,
  successProps: PropTypes.object,
};

Resolver.defaultProps = {
  onSuccess: undefined,
  onError: undefined,
  redirectOnError: undefined,
  successProps: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  redirect: (URL) => dispatch(push(URL)),
});

export default withRouter(connect(undefined, mapDispatchToProps)(Resolver));
