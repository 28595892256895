import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { loadMentors } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import MentorsPage from './MentorsPage';

const MentorsPageResolver = (props) => {
  const { loadMentors, teamId } = props;
  const loadDependencies = () =>
    teamId ? loadMentors(teamId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Mentors</DocumentTitle>
      <Resolver
        successComponent={MentorsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

MentorsPageResolver.propTypes = {
  loadMentors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { loadMentors };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentorsPageResolver)
);
