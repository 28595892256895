import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { TeamProfilePage } from '../../../team/components/pages';
import { getTeam } from '../../../team/ducks';

const TeamProfilePageResolver = (props) => {
  const {
    getTeam,
    match: {
      params: { teamId },
    },
  } = props;
  const loadDependencies = () => (teamId ? getTeam(teamId) : Promise.resolve());

  return (
    <>
      <DocumentTitle>Echipa</DocumentTitle>
      <Resolver
        successComponent={TeamProfilePage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

TeamProfilePageResolver.propTypes = {
  getTeam: PropTypes.func.isRequired,
};

const mapDispatchToProps = { getTeam };

export default withRouter(
  connect(undefined, mapDispatchToProps)(TeamProfilePageResolver)
);
