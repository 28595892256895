import React from 'react';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DatePicker,
  ButtonDiv,
  LinkIcon,
  PositionParent,
  Input,
  Heading2,
  SubmitButton,
  SaveIcon,
} from '../../../common/components/styled';

const RenderLinkField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    md,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <PositionParent>
        <LinkIcon />
        <Input
          formlink
          type={type}
          name={name}
          placeholder={placeholder}
          value={fieldValue}
          onBlur={() => {
            helpers.setTouched();
          }}
          onChange={(e) => {
            helpers.setTouched();
            handleChange(e);
          }}
          isInvalid={meta.touched && !!meta.error}
        />
        <Form.Control.Feedback type="invalid">
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </PositionParent>
    </Form.Group>
  );
};

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDateField = (props) => {
  const { values, name, placeholder, controlId, label, setFieldValue } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, '');
    }
  };
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        component={DatePicker}
        as={DatePicker}
        name={name}
        placeholderText={placeholder}
        selected={fieldValue}
        value={fieldValue}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd/MM/yyyy"
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
          handleNullValue(v);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderTextAreaField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="100px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  initialValues,
  setFieldValue,
  isValid,
  role,
  ...rest
}) => (
  <Form noValidate>
    <Form.Row>
      <RenderTextField
        placeholder="Numele"
        name="firstName"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Nume*"
      />
      <RenderTextField
        placeholder="Prenumele"
        name="lastName"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Prenume*"
      />
    </Form.Row>
    <Form.Row>
      <RenderDateField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik03"
        name="birthDate"
        label="Data nașterii"
        placeholder="zz/ll/aaaa"
      />
      <RenderTextField
        name="address"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik04"
        label="Localitatea"
        placeholder="Localitatea"
      />
    </Form.Row>
    <Form.Row>
      <RenderTextField
        name="phone"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik05"
        label="Telefon"
      />
      <RenderLinkField
        name="linkedIn"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik06"
        label="Profil LinkedIn"
        placeholder="URL LinkedIn"
      />
    </Form.Row>

    <Form.Row>
      <RenderTextAreaField
        name="about"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik07"
        label="Câteva cuvinte despre tine*"
        type="textarea"
        placeholder="Care sunt pasiunile tale? Ce interese ai? Expertiza și experiență dobândita? Cunoștințe?"
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit" onClick={handleSubmit}>
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const ProfileForm = ({ onSubmit, userProfile, role }) => {
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    firstName: Yup.string().required('Câmp obligatoriu'),
    lastName: Yup.string().required('Câmp obligatoriu'),
    birthDate: Yup.string(),
    address: Yup.string(),
    phone: Yup.string(),
    linkedIn: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    about: Yup.string().required('Câmp obligatoriu'),
  });

  const initialValues = {
    firstName: userProfile.firstName ? userProfile.firstName : '',
    lastName: userProfile.lastName ? userProfile.lastName : '',
    birthDate: userProfile.birthDate ? new Date(userProfile.birthDate) : '',
    address: userProfile.address ? userProfile.address : '',
    phone: userProfile.phone ? userProfile.phone : '',
    linkedIn: userProfile.linkedIn ? userProfile.linkedIn : '',
    about: userProfile.about ? userProfile.about : '',
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} role={role} />}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  role: state.account.login.user.role,
});

export default connect(mapStateToProps)(ProfileForm);
