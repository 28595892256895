import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PositionText, NavigationBarLink } from '../styled';
import { ReactComponent as UsersIcon } from '../../assets/icons/Avatar_Cohorta-2.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/icons/Navigation_Resurse.svg';

const AdminNavigationMenu = ({ isMenuOpen, handleHoverIcon }) => (
  <>
    <li className="side-nav-item">
      <NavigationBarLink
        activeClassName="activeNavItem"
        to="/admin/users"
        className="side-sub-nav-link side-nav-link"
        style={{
          padding: isMenuOpen ? '8px 16px' : '8px 30px',
        }}
      >
        <UsersIcon onMouseEnter={handleHoverIcon} />
        <PositionText open={isMenuOpen}>Users</PositionText>
      </NavigationBarLink>
    </li>
    <li className="side-nav-item">
      <NavigationBarLink
        activeClassName="activeNavItem"
        to="/admin/resources"
        className="side-sub-nav-link side-nav-link"
        style={{
          padding: isMenuOpen ? '8px 16px' : '8px 30px',
        }}
      >
        <ResourcesIcon onMouseEnter={handleHoverIcon} />
        <PositionText open={isMenuOpen}>Resurse</PositionText>
      </NavigationBarLink>
    </li>
  </>
);

const mapStateToProps = (state) => ({
  isMenuOpen: state.common.menu.isMenuOpen,
});

export default withRouter(connect(mapStateToProps)(AdminNavigationMenu));
