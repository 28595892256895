import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { AddHypothesisForm } from '../forms';
import { loadHypotheses, editHypothesis, addHypothesis } from '../../ducks';

import { currentTeamIdSelector } from '../../../common/ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddHypothesisModal = (props) => {
  const {
    addHypothesis,
    isCreating,
    isEditing,
    isModalOpen,
    teamId,
    isEditMode,
    loadHypotheses,
    closeModal,
    entityId,
    editHypothesis,
    loadHypothesis,
    setIsModalOpen,
    global,
    firstName,
    lastName,
    hypothesis,
    push,
  } = props;

  useEffect(() => {
    if (teamId) {
      loadHypotheses(teamId);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses]);

  const handleModalClose = () =>
    global ? closeModal() : setIsModalOpen(false);

  const onSubmit = (values) => {
    const createdBy = `${firstName} ${lastName} `;

    if (isEditMode) {
      const editPromise = editHypothesis(values, entityId);
      editPromise.then(() => {
        handleModalClose();
        loadHypothesis(entityId);
        push('/canvas');
      });
      return editPromise;
    }
    const addPromise = addHypothesis(values, teamId, createdBy);
    addPromise.then(() => {
      handleModalClose();
      if (global) loadHypotheses(teamId);
    });
    return addPromise;
  };

  const handleCloseModal = () => {
    handleModalClose();
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="modal-responsive scrollbar-modal">
        <TitleButtonsDiv borderBottom marginBottom="30px">
          <Title lineHeight="28px" fontSize="24px">
            {isEditMode ? 'Editează ipoteza' : 'Adaugă o ipoteză nouă'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <AddHypothesisForm
          global={global}
          onSubmit={onSubmit}
          isEditMode={isEditMode}
          hypothesis={hypothesis}
        />
      </Modal.Body>

      <Loading isLoading={isEditMode ? isEditing : isCreating} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.hypotheses.isEditing,
  isCreating: state.hypotheses.isCreating,
  teamId: currentTeamIdSelector(state.common.userTeams),
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {
  addHypothesis,
  loadHypotheses,
  editHypothesis,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddHypothesisModal)
);
