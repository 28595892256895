export const getBadgeColor = (role, userId, teamCreadtedBy, userEquity) => {
  if (userId === teamCreadtedBy) return 'success';
  if (role === 'FOUNDER' && userEquity > 0) return 'success';
  if (role === 'FOUNDER' && !userEquity) return 'light';
  if (role === 'MENTOR') return 'warning';
  if (role === 'ACCELERATOR') return 'info';
};
export const getUserRole = (role, userId, teamCreadtedBy, userEquity) => {
  if (userId === teamCreadtedBy) return 'Fondator';
  if (role === 'FOUNDER' && userEquity > 0) return 'Fondator';
  if (role === 'FOUNDER' && !userEquity) return 'Partener';
  if (role === 'MENTOR') return 'Mentor';
  if (role === 'ACCELERATOR') return 'Incubator/Accelerator';
};
