import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { map, get, size, filter, groupBy } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CohortIcon } from '../../../common/assets/icons/Avatar_Cohorta-1.svg';
import { ReactComponent as BulbIcon } from '../../../common/assets/icons/Navigation_Idei.svg';
import { ReactComponent as AddIcon } from '../../../common/assets/icons/Button_Add-2.svg';
import AvatarImage from '../../../common/components/AvatarImage';
import { SortTableHead } from '../../../common/components';
import {
  IdeeaDescription,
  InvitationContainer,
  LabelFieldDiv,
  TitleCategoriesDiv,
  IndustryText,
  ImageFieldsDiv,
  FieldsDiv,
  WrapContainer,
  DivBorderBottom,
  AcceptButton,
  ButtonText,
  TitleButtonsDiv,
  ButtonsDiv,
} from '../../../mentors/components/styled';
import {
  Heading2,
  Heading1,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  TableRow,
  TableHeadCell,
  Loading,
  TableBodyCell,
  LinkTable,
  TagButton,
  Link,
  DeleteButtonSmall,
  DeleteCommentButton,
  Input,
} from '../../../common/components/styled';

import { JoinTeamModal, JoinCohortModal } from '../modals';
import { CreateTeamModal } from '../../../team/components/modals';

import { setCurrentUserTeam, loadUserTeams } from '../../../common/ducks';
import {
  mentorLeave,
  getTeam,
  leaveTeamAndReassignInformation,
} from '../../../team/ducks';
import { Dropdown } from '../../../core/components';

const AlertMessage = ({
  show,
  setShow,
  onSubmit,
  userId,
  teamId,
  resetDeleteTeam,
}) => {
  const handleClose = () => {
    resetDeleteTeam();
    setShow(false);
  };
  const handleSubmit = () => {
    onSubmit(userId, teamId);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Părăsește echipa</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești să părăsești echipa?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit} maxWidth="100%">
            <Heading4Regular white>Da, părăsește</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const LeaveTeamAlertMessage = ({
  show,
  setShow,
  onSubmit,
  userId,
  teamId,
  resetDeleteTeam,
  getTeam,
}) => {
  const [members, setMembers] = useState([]);
  const [newOwner, setNewOwner] = useState(undefined);

  const handleClose = () => {
    setShow(false);
    resetDeleteTeam();
    setNewOwner(undefined);
  };

  const handleSubmit = () => {
    onSubmit(userId, teamId, newOwner);
    setNewOwner(undefined);
    setShow(false);
  };

  useEffect(() => {
    const getMembers = async () => {
      const team = await getTeam(teamId);
      const { members } = team.data;

      setMembers(
        map(
          filter(
            members,
            (member) =>
              member.profile.userId.role === 'FOUNDER' &&
              member.profile.userId._id !== userId
          ),
          (member) => ({
            label: `${member.profile.firstName} ${member.profile.lastName}`,
            value: member.profile.userId._id,
          })
        )
      );
    };

    if (show) getMembers();
  }, [show, getTeam, teamId, userId]);

  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Părăsește echipa sau șterge ideea</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px" noEllipsis>
          Ești sigur/ă că vrei să renunți la această idee/echipă? Odată ștearsă
          o idee sau părăsită o echipă informațiile nu vor mai putea fi
          recuperate sau asociate altui cont.
        </Heading4Regular>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
          }}
        >
          <div style={{ maxWidth: '250px', width: '100%' }}>
            <Heading4Regular style={{ marginBottom: '10px' }}>
              Asociază informațiile unui alt cont{' '}
            </Heading4Regular>
            <Input
              as={Dropdown}
              options={members}
              placeholder="Selectează"
              onChange={(e) => setNewOwner(e.value)}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit} maxWidth="100%">
            <Heading4Regular white>Da</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MyTeamsPage = (props) => {
  const {
    userId,
    userTeams,
    isCreating,
    isLoading,
    role,
    mentorLeave,
    loadUserTeams,
    getTeam,
    leaveTeamAndReassignInformation,
  } = props;
  const [isJoinTeamModalOpen, setIsJoinTeamModalOpen] = useState(false);
  const [isJoinCohortModalOpen, setIsJoinCohortModalOpen] = useState(false);
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openLeaveTeamAlertMessage, setOpenLeaveTeamAlertMessage] =
    useState(false);
  const [deleteTeam, setDeleteTeam] = useState(null);
  const [sortedUserTeams, setSortedUserTeams] = useState(userTeams);

  const resetDeleteTeam = () => setDeleteTeam(undefined);

  const handleMentorLeave = async (userId, teamId) => {
    await mentorLeave(userId, teamId);
    resetDeleteTeam();
    await loadUserTeams(userId);
  };

  const handleMemberLeave = async (userId, teamId, newOwnerId) => {
    await leaveTeamAndReassignInformation(userId, teamId, newOwnerId);
    resetDeleteTeam();
    await loadUserTeams(userId);
  };

  const handleHookSet = (type) => {
    switch (type) {
      case 'join':
        setIsJoinTeamModalOpen(true);
        break;
      case 'joinCohort':
        setIsJoinCohortModalOpen(true);
        break;
      case 'create':
        setIsCreateTeamModalOpen(true);
        break;

      default:
        setIsJoinTeamModalOpen(false);
        setIsCreateTeamModalOpen(false);
    }
  };

  return (
    <>
      <TitleButtonsDiv marginBottom="40px" marginRight="10px" paddingRight>
        <TitleCounterDiv width="auto" style={{ marginRight: '10px' }} noMargin>
          <Title>Ideile mele</Title>
          <CounterDiv>
            <CounterText>{size(sortedUserTeams)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        {role === 'FOUNDER' && (
          <ButtonsDiv flexEnd>
            <AcceptButton
              myTeamsButton
              maxWidth="100%"
              onClick={() => handleHookSet('create')}
            >
              <BulbIcon />
              <ButtonText ellipsis color="#FFFFFF">
                Adauga Idee
              </ButtonText>
            </AcceptButton>
            <AcceptButton
              myTeamsButton
              maxWidth="100%"
              onClick={() => handleHookSet('joinCohort')}
              backgroundNone
              marginLeft="10px"
            >
              <CohortIcon />
              <ButtonText ellipsis backgroundNone color="#FFFFFF">
                Alatura-te unei cohorte
              </ButtonText>
            </AcceptButton>
            <AcceptButton
              myTeamsButton
              maxWidth="100%"
              marginLeft="10px"
              onClick={() => handleHookSet('join')}
              backgroundNone
            >
              <AddIcon />
              <ButtonText ellipsis backgroundNone color="#FFFFFF">
                Alatura-te unei echipe
              </ButtonText>
            </AcceptButton>
          </ButtonsDiv>
        )}
      </TitleButtonsDiv>
      {role === 'MENTOR' ? (
        <WrapContainer>
          {map(sortedUserTeams, (team, index) => (
            <InvitationContainer key={index}>
              <DivBorderBottom>
                <TitleCategoriesDiv>
                  <Heading1
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.setCurrentUserTeam(userId, team._id)}
                  >
                    {team.name}
                  </Heading1>
                  <DeleteButtonSmall
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      setDeleteTeam(team._id);
                      setOpenAlertMessage(true);
                    }}
                  />
                </TitleCategoriesDiv>
                <div style={{ display: 'flex' }}>
                  {map(team.ideaCategory, (category, i) => (
                    <TagButton key={i}>
                      <IndustryText>{category}</IndustryText>
                    </TagButton>
                  ))}
                </div>
              </DivBorderBottom>
              <ImageFieldsDiv>
                <AvatarImage src={team.logo} medium />
                <FieldsDiv>
                  <LabelFieldDiv>
                    <Link href={team.domain} target="blank">
                      {team.domain}
                    </Link>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Stadiu</Heading2>
                    <Heading4Regular> {team.stage}</Heading4Regular>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Data Infintare</Heading2>
                    <Heading4Regular>
                      {team.dateOfEstablishment &&
                        moment(team.dateOfEstablishment).format('DD/MM/YYYY')}
                    </Heading4Regular>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Locatie</Heading2>
                    <Heading4Regular> {team.headquarters}</Heading4Regular>
                  </LabelFieldDiv>
                  <LabelFieldDiv>
                    <Heading2>Cui</Heading2>
                    <Heading4Regular>{team.cui}</Heading4Regular>
                  </LabelFieldDiv>
                </FieldsDiv>
              </ImageFieldsDiv>
              <LabelFieldDiv>
                <Heading2>Descriere</Heading2>
              </LabelFieldDiv>
              <IdeeaDescription className="scrollbar-modal">
                <Heading4Regular noEllipsis>{team.description}</Heading4Regular>
              </IdeeaDescription>
              <LabelFieldDiv>
                <Heading2>IRL</Heading2>
                <Heading4Regular>{`${size(
                  get(
                    groupBy(
                      map(
                        Object.keys(team.irlLevels),
                        (el) => team.irlLevels[el].isActive
                      )
                    ),
                    'true',
                    []
                  )
                )}/14`}</Heading4Regular>
              </LabelFieldDiv>
            </InvitationContainer>
          ))}
        </WrapContainer>
      ) : (
        <>
          <SortTableHead
            array={sortedUserTeams}
            setArray={setSortedUserTeams}
            cellSortKeys={[null, ['name'], ['teamCode']]}
          >
            <TableHeadCell alignLeft cellWidth="2%">
              <Heading2>#</Heading2>
            </TableHeadCell>
            <TableHeadCell alignLeft cellWidth="30%">
              <Heading2>Nume</Heading2>
            </TableHeadCell>
            <TableHeadCell alignLeft cellWidth="19%">
              <Heading2>IRL</Heading2>
            </TableHeadCell>
            <TableHeadCell alignLeft cellWidth="39%">
              <Heading2>Cod echipă</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2>Acțiuni</Heading2>
            </TableHeadCell>
          </SortTableHead>
          {map(sortedUserTeams, (team, index) => (
            <TableRow key={index}>
              <TableBodyCell alignLeft cellWidth="2%">
                <Heading4Regular>{index + 1}</Heading4Regular>
              </TableBodyCell>
              <TableBodyCell alignLeft cellWidth="30%">
                <LinkTable
                  maxWidth="90%"
                  onClick={() => props.setCurrentUserTeam(userId, team._id)}
                >
                  {team.name}
                </LinkTable>
              </TableBodyCell>
              <TableBodyCell alignLeft cellWidth="19%">
                <Heading4Regular>
                  {size(
                    get(
                      groupBy(
                        map(
                          Object.keys(team.irlLevels),
                          (el) => team.irlLevels[el].isActive
                        )
                      ),
                      'true',
                      []
                    )
                  )}
                </Heading4Regular>
              </TableBodyCell>
              <TableBodyCell alignLeft cellWidth="39%">
                <Heading4Regular>{team.teamCode}</Heading4Regular>
              </TableBodyCell>
              <TableBodyCell cellWidth="10%">
                <Heading4Regular>
                  <DeleteCommentButton
                    onClick={() => {
                      setDeleteTeam(team._id);
                      setOpenLeaveTeamAlertMessage(true);
                    }}
                  />
                </Heading4Regular>
              </TableBodyCell>
            </TableRow>
          ))}
        </>
      )}

      <JoinTeamModal
        isOpen={isJoinTeamModalOpen}
        setIsModalOpen={setIsJoinTeamModalOpen}
      />
      <CreateTeamModal
        isModalOpen={isCreateTeamModalOpen}
        setIsModalOpen={setIsCreateTeamModalOpen}
        isLoading={isCreating}
      />
      <JoinCohortModal
        isModalOpen={isJoinCohortModalOpen}
        setIsModalOpen={setIsJoinCohortModalOpen}
        isLoading={isLoading}
        userId={userId}
      />
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleMentorLeave}
        userId={userId}
        teamId={deleteTeam}
        resetDeleteTeam={resetDeleteTeam}
      />
      <LeaveTeamAlertMessage
        show={openLeaveTeamAlertMessage}
        setShow={setOpenLeaveTeamAlertMessage}
        onSubmit={handleMemberLeave}
        userId={userId}
        teamId={deleteTeam}
        getTeam={getTeam}
        resetDeleteTeam={resetDeleteTeam}
      />

      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  isLoading: state.common.userTeams.isLoading,
  userTeams: state.common.userTeams.userTeams,
  isCreating: state.team.isCreating,
  role: get(state.account.login, 'user.role'),
});

const mapDispatchToProps = {
  setCurrentUserTeam,
  mentorLeave,
  loadUserTeams,
  getTeam,
  leaveTeamAndReassignInformation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyTeamsPage)
);
