import update from 'immutability-helper';
import { notifyError } from '../../core/ducks/notifications';
import {
  loadResources as doLoadResources,
  loadResourcesBySection as doLoadResourcesBySection,
  deleteResource as doDeleteResource,
  addResource as doAddResource,
  editResource as doEditResource,
  reorderResources as doReorderResources,
} from '../services/resources';

// Actions
const START_LOAD = 'resources/START_LOAD';
const COMPLETE_LOAD = 'resources/COMPLETE_LOAD';
const FAIL_LOAD = 'resources/FAIL_LOAD';

const START_ADD_RESOURCE = 'resource/START_ADD_RESOURCE';
const COMPLETE_ADD_RESOURCE = 'resource/COMPLETE_ADD_RESOURCE';
const FAIL_ADD_RESOURCE = 'resource/FAIL_ADD_RESOURCE';

const START_EDIT_RESOURCE = 'resource/START_EDIT_RESOURCE';
const COMPLETE_EDIT_RESOURCE = 'resource/COMPLETE_EDIT_RESOURCE';
const FAIL_EDIT_RESOURCE = 'resource/FAIL_EDIT_RESOURCE';

const START_DELETE = 'resource/START_DELETE';
const COMPLETE_DELETE = 'resource/COMPLETE_DELETE';
const FAIL_DELETE = 'resource/FAIL_DELETE';

const START_REORDER = 'resources/START_REORDER';
const COMPLETE_REORDER = 'resources/COMPLETE_REORDER';
const FAIL_REORDER = 'resources/FAIL_REORDER';

const RESET = 'resources/RESET';

// Initial state
const initialState = {
  isDeleting: false,
  isLoading: false,
  isEditing: false,
  isCreating: false,
  resources: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          resources: action.resources,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          resources: undefined,
        },
      });

    case START_EDIT_RESOURCE:
      return update(state, {
        $merge: {
          isEditing: true,
        },
      });

    case COMPLETE_EDIT_RESOURCE:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case FAIL_EDIT_RESOURCE:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case START_ADD_RESOURCE:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_RESOURCE:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_RESOURCE:
      return update(state, { $merge: { isCreating: false } });

    case START_REORDER:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_REORDER:
      return update(state, {
        $merge: { isLoading: false, resources: action.resources },
      });

    case FAIL_REORDER:
      return update(state, {
        $merge: { isLoading: false, resources: undefined },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (resources) => ({
  type: COMPLETE_LOAD,
  resources,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startReorder = () => ({
  type: START_REORDER,
});

const completeReorder = (resources) => ({
  type: COMPLETE_REORDER,
  resources,
});

const failReorder = () => ({
  type: FAIL_REORDER,
});

const startAddResource = () => ({
  type: START_ADD_RESOURCE,
});

const completeAddResource = () => ({
  type: COMPLETE_ADD_RESOURCE,
});

const failAddResource = () => ({
  type: FAIL_ADD_RESOURCE,
});

const startEditResource = () => ({
  type: START_EDIT_RESOURCE,
});

const completeEditResource = () => ({
  type: COMPLETE_EDIT_RESOURCE,
});

const failEditResource = () => ({
  type: FAIL_EDIT_RESOURCE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadResources = () => (dispatch) => {
  dispatch(startLoad());
  return doLoadResources()
    .then((res) => dispatch(completeLoad(res.data)))
    .catch(() => dispatch(failLoad()));
};

export const reorderResources = (resources) => (dispatch) => {
  dispatch(startReorder());
  return doReorderResources(resources)
    .then((res) => dispatch(completeReorder(res.data)))
    .catch(() => dispatch(failReorder()));
};

export const loadResourcesBySection = (section) => (dispatch) => {
  dispatch(startLoad());
  return doLoadResourcesBySection(section)
    .then((res) => dispatch(completeLoad(res.data)))
    .catch(() => dispatch(failLoad()));
};

export const deleteResource = (resourceId) => (dispatch) => {
  dispatch(startDelete());
  return doDeleteResource(resourceId)
    .then(() => dispatch(completeDelete()))
    .catch(() => dispatch(failDelete()));
};

export const addResource = (values) => (dispatch) => {
  dispatch(startAddResource());
  return doAddResource(values)
    .then(() => dispatch(completeAddResource()))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddResource());
    });
};

export const editResource = (values) => (dispatch) => {
  dispatch(startEditResource());
  return doEditResource(values)
    .then(() => dispatch(completeEditResource()))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEditResource());
    });
};
