export const stageOptions = [
  {
    label: 'idee nerafinată',
    value: 'idee nerafinată',
  },
  {
    label: 'concept/PoC',
    value: 'concept/PoC',
  },
  {
    label: 'prototip/MVP',
    value: 'prototip/MVP',
  },
  {
    label: 'startup',
    value: 'startup',
  },
  {
    label: 'afacere în dezvoltare',
    value: 'afacere în dezvoltare',
  },
  {
    label: 'afacere în scalare',
    value: 'afacere în scalare',
  },
  {
    label: 'nedefinită',
    value: 'nedefinită',
  },
];

export const ideaCategoryOptions = [
  { label: 'FinTech', value: 'FinTech' },
  { label: 'AgroTech', value: 'AgroTech' },
  { label: 'Web/mobile', value: 'Web/mobile' },
  { label: 'HealthTech', value: 'HealthTech' },
  { label: 'PropTech', value: 'PropTech' },
  { label: 'EdTech', value: 'EdTech' },
  { label: 'Green Energy Blue Energy', value: 'Green Energy Blue Energy' },
  { label: 'Recycling', value: 'Recycling' },
  { label: 'Tech', value: 'Tech' },
  { label: 'InsurTech', value: 'InsurTech' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Servicii/Services', value: 'Servicii/Services' },
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Comerț/Retail', value: 'Comerț/Retail' },
  {
    label: 'HORECA/Hospitality industry',
    value: 'HORECA/Hospitality industry',
  },
  { label: 'BioTech', value: 'BioTech' },
  { label: 'MadTech', value: 'MadTech' },
  { label: 'Other', value: 'Other' },
  { label: 'B2B', value: 'B2B' },
];
