import React from 'react';
import { ReactComponent as UploadIcon } from '../assets/icons/Upload.svg';

import {
  AvatarImageContainer,
  AvatarImagePlaceholder,
  AvatarIconPlaceholder,
} from './styled';

const AvatarImage = ({ src, large = false, medium = false, upload }) => (
  <>
    {src ? (
      <AvatarImageContainer large={large} medium={medium} backgroundUrl={src} />
    ) : (
      <AvatarImagePlaceholder>
        <AvatarIconPlaceholder>
          {upload ? <UploadIcon /> : 'N/A'}
        </AvatarIconPlaceholder>
      </AvatarImagePlaceholder>
    )}
  </>
);

export default AvatarImage;
