import React, { useState, useEffect, useMemo } from 'react';
import { find, get, map, orderBy, size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { deleteResource, loadResources, reorderResources } from '../../ducks';
import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  Loading,
  DeleteCommentButton,
  PlusButton,
  LinkTable,
  EditButtonSmall,
  DragButton,
} from '../../../common/components/styled';
import { Export, SortTableHead } from '../../../common/components';
import {
  AcceptButton,
  ButtonText,
  DivBorderBottom,
  TitleButtonsDiv,
} from '../../../mentors/components/styled';
import { AddResourceModal } from '../modals';
import { RESOURCE_SECTION_OPTIONS } from '../../constants';

const AlertMessage = ({ show, setShow, onSubmit, resetResource, id }) => {
  const handleClose = () => {
    resetResource();
    setShow(false);
  };
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere resursă</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea resursei?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return map(result, (el, index) => ({ ...el, order: index }));
};

const ResourcesPage = (props) => {
  const {
    resources,
    isLoading,
    deleteResource,
    loadResources,
    isDeleting,
    reorderResources,
  } = props;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [resource, setResource] = useState(undefined);
  const [sortedResources, setSortedResources] = useState(resources);

  const handleDeleteResource = async (resourceId) => {
    await deleteResource(resourceId);
    setResource(undefined);
    await loadResources();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortedResources,
      result.source.index,
      result.destination.index
    );

    reorderResources(items);

    setSortedResources(items);
  };

  const resetResource = () => setResource(undefined);

  useEffect(() => {
    setSortedResources(orderBy(resources, 'order'));
  }, [resources]);

  const getDownloadData = useMemo(
    () =>
      map(sortedResources, (resource, index) => ({
        '#': index + 1,
        Titlu: resource.title,
        URL: resource.url,
        'Secțiune Launchpad': get(
          find(RESOURCE_SECTION_OPTIONS, {
            value: resource.section,
          }),
          'label',
          '-'
        ),
      })),
    [sortedResources]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv width="100%">
          <Title>Resurse</Title>
          <CounterDiv>
            <CounterText>{size(sortedResources)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <Export
          data={getDownloadData}
          filename="resurse.csv"
          withMargin={false}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '39px',
          }}
        >
          <PlusButton
            style={{ float: 'right', marginLeft: '10px' }}
            onClick={() => setIsEditModalOpen(true)}
          />
        </div>
      </TitleButtonsDiv>
      <SortTableHead
        array={sortedResources}
        setArray={setSortedResources}
        cellSortKeys={[null, ['title'], ['url'], ['section'], null]}
        defaultSortKey={['position']}
      >
        <TableHeadCell cellWidth="5%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="20%">
          <Heading2>Titlu</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="40%">
          <Heading2>URL</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="20%">
          <Heading2>Secțiune Launchpad</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="15%">
          <Heading2>Acțiuni</Heading2>
        </TableHeadCell>
      </SortTableHead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {map(sortedResources, (resource, index) => (
                <Draggable
                  key={resource._id}
                  draggableId={resource._id}
                  index={index}
                >
                  {(provided) => (
                    <TableRow
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <TableBodyCell cellWidth="5%">
                        <Heading4Regular>{index + 1}</Heading4Regular>
                      </TableBodyCell>
                      <TableBodyCell alignLeft cellWidth="20%">
                        <Heading4Regular>{resource.title}</Heading4Regular>
                      </TableBodyCell>
                      <TableBodyCell alignLeft cellWidth="40%">
                        <LinkTable
                          maxWidth="100%"
                          href={resource.url}
                          target="_blank"
                        >
                          {resource.url}
                        </LinkTable>
                      </TableBodyCell>
                      <TableBodyCell alignLeft cellWidth="20%">
                        <Heading4Regular>
                          {get(
                            find(RESOURCE_SECTION_OPTIONS, {
                              value: resource.section,
                            }),
                            'label'
                          )}
                        </Heading4Regular>
                      </TableBodyCell>
                      <TableBodyCell cellWidth="15%">
                        <Heading4Regular>
                          <EditButtonSmall
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              setResource(resource._id);
                              setIsEditModalOpen(true);
                            }}
                          />
                          <DeleteCommentButton
                            onClick={() => {
                              setResource(resource._id);
                              setOpenAlertMessage(true);
                            }}
                          />
                          <span {...provided.dragHandleProps}>
                            <DragButton onClick={(e) => e.preventDefault()} />
                          </span>
                        </Heading4Regular>
                      </TableBodyCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteResource}
        resetResource={resetResource}
        id={resource}
      />
      <AddResourceModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        resetResource={resetResource}
        resource={find(resources, { _id: resource })}
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  resources: state.admin.resources.resources,
  isLoading: state.admin.resources.isLoading,
  isDeleting: state.admin.resources.isDeleting,
});

const mapDispatchToProps = { deleteResource, loadResources, reorderResources };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcesPage)
);
