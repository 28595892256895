import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadUsers } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import UsersPage from './UsersPage';

const UsersPageResolver = (props) => {
  const { loadUsers } = props;
  const loadDependencies = async () => {
    await loadUsers();
  };

  return (
    <>
      <DocumentTitle>Users</DocumentTitle>
      <Resolver
        successComponent={UsersPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

UsersPageResolver.propTypes = {
  loadUsers: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadUsers };

export default withRouter(
  connect(undefined, mapDispatchToProps)(UsersPageResolver)
);
