import React, { useState } from 'react';
import { map, get, find, size, some } from 'lodash-es';
import { connect } from 'react-redux';

import moment from 'moment';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { Modal, Row, Col } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { getExperimentIcon } from '../../../experiments/components/pages/ExperimentDetailsPage';
import { BMC_LABELS } from '../../../canvas/constants';
import EditIconLarge from '../../../common/assets/icons/Button_Edit_Large.svg';
import EditIconLargeHover from '../../../common/assets/icons/Button_Edit_Large_Hover.svg';

import {
  loadHypothesis,
  addHypothesisComment,
  editHypothesis,
  editHypothesisComment,
  deleteHypothesis,
  deleteHypothesisComment,
  addHypothesisCommentReply,
  deleteHypothesisCommentReply,
  editHypothesisCommentReply,
} from '../../ducks';

import {
  isMentorSelector,
  isAcceleratorSelector,
} from '../../../account/ducks';
import { ReactComponent as HypothesisInvalidIcon } from '../../../common/assets/icons/Ipoteza-Invalida_S.svg';
import { ReactComponent as HypothesisValidIcon } from '../../../common/assets/icons/Ipoteza-Valida_S.svg';
import { ReactComponent as HypothesisInProgressIcon } from '../../../common/assets/icons/Ipoteza-Neconcludent_S.svg';
import { ReactComponent as ValidHypothesisMVP } from '../../../common/assets/icons/Ipoteza-valida-w-MVP.svg';
import { ReactComponent as InvalidHypothesisMVP } from '../../../common/assets/icons/Ipoteza-Invalida-MVP.svg';
import { outcomeOptions } from '../../../resolutions/constants/resolutions';
import {
  CommentRenderComponent,
  ResourcesSection,
} from '../../../common/components';

import {
  ENGAGEMENT_LEVEL_OPTIONS,
  IS_EARLY_ADOPTER_OPTIONS,
  CAN_CONTACT_LATER_OPTIONS,
  CONTACT_TYPE_OPTIONS,
  DETAIL_CATEGORY_OPTIONS,
  BUYING_SIGNALS_OPTIONS,
} from '../../../common/constants';

import {
  TitleButtonsDiv,
  DivBorderBottom,
  AcceptButton,
  ButtonText,
} from '../../../mentors/components/styled';

import {
  Heading2,
  Heading4Regular,
  Link,
  Title,
  CounterDiv,
  CounterText,
  Loading,
  PlaceHolderInput,
  DeleteButton,
  AddCommentButton,
  RowDiv,
  ChildCard,
  ChildsDiv,
  DivBorderLeft,
  ExperimentCardIcon,
  DetailCardIcon,
  DeleteCommentButton,
  TextInactive,
  EditButtonLarge,
  WhiteCard,
  LeftSection,
  RightSection,
} from '../../../common/components/styled';

import { AddExperimentModal } from '../../../experiments/components/modals';
import {
  AddCommentModal,
  AddDetailModal,
} from '../../../common/components/modals';
import { AddResolutionModal } from '../../../resolutions/components/modals';
import { AddHypothesisModal, EmpathyMapModal } from '../modals';
import { addResolution } from '../../../resolutions/ducks';
import { addHypothesisDetail } from '../../../hypothesisDetails/ducks';
import { sizeComments } from '../../../common/functions';

export const handleBmcLabels = (value) =>
  get(find(BMC_LABELS, { value }), 'label');

const AlertMessage = ({ show, setShow, onSubmit, id }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere ipoteză</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea ipotezei?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HypothesisDetailsPage = (props) => {
  const {
    hypothesis,
    addHypothesisComment,
    isLoadingComment,
    isLoadingResolution,
    isLoading,
    isDeleting,
    isCreatingDetail,
    hypotheses,
    loadHypothesis,
    deleteHypothesis,
    editHypothesisComment,
    isEditingComment,
    addResolution,
    isCreatingExperiment,
    deleteHypothesisComment,
    addHypothesisDetail,
    push,
    isMentor,
    isAccelerator,
    resources,
    match: {
      params: { hypothesisId },
    },
  } = props;

  const [isEmpathyMapModalOpen, setIsEmpathyMapModalOpen] = useState(false);
  const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isHypothesisModalOpen, setIsHypothesisModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const [isEditModeComment, setIsEditModeComment] = useState(false);
  const [comment, setComment] = useState(undefined);

  const [hovered, setHovered] = useState(false);

  const handleSegment = (segmentId) =>
    get(find(hypotheses, { _id: segmentId }), 'title');

  const handleExperimentCardClick = (url) => push(url);

  const handleDeleteHypothesis = async () => {
    await deleteHypothesis(hypothesis._id);
    push('/canvas');
  };

  const handleOutcomeIcon = (outcome) =>
    get(find(outcomeOptions, { value: outcome }), 'icon');

  const handleOutcomeValue = (outcome) =>
    get(find(outcomeOptions, { value: outcome }), 'label');

  const handleDeleteComment = async (commentId) => {
    await deleteHypothesisComment(commentId);
    await loadHypothesis(hypothesis._id);
  };

  const getHypothesisIcon = (resolutions) => {
    if (!size(resolutions) > 0) {
      return (
        <HypothesisInProgressIcon style={{ width: '28px', height: '28px' }} />
      );
    }
    const outcomes = map(resolutions, (resolution) => resolution.outcome);

    if (some(outcomes, (outcome) => outcome === 'invalidatedWithMVP')) {
      return (
        <HypothesisInvalidIcon style={{ width: '28px', height: '28px' }} />
      );
    }
    if (some(outcomes, (outcome) => outcome === 'invalidatedWithoutMVP')) {
      return <InvalidHypothesisMVP style={{ width: '28px', height: '28px' }} />;
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithMVP')) {
      return <HypothesisValidIcon style={{ width: '28px', height: '28px' }} />;
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithoutMVP')) {
      return <ValidHypothesisMVP style={{ width: '26px', height: '26px' }} />;
    }
  };

  const handleHookSet = (type) => {
    switch (type) {
      case 'detail':
        setIsDetailModalOpen(true);
        break;
      case 'hypothesis':
        setIsHypothesisModalOpen(true);
        break;

      case 'experiment':
        setIsExperimentModalOpen(true);
        break;
      case 'resolution':
        setIsResolutionModalOpen(true);
        break;
      case 'comment':
        setIsCommentModalOpen(true);
        break;
      case 'empathyMap':
        setIsEmpathyMapModalOpen(true);
        break;
      default:
        setIsDetailModalOpen(false);
        setIsExperimentModalOpen(false);
        setIsResolutionModalOpen(false);
        setIsCommentModalOpen(false);
        setIsHypothesisModalOpen(false);
        setIsEmpathyMapModalOpen(false);
    }
  };

  const handleOpenEditCommentModal = (type, commentId) => {
    handleHookSet(type);
    setComment(commentId);
    setIsEditModeComment(true);
  };

  return (
    <>
      <div>
        <TitleButtonsDiv paddingRight marginBottom="15px">
          <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            {getHypothesisIcon(hypothesis.resolutions)}
            <Title
              marginLeft={`${
                getHypothesisIcon(hypothesis.resolutions) ? '10px' : '0px'
              }`}
              width="80%"
            >
              {`${handleBmcLabels(hypothesis.columnBMC)}: ${hypothesis.title}`}
            </Title>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isMentor && !isAccelerator && (
              <>
                <EditButtonLarge
                  src={hovered ? EditIconLargeHover : EditIconLarge}
                  onClick={() => handleHookSet('hypothesis')}
                  onMouseEnter={() => setHovered(!hovered)}
                  onMouseLeave={() => setHovered(!hovered)}
                />
                <DeleteButton
                  style={{ float: 'right' }}
                  onClick={() => setOpenAlertMessage(true)}
                />
              </>
            )}
          </div>
        </TitleButtonsDiv>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '40px',
          }}
        >
          <Heading4Regular inactive>{`${hypothesis.createdBy} ${moment(
            hypothesis.createdAt
          ).format('DD/MM/YYYY  H:mm')}`}</Heading4Regular>
        </div>
      </div>

      <RowDiv marginBottom="30px">
        <LeftSection>
          <WhiteCard height="413px" borderRadius="3px" padding="20px">
            <Title marginBottom="10px" fontSize="24px" lineHeight="28px">
              Descriere ipoteză
            </Title>
            <Heading4Regular
              className="scrollbar-modal"
              textArea
              marginBottom="40px"
              noEllipsis
            >
              {hypothesis.description}
            </Heading4Regular>
            <div style={{ height: '80px', marginBottom: '40px' }}>
              <Heading2 marginBottom="10px">Segmente vizate</Heading2>
              {hypothesis.columnBMC !== 'customerSegments' && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      maxWidth: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {map(hypothesis.segment, (segment, index) => (
                      <PlaceHolderInput
                        key={index}
                        maxWidth="20%"
                        style={{
                          padding: '7px 10px',
                          width: '133px',
                          marginRight: '10px',
                        }}
                      >
                        <Heading4Regular style={{ lineHeight: '16px' }}>
                          <span>{handleSegment(segment)}</span>
                        </Heading4Regular>
                      </PlaceHolderInput>
                    ))}
                  </div>
                </>
              )}
            </div>

            {size(hypothesis.KPIIndicators) !== 0 ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Heading2 marginBottom="10px">Indicator</Heading2>
                  {map(hypothesis.KPIIndicators, ({ title }) => (
                    <Heading4Regular marginBottom="5px" key={title}>
                      {title}
                    </Heading4Regular>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'right',
                  }}
                >
                  <Heading2 marginBottom="10px" style={{ marginRight: '0' }}>
                    Valoare țintă
                  </Heading2>
                  {map(hypothesis.KPIIndicators, ({ targetValue }) => (
                    <Heading4Regular marginBottom="5px" key={targetValue}>
                      {targetValue || 'Valoarea țintă'}
                    </Heading4Regular>
                  ))}
                </div>
              </div>
            ) : (
              <Row style={{ margin: '0' }}>
                <Col style={{ padding: '0' }} md={8}>
                  <Heading2 marginBottom="10px">Indicator</Heading2>
                  <Heading4Regular marginBottom="5px">
                    <TextInactive>
                      Cum putem măsura valoarea și succesul?
                    </TextInactive>
                  </Heading4Regular>
                </Col>
                <Col md={4} style={{ textAlign: 'right', padding: '0' }}>
                  <Heading2 marginBottom="10px" style={{ marginRight: '0' }}>
                    Valoare țintă
                  </Heading2>
                  <TextInactive style={{ textAlign: 'right' }}>
                    Valoarea indicatorului
                  </TextInactive>
                </Col>
              </Row>
            )}
          </WhiteCard>
        </LeftSection>
        <RightSection>
          <WhiteCard
            noOverflow
            height="100%"
            borderRadius="3px"
            padding="20px"
            style={{ height: '413px' }}
          >
            <TitleButtonsDiv marginBottom="15px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Comentarii
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{sizeComments(hypothesis.comments)}</CounterText>
                </CounterDiv>
              </div>
              <AddCommentButton onClick={() => handleHookSet('comment')} />
            </TitleButtonsDiv>
            {!size(hypothesis.comments) > 0 && (
              <TextInactive>
                Momentan nu există niciun comentariu pentru această secțiune.
                Poți adăuga un comentariu făcând click pe butonul „+” din partea
                dreaptă a secțiunii.
              </TextInactive>
            )}
            <div
              style={{
                maxHeight: '90%',
                overflow: 'auto',
                paddingRight: '4px',
              }}
              className="scrollbar-modal"
            >
              {map(hypothesis.comments, (comment) => (
                <CommentRenderComponent
                  comment={comment}
                  handleDeleteComment={handleDeleteComment}
                  handleEdit={() =>
                    handleOpenEditCommentModal('comment', comment)
                  }
                  handleAddReply={props.addHypothesisCommentReply}
                  handleEditReply={(values, replyId) =>
                    props.editHypothesisCommentReply(
                      values,
                      comment._id,
                      replyId
                    )
                  }
                  handleDeleteReply={(replyId) =>
                    props.deleteHypothesisCommentReply(comment._id, replyId)
                  }
                  loadData={() => loadHypothesis(hypothesis._id)}
                  isLoading={
                    isEditModeComment ? isEditingComment : isLoadingComment
                  }
                />
              ))}
            </div>
          </WhiteCard>
        </RightSection>
      </RowDiv>
      <RowDiv marginBottom="30px">
        <LeftSection>
          <WhiteCard height="auto" borderRadius="3px" padding="20px">
            <TitleButtonsDiv marginBottom="15px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Experimente
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{size(hypothesis.experiments)}</CounterText>
                </CounterDiv>
              </div>
              {!isMentor && !isAccelerator && (
                <AddCommentButton onClick={() => handleHookSet('experiment')} />
              )}
            </TitleButtonsDiv>
            {!size(hypothesis.experiments) > 0 && (
              <TextInactive>
                Adaugă un prim experiment pentru a începe validarea acestei
                ipoteze făcând click pe butonul „+” din partea dreaptă a
                secțiunii.
              </TextInactive>
            )}
            <ChildsDiv className="scrollbar-modal">
              {map(hypothesis.experiments, (experiment, index) => (
                <ChildCard noBorder key={index}>
                  <div style={{ display: 'flex', width: '60%' }}>
                    <ExperimentCardIcon />
                    <Link
                      maxWidth="80%"
                      marginLeft="12px"
                      marginRight="10px"
                      onClick={() =>
                        handleExperimentCardClick(
                          `/hypothesis/${hypothesis._id}/experiment/${experiment._id}`
                        )
                      }
                    >
                      {experiment.title}
                    </Link>
                    {size(experiment.details) !== 0 && <DetailCardIcon />}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '40%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Heading4Regular inactive marginRight="5px">
                      {experiment.status}
                    </Heading4Regular>
                    {getExperimentIcon(
                      experiment.resolutions[0] &&
                        experiment.resolutions[0].outcome,
                      experiment.status
                    )}
                    <DivBorderLeft>
                      <Heading4Regular experimentPrice inactive>
                        {`${experiment.estimatedPrice} €`}
                      </Heading4Regular>
                    </DivBorderLeft>
                  </div>
                </ChildCard>
              ))}
            </ChildsDiv>
          </WhiteCard>
        </LeftSection>
        <RightSection>
          <ResourcesSection resources={resources} />
        </RightSection>
      </RowDiv>

      <RowDiv marginBottom="30px">
        <LeftSection>
          <WhiteCard height="auto" borderRadius="3px" padding="20px">
            <TitleButtonsDiv marginBottom="15px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Detalii ipoteză
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{size(hypothesis.details)}</CounterText>
                </CounterDiv>
              </div>
              {!isMentor && !isAccelerator && (
                <AddCommentButton onClick={() => handleHookSet('detail')} />
              )}
            </TitleButtonsDiv>
            {!size(hypothesis.details) > 0 && (
              <TextInactive>
                Adaugă un detaliu sau informație importantă pentru această
                ipoteză făcând click pe butonul „+” din partea dreaptă a
                secțiunii.
              </TextInactive>
            )}
            <ChildsDiv className="scrollbar-modal">
              {map(hypothesis.details, (detail, index) => (
                <ChildCard key={index}>
                  <div style={{ display: 'flex', width: '68%' }}>
                    <DetailCardIcon />
                    <Link
                      maxWidth="80%"
                      marginLeft="12px"
                      onClick={() =>
                        handleExperimentCardClick(
                          `/hypothesis/${hypothesis._id}/detail/${detail._id}`
                        )
                      }
                    >
                      {detail.title}
                    </Link>
                  </div>
                  <div style={{ display: 'flex', width: '32%' }}>
                    <div style={{ width: '16.6%' }}>
                      {get(
                        find(DETAIL_CATEGORY_OPTIONS, {
                          value: detail.detailCategory,
                        }),
                        'renderIcon',
                        () => {}
                      )()}
                    </div>
                    <div style={{ width: '16.6%' }}>
                      {get(
                        find(CONTACT_TYPE_OPTIONS, {
                          value: detail.contactType,
                        }),
                        'renderIcon',
                        () => {}
                      )()}
                    </div>
                    <DivBorderLeft style={{ width: '66.6%', display: 'flex' }}>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(ENGAGEMENT_LEVEL_OPTIONS, {
                            value: detail.engagementLevel,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(CAN_CONTACT_LATER_OPTIONS, {
                            value: detail.canContactLater,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(BUYING_SIGNALS_OPTIONS, {
                            value: detail.buyingSignals,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(IS_EARLY_ADOPTER_OPTIONS, {
                            value: detail.isEarlyAdopter,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                    </DivBorderLeft>
                  </div>
                </ChildCard>
              ))}
            </ChildsDiv>
          </WhiteCard>
        </LeftSection>
      </RowDiv>
      <RowDiv>
        <LeftSection>
          <WhiteCard height="auto" borderRadius="3px" padding="20px">
            <TitleButtonsDiv marginBottom="20px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Rezoluții ipoteză​
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{size(hypothesis.resolutions)}</CounterText>
                </CounterDiv>
              </div>
              {!isMentor && !isAccelerator && (
                <AddCommentButton onClick={() => handleHookSet('resolution')} />
              )}
            </TitleButtonsDiv>
            {!size(hypothesis.resolutions) > 0 && (
              <TextInactive>
                Ai suficiente date și informații din piață pentru a trage o
                concluzie? Atunci adaugă o rezoluție privind validarea sau
                invalidarea acestei ipoteze făcând click pe butonul „+”.
              </TextInactive>
            )}
            <ChildsDiv className="scrollbar-modal">
              {map(hypothesis.resolutions, (resolution, index) => (
                <ChildCard noBorder key={index}>
                  <div style={{ display: 'flex', width: '60%' }}>
                    <Link
                      maxWidth="80%"
                      marginLeft="12px"
                      marginRight="10px"
                      onClick={() =>
                        handleExperimentCardClick(
                          `/hypothesis/${hypothesis._id}/resolution/${resolution._id}`
                        )
                      }
                    >
                      {resolution.insightName}
                    </Link>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '40%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Heading4Regular inactive marginRight="5px">
                      {handleOutcomeValue(resolution.outcome)}
                    </Heading4Regular>
                    <DivBorderLeft>
                      <img
                        alt=""
                        src={handleOutcomeIcon(resolution.outcome)}
                        style={{ width: '26px', height: '26px' }}
                      />
                    </DivBorderLeft>
                  </div>
                </ChildCard>
              ))}
            </ChildsDiv>
          </WhiteCard>
        </LeftSection>
      </RowDiv>

      <EmpathyMapModal
        isModalOpen={isEmpathyMapModalOpen}
        setIsModalOpen={setIsEmpathyMapModalOpen}
        id={hypothesisId}
      />
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteHypothesis}
      />
      <AddHypothesisModal
        isModalOpen={isHypothesisModalOpen}
        setIsModalOpen={setIsHypothesisModalOpen}
        isEditMode
        entityId={hypothesis._id}
        hypothesis={hypothesis}
        loadHypothesis={loadHypothesis}
      />
      <AddExperimentModal
        isModalOpen={isExperimentModalOpen}
        setIsModalOpen={setIsExperimentModalOpen}
        loadData={loadHypothesis}
        id={hypothesisId}
        hypothesis={hypothesis}
        isLoading={isCreatingExperiment}
      />
      <AddCommentModal
        isModalOpen={isCommentModalOpen}
        setIsModalOpen={setIsCommentModalOpen}
        parentId={hypothesis._id}
        onSubmit={addHypothesisComment}
        isLoading={isEditModeComment ? isEditingComment : isLoadingComment}
        loadData={loadHypothesis}
        isEditModeComment={isEditModeComment}
        comment={isEditModeComment ? comment : undefined}
        setIsEditModeComment={setIsEditModeComment}
        editComment={editHypothesisComment}
      />
      <AddResolutionModal
        isModalOpen={isResolutionModalOpen}
        setIsModalOpen={setIsResolutionModalOpen}
        isLoading={isLoadingResolution}
        loadData={loadHypothesis}
        entityId={hypothesisId}
        isHypothesis
        parent={hypothesis}
        onSubmitResolution={
          addResolution // onSubmit={editHypothesis}
        }
      />
      <AddDetailModal
        addDetail={addHypothesisDetail}
        parentId={hypothesis._id}
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        loadData={loadHypothesis}
        isLoading={isCreatingDetail}
        isHypothesisTitle
      />
      <EmpathyMapModal
        isModalOpen={isEmpathyMapModalOpen}
        setIsModalOpen={setIsEmpathyMapModalOpen}
        hypothesisId={hypothesis._id}
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  hypothesis: state.hypotheses.hypothesis,
  hypotheses: state.hypotheses.hypotheses,
  isLoadingComment: state.hypotheses.isCreating,
  isEditingComment: state.hypotheses.isEditing,
  isLoadingResolution: state.resolution.isLoading,
  isCreatingExperiment: state.experiments.isCreating,
  isLoading: state.hypotheses.isLoadingHypothesis,
  isDeleting: state.hypotheses.isDeleting,
  isCreatingDetail: state.hypothesisDetail.isCreating,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
  resources: state.admin.resources.resources,
});

const mapDispatchToProps = {
  addHypothesisComment,
  editHypothesis,
  loadHypothesis,
  editHypothesisComment,
  deleteHypothesisComment,
  push,
  deleteHypothesis,
  addHypothesisDetail,
  addHypothesisCommentReply,
  deleteHypothesisCommentReply,
  editHypothesisCommentReply,
  addResolution,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HypothesisDetailsPage)
);
