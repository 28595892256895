import React from 'react';
import * as Yup from 'yup';
import { get, trim } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  ButtonDiv,
  InfoIcon,
  SubmitButton,
} from '../../../common/components/styled';
import { isCohortCodeUsed } from '../../services/createTeam';
import { isCohortExpired } from '../../../accelerator/services/cohorts';

const RenderCohortCodeField = (props) => {
  const { md, values, handleChange, name, controlId } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Form.Label>Cod Cohortă</Form.Label>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            Introdu codul Cohortei la care vrei să te alături cu ideea, dacă
            este cazul. Poți să-l introduci și mai târziu din pagina Profilul
            Echipei
          </Tooltip>
        }
      >
        <InfoIcon />
      </OverlayTrigger>

      <Form.Control
        type="text"
        placeholder="Cod Cohortă"
        name="cohortCode"
        value={fieldValue}
        onBlur={async () => {
          helpers.setTouched();
        }}
        onChange={(event) => {
          helpers.setTouched();
          return handleChange(event);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  initialValues,
  isValid,
  ...rest
}) => (
  <Form onSubmit={handleSubmit}>
    <Form.Row>
      <RenderCohortCodeField
        values={values}
        name="cohortCode"
        md="12"
        handleChange={handleChange}
        controlId="validationFormik11"
        initialValues={initialValues}
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit" disabled={!isValid}>
        Alătură-te
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const JoinCohortForm = (props) => {
  const { onSubmit } = props;
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    cohortCode: Yup.string()
      .required('Câmp obligatoriu')
      .test(
        'is-invalid',
        'Cod cohortă invalid',
        async (value) =>
          !!trim(value) &&
          isCohortCodeUsed(value).then(({ data }) => data.isUsed)
      )
      .test(
        'is-expired',
        'Aceasta cohortă a expirat',
        async (value) =>
          !!trim(value) &&
          isCohortExpired(value).then(({ data }) => !data.isExpired)
      ),
  });

  const initialValues = {
    cohortCode: undefined,
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};

export default JoinCohortForm;
