import { http } from '../../core/services/http';

export const loadResources = () => http.get('/resources/get-all');
export const loadResourcesBySection = (section) =>
  http.get('/resources/get-by-section', { params: { section } });
export const addResource = (values) => http.post('/resources/add', values);
export const deleteResource = (resourceId) =>
  http.delete(`/resources/delete/${resourceId}`);
export const getResource = (resourceId) =>
  http.get(`/resources/get/${resourceId}`);
export const editResource = (values) =>
  http.put(`/resources/edit/${values._id}`, values);
export const reorderResources = (resources) =>
  http.post('/resources/reorder', resources);
