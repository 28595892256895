import styled, { css } from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icons/Button_Add.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/Button_Edit.svg';
import { ReactComponent as EditIconSmall } from '../../assets/icons/Button_Edit-1.svg';
import { ReactComponent as EditIconHover } from '../../assets/icons/Button_Edit_Hover.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Button_Delete.svg';
import { ReactComponent as DeleteIconX } from '../../assets/icons/Button_Delete_X.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/Button_Add-2.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/Button_Minus.svg';
import { ReactComponent as DetailIcon } from '../../assets/icons/Menu_Detaliu_Ipoteza_Small.svg';
import { ReactComponent as ExperimentIcon } from '../../assets/icons/Menu_Experiment.svg';
import { ReactComponent as ExperimentGoodIcon } from '../../assets/icons/Thumbsup.svg';
import { ReactComponent as ExperimentBadIcon } from '../../assets/icons/Thumbsdown.svg';
import { ReactComponent as ValidHypothesisIcon } from '../../assets/icons/Ipoteza-Valida.svg';
import { ReactComponent as InvalidHypothesisIcon } from '../../assets/icons/Ipoteza-Invalida.svg';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIcon.svg';
import { ReactComponent as ExcelIcon } from '../../assets/icons/Excel-icon.svg';

export const ExperimentGoodButton = styled(ExperimentGoodIcon)`
  cursor: pointer;
  &:hover > path {
    fill: ${(props) => props.theme.successHover};
  }

  ${(props) =>
    props.pressed &&
    css`
      > path {
        fill: ${(props) => props.theme.successHover};
        opacity: 0.25;
      }
    `}
`;

export const ExperimentBadButton = styled(ExperimentBadIcon)`
  cursor: pointer;
  &:hover > path {
    fill: ${(props) => props.theme.dangerHover};
  }
  ${(props) =>
    props.pressed &&
    css`
      > path {
        fill: ${(props) => props.theme.dangerHover};
        opacity: 0.25;
      }
    `}
`;

export const ValidHypothesisButton = styled(ValidHypothesisIcon)`
  cursor: pointer;
  &:hover > path {
    fill: ${(props) => props.theme.successHover};
  }

  ${(props) =>
    props.pressed &&
    css`
      > path {
        fill: ${(props) => props.theme.successHover};
        opacity: 0.25;
      }
    `}
`;

export const InvalidHypothesisButton = styled(InvalidHypothesisIcon)`
  cursor: pointer;
  &:hover > path {
    fill: ${(props) => props.theme.dangerHover};
  }
  ${(props) =>
    props.pressed &&
    css`
      > path {
        fill: ${(props) => props.theme.dangerHover};
        opacity: 0.25;
      }
    `}
`;

export const ExperimentCardIcon = styled(ExperimentIcon)`
  > path {
    fill: ${(props) => props.theme.blueAccent};
  }
`;
export const DetailCardIcon = styled(DetailIcon)`
  > path {
    fill: ${(props) => props.theme.accentLight};
  }
`;
export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const PlusButton = styled(PlusIcon)`
  margin-left: auto;
  cursor: pointer;
  :hover > path {
    fill: ${(props) => props.theme.accentHover};
  }
`;

export const ExcelButton = styled(ExcelIcon)`
  margin-left: auto;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      > path {
        fill: ${(innerProps) => innerProps.theme.textInactive};
      }
    `}

  :hover > path {
    fill: ${(props) => props.theme.successHover};
  }
`;

export const DragButton = styled(DragIcon)`
  margin-left: 10px;
  cursor: pointer;
  :hover > path {
    fill: ${(props) => props.theme.accentHover};
  }
`;

export const EditButton = styled(EditIcon)`
  cursor: pointer;
  margin-left: ${(props) => props.marginLeft};
`;
export const DeleteButtonSmall = styled(DeleteIconX)`
  cursor: pointer;
  :hover > path {
    fill: ${(props) => props.theme.dangerHover};
  }
`;
export const EditButtonSmall = styled(EditIconSmall)`
  cursor: pointer;
  :hover > path {
    fill: ${(props) => props.theme.text};
  }
  margin-left: ${(props) => props.marginleft};

  ${(props) =>
    props.commentComponent &&
    css`
      width: 24px;
      height: 24px;
    `}
`;
export const EditButtonHover = styled(EditIconHover)`
  cursor: pointer;
`;
export const DeleteButton = styled(DeleteIcon)`
  cursor: pointer;
  :hover > path {
    fill: ${(props) => props.theme.dangerHover};
  }
`;

export const EditButtonLarge = styled.img`
  cursor: pointer;

  margin-right: 10px;
`;

export const TagButton = styled.div`
  height: 30px;
  background: ${(props) => (props.color ? props.color : '#74a3fe')};
  border-radius: 3px;
  text-align: center;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
  width: ${(props) => props.maxWidth};
`;

export const KpiButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 20px;
  width: 100%;
  margin-left: 5px;
`;
export const AddCommentButton = styled(AddIcon)`
  cursor: pointer;

  :hover {
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 3px;
  }

  ${(props) =>
    props.positionedButton &&
    css`
      position: absolute;
      top: -47px;
      right: 0;
      float: right;
    `}
`;

export const RadioLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const MinusButton = styled(MinusIcon)`
  cursor: pointer;
  :hover {
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 3px;
  }
  margin-top: ${(props) => props.marginTop};
`;

export const RoleBadge = styled.div`
  background: ${(props) => props.backgroundColor};
  border-radius: 3px;
  height: 30px;
  padding: 7px 10px;
  color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
