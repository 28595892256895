import update from 'immutability-helper';

import { push } from 'connected-react-router';

import { notifyError, notifySuccess } from '../../core/ducks';
import {
  register as doRegister,
  isEmailRegistered as doIsEmailRegistered,
} from '../services/auth';

// Actions
const START_REGISTER = 'account/register/START_REGISTER';
const COMPLETE_REGISTER = 'account/register/COMPLETE_REGISTER';
const FAIL_REGISTER = 'account/register/FAIL_REGISTER';
const RESET = 'account/register/RESET';
const START_CHECK_EMAIL_REGISTERED =
  '/accoutn/register/START_CHECK_EMAIL_REGISTERED';
const COMPLETE_CHECK_EMAIL_REGISTERED =
  '/accoutn/register/COMPLETE_CHECK_EMAIL_REGISTERED';
const FAIL_CHECK_EMAIL_REGISTERED =
  '/accoutn/register/FAIL_CHECK_EMAIL_REGISTERED';

// Initial state
const initialState = {
  isRegisterFailed: false,
  isEmailRegistered: false,
  isSubmitting: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REGISTER:
      return update(state, {
        $merge: {
          isregisterFailed: false,
          isSubmitting: true,
        },
      });

    case COMPLETE_REGISTER:
      return update(state, {
        $merge: {
          isRegisterFailed: false,
          isSubmitting: false,
        },
      });

    case FAIL_REGISTER:
      return update(state, {
        $merge: {
          isRegisterFailed: true,
          isSubmitting: false,
        },
      });

    case START_CHECK_EMAIL_REGISTERED:
      return update(state, { $merge: { isEmailRegistered: false } });
    case COMPLETE_CHECK_EMAIL_REGISTERED:
      return update(state, {
        $merge: { isEmailRegistered: action.isEmailRegistered },
      });
    case FAIL_CHECK_EMAIL_REGISTERED:
      return update(state, { $merge: { isEmailRegistered: false } });

    case RESET:
      return update(state, {
        $merge: {
          isRegisterFailed: false,
          isSubmitting: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startRegister = () => ({
  type: START_REGISTER,
});

const completeRegister = (user) => ({
  type: COMPLETE_REGISTER,
  user,
});

const failRegister = () => ({
  type: FAIL_REGISTER,
});

const startCheckEmailRegistered = () => ({
  type: START_CHECK_EMAIL_REGISTERED,
});

const completeCheckEmailRegistered = (isEmailRegistered) => ({
  type: COMPLETE_CHECK_EMAIL_REGISTERED,
  isEmailRegistered,
});

const failCheckEmailRegistered = () => ({
  type: FAIL_CHECK_EMAIL_REGISTERED,
});

export const checkEmailRegistered = (email) => (dispatch) => {
  dispatch(startCheckEmailRegistered());
  const isRegisteredPromice = doIsEmailRegistered(email);
  isRegisteredPromice
    .then((res) =>
      dispatch(completeCheckEmailRegistered(res.data.isRegistered))
    )
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failCheckEmailRegistered());
    });
  return isRegisteredPromice;
};

export const register = (data) => (dispatch) => {
  dispatch(startRegister());
  return doRegister(data)
    .then((user) => {
      dispatch(completeRegister(user));
      dispatch(notifySuccess('Cont creat cu succes!'));
      dispatch(push('/account/login'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failRegister());
    });
};

export const resetRegister = () => ({
  type: RESET,
});

// Selectors
