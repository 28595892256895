import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadExperimentDetails, loadHypothesisDetails } from '../ducks';
import { Resolver } from '../../core/components';
import { Loading } from '../../common/components/styled';
import { DocumentTitle } from '../../common/components';
import { currentTeamIdSelector } from '../../common/ducks';
import DetailsPage from './DetailsPage';

const DetailsPageResolver = (props) => {
  const { loadExperimentDetails, loadHypothesisDetails, teamId } = props;
  const loadDependencies = () =>
    teamId
      ? Promise.all([
          loadExperimentDetails(teamId),
          loadHypothesisDetails(teamId),
        ])
      : Promise.resolve();

  return (
    <>
      <DocumentTitle>Detalii</DocumentTitle>
      <Resolver
        successComponent={DetailsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

DetailsPageResolver.propTypes = {
  loadExperimentDetails: PropTypes.func.isRequired,
  loadHypothesisDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  loadExperimentDetails,
  loadHypothesisDetails,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsPageResolver)
);
