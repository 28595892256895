import update from 'immutability-helper';
import { push } from 'react-router-redux';

import {
  loadExperiments as doLoadExperiments,
  addExperiment as doAddExperiment,
  addExperimentComment as doAddExperimentComment,
  loadExperiment as doLoadExperiment,
  editExperimentComment as doEditExperimentComment,
  deleteExperimentComment as doDeleteExperimentComment,
  editExperiment as doEditExperiment,
  deleteExperiment as doDeleteExperiment,
  addExperimentCommentReply as doAddExperimentCommentReply,
  editExperimentCommentReply as doEditExperimentCommentReply,
  deleteExperimentCommentReply as doDeleteExperimentCommentReply,
} from '../services/experiments';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';

// Actions
const START_LOAD = 'experiments/START_LOAD';
const COMPLETE_LOAD = 'experiments/COMPLETE_LOAD';
const FAIL_LOAD = 'experiments/FAIL_LOAD';

const START_LOAD_EXPERIMENT = 'experiment/START_GET';
const COMPLETE_LOAD_EXPERIMENT = 'experiment/COMPLETE_GET';
const FAIL_LOAD_EXPERIMENT = 'experiment/FAIL_GET';

const START_ADD = 'experiments/START_ADD';
const COMPLETE_ADD = 'experiments/COMPLETE_ADD';
const FAIL_ADD = 'experiments/FAIL_ADD';

const START_EDIT = 'experiments/START_EDIT';
const COMPLETE_EDIT = 'experiments/COMPLETE_EDIT';
const FAIL_EDIT = 'experiments/FAIL_EDIT';

const START_DELETE = 'experiments/START_DELETE';
const COMPLETE_DELETE = 'experiments/COMPLETE_DELETE';
const FAIL_DELETE = 'experiments/FAIL_DELETE';

const START_ADD_COMMENT = 'experiments/START_ADD_COMMENT';
const COMPLETE_ADD_COMMENT = 'experiments/COMPLETE_ADD_COMMENT';
const FAIL_ADD_COMMENT = 'experiments/FAIL_ADD_COMMENT';

const START_DELETE_COMMENT = 'experiments/START_DELETE_COMMENT';
const COMPLETE_DELETE_COMMENT = 'experiments/COMPLETE_DELETE_COMMENT';
const FAIL_DELETE_COMMENT = 'experiments/FAIL_DELETE_COMMENT';

const START_EDIT_COMMENT = 'experiments/START_EDIT_COMMENT';
const COMPLETE_EDIT_COMMENT = 'experiments/COMPLETE_EDIT_COMMENT';
const FAIL_EDIT_COMMENT = 'experiments/FAIL_EDIT_COMMENT';

const START_ADD_REPLY = 'experiments/START_ADD_REPLY';
const COMPLETE_ADD_REPLY = 'experiments/COMPLETE_ADD_REPLY';
const FAIL_ADD_REPLY = 'experiments/FAIL_ADD_REPLY';

const START_EDIT_REPLY = 'experiments/START_EDIT_REPLY';
const COMPLETE_EDIT_REPLY = 'experiments/COMPLETE_EDIT_REPLY';
const FAIL_EDIT_REPLY = 'experiments/FAIL_EDIT_REPLY';

const START_DELETE_REPLY = 'experiments/START_DELETE_REPLY';
const COMPLETE_DELETE_REPLY = 'experiments/COMPLETE_DELETE_REPLY';
const FAIL_DELETE_REPLY = 'experiments/FAIL_DELETE_REPLY';

const RESET = 'experiments/RESET';

// Initial state
const initialState = {
  isCreating: false,
  isLoading: false,
  isEditing: false,
  isDeleting: false,
  experiment: undefined,
  experiments: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          experiments: action.experiments,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          experiments: undefined,
        },
      });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });
    case START_DELETE:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case START_LOAD_EXPERIMENT:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_EXPERIMENT:
      return update(state, {
        $merge: {
          isLoading: false,
          experiment: action.experiment,
        },
      });

    case FAIL_LOAD_EXPERIMENT:
      return update(state, {
        $merge: {
          isLoading: false,
          experiment: undefined,
        },
      });

    case START_ADD:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD:
      return update(state, { $merge: { isCreating: false } });

    case START_ADD_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });
    case START_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (experiments) => ({
  type: COMPLETE_LOAD,
  experiments,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startLoadExperiment = () => ({
  type: START_LOAD_EXPERIMENT,
});

const completeLoadExperiment = (experiment) => ({
  type: COMPLETE_LOAD_EXPERIMENT,
  experiment,
});

const failLoadExperiment = () => ({
  type: FAIL_LOAD_EXPERIMENT,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startAddComment = () => ({
  type: START_ADD_COMMENT,
});

const completeAddComment = () => ({
  type: COMPLETE_ADD_COMMENT,
});

const failAddComment = () => ({
  type: FAIL_ADD_COMMENT,
});

const startEditComment = () => ({
  type: START_EDIT_COMMENT,
});

const completeEditComment = () => ({
  type: COMPLETE_EDIT_COMMENT,
});

const failEditComment = () => ({
  type: FAIL_EDIT_COMMENT,
});

const startDeleteComment = () => ({
  type: START_DELETE_COMMENT,
});

const completeDeleteComment = () => ({
  type: COMPLETE_DELETE_COMMENT,
});

const failDeleteComment = () => ({
  type: FAIL_DELETE_COMMENT,
});

const startAddReply = () => ({
  type: START_ADD_REPLY,
});

const completeAddReply = () => ({
  type: COMPLETE_ADD_REPLY,
});

const failAddReply = () => ({
  type: FAIL_ADD_REPLY,
});

const startEditReply = () => ({
  type: START_EDIT_REPLY,
});

const completeEditReply = () => ({
  type: COMPLETE_EDIT_REPLY,
});

const failEditReply = () => ({
  type: FAIL_EDIT_REPLY,
});

const startDeleteReply = () => ({
  type: START_DELETE_REPLY,
});

const completeDeleteReply = () => ({
  type: COMPLETE_DELETE_REPLY,
});

const failDeleteReply = () => ({
  type: FAIL_DELETE_REPLY,
});

export const addExperimentComment = (values, experimentId) => (dispatch) => {
  dispatch(startAddComment());
  const addExperimentCommentPromise = doAddExperimentComment(
    values,
    experimentId
  );
  addExperimentCommentPromise
    .then(() => {
      dispatch(completeAddComment());
      dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddComment());
    });
  return addExperimentCommentPromise;
};

export const editExperiment =
  (values, parent, parentId, personResponsible) => (dispatch) => {
    dispatch(startEdit());
    const editExperimentPromise = doEditExperiment(
      values,
      parent,
      parentId,
      personResponsible
    );
    editExperimentPromise
      .then(() => {
        dispatch(completeEdit());
        dispatch(notifySuccess('Experimentul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEdit());
      });
    return editExperimentPromise;
  };

export const deleteExperiment = (experimentId) => (dispatch) => {
  dispatch(startDelete());
  const deleteExperimentPromise = doDeleteExperiment(experimentId);
  deleteExperimentPromise
    .then(() => {
      dispatch(completeDelete());
      dispatch(notifySuccess('Experimentul a fost șters cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDelete());
    });
  return deleteExperimentPromise;
};

export const editExperimentComment =
  (values, experimentCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editExperimentCommentPromise = doEditExperimentComment(
      values,
      experimentCommentId
    );
    editExperimentCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editExperimentCommentPromise;
  };

export const deleteExperimentComment = (experimentCommentId) => (dispatch) => {
  dispatch(startDeleteComment());
  const deleteExperimentCommentPromise =
    doDeleteExperimentComment(experimentCommentId);
  deleteExperimentCommentPromise
    .then(() => {
      dispatch(completeDeleteComment());
      dispatch(notifySuccess('Comentariul a fost șters cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDeleteComment());
    });
  return deleteExperimentCommentPromise;
};

export const loadExperiments = (teamId) => (dispatch) => {
  dispatch(startLoad());
  const loadExperimentsPromise = doLoadExperiments(teamId);

  loadExperimentsPromise
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
    });
  return loadExperimentsPromise;
};

export const addExperiment = (values, teamId, createdBy) => (dispatch) => {
  dispatch(startAdd());
  const addExperimentPromise = doAddExperiment(values, teamId, createdBy);

  addExperimentPromise
    .then(() => {
      dispatch(completeAdd());
      dispatch(notifySuccess('Experimentul a fost adăugat cu succes'));
    })
    .catch((error) => {
      dispatch(failAdd());
      dispatch(notifyError(error.response.data));
    });

  return addExperimentPromise;
};

export const loadExperiment = (experimentId) => (dispatch) => {
  dispatch(startLoadExperiment());
  const loadExperimentPromise = doLoadExperiment(experimentId);

  loadExperimentPromise
    .then((res) => {
      dispatch(completeLoadExperiment(res.data));
    })
    .catch((error) => {
      dispatch(failLoadExperiment());
      dispatch(notifyError(error.response.data));
      if (error.response.status === 404) dispatch(push('/canvas'));
    });

  return loadExperimentPromise;
};

export const addExperimentCommentReply =
  (values, experimentCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addExperimentCommentReplyPromise = doAddExperimentCommentReply(
      values,
      experimentCommentId
    );
    addExperimentCommentReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addExperimentCommentReplyPromise;
  };

export const editExperimentCommentReply =
  (values, experimentCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editExperimentCommentReplyPromise = doEditExperimentCommentReply(
      values,
      experimentCommentId,
      replyId
    );
    editExperimentCommentReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editExperimentCommentReplyPromise;
  };

export const deleteExperimentCommentReply =
  (experimentCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteExperimentCommentReplyPromise = doDeleteExperimentCommentReply(
      experimentCommentId,
      replyId
    );
    deleteExperimentCommentReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteExperimentCommentReplyPromise;
  };
