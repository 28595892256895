import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { ProfileForm } from '../forms';
import { loadProfile, editProfile } from '../../ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const ProfileModal = (props) => {
  const {
    loadProfile,
    isModalOpen,
    setIsModalOpen,
    editProfile,
    isEditing,
    userProfile,
  } = props;
  const onSubmit = async (values) => {
    await editProfile(values);
    setIsModalOpen(false);
    await loadProfile();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">Editează Profil</Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <ProfileForm onSubmit={onSubmit} userProfile={userProfile} />
      </Modal.Body>

      <Loading isLoading={isEditing} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.userProfile.isEditing,
  userProfile: state.userProfile.userProfile,
});

const mapDispatchToProps = {
  loadProfile,
  editProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileModal)
);
