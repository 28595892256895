import styled, { css } from 'styled-components';
import { ReactComponent as ReplyIcon } from '../../assets/icons/Reply_Small.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Button_Delete_S.svg';

export const ReplyButton = styled(ReplyIcon)`
  cursor: pointer;
  :hover {
    border: 1px solid ${(props) => props.theme.text};
  }
`;

export const ReplyButtonWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #cccccc;
`;
export const DeleteCommentButton = styled(DeleteIcon)`
  cursor: pointer;
  :hover > path {
    fill: ${(props) => props.theme.dangerHover};
  }
  ${(props) =>
    props.commentComponent &&
    css`
      width: 24px;
      height: 24px;
    `}
`;

export const CommentDiv = styled.div`
  width: ${(props) => props.width};
  border-radius: 8px;
  background: #f7f7f8;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const TextCommentDiv = styled.div`
  display: flex;
  width: 248px;
`;
export const CommentButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
  min-width: 60px;
`;

export const DeleteEditCommentDiv = styled.div`
  padding-top: 10px;
  ${(props) =>
    props.replyComment &&
    css`
      padding: 0;
    `}
`;
