import PropTypes from 'prop-types';

const DocumentTitle = ({ children }) => {
  document.title = `${children}`;

  return null;
};

DocumentTitle.propTypes = {
  children: PropTypes.node,
};

DocumentTitle.defaultProps = {
  children: undefined,
};

export default DocumentTitle;
