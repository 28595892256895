import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { get, map, omit, size, find } from 'lodash-es';
import { Formik, useField, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { Col, Form } from 'react-bootstrap';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonDiv,
  LinkIcon,
  PositionParent,
  Input,
  Heading2,
  SubmitButton,
  SaveIcon,
  MinusButton,
  AddCommentButton,
  LabelButtonContainer,
} from '../styled';
import { Dropdown } from '../../../core/components';

import {
  ENGAGEMENT_LEVEL_OPTIONS,
  IS_EARLY_ADOPTER_OPTIONS,
  CAN_CONTACT_LATER_OPTIONS,
  CONTACT_TYPE_OPTIONS,
  DETAIL_CATEGORY_OPTIONS,
  BUYING_SIGNALS_OPTIONS,
} from '../../constants';

const RenderTitleField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDetailField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={type}
        height="100px"
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderResourcesField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,
    setShowPlusButton,
    showPlusButton,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (size(values.resources) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.resources, setShowPlusButton]);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <LabelButtonContainer>
            <Heading2>Link-uri</Heading2>
            {showPlusButton && (
              <AddCommentButton onClick={() => arrayHelpers.push('')} />
            )}
          </LabelButtonContainer>
          {values.resources.map((resource, index) => (
            <Form.Row
              key={index}
              style={{ width: '100%', margin: '0 0 10px 0' }}
            >
              <PositionParent width="93%">
                <LinkIcon icon={faLink} />
                <Input
                  formlink
                  type={type}
                  placeholder={placeholder}
                  name={`resources[${index}]`}
                  onChange={(e) => {
                    helpers.setTouched();
                    handleChange(e);
                  }}
                  value={values.resources[index]}
                  isInvalid={
                    meta.touched &&
                    meta.error &&
                    meta.error[index] &&
                    meta.error[index]
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: 'inherit' }}
                >
                  {meta.touched &&
                    meta.error &&
                    meta.error[index] &&
                    meta.error[index]}
                </Form.Control.Feedback>
              </PositionParent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '7%',
                }}
              >
                {values.resources.length > 1 && (
                  <MinusButton
                    marginTop="10px"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                )}
              </div>
            </Form.Row>
          ))}
        </>
      )}
    />
  );
};

const RenderDropdownField = (props) => {
  const { label, options, name, setFieldValue, values, controlId } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={Dropdown}
        options={options}
        name={name}
        value={fieldValue}
        onBlur={helpers.setTouched}
        showIconOption
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  hypothesesOptions,
  experimentsOptions,
  isHypothesis,
  isExperiment,
  setFieldValue,
  showPlusButton,
  setShowPlusButton,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      {isHypothesis && (
        <RenderDropdownField
          values={values}
          controlId="validationFormik01"
          setFieldValue={setFieldValue}
          label="Alege ipoteza"
          name="hypothesis"
          options={hypothesesOptions}
        />
      )}

      {isExperiment && (
        <RenderDropdownField
          values={values}
          controlId="validationFormik02"
          setFieldValue={setFieldValue}
          name="experiment"
          label="Alege experimentul"
          options={experimentsOptions}
        />
      )}
    </Form.Row>
    <Form.Row>
      <RenderTitleField
        name="title"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik03"
        label="Titlu detaliu*"
        placeholder="Titlu detaliu"
        type="text"
      />
    </Form.Row>
    <Form.Row>
      <RenderDetailField
        name="detail"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik04"
        label="Descriere"
        placeholder="Menționează ce reprezintă acest detaliu"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <RenderDropdownField
        values={values}
        controlId="validationFormik05"
        setFieldValue={setFieldValue}
        name="detailCategory"
        label="Categorie detaliu"
        options={DETAIL_CATEGORY_OPTIONS}
      />

      <RenderDropdownField
        values={values}
        controlId="validationFormik06"
        setFieldValue={setFieldValue}
        name="isEarlyAdopter"
        label='Are profilul unui "early-adopter"?'
        options={IS_EARLY_ADOPTER_OPTIONS}
      />
    </Form.Row>
    <Form.Row>
      <RenderDropdownField
        values={values}
        controlId="validationFormik07"
        setFieldValue={setFieldValue}
        name="contactType"
        label="Tip contact"
        options={CONTACT_TYPE_OPTIONS}
      />

      <RenderDropdownField
        values={values}
        controlId="validationFormik08"
        setFieldValue={setFieldValue}
        name="canContactLater"
        label="Există disponibilitate de contact ulterior?"
        options={CAN_CONTACT_LATER_OPTIONS}
      />
    </Form.Row>
    <Form.Row>
      <RenderDropdownField
        values={values}
        controlId="validationFormik09"
        setFieldValue={setFieldValue}
        name="engagementLevel"
        label="Cât de interesat părea interlocutorul?"
        options={ENGAGEMENT_LEVEL_OPTIONS}
      />

      <RenderDropdownField
        values={values}
        controlId="validationFormik10"
        setFieldValue={setFieldValue}
        name="buyingSignals"
        label="Au existat semnale de cumpărare?"
        options={BUYING_SIGNALS_OPTIONS}
      />
    </Form.Row>
    <Form.Row>
      <Col md="12">
        <RenderResourcesField
          name="resources"
          values={values}
          handleChange={handleChange}
          type="text"
          label="Linkuri"
          placeholder="https://"
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
        />
      </Col>
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        {' '}
        <SaveIcon />
        Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddDetailForm = (props) => {
  const {
    onSubmit,
    isEditMode,
    detail,
    experiments,
    hypotheses,
    isHypothesis,
    isExperiment,
    global,
    onSubmitGlobal,
  } = props;

  const [showPlusButton, setShowPlusButton] = useState(false);

  const hypothesesOptions = map(hypotheses, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
  }));

  const experimentsOptions = map(experiments, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
  }));

  const handleSubmit = (values, { resetForm }) =>
    global
      ? onSubmitGlobal(
          omit(values, ['experiment', 'hypothesis']),
          isHypothesis
            ? get(values.hypothesis, 'value')
            : get(values.experiment, 'value')
        )
      : onSubmit(omit(values, ['experiment', 'hypothesis']));

  const schema = Yup.object({
    title: Yup.string().required('Câmp obligatoriu'),
    detail: Yup.string(),
    resources: Yup.array().of(
      Yup.string().url(
        'URL Invalid (linkul trebuie sa contina http:// sau https://)'
      )
    ),
    hypothesis: isHypothesis && Yup.string().required('Câmp obligatoriu'),
    experiment: isExperiment && Yup.string().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    title: '',
    detail: '',
    resources:
      detail && detail.resources && size(detail.resources) !== 0
        ? detail.resources
        : [''],
    hypothesis: '',
    experiment: '',
  };

  const defaultDetail = {
    ...detail,
    resources:
      detail && detail.resources && size(detail.resources) !== 0
        ? detail.resources
        : [''],
    detailCategory: find(DETAIL_CATEGORY_OPTIONS, {
      value: get(detail, 'detailCategory'),
    }),

    contactType: find(CONTACT_TYPE_OPTIONS, {
      value: get(detail, 'contactType'),
    }),
    engagementLevel: find(ENGAGEMENT_LEVEL_OPTIONS, {
      value: get(detail, 'engagementLevel'),
    }),
    canContactLater: find(CAN_CONTACT_LATER_OPTIONS, {
      value: get(detail, 'canContactLater'),
    }),
    buyingSignals: find(BUYING_SIGNALS_OPTIONS, {
      value: get(detail, 'buyingSignals'),
    }),
    isEarlyAdopter: find(IS_EARLY_ADOPTER_OPTIONS, {
      value: get(detail, 'isEarlyAdopter'),
    }),
  };

  const initialValues = isEditMode ? defaultDetail : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          isEditMode={isEditMode}
          experimentsOptions={experimentsOptions}
          hypothesesOptions={hypothesesOptions}
          isExperiment={isExperiment}
          isHypothesis={isHypothesis}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
        />
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
  experiments: state.experiments.experiments,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetailForm);
