import React, { useState } from 'react';
import { map, orderBy, size } from 'lodash-es';

import {
  ResourceBox,
  ResourceContent,
  ResourceTitle,
  ResourcesContainer,
  ResourcesHeaderRow,
  ResourcesHeaderText,
  ResourceLinkIcon,
  ResourcesHeaderToggleIcon,
  TextInactive,
} from './styled';

const ResourcesSection = ({ resources }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleExpand = () => setIsExpanded(!isExpanded);

  return (
    <ResourcesContainer>
      <ResourcesHeaderRow>
        <ResourcesHeaderText>Resurse</ResourcesHeaderText>
        <ResourcesHeaderToggleIcon
          onClick={handleExpand}
          isExpanded={isExpanded}
        />
      </ResourcesHeaderRow>
      <ResourceContent className="scrollbar-modal">
        {isExpanded &&
          map(orderBy(resources, 'order'), (res) => (
            <ResourceBox href={res.url} target="_blank">
              <ResourceTitle>{res.title}</ResourceTitle>
              <ResourceLinkIcon />
            </ResourceBox>
          ))}

        {!size(resources) > 0 && (
          <TextInactive>
            Momentan nu există nicio resursă pentru această secțiune.
          </TextInactive>
        )}
      </ResourceContent>
    </ResourcesContainer>
  );
};

export default ResourcesSection;
