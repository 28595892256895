import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  Input,
  Heading2,
  SubmitButton,
  ButtonDiv,
} from '../../../common/components/styled';
import { AccountPanelLink, LoginPageDelimiter } from '../styled';
import { facebookLogin } from '../../ducks';

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  facebookLogin,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row style={{ marginBottom: '30px' }}>
      <Form.Group
        style={{ marginBottom: '0' }}
        as={Col}
        md="12"
        controlId="validationFormik01"
      >
        <Heading2 marginBottom="10px">Email</Heading2>
        <Input
          type="text"
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          isInvalid={submitCount > 0 && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {submitCount > 0 && errors.email}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
    <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationFormik02">
        <Heading2 marginBottom="10px">Parolă</Heading2>
        <Input
          type="password"
          placeholder="Parolă"
          name="password"
          value={values.password}
          onChange={handleChange}
          isInvalid={submitCount > 0 && !!errors.password}
        />

        <Form.Control.Feedback type="invalid">
          {submitCount > 0 && errors.password}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
    <Form.Row
      style={{
        marginBottom: '30px',
      }}
    >
      <Form.Group as={Col} md="6">
        <AccountPanelLink to="/account/forgot-password">
          Ai uitat parola?
        </AccountPanelLink>
      </Form.Group>
      <Form.Group style={{ marginBottom: '0' }} as={Col} md="6">
        <AccountPanelLink style={{ float: 'right' }} to="/account/register">
          Nu ai cont? Înregistrează-te!
        </AccountPanelLink>
      </Form.Group>
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit" style={{ width: '205px' }}>
        Intră in cont
      </SubmitButton>
    </ButtonDiv>
    <LoginPageDelimiter>
      <span>sau</span>
    </LoginPageDelimiter>
    <ButtonDiv>
      <SubmitButton
        style={{ width: '205px' }}
        facebookButton
        type="button"
        onClick={facebookLogin}
      >
        <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} />
        Continuă cu Facebook
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const LoginForm = ({ onSubmit, isLoginFailed, facebookLogin }) => {
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    email: Yup.string().required('Câmp obligatoriu'),
    // password: Yup.string().required('Câmp obligatoriu'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {(props) => (
        <RenderForm
          {...props}
          isLoginFailed={isLoginFailed}
          facebookLogin={facebookLogin}
        />
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  facebookLogin,
};

export default connect(undefined, mapDispatchToProps)(LoginForm);
