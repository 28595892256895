import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get, map } from 'lodash-es';
import { Modal } from 'react-bootstrap';
import { ReactComponent as ReplyIconMessage } from '../assets/icons/Reply.svg';

import { AddCommentReplyModal } from './modals';

import {
  AcceptButton,
  DivBorderBottom,
  ButtonText,
} from '../../mentors/components/styled';
import {
  CommentDiv,
  Heading2,
  Heading4Regular,
  ReplyButton,
  DeleteCommentButton,
  EditButtonSmall,
  TextCommentDiv,
  CommentButtonsDiv,
  DeleteEditCommentDiv,
  ReplyButtonWrapper,
} from './styled';

const AlertMessage = ({ show, setShow, onSubmit, id }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere comentariu</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea comentariului?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const CommentRenderComponent = (props) => {
  const {
    comment,
    handleEdit,
    handleDeleteComment,
    handleAddReply,
    handleEditReply,
    handleDeleteReply,
    loadData,
    isLoading,
    userProfileId,
  } = props;
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [isEditModeReply, setIsEditModeReply] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openAlertMessageReply, setOpenAlertMessageReply] = useState(false);
  const [reply, setReply] = useState(undefined);
  const [replyToDelete, setReplyToDelete] = useState(undefined);

  const handleDeleteCommentReply = (id) => {
    handleDeleteReply(id);
    loadData();
  };

  return (
    <div>
      {comment && (
        <CommentDiv>
          <TextCommentDiv>
            <Heading4Regular noEllipsis>
              <b>{`${comment.user.firstName} ${comment.user.lastName}: `}</b>
              {comment && comment.comment}
            </Heading4Regular>
          </TextCommentDiv>
          <CommentButtonsDiv>
            <ReplyButtonWrapper>
              <ReplyButton onClick={() => setIsReplyModalOpen(true)} />
            </ReplyButtonWrapper>
            {userProfileId === get(comment, 'user._id') && (
              <DeleteEditCommentDiv>
                <DeleteCommentButton
                  commentComponent
                  onClick={() => setOpenAlertMessage(true)}
                />
                <EditButtonSmall
                  commentComponent
                  onClick={handleEdit}
                  marginLeft="15px"
                />
              </DeleteEditCommentDiv>
            )}
          </CommentButtonsDiv>
        </CommentDiv>
      )}
      {map(comment.replys, (reply, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '10%',
              height: '50px',
              marginRight: '10px',
            }}
          >
            <ReplyIconMessage />
          </div>
          <CommentDiv>
            <TextCommentDiv style={{ width: '220px' }}>
              <Heading2>{`${reply.user.firstName} ${reply.user.lastName}:`}</Heading2>
              <Heading4Regular noEllipsis>
                {reply && reply.comment}
              </Heading4Regular>
            </TextCommentDiv>
            <CommentButtonsDiv>
              {userProfileId === get(reply, 'user._id') && (
                <DeleteEditCommentDiv replyComment>
                  <DeleteCommentButton
                    commentComponent
                    onClick={() => {
                      setReplyToDelete(reply._id);
                      setOpenAlertMessageReply(true);
                    }}
                  />
                  <EditButtonSmall
                    commentComponent
                    onClick={() => {
                      setReply(reply);
                      setIsEditModeReply(true);
                      setIsReplyModalOpen(true);
                    }}
                    marginLeft="20px"
                  />
                </DeleteEditCommentDiv>
              )}
            </CommentButtonsDiv>
          </CommentDiv>
        </div>
      ))}
      <AddCommentReplyModal
        isModalOpen={isReplyModalOpen}
        setIsModalOpen={setIsReplyModalOpen}
        isEditModeReply={isEditModeReply}
        setIsEditModeReply={setIsEditModeReply}
        handleAddReply={(values) => handleAddReply(values, comment._id)}
        handleEditReply={(values) => handleEditReply(values, reply._id)}
        loadData={loadData}
        isLoading={isLoading}
        comment={isEditModeReply ? reply : undefined}
      />
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteComment}
        id={comment._id}
      />

      <AlertMessage
        show={openAlertMessageReply}
        setShow={setOpenAlertMessageReply}
        onSubmit={handleDeleteCommentReply}
        id={replyToDelete}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfileId: get(state.userProfile, 'userProfile._id'),
});

export default connect(mapStateToProps)(CommentRenderComponent);
