import { get, filter } from 'lodash-es';
import { http } from '../../core/services/http';

export const addHypothesisDetailComment = (values, hypothesisDetailId) => {
  const registerData = {
    ...values,
    hypothesisDetailId,
  };
  return http.post('/hypothesis-detail-comment/add', {
    ...registerData,
  });
};

export const deleteHypothesisDetail = (hypothesisDetailId) =>
  http.delete(`/hypothesis-detail/delete/${hypothesisDetailId}`);

export const deleteHypothesisDetailComment = (hypothesisDetailCommentId) =>
  http.delete(`/hypothesis-detail-comment/delete/${hypothesisDetailCommentId}`);

export const editHypothesisDetailComment = (
  values,
  hypothesisDetailCommentId
) =>
  http.put(
    `/hypothesis-detail-comment/edit/${hypothesisDetailCommentId}`,
    values
  );

export const addHypothesisDetail = (values, createdBy, parentId) => {
  const registerValues = {
    ...values,
    resources: filter(values.resources, (elem) => elem !== ''),
    createdBy,
    hypothesisId: parentId,
  };

  return http.post('/hypothesis-detail/add', registerValues);
};

export const editHypothesisDetail = (
  values,
  entityId,
  parent,
  personResponsible
) => {
  const registerValues = {
    ...values,
    resources: filter(values.resources, (elem) => elem !== ''),
  };

  const registerDataResolution = {
    ...parent,
    resolution: {
      ...values.resolution,
      personResponsible,
      actionRequired:
        values.resolution && get(values.resolution.actionRequired, 'value'),
      dataReliability:
        values.resolution && get(values.resolution.dataReliability, 'value'),
    },
  };
  const registerData =
    parent === undefined ? registerValues : registerDataResolution;

  return http.put(`/hypothesis-detail/edit/${entityId}`, registerData);
};

export const loadHypothesisDetail = (hypothesisDetailId) =>
  http.get(`/hypothesis-detail/get/${hypothesisDetailId}`);

export const addHypothesisDetailCommentReply = (
  values,
  hypothesisDetailCommentId
) =>
  http.post(
    `/hypothesis-detail-comment/reply/add/${hypothesisDetailCommentId}`,
    values
  );

export const editHypothesisDetailCommentReply = (
  values,
  hypothesisDetailCommentId,
  replyId
) =>
  http.put(
    `/hypothesis-detail-comment/reply/edit/${hypothesisDetailCommentId}/${replyId}`,
    values
  );

export const deleteHypothesisDetailCommentReply = (
  hypothesisDetailCommentId,
  replyId
) =>
  http.delete(
    `/hypothesis-detail-comment/reply/delete/${hypothesisDetailCommentId}/${replyId}`
  );
