import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { dropdownStyles } from '../../constants';
import { Dropdown } from '../../../core/components';

const TeamFilterForm = ({ userTeams, userTeamValue, handleChangeTeam }) => (
  <Formik onSubmit={() => {}}>
    {() => (
      <Form>
        <Form.Group style={{ padding: '8px 11px', marginBottom: 0 }}>
          <Form.Control
            as={Dropdown}
            placeholder="IDEEA MEA"
            options={userTeams}
            styles={dropdownStyles}
            showCustomControl
            name="team"
            value={userTeamValue}
            onChange={(e) => handleChangeTeam(e)}
          />
        </Form.Group>
      </Form>
    )}
  </Formik>
);

export default TeamFilterForm;
