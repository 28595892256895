import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get, map, includes, filter, find, trim } from 'lodash-es';
import uuid from 'react-uuid';
import {
  DatePicker,
  SaveIcon,
  LinkIcon,
  PositionParent,
  Input,
  Heading2,
  SubmitButton,
  InfoIcon,
  ButtonDiv,
} from '../../../common/components/styled';
import { Dropdown } from '../../../core/components';
import { stageOptions, ideaCategoryOptions } from '../../constants';
import { isCohortCodeUsed } from '../../services/createTeam';
import { isCohortExpired } from '../../../accelerator/services/cohorts';

const RenderDropdown = (props) => {
  const {
    values,
    name,
    md,
    label,
    options,
    setFieldValue,
    isMulti,
    controlId,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, []);
    }
  };
  return (
    <Form.Group as={Col} md={md || 12} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={Dropdown}
        name={name}
        value={fieldValue}
        isMulti={isMulti}
        options={options}
        placeholder={placeholder}
        isInvalid={meta.touched && !!meta.error}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
          handleNullValue(v);
        }}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
        {get(fieldValue, 'length') > 3 && 'Maxim 3 domenii'}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderTextArea = (props) => {
  const { label, md, values, name, handleChange, controlId, placeholder } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="100px"
        as="textarea"
        placeholder={placeholder}
        onBlur={helpers.setTouched}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        name={name}
        value={fieldValue}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    md,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onBlur={() => {
          helpers.setTouched();
        }}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderLinkField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    md,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <PositionParent>
        <LinkIcon />
        <Input
          formlink
          type={type}
          name={name}
          placeholder={placeholder}
          value={fieldValue}
          onBlur={() => {
            helpers.setTouched();
          }}
          onChange={(e) => {
            helpers.setTouched();
            handleChange(e);
          }}
          isInvalid={meta.touched && !!meta.error}
        />

        <Form.Control.Feedback type="invalid">
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </PositionParent>
    </Form.Group>
  );
};

const RenderCohortCodeField = (props) => {
  const { values, handleChange, name, controlId, label, placeholder } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <Heading2>{label}</Heading2>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Introdu codul Cohortei la care vrei să te alături cu ideea, dacă
              este cazul. Poți să-l introduci și mai târziu din pagina Profil
              echipă
            </Tooltip>
          }
        >
          <InfoIcon />
        </OverlayTrigger>
      </div>

      <Input
        type="text"
        placeholder={placeholder}
        name="cohortCode"
        value={fieldValue}
        onBlur={async () => {
          helpers.setTouched();
        }}
        onChange={(event) => {
          helpers.setTouched();
          return handleChange(event);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDateField = (props) => {
  const { values, name, placeholder, controlId, label, setFieldValue } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, '');
    }
  };
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        component={DatePicker}
        as={DatePicker}
        name={name}
        placeholderText={placeholder}
        selected={fieldValue}
        value={fieldValue}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd/MM/yyyy"
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('dateOfEstablishment', v);
          handleNullValue(v);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  checkTeamChodeUsed,
  initialValues,
  setFieldValue,
  isValid,
  isSubmitting,
  onSubmit,
  setPrivateTeam,
  privateTeam,
  isEditMode,
  checkIsCohortExpired,
  isCohortExpired,
  ...rest
}) => (
  <Form>
    <Form.Row>
      <RenderTextField
        values={values}
        md="12"
        name="name"
        label="Numele ideii*"
        placeholder="Cum se va numi proiectul nou?"
        handleChange={handleChange}
        controlId="validationFormik01"
      />
    </Form.Row>
    <Form.Row>
      <RenderLinkField
        values={values}
        md="6"
        name="domain"
        label="Website"
        placeholder="https://"
        handleChange={handleChange}
        controlId="validationFormik03"
        isLink
      />
      <RenderTextField
        values={values}
        md="6"
        name="headquarters"
        label="Locație"
        placeholder="Localitatea de unde activati"
        handleChange={handleChange}
        controlId="validationFormik04"
      />
    </Form.Row>
    <Form.Row>
      <RenderTextField
        values={values}
        md="6"
        name="cui"
        label="CUI"
        handleChange={handleChange}
        placeholder="Doar daca este existent"
        controlId="validationFormik05"
      />
      <RenderDateField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik06"
        name="dateOfEstablishment"
        label="Dată înființare/idee"
        placeholder="zz/ll/aaaa"
      />
    </Form.Row>
    <Form.Row>
      <RenderDropdown
        values={values}
        setFieldValue={setFieldValue}
        label="Industria sau domeniul* (maxim 3)"
        name="ideaCategory"
        isMulti
        md="6"
        options={ideaCategoryOptions}
        controlId="validationFormik07"
        placeholder="Alege maxim 3 industrii"
      />
      <RenderDropdown
        values={values}
        setFieldValue={setFieldValue}
        label=" Stadiul în care ești cu ideea*"
        placeholder="Alege stadiul ideii tale"
        name="stage"
        md="6"
        options={stageOptions}
        controlId="validationFormik08"
      />
    </Form.Row>

    <Form.Row>
      <RenderTextArea
        values={values}
        md="12"
        name="description"
        label="Descrie ideea ta*"
        placeholder="Ce probleme rezolva, cui se adresează, care sunt beneficiile etc"
        handleChange={handleChange}
        controlId="validationFormik09"
      />
    </Form.Row>
    {!isEditMode && (
      <Form.Row>
        <RenderCohortCodeField
          values={values}
          name="cohortCode"
          handleChange={handleChange}
          label="Vrei să te alături unei cohorte sau program de accelerare/incubare?"
          placeholder="Introdu aici codul cohortei căreia vrei să te alături"
          controlId="validationFormik1"
          initialValues={initialValues}
        />
      </Form.Row>
    )}

    <ButtonDiv>
      <SubmitButton type="button" onClick={handleSubmit}>
        <SaveIcon />
        Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const CreateTeamForm = ({ onSubmit, team, isEditMode }) => {
  const checkTeamChodeUsed = null;
  const [privateTeam, setPrivateTeam] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    name: Yup.string().required('Câmp obligatoriu'),
    ideaCategory: Yup.string().required('Câmp obligatoriu'),
    stage: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    cohortCode: Yup.string()
      .test('is-invalid', 'Cod cohortă invalid', async (value) =>
        trim(value)
          ? isCohortCodeUsed(value).then(({ data }) => data.isUsed)
          : true
      )
      .test('is-expired', 'Această cohortă a expirat', async (value) =>
        trim(value)
          ? isCohortExpired(value).then(({ data }) => !data.isExpired)
          : true
      ),
    teamCode: Yup.string(),
    domain: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    dateOfEstablishment: Yup.string(),
    headquarters: Yup.string(),
    cui: Yup.string(),
  });

  const defaultIdeeas =
    team &&
    team.ideaCategory &&
    filter(ideaCategoryOptions, (elem) =>
      includes(map(team.ideaCategory), elem.value)
    );

  const defaultStage =
    team && team.stage && find(stageOptions, { value: team.stage });

  const initialValues = {
    name: team && team.name ? team.name : '',
    ideaCategory: team && team.ideaCategory ? defaultIdeeas : '',
    stage: team && team.stage ? defaultStage : '',
    description: team && team.description ? team.description : '',
    cohortCode: team && team.cohortCode ? team.cohortCode : '',
    teamCode: team && team.teamCode ? team.teamCode : uuid(),
    domain: team && team.domain ? team.domain : '',
    dateOfEstablishment:
      team && team.dateOfEstablishment
        ? new Date(team.dateOfEstablishment)
        : '',
    headquarters: team && team.headquarters ? team.headquarters : '',
    cui: team && team.cui ? team.cui : '',
  };
  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          checkTeamChodeUsed={checkTeamChodeUsed}
          setPrivateTeam={() => setPrivateTeam(!privateTeam)}
          privateTeam={privateTeam}
          isEditMode={isEditMode}
        />
      )}
    </Formik>
  );
};

CreateTeamForm.propTypes = {};

export default CreateTeamForm;
