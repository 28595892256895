/* eslint-disable no-nested-ternary */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { editTeam, getTeam } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import { AddTeamDetailsForm } from '../forms';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddTeamDetailsModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    editTeam,
    getTeam,
    teamId,
    isLoading,
    setSection,
    section,
    userId,
  } = props;
  const onSubmit = async (values) => {
    await editTeam(teamId, values, userId);
    setIsModalOpen(false);
    setSection(undefined);
    await getTeam(teamId);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSection(undefined);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Body className="modal-responsive scrollbar-modal">
          <TitleButtonsDiv marginBottom="30px">
            <Title fontSize="24px">
              {section === 'IRL'
                ? 'Editează detalii IRL'
                : section === 'investment'
                ? 'Editează detalii investiție căutată'
                : 'Adaugă detaliile echipei'}{' '}
            </Title>
            <DeleteButtonSmall onClick={handleCloseModal} />
          </TitleButtonsDiv>
          <AddTeamDetailsForm onSubmit={onSubmit} section={section} />
        </Modal.Body>
        <Loading isLoading={isLoading} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  userId: get(state.account.login, 'user._id'),
  isLoading: state.team.isEditing,
});

const mapDispatchToProps = { editTeam, getTeam };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTeamDetailsModal)
);
