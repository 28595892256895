import { combineReducers } from 'redux';

// Import Reducers
import { reducer as notificationsReducer } from './notifications';

// Export Action Creators
export { notifySuccess, notifyWarning, notifyError } from './notifications';

export const reducer = combineReducers({
  notifications: notificationsReducer,
});
