import { connect } from 'react-redux';
import { find, get, map, groupBy, size, indexOf } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from 'react-bootstrap';
import {
  Heading1,
  IRLBubble,
  IRLContainer,
  IRLRow,
  IRLTitleRow,
  WhiteCard,
  Heading4Regular,
} from './styled';

import { thermometerLabels } from './IRLThermometer';
import useOnClickOutside from '../../utils/services/useOutsideClick';
import { getTeam } from '../../team/ducks';

const IRL = ({ teamId, getTeam, team }) => {
  const [isOpenTooltipPopver, setIsOpenTooltipPopover] = useState(false);
  const [tooltipData, setTooltipData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(undefined);

  const ref = useRef(null);
  const irlContainerRef = useRef(null);

  useEffect(() => {
    getTeam(teamId);
  });

  const closeTooltipPopover = () => setIsOpenTooltipPopover(false);
  useOnClickOutside(ref, closeTooltipPopover);

  const irlLevels = useMemo(() => get(team, 'irlLevels', {}), [team]);
  const irlPercentage = useMemo(() => {
    const levels = map(Object.keys(irlLevels), (el) => irlLevels[el].isActive);
    const levelGroups = groupBy(levels);
    const percentage = Math.round(
      (size(get(levelGroups, 'true', [])) * 100) / size(levels),
      2
    );
    return percentage;
  }, [irlLevels]);

  const nextIrlLevel = useMemo(() => {
    const level = find(
      Object.keys(irlLevels),
      (level) => irlLevels[level].isActive === false
    );
    const levelNumber = indexOf(Object.keys(irlLevels), level);

    return get(
      find(thermometerLabels, { number: levelNumber }),
      'tooltipTitle',
      ''
    );
  }, [irlLevels]);

  const handleClick = (e, index) => {
    e.stopPropagation();
    if (!(isOpenTooltipPopver && selectedIndex !== index)) {
      setIsOpenTooltipPopover(!isOpenTooltipPopver);
    }
    setSelectedIndex(index);
    const { tooltipTitle, tooltipContents } = find(thermometerLabels, {
      number: index,
    });
    setTooltipData({ tooltipTitle, tooltipContents, index });
  };

  return (
    <IRLContainer ref={irlContainerRef}>
      {!!isOpenTooltipPopver && (
        <Popover
          id="popover-basic"
          style={{
            marginTop: '105px',
            marginLeft: `${Math.min(
              tooltipData.index * 75 + 20,
              irlContainerRef.current.clientWidth - 650
            )}px`,
          }}
          placement="bottom"
          ref={ref}
        >
          <WhiteCard width="650px" height="350px">
            <Heading1 marginBottom="15px">{tooltipData.tooltipTitle}</Heading1>
            <Heading4Regular
              style={{ whiteSpace: 'pre-wrap', maxHeight: '100%' }}
              noEllipsis
            >
              {tooltipData.tooltipContents}
            </Heading4Regular>
          </WhiteCard>
        </Popover>
      )}
      <IRLTitleRow>
        <Heading1 style={{ marginRight: '2px' }}>IRL:</Heading1>
        <Heading1 style={{ fontWeight: '400', marginRight: '5px' }}>
          {`${irlPercentage} %`}
        </Heading1>
        <Heading1 style={{ marginRight: '8px' }}> | </Heading1>
        <Heading1 style={{ marginRight: '2px' }}>Următorul prag:</Heading1>
        <Heading1
          style={{
            fontWeight: '400',
            marginRight: '5px',
          }}
        >
          {nextIrlLevel}
        </Heading1>
      </IRLTitleRow>
      <IRLRow>
        {map(Object.keys(irlLevels), (el, index) => (
          <IRLBubble
            key={el}
            isActive={irlLevels[el].isActive}
            onClick={(e) => handleClick(e, index)}
            index={index + 1}
          >
            {index + 1}
          </IRLBubble>
        ))}
      </IRLRow>
    </IRLContainer>
  );
};

const mapStateToProps = (state) => ({
  team: state.team.team,
});

const mapDispatchToProps = {
  getTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(IRL);
