import { http } from '../../core/services/http';

export const addResolution = (values) =>
  http.post('/hypothesis-resolution/add', values);

export const getResolution = (resolutionId) =>
  http.get(`/hypothesis-resolution/get/${resolutionId}`);

export const editResolution = (values) =>
  http.put(`/hypothesis-resolution/edit/${values._id}`, values);

export const deleteResolution = (resolutionId) =>
  http.delete(`/hypothesis-resolution/delete/${resolutionId}`);

export const addResolutionComment = (values, resolutionCommentId) =>
  http.post('/hypothesis-resolution-comment/add', {
    ...values,
    hypothesisResolutionId: resolutionCommentId,
  });

export const editResolutionComment = (values, resolutionCommentId) =>
  http.put(
    `/hypothesis-resolution-comment/edit/${resolutionCommentId}`,
    values
  );

export const deleteResolutionComment = (resolutionCommentId) =>
  http.delete(`/hypothesis-resolution-comment/delete/${resolutionCommentId}`);

export const addResolutionCommentReply = (values, resolutionCommentId) =>
  http.post(
    `/hypothesis-resolution-comment/reply/add/${resolutionCommentId}`,
    values
  );

export const editResolutionCommentReply = (
  values,
  resolutionCommentId,
  replyId
) =>
  http.put(
    `/hypothesis-resolution-comment/reply/edit/${resolutionCommentId}/${replyId}`,
    values
  );

export const deleteResolutionCommentReply = (resolutionCommentId, replyId) =>
  http.delete(
    `/hypothesis-resolution-comment/reply/delete/${resolutionCommentId}/${replyId}`
  );

// Experiment Resolution

export const addExperimentResolution = (values) =>
  http.post('/experiment-resolution/add', values);

export const getExperimentResolution = (resolutionId) =>
  http.get(`/experiment-resolution/get/${resolutionId}`);

export const editExperimentResolution = (values) =>
  http.put(`/experiment-resolution/edit/${values._id}`, values);

export const deleteExperimentResolution = (resolutionId) =>
  http.delete(`/experiment-resolution/delete/${resolutionId}`);

export const addExperimentResolutionComment = (values, resolutionCommentId) =>
  http.post('/experiment-resolution-comment/add', {
    ...values,
    experimentResolutionId: resolutionCommentId,
  });

export const editExperimentResolutionComment = (values, resolutionCommentId) =>
  http.put(
    `/experiment-resolution-comment/edit/${resolutionCommentId}`,
    values
  );

export const deleteExperimentResolutionComment = (resolutionCommentId) =>
  http.delete(`/experiment-resolution-comment/delete/${resolutionCommentId}`);

export const addExperimentResolutionCommentReply = (
  values,
  resolutionCommentId
) =>
  http.post(
    `/experiment-resolution-comment/reply/add/${resolutionCommentId}`,
    values
  );

export const editExperimentResolutionCommentReply = (
  values,
  resolutionCommentId,
  replyId
) =>
  http.put(
    `/experiment-resolution-comment/reply/edit/${resolutionCommentId}/${replyId}`,
    values
  );

export const deleteExperimentResolutionCommentReply = (
  resolutionCommentId,
  replyId
) =>
  http.delete(
    `/experiment-resolution-comment/reply/delete/${resolutionCommentId}/${replyId}`
  );
