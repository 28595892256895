import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { some, map, isArray, size } from 'lodash-es';
// import { ReactComponent as ValueICon } from '../../common/assets/icons/Value.svg';
import { ReactComponent as KPIsIcon } from '../../common/assets/icons/KPI.svg';
import { ReactComponent as CommentIcon } from '../../common/assets/icons/Comment.svg';
import { ReactComponent as HypothesisInvalidIcon } from '../../common/assets/icons/Ipoteza-Invalida_S_Card.svg';
import { ReactComponent as HypothesisValidIcon } from '../../common/assets/icons/Ipoteza-Valida_S_Card.svg';
import { ReactComponent as HypothesisInProgressIcon } from '../../common/assets/icons/Experiment_Small.svg';
import { ReactComponent as ValidHypothesisMVP } from '../../common/assets/icons/Ipoteza-valida-w-MVP.svg';
import { ReactComponent as InvalidHypothesisMVP } from '../../common/assets/icons/Ipoteza-Invalida-MVP.svg';

import {
  HypothesisCardContainer,
  HypothesisColor,
  HypothesisCardIconsContainer,
  HypothesisTitle,
  HypothesisColorContainer,
  ColorColumnDiv,
  SegmentCardIcon,
} from './styled';

const HypothesisCard = (props) => {
  const { hypothesis, backgroundColor, floatRight, customerSegment } = props;

  const getHypothesisIcon = (resolutions) => {
    const outcomes = map(resolutions, (resolution) => resolution.outcome);

    if (some(outcomes, (outcome) => outcome === 'invalidatedWithMVP')) {
      return <HypothesisInvalidIcon style={{ marginLeft: 'auto' }} />;
    }
    if (some(outcomes, (outcome) => outcome === 'invalidatedWithoutMVP')) {
      return (
        <InvalidHypothesisMVP
          style={{ marginLeft: 'auto', width: '16px', height: '16px' }}
        />
      );
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithMVP')) {
      return <HypothesisValidIcon style={{ marginLeft: 'auto' }} />;
    }
    if (some(outcomes, (outcome) => outcome === 'validatedWithoutMVP')) {
      return (
        <ValidHypothesisMVP
          style={{ marginLeft: 'auto', width: '16px', height: '16px' }}
        />
      );
    }
  };

  return (
    <HypothesisCardContainer
      customerSegment={customerSegment}
      floatRight={floatRight}
      onClick={() => props.push(`/hypothesis/${hypothesis._id}`)}
      key={hypothesis._id}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <HypothesisTitle
          decorationThrough={some(
            hypothesis.resolutions,
            (resolution) =>
              resolution.outcome === 'invalidatedWithMVP' ||
              resolution.outcome === 'invalidatedWithoutMVP'
          )}
        >
          {hypothesis.title}
        </HypothesisTitle>
        <HypothesisCardIconsContainer>
          {customerSegment ? (
            <SegmentCardIcon />
          ) : (
            <>
              {getHypothesisIcon(hypothesis.resolutions)}

              {size(hypothesis.experiments) > 0 && (
                <HypothesisInProgressIcon style={{ marginLeft: 'auto' }} />
              )}

              {!!hypothesis.comments &&
                some(
                  hypothesis.comments,
                  (comment) =>
                    !comment.replys ||
                    (comment.replys && comment.replys.length === 0)
                ) && <CommentIcon style={{ marginLeft: 'auto' }} />}
              {hypothesis.KPIIndicators &&
                hypothesis.KPIIndicators.length > 0 && (
                  <KPIsIcon style={{ marginLeft: 'auto' }} />
                )}

              {/* {!!hypothesis.estimatedValue && (
                <ValueICon style={{ marginLeft: 'auto' }} />
              )} */}
            </>
          )}
        </HypothesisCardIconsContainer>
        {!!customerSegment && (
          <ColorColumnDiv backgroundColor={backgroundColor} />
        )}
      </div>

      {backgroundColor && !customerSegment && (
        <HypothesisColorContainer>
          {isArray(backgroundColor) ? (
            map(backgroundColor, (color, index) => (
              <HypothesisColor
                key={index}
                backgroundColor={color}
                style={{ marginRight: '5px' }}
              />
            ))
          ) : (
            <HypothesisColor
              backgroundColor={backgroundColor}
              style={{ marginRight: '5px' }}
            />
          )}
        </HypothesisColorContainer>
      )}
    </HypothesisCardContainer>
  );
};
export default connect(undefined, { push })(HypothesisCard);
