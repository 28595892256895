import React, { useState } from 'react';
import { size, map, get, find } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { push } from 'connected-react-router';
import moment from 'moment';
import { ReactComponent as ExperimentGoodIcon } from '../../../common/assets/icons/Experiment_Good.svg';
import { ReactComponent as ExperimentBadIcon } from '../../../common/assets/icons/Experiment_Bad.svg';
import { ReactComponent as ExperimentLoadingIcon } from '../../../common/assets/icons/Experiment_Loading.svg';
import { ReactComponent as ExperimentFinishedIcon } from '../../../common/assets/icons/Experiment_Finished.svg';
import { ReactComponent as ExperimentNeutral } from '../../../common/assets/icons/Experiment_NO_conclusion.svg';

import { ReactComponent as ExperimentInProgressIcon } from '../../../common/assets/icons/Experiment_Progress.svg';
import { ReactComponent as DetailIcon } from '../../../common/assets/icons/Menu_Detaliu_Experiment_Small.svg';
import EditIconLarge from '../../../common/assets/icons/Button_Edit_Large.svg';
import EditIconLargeHover from '../../../common/assets/icons/Button_Edit_Large_Hover.svg';
// eslint-disable-next-line import/no-cycle
import { handleBmcLabels } from '../../../hypotheses/components/pages/HypothesisDetailsPage';

import { AddExperimentModal } from '../modals';
import {
  isMentorSelector,
  isAcceleratorSelector,
} from '../../../account/ducks';

import { addExperimentResolution } from '../../../resolutions/ducks';
import {
  CommentRenderComponent,
  ResourcesSection,
} from '../../../common/components';
import {
  AddCommentModal,
  AddDetailModal,
} from '../../../common/components/modals';
import { AddResolutionModal } from '../../../resolutions/components/modals';
import {
  addExperimentComment,
  loadExperiment,
  editExperimentComment,
  deleteExperimentComment,
  editExperiment,
  deleteExperiment,
  addExperimentCommentReply,
  editExperimentCommentReply,
  deleteExperimentCommentReply,
} from '../../ducks';
import { addExperimentDetail } from '../../../experimentDetails/ducks';

import {
  AcceptButton,
  TitleButtonsDiv,
  DivBorderBottom,
  ButtonText,
} from '../../../mentors/components/styled';

import {
  Heading2,
  Heading4Regular,
  Link,
  Title,
  CounterDiv,
  CounterText,
  Loading,
  PlaceHolderInput,
  DeleteButton,
  AddCommentButton,
  ChildCard,
  ChildsDiv,
  DeleteCommentButton,
  TextInactive,
  DivBorderLeft,
  EditButtonLarge,
  WhiteCard,
  RightSection,
  LeftSection,
  RowDiv,
} from '../../../common/components/styled';
import {
  BUYING_SIGNALS_OPTIONS,
  CAN_CONTACT_LATER_OPTIONS,
  CONTACT_TYPE_OPTIONS,
  DETAIL_CATEGORY_OPTIONS,
  ENGAGEMENT_LEVEL_OPTIONS,
  IS_EARLY_ADOPTER_OPTIONS,
} from '../../../common/constants';
import { sizeComments } from '../../../common/functions';

const AlertMessage = ({ show, setShow, onSubmit, id }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere experiment</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea experimentului?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const getExperimentIcon = (outcome, status) => {
  if (outcome) {
    switch (outcome) {
      case 'like':
        return <ExperimentGoodIcon style={{ width: '24px' }} />;
      case 'dislike':
        return <ExperimentBadIcon style={{ width: '24px' }} />;
      case 'inProgress':
      case 'neutral':
        return <ExperimentNeutral style={{ width: '18px', height: '18px' }} />;
      default:
        return <ExperimentGoodIcon style={{ width: '24px' }} />;
    }
  }
  if (status) {
    switch (status) {
      case 'În constructie':
        return <ExperimentInProgressIcon style={{ width: '24px' }} />;
      case 'În derulare':
        return <ExperimentLoadingIcon style={{ width: '24px' }} />;
      case 'Finalizat':
        return <ExperimentFinishedIcon style={{ width: '24px' }} />;
      default:
        return <ExperimentLoadingIcon style={{ width: '24px' }} />;
    }
  }
};

const ExperimentDetailsPage = (props) => {
  const {
    experiment,
    addExperimentComment,
    loadExperiment,
    isLoading,
    isCreatingComment,
    editExperimentComment,
    deleteExperiment,
    deleteExperimentComment,
    addExperimentDetail,
    isCreatingDetail,
    isEditing,
    isDeleting,
    isMentor,
    resources,
    isAccelerator,
    addExperimentResolution,
    push,
    match: {
      params: { experimentId, hypothesisId },
    },
  } = props;

  const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const [isEditModeComment, setIsEditModeComment] = useState(false);
  const [comment, setComment] = useState(undefined);

  const [hovered, setHovered] = useState(false);

  const handleHookSet = (type) => {
    switch (type) {
      case 'detail':
        setIsDetailModalOpen(true);
        break;

      case 'experiment':
        setIsExperimentModalOpen(true);
        break;
      case 'resolution':
        setIsResolutionModalOpen(true);
        break;
      case 'comment':
        setIsCommentModalOpen(true);
        break;
      default:
        setIsDetailModalOpen(false);
        setIsExperimentModalOpen(false);
        setIsResolutionModalOpen(false);
        setIsCommentModalOpen(false);
    }
  };

  const handleOpenEditCommentModal = (type, commentId) => {
    handleHookSet(type);
    setComment(commentId);
    setIsEditModeComment(true);
  };

  const handleCardClick = (url) => push(url);

  const handleDeleteExperiment = async () => {
    await deleteExperiment(experiment._id);
    push(`/hypothesis/${hypothesisId}`);
  };

  const handleDeleteComment = async (commentId) => {
    await deleteExperimentComment(commentId);
    await loadExperiment(experiment._id);
  };

  return (
    <>
      <div>
        <TitleButtonsDiv paddingRight marginBottom="15px">
          <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            {getExperimentIcon(
              experiment.resolutions[0] && experiment.resolutions[0].outcome,
              experiment.status
            )}
            <Title marginLeft="10px" width="80%">
              {experiment.title}{' '}
            </Title>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isMentor && !isAccelerator && (
              <>
                <EditButtonLarge
                  src={hovered ? EditIconLargeHover : EditIconLarge}
                  onClick={() => handleHookSet('experiment')}
                  onMouseEnter={() => setHovered(!hovered)}
                  onMouseLeave={() => setHovered(!hovered)}
                />
                <DeleteButton
                  style={{ float: 'right' }}
                  onClick={() => setOpenAlertMessage(true)}
                />
              </>
            )}
          </div>
        </TitleButtonsDiv>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '40px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
            <Heading2>Ipoteze: </Heading2>

            {map(experiment.hypothesisId, (hypothesis, index) => (
              <PlaceHolderInput
                style={{ marginLeft: '10px' }}
                key={index}
                hypothesisCard
                maxWidth="30%"
                onClick={() => handleCardClick(`/hypothesis/${hypothesis._id}`)}
              >
                <Heading4Regular>
                  {`${handleBmcLabels(hypothesis.columnBMC)}: ${
                    hypothesis.title
                  }`}
                </Heading4Regular>
              </PlaceHolderInput>
            ))}
          </div>
          <Heading4Regular inactive>{`${experiment.createdBy} ${moment(
            experiment.createdAt
          ).format('DD/MM/YYYY  H:mm')}`}</Heading4Regular>
        </div>
      </div>
      <RowDiv style={{ marginBottom: '30px' }}>
        <LeftSection>
          <WhiteCard height="auto" borderRadius="3px" padding="20px">
            <Title marginBottom="10px" fontSize="24px" lineHeight="28px">
              Descriere experiment
            </Title>
            <Heading2 marginBottom="10px">
              Pentru a valida ipoteza vom:
            </Heading2>
            <Heading4Regular
              className="scrollbar-modal"
              textArea
              marginBottom="30px"
              noEllipsis
            >
              {experiment.forValidation}
            </Heading4Regular>
            <Heading2 marginBottom="10px">Avem dreptate dacă:</Heading2>
            <Heading4Regular
              className="scrollbar-modal"
              textArea
              marginBottom="30px"
              noEllipsis
            >
              {experiment.weConsider}
            </Heading4Regular>
            <Heading2 marginBottom="10px">
              Vom măsura rezultatele prin:
            </Heading2>
            <Heading4Regular
              className="scrollbar-modal"
              textArea
              marginBottom="30px"
              noEllipsis
            >
              {experiment.measure}
            </Heading4Regular>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Heading2 marginBottom="10px">
                  Vom culege informații în intervalul:
                </Heading2>
                <Heading4Regular>
                  {experiment.startDate &&
                    moment(experiment.startDate).format('DD/MM/YYYY')}
                  {experiment.startDate || experiment.deadline ? ' - ' : ''}
                  {experiment.deadline &&
                    moment(experiment.deadline).format('DD/MM/YYYY')}
                </Heading4Regular>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}
              >
                <Heading2 marginBottom="10px" style={{ marginRight: '0' }}>
                  Estimăm că ne va costa (€):
                </Heading2>
                <Heading4Regular marginBottom="30px">
                  {experiment.estimatedPrice}
                </Heading4Regular>
              </div>
            </div>
          </WhiteCard>
        </LeftSection>
        <RightSection>
          <WhiteCard
            noOverflow
            height="100%"
            borderRadius="3px"
            padding="20px"
            style={{ height: '413px' }}
          >
            <TitleButtonsDiv marginBottom="15px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Comentarii
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{sizeComments(experiment.comments)}</CounterText>
                </CounterDiv>
              </div>
              <AddCommentButton onClick={() => handleHookSet('comment')} />
            </TitleButtonsDiv>
            {!size(experiment.comments) > 0 && (
              <TextInactive>
                Momentan nu există niciun comentariu pentru această secțiune.
                Poți adăuga un comentariu făcând click pe butonul „+” din partea
                dreaptă a secțiunii.
              </TextInactive>
            )}
            <div
              style={{
                maxHeight: '90%',
                overflow: 'auto',
                paddingRight: '4px',
              }}
              className="scrollbar-modal"
            >
              {map(experiment.comments, (comment, index) => (
                <CommentRenderComponent
                  key={index}
                  comment={comment}
                  handleDeleteComment={handleDeleteComment}
                  handleEdit={() =>
                    handleOpenEditCommentModal('comment', comment)
                  }
                  handleAddReply={props.addExperimentCommentReply}
                  handleEditReply={(values, replyId) =>
                    props.editExperimentCommentReply(
                      values,
                      comment._id,
                      replyId
                    )
                  }
                  handleDeleteReply={(replyId) =>
                    props.deleteExperimentCommentReply(comment._id, replyId)
                  }
                  loadData={() => loadExperiment(experiment._id)}
                  isLoading={isEditModeComment ? isEditing : isCreatingComment}
                />
              ))}
            </div>
          </WhiteCard>
        </RightSection>
      </RowDiv>
      <RowDiv style={{ marginBottom: '30px' }}>
        <LeftSection>
          <WhiteCard height="auto" borderRadius="3px" padding="20px">
            <TitleButtonsDiv marginBottom="15px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Detalii experiment
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{size(experiment.details)}</CounterText>
                </CounterDiv>
              </div>
              {!isMentor && !isAccelerator && (
                <AddCommentButton onClick={() => handleHookSet('detail')} />
              )}
            </TitleButtonsDiv>
            {!size(experiment.details) > 0 && (
              <TextInactive>
                Adaugă un detaliu sau informație importantă pentru aceast
                experiment aceasta făcând click pe butonul „+” din partea
                dreaptă a secțiunii.
              </TextInactive>
            )}
            <ChildsDiv className="scrollbar">
              {map(experiment.details, (detail, index) => (
                <ChildCard key={index}>
                  <div>
                    <DetailIcon />
                    <Link
                      maxWidth="100%"
                      marginLeft="12px"
                      onClick={() =>
                        handleCardClick(
                          `/hypothesis/${hypothesisId}/experiment/${experimentId}/detail/${detail._id}`
                        )
                      }
                    >
                      {detail.title}
                    </Link>
                  </div>
                  <div style={{ display: 'flex', width: '32%' }}>
                    <div style={{ width: '16.6%' }}>
                      {get(
                        find(DETAIL_CATEGORY_OPTIONS, {
                          value: detail.detailCategory,
                        }),
                        'renderIcon',
                        () => {}
                      )()}
                    </div>
                    <div style={{ width: '16.6%' }}>
                      {get(
                        find(CONTACT_TYPE_OPTIONS, {
                          value: detail.contactType,
                        }),
                        'renderIcon',
                        () => {}
                      )()}
                    </div>
                    <DivBorderLeft style={{ width: '66.6%', display: 'flex' }}>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(ENGAGEMENT_LEVEL_OPTIONS, {
                            value: detail.engagementLevel,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(CAN_CONTACT_LATER_OPTIONS, {
                            value: detail.canContactLater,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(BUYING_SIGNALS_OPTIONS, {
                            value: detail.buyingSignals,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                      <div style={{ width: '25%' }}>
                        {get(
                          find(IS_EARLY_ADOPTER_OPTIONS, {
                            value: detail.isEarlyAdopter,
                          }),
                          'renderIcon',
                          () => {}
                        )()}
                      </div>
                    </DivBorderLeft>
                  </div>
                </ChildCard>
              ))}
            </ChildsDiv>
          </WhiteCard>
        </LeftSection>
        <RightSection>
          <ResourcesSection resources={resources} />
        </RightSection>
      </RowDiv>

      <RowDiv>
        <LeftSection>
          <WhiteCard
            noOverflow
            height="140px"
            borderRadius="3px"
            padding="20px"
          >
            <TitleButtonsDiv marginBottom="20px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Concluzie experiment
                </Title>
                <CounterDiv marginLeft="10px">
                  <CounterText>{size(experiment.resolutions)}</CounterText>
                </CounterDiv>
              </div>
              {!isMentor &&
                !isAccelerator &&
                size(experiment.resolutions) === 0 && (
                  <AddCommentButton
                    onClick={() => handleHookSet('resolution')}
                  />
                )}
            </TitleButtonsDiv>
            {!size(experiment.resolutions) > 0 && (
              <TextInactive>
                Ai încheiat experimentul și ai suficiente date pentru a trage o
                concluzie? Atunci adaugă o concluzie făcând click pe butonul "+"
                din partea dreaptă a secțiunii.
              </TextInactive>
            )}
            <ChildsDiv height="50px" className="scrollbar-modal">
              {map(experiment.resolutions, (resolution, index) => (
                <ChildCard style={{ marginBottom: '0' }} noBorder key={index}>
                  <div style={{ display: 'flex', width: '60%' }}>
                    <Link
                      maxWidth="80%"
                      marginLeft="12px"
                      marginRight="10px"
                      onClick={() =>
                        handleCardClick(
                          `/hypothesis/${hypothesisId}/experiment/${experimentId}/resolution/${resolution._id}`
                        )
                      }
                    >
                      {resolution.conclusion}
                    </Link>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '40%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <DivBorderLeft>
                      {getExperimentIcon(resolution.outcome)}
                    </DivBorderLeft>
                  </div>
                </ChildCard>
              ))}
            </ChildsDiv>
          </WhiteCard>
        </LeftSection>
      </RowDiv>

      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteExperiment}
      />
      <AddResolutionModal
        isModalOpen={isResolutionModalOpen}
        setIsModalOpen={setIsResolutionModalOpen}
        isLoading={isEditing}
        loadData={loadExperiment}
        entityId={experimentId}
        isExperiment
        parent={experiment}
        onSubmitResolution={addExperimentResolution}
      />
      <AddExperimentModal
        isModalOpen={isExperimentModalOpen}
        setIsModalOpen={setIsExperimentModalOpen}
        loadData={loadExperiment}
        id={experimentId}
        isEditMode
        experiment={experiment}
        isLoading={isEditing}
      />
      <AddCommentModal
        isModalOpen={isCommentModalOpen}
        setIsModalOpen={setIsCommentModalOpen}
        parentId={experiment._id}
        onSubmit={addExperimentComment}
        isLoading={isEditModeComment ? isEditing : isCreatingComment}
        loadData={loadExperiment}
        isEditModeComment={isEditModeComment}
        comment={isEditModeComment ? comment : undefined}
        setIsEditModeComment={setIsEditModeComment}
        editComment={editExperimentComment}
      />
      <AddDetailModal
        addDetail={addExperimentDetail}
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        loadData={loadExperiment}
        parentId={experiment._id}
        isLoading={isCreatingDetail}
        isExperimentTitle
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  experiment: state.experiments.experiment,
  isLoading: state.experiments.isLoading,
  isCreatingComment: state.experiments.isCreating,
  isEditing: state.experiments.isEditing,
  isDeleting: state.experiments.isDeleting,
  isCreatingDetail: state.experimentDetail.isCreating,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
  resources: state.admin.resources.resources,
});

const mapDispatchToProps = {
  addExperimentComment,
  loadExperiment,
  editExperimentComment,
  deleteExperimentComment,
  editExperiment,
  deleteExperiment,
  addExperimentDetail,
  addExperimentCommentReply,
  editExperimentCommentReply,
  deleteExperimentCommentReply,
  addExperimentResolution,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExperimentDetailsPage)
);
