import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { AddCommentForm } from '../forms';
import { Loading, Title, DeleteButtonSmall } from '../styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddCommentModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    isLoading,
    parentId,
    loadData,
    isEditModeComment,
    setIsEditModeComment,
    editComment,
    comment,
    teamId,
  } = props;
  const handleSubmit = (values) => {
    if (isEditModeComment) {
      const editPromise = editComment(values, comment._id);
      editPromise.then(() => {
        setIsModalOpen(false);
        setIsEditModeComment(false);
        loadData(teamId || parentId);
      });

      return editPromise;
    }
    const addPromise = onSubmit(values, parentId);
    addPromise.then(() => {
      setIsModalOpen(false);
      setIsEditModeComment(false);
      loadData(teamId || parentId);
    });
    return addPromise;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModeComment(false);
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">
            {isEditModeComment ? 'Editează comentariu' : 'Adaugă comentariu'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <AddCommentForm
          onSubmit={handleSubmit}
          comment={comment && comment.comment}
          isEditModeComment={isEditModeComment}
        />
      </Modal.Body>

      <Loading isLoading={isLoading} />
    </Modal>
  );
};

const mapDispatchToProps = {};

export default withRouter(
  connect(undefined, mapDispatchToProps)(AddCommentModal)
);
