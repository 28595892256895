import React from 'react';
import { get } from 'lodash-es';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createTeam, editTeam, getTeam } from '../../ducks';
import { loadUserTeams, currentTeamIdSelector } from '../../../common/ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

import { CreateTeamForm } from '../forms';

const CreateTeamModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    createTeam,
    isLoading,
    loadUserTeams,
    userId,
    isEditMode,
    team,
    teamId,
    editTeam,
    getTeam,
  } = props;
  const onSubmit = async (values) => {
    if (isEditMode) {
      await editTeam(teamId, values, userId);
      setIsModalOpen(false);
      await getTeam(teamId);
    } else {
      await createTeam(values);
      setIsModalOpen(false);
      await loadUserTeams(userId);
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Body>
          <TitleButtonsDiv marginBottom="30px">
            <Title fontSize="24px">
              {isEditMode ? 'Editează echipa' : 'Adaugă o idee nouă'}
            </Title>
            <DeleteButtonSmall onClick={handleCloseModal} />
          </TitleButtonsDiv>
          <CreateTeamForm
            onSubmit={onSubmit}
            team={team}
            isEditMode={isEditMode}
          />
        </Modal.Body>
        <Loading isLoading={isLoading} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { createTeam, loadUserTeams, editTeam, getTeam };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateTeamModal)
);
