import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { get, find, size } from 'lodash-es';
import { Formik, useField, FieldArray } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../../../core/components';
import { dataOptions, actionOptions } from '../../constants';
import { ReactComponent as RadioEmpty } from '../../../common/assets/icons/radio-empty.svg';
import { ReactComponent as RadioFill } from '../../../common/assets/icons/radio-fill.svg';

import {
  Input,
  Heading2,
  SubmitButton,
  ButtonDiv,
  SaveIcon,
  ExperimentGoodButton,
  ExperimentBadButton,
  AddCommentButton,
  PositionParent,
  MinusButton,
  LinkIcon,
  LabelButtonContainer,
  RadioLabelWrapper,
} from '../../../common/components/styled';

const RenderConclusionsField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="93px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderNextStepsField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,
    setShowPlusButton,
    showPlusButton,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (size(values.resolution.nextSteps) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.resolution.nextSteps, setShowPlusButton]);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <LabelButtonContainer>
            <Heading2>Link-uri</Heading2>
            {showPlusButton && (
              <AddCommentButton onClick={() => arrayHelpers.push('')} />
            )}
          </LabelButtonContainer>
          {values.resolution.nextSteps.map((resource, index) => (
            <Form.Row
              key={index}
              style={{ width: '100%', margin: '0 0 10px 0' }}
            >
              <PositionParent width="93%">
                <LinkIcon icon={faLink} />
                <Input
                  formlink
                  type={type}
                  placeholder={placeholder}
                  name={`resolution.nextSteps[${index}]`}
                  onChange={(e) => {
                    helpers.setTouched();
                    handleChange(e);
                  }}
                  value={values.resolution.nextSteps[index]}
                  isInvalid={
                    meta.touched &&
                    meta.error &&
                    meta.error[index] &&
                    meta.error[index]
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: 'inherit' }}
                >
                  {meta.touched &&
                    meta.error &&
                    meta.error[index] &&
                    meta.error[index]}
                </Form.Control.Feedback>
              </PositionParent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '7%',
                }}
              >
                {values.resolution.nextSteps.length > 1 && (
                  <MinusButton
                    marginTop="10px"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                )}
              </div>
            </Form.Row>
          ))}
        </>
      )}
    />
  );
};

const RenderObservationsField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="93px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderActionRequiredField = (props) => {
  const { values, setFieldValue, controlId, name, label } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={Dropdown}
        placeholder="Alege actiunea"
        name={name}
        options={actionOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('resolution.actionRequired', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderDataReliabilityField = (props) => {
  const { values, setFieldValue, controlId, name, label } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={Dropdown}
        placeholder="Alege datele"
        name={name}
        options={dataOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('resolution.dataReliability', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderRadioInput = (props) => {
  const { values, setFieldValue, controlId, value, name, label } = props;

  return (
    <Form.Group style={{ margin: '0' }} controlId={controlId}>
      <RadioLabelWrapper>
        <input
          checked={values.resolution.outcome === value}
          type="radio"
          name={name}
          onChange={() => setFieldValue('resolution.outcome', value)}
          style={{
            position: 'absolute',
            opacity: '0',
            width: '20px',
            height: '20px',
          }}
        />
        {values.resolution.outcome === value ? <RadioFill /> : <RadioEmpty />}
        <Heading2 style={{ fontWeight: 'normal' }} marginLeft="10px">
          {label}
        </Heading2>
      </RadioLabelWrapper>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  setFieldValue,
  errors,
  showPlusButton,
  setShowPlusButton,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderConclusionsField
        name="resolution.conclusion"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Sumar concluzie*"
        type="textarea"
        placeholder="Descrie pe scurt concluzia experimentului tău."
      />
    </Form.Row>
    <Form.Row>
      <RenderObservationsField
        name="resolution.observations"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Observații*"
        type="textarea"
        placeholder="Ce ai observat in timpul experimentului?"
      />
    </Form.Row>
    <Form.Row>
      <RenderDataReliabilityField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik03"
        name="resolution.dataReliability"
        label="Considerăm datele obținute ca fiind:"
      />
      <RenderActionRequiredField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik04"
        name="resolution.actionRequired"
        label="În baza concluziei estimăm o acțiune:"
      />
    </Form.Row>
    <Form.Row>
      <Col md="12">
        <RenderNextStepsField
          name="resolution.nextSteps"
          values={values}
          handleChange={handleChange}
          controlId="validationFormik05"
          label="Cu urmatorii pasi"
          placeholder="https://"
          type="text"
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
        />
      </Col>
    </Form.Row>
    <div style={{ marginBottom: '10px', position: 'relative' }}>
      <div>
        <Heading2 marginBottom="10px">
          Considerăm că experimentul confirmă / infirmă ipoteza: *
        </Heading2>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <RenderRadioInput
            values={values}
            value="like"
            controlId="validationFormik06"
            setFieldValue={setFieldValue}
            label="Confirmă"
          />
          <ExperimentGoodButton
            pressed={values.resolution.outcome === 'like'}
            style={{ marginLeft: '10px', marginRight: '20px' }}
            onClick={() => {
              setFieldValue('resolution.outcome', 'like');
            }}
          />
          <RenderRadioInput
            values={values}
            value="dislike"
            controlId="validationFormik07"
            setFieldValue={setFieldValue}
            label="Infirmă"
          />
          <ExperimentBadButton
            style={{ marginLeft: '10px' }}
            pressed={values.resolution.outcome === 'dislike'}
            onClick={() => {
              setFieldValue('resolution.outcome', 'dislike');
            }}
          />
        </div>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: 'inherit', position: 'absolute', top: '100%' }}
        >
          {errors && errors.resolution && errors.resolution.outcome}
        </Form.Control.Feedback>
      </div>
    </div>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddResolutionFormExperiment = (props) => {
  const { onSubmit, resolution } = props;

  const [showPlusButton, setShowPlusButton] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    resolution: Yup.object({
      conclusion: Yup.string().required('Câmp obligatoriu'),
      observations: Yup.string().required('Câmp obligatoriu'),
      nextSteps: Yup.array().of(
        Yup.string().url(
          'URL Invalid (linkul trebuie sa contina http:// sau https://)'
        )
      ),
      dataReliability: Yup.string(),
      actionRequired: Yup.string(),
      outcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
    }),
  });

  const defaultValues = {
    resolution: {
      conclusion: '',
      observations: '',
      nextSteps:
        resolution && resolution.nextSteps && size(resolution.nextSteps) !== 0
          ? resolution.nextSteps
          : [''],
      dataReliability: '',
      actionRequired: '',
    },
  };
  const defaultResolution = {
    resolution: {
      ...resolution,
      nextSteps:
        resolution && resolution.nextSteps && size(resolution.nextSteps) !== 0
          ? resolution.nextSteps
          : [''],
      dataReliability:
        resolution &&
        find(dataOptions, {
          value: resolution.dataReliability,
        }),
      actionRequired:
        resolution &&
        find(actionOptions, {
          value: resolution.actionRequired,
        }),
    },
  };

  const initialValues = resolution ? defaultResolution : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
        />
      )}
    </Formik>
  );
};

export default AddResolutionFormExperiment;
