import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadUserTeams } from '../ducks';
import { Resolver } from '../../core/components';
import { Loading } from './styled';
import { isAdminSelector, isAcceleratorSelector } from '../../account/ducks';
import { loadCohorts } from '../../accelerator/ducks';
import { loadProfile } from '../../userProfile/ducks';
import NavigationBar from './NavigationBar';

const NavigationBarResolver = (props) => {
  const {
    loadUserTeams,
    loadProfile,
    isAdmin,
    userId,
    isAccelerator,
    loadCohorts,
  } = props;
  const loadDependencies = () =>
    !isAdmin
      ? Promise.all([
          isAccelerator ? loadCohorts() : loadUserTeams(userId),
          loadProfile(),
        ])
      : Promise.resolve();

  return (
    <Resolver
      successComponent={NavigationBar}
      loadingComponent={Loading}
      resolve={loadDependencies}
    />
  );
};

NavigationBarResolver.propTypes = {
  loadUserTeams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: isAdminSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
  userId: get(state.account.login, 'user._id'),
});
const mapDispatchToProps = { loadUserTeams, loadProfile, loadCohorts };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBarResolver)
);
