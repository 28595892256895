import React from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash-es';
import {
  ProfileImageUploader as ProfileImageUploaderContainer,
  ProfileImageUploaderLabel,
  ProfileImageUploaderInput,
} from './styled';

import AvatarImage from './AvatarImage';

const ProfileImageUploader = (props) => {
  const { imageUrl, disabled, upload } = props;

  const onFilesSelected = (event) =>
    size(event.target.files) !== 0 && props.onFilesSelected(event.target.files);

  return (
    <ProfileImageUploaderContainer>
      <ProfileImageUploaderLabel>
        <ProfileImageUploaderInput
          accept="image/jpg,image/png,image/jpeg"
          onChange={onFilesSelected}
          type="file"
          disabled={disabled}
        />
        <AvatarImage upload={upload} src={imageUrl} medium />
      </ProfileImageUploaderLabel>
    </ProfileImageUploaderContainer>
  );
};

ProfileImageUploader.propTypes = {
  onFilesSelected: PropTypes.func.isRequired,
};

export default ProfileImageUploader;
