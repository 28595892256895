import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { register, resetRegister } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel } from '../styled';
import { RegisterForm } from '../forms';
import { Title, WhiteCard } from '../../../common/components/styled';

const RegisterPage = (props) => {
  const { isSubmitting, resetRegister, register } = props;
  useEffect(() => () => resetRegister(), [resetRegister]);
  const onSubmit = (data) => register(data, '/');

  return (
    <>
      <DocumentTitle>Register</DocumentTitle>
      <AccountPage register>
        <AccountPanel size="small" isLoading={isSubmitting}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <Title>Bine ai venit! Înregistrează-te!</Title>
          </div>
          <WhiteCard borderRadius="10px" height="100%">
            <RegisterForm onSubmit={onSubmit} />
          </WhiteCard>
        </AccountPanel>
      </AccountPage>
    </>
  );
};

RegisterPage.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  resetRegister: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isSubmitting: state.account.register.isSubmitting,
  resetRegister: state.account.register.resetRegister,
});

const mapDispatchToProps = {
  register,
  resetRegister,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
);
