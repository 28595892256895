import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypotheses } from '../../../hypotheses/ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { loadOpportunityAnalysis } from '../../../opportunityAnalysis/ducks';
import BusinessModelCanvasPage from '../../../canvas/components/BusinessModelCanvasPage';

const TeamCanvasPageResolver = (props) => {
  const {
    loadHypotheses,
    loadOpportunityAnalysis,
    match: {
      params: { teamId },
    },
  } = props;

  const loadDependencies = () =>
    Promise.all([loadHypotheses(teamId), loadOpportunityAnalysis(teamId)]);

  return (
    <>
      <DocumentTitle>Business Model Canvas</DocumentTitle>
      <Resolver
        successComponent={BusinessModelCanvasPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

TeamCanvasPageResolver.propTypes = {
  loadHypotheses: PropTypes.func.isRequired,
  loadOpportunityAnalysis: PropTypes.func.isRequired,
  teamId: PropTypes.string,
};

const mapDispatchToProps = { loadHypotheses, loadOpportunityAnalysis };

export default withRouter(
  connect(undefined, mapDispatchToProps)(TeamCanvasPageResolver)
);
