import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { get, trim, map, filter } from 'lodash-es';
import { connect } from 'react-redux';
import { Formik, useField } from 'formik';
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  ButtonDiv,
  InfoIcon,
  Input,
  Heading2,
  SubmitButton,
} from '../../../common/components/styled';
import { Dropdown } from '../../../core/components';
import { isCohortCodeUsed } from '../../../team/services/createTeam';
import { isCohortExpired } from '../../../accelerator/services/cohorts';

const RenderCohortCodeField = (props) => {
  const { md, values, handleChange, name, controlId } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <Heading2>Cod Cohortă</Heading2>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Introdu codul Cohortei la care vrei să te alături cu ideea, dacă
              este cazul. Poți să-l introduci și mai târziu din pagina Profilul
              Echipei
            </Tooltip>
          }
        >
          <InfoIcon />
        </OverlayTrigger>
      </div>

      <Input
        type="text"
        placeholder="Cod Cohortă"
        name="cohortCode"
        value={fieldValue}
        onBlur={async () => {
          helpers.setTouched();
        }}
        onChange={(event) => {
          helpers.setTouched();
          return handleChange(event);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderTeamsDropdown = (props) => {
  const { values, setFieldValue, userTeams } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, 'teamId');
  const getTeams = useCallback(
    () =>
      map(
        filter(userTeams, (team) => team.cohortId === null),
        (team) => ({
          label: team.name,
          value: team._id,
        })
      ),
    [userTeams]
  );

  return (
    <Form.Group as={Col} md="12">
      <Heading2
        marginBottom="10px"
        style={{ paddingTop: '5px', height: '24px' }}
      >
        Idee
      </Heading2>
      <Input
        as={Dropdown}
        placeholder="Selectează ideea"
        name="teamId"
        options={getTeams()}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(team) => {
          helpers.setTouched();
          setFieldValue('teamId', team);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  initialValues,
  isValid,
  userTeams,
  setFieldValue,
  ...rest
}) => (
  <Form onSubmit={handleSubmit}>
    <Form.Row>
      <Col md="6">
        <RenderCohortCodeField
          values={values}
          name="cohortCode"
          handleChange={handleChange}
          controlId="validationFormik1"
          initialValues={initialValues}
        />
      </Col>
      <Col md="6">
        <RenderTeamsDropdown
          values={values}
          setFieldValue={setFieldValue}
          name="teamId"
          controlId="validationFormik2"
          userTeams={userTeams}
        />
      </Col>
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">Alătură-te</SubmitButton>
    </ButtonDiv>
  </Form>
);

const JoinCohortForm = (props) => {
  const { onSubmit, userTeams } = props;
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    cohortCode: Yup.string()
      .required('Câmp obligatoriu')
      .test(
        'is-invalid',
        'Cod cohortă invalid',
        async (value) =>
          !!trim(value) &&
          isCohortCodeUsed(value).then(({ data }) => data.isUsed)
      )
      .test(
        'is-expired',
        'Aceasta cohortă a expirat',
        async (value) =>
          !!trim(value) &&
          isCohortExpired(value).then(({ data }) => !data.isExpired)
      ),
    teamId: Yup.object().required('Câmp obligatoriu'),
  });

  const initialValues = {
    cohortCode: undefined,
    teamId: undefined,
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} userTeams={userTeams} />}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  userTeams: state.common.userTeams.userTeams,
});

export default connect(mapStateToProps)(JoinCohortForm);
