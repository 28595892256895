import { get } from 'lodash-es';
import { http } from '../../core/services/http';

export const addOpportunityAnalysisComment = (
  values,
  opportunityAnalysisId
) => {
  const registerData = {
    ...values,
    opportunityAnalysisId,
  };
  return http.post('/opportunity-analysis-comment/add', registerData);
};

export const deleteOpportunityAnalysisComment = (
  opportunityAnalysisCommentId
) =>
  http.delete(
    `/opportunity-analysis-comment/delete/${opportunityAnalysisCommentId}`
  );

export const editOpportunityAnalysisComment = (
  values,
  opportunityAnalysisCommentId
) =>
  http.put(
    `/opportunity-analysis-comment/edit/${opportunityAnalysisCommentId}`,
    values
  );

export const deleteOpportunityAnalysis = (opportunityAnalysisId) =>
  http.delete(`/opportunity-analysis/delete/${opportunityAnalysisId}`);

export const addOpportunityAnalysis = (values) =>
  http.post('/opportunity-analysis/add', values);

export const editOpportunityAnalysis = (
  values,
  entityId,
  parent,
  personResponsible
) => {
  const registerDataResolution = {
    ...parent,
    resolution: {
      ...values.resolution,
      personResponsible,
      actionRequired:
        values.resolution && get(values.resolution.actionRequired, 'value'),
      dataReliability:
        values.resolution && get(values.resolution.dataReliability, 'value'),
    },
  };
  const registerData = parent === undefined ? values : registerDataResolution;
  return http.put(`/opportunity-analysis/edit/${entityId}`, registerData);
};

export const loadOpportunityAnalysis = (teamId) =>
  http.get(`/opportunity-analysis/get/${teamId}`);

export const addOpportunityAnalysisReply = (
  values,
  opportunityAnalysisCommentId
) =>
  http.post(
    `/opportunity-analysis-comment/reply/add/${opportunityAnalysisCommentId}`,
    values
  );

export const editOpportunityAnalysisReply = (
  values,
  opportunityAnalysisCommentId,
  replyId
) =>
  http.put(
    `/opportunity-analysis-comment/reply/edit/${opportunityAnalysisCommentId}/${replyId}`,
    values
  );

export const deleteOpportunityAnalysisReply = (
  opportunityAnalysisCommentId,
  replyId
) =>
  http.delete(
    `/opportunity-analysis-comment/reply/delete/${opportunityAnalysisCommentId}/${replyId}`
  );
