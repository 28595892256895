import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as ArrowIconSvg } from '../../assets/icons/Polygon_2.svg';

export const PageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-width: 800px;
  min-height: 100vh;
  background-color: ${(props) => props.theme.gray};
  display: flex;
  flex-direction: column;
`;

export const AddNewButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 3px;
  right: 0px;
  ${(props) =>
    props.showIRL &&
    css`
      top: 141px;
    `}
`;

export const DropDownButton = styled(Dropdown.Toggle)`
  background-color: none;
  padding: 0 !important;
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  &:hover {
    background: none;
  }

  &:hover > svg > path {
    fill: ${(props) => props.theme.accentHover};
  }
  &:focus {
    box-shadow: none !important;
    background-color: none;
    outline: none;
    border: none;
  }
  &:active {
    box-shadow: none !important;
    background-color: none !important;
    outline: none !important;
    border: none !important;
    color: none !important;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.blueAccent};
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  position: absolute !important;
  top: 12px !important;
  transform: translate3d(-215px, 32px, 0px) !important;
`;

export const DropdownItem = styled.button`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.text};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '14px'};
  padding-left: 0;
  &:hover {
    font-weight: bold;
    background-color: #efeff4;
  }
`;

export const ArrowIcon = styled(ArrowIconSvg)`
  position: absolute;
  top: -8px;
  right: 10px;
`;

export const PageBody = styled.div`
  position: relative;
  flex: 1 0 auto;
  padding-top: 30px;
  padding-left: ${(props) => (props.open ? '290px' : '120px')};
  padding-right: 40px;
  padding-bottom: 30px;
  background: ${(props) => props.theme.bodyBackgroundColor};
  transition: 0.5s ease-out;
`;

export const PageTitle = styled.h2`
  margin-top: 70px;
  margin-left: 50px;
  margin-bottom: 50px;
`;

export const PageContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
`;

export const LeftSection = styled.div`
  width: calc(63% - 20px);
  margin-right: 20px;
`;

export const RightSection = styled.div`
  width: 37%;
`;
