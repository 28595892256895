/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { map, size } from 'lodash-es';
import { ReactComponent as AddIcon } from '../../../common/assets/icons/Button_Add-2.svg';
import AvatarIcon from '../../../common/assets/icons/Avatar_Membru.svg';
import { addMentorInvitation, loadMentors } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import {
  Loading,
  Heading2,
  Heading4Regular,
  Heading3Semibold,
  Link,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
} from '../../../common/components/styled';
import {
  MentorCard,
  MentorMarginDiv,
  AcceptButton,
  ButtonText,
  ImageIconRound,
  ImageIconRoundDiv,
  ImageUserNameDiv,
  WrapContainer,
} from '../styled';

const MentorsPage = (props) => {
  const { mentors, addMentorInvitation, loadMentors, teamId, isLoading } =
    props;

  const handleSendMentorInvitation = async (mentorId) => {
    await addMentorInvitation(mentorId, teamId);
    await loadMentors(teamId);
  };

  return (
    <>
      <TitleCounterDiv>
        <Title>Listă mentori</Title>
        <CounterDiv>
          <CounterText>{size(mentors)}</CounterText>
        </CounterDiv>
      </TitleCounterDiv>
      <WrapContainer>
        {map(mentors, (mentor, index) => (
          <MentorCard key={index}>
            <ImageUserNameDiv>
              <ImageIconRoundDiv>
                <ImageIconRound
                  src={
                    mentor.profilePicture ? mentor.profilePicture : AvatarIcon
                  }
                />
              </ImageIconRoundDiv>

              <Heading3Semibold>
                {mentor.firstName} {mentor.lastName}
              </Heading3Semibold>
            </ImageUserNameDiv>
            <MentorMarginDiv small>
              <Heading2>Descriere</Heading2>
            </MentorMarginDiv>
            <MentorMarginDiv fixedHeight>
              <Heading4Regular
                className="scrollbar-modal"
                style={{
                  height: '100% !important',
                  overflowY: 'auto',
                  paddingRight: '5px',
                }}
                noEllipsis
              >
                {mentor.about}
              </Heading4Regular>
            </MentorMarginDiv>
            <MentorMarginDiv maxWidth>
              <Heading2>{mentor.address}</Heading2>
            </MentorMarginDiv>
            <MentorMarginDiv>
              <Link href={mentor.linkedIn} target="_blank" maxWidth="200px">
                LinkedIn
              </Link>
            </MentorMarginDiv>
            {mentor.isInvited ? (
              <AcceptButton dashed>
                <ButtonText color="#131336" isInvited>
                  Invitat
                </ButtonText>
              </AcceptButton>
            ) : mentor.isMember ? (
              <AcceptButton dashed>
                <ButtonText color="#131336" isInvited>
                  Membru
                </ButtonText>
              </AcceptButton>
            ) : (
              <AcceptButton
                onClick={() => handleSendMentorInvitation(mentor.userId)}
              >
                <AddIcon />
                <ButtonText color="#FFFFFF">Invită</ButtonText>
              </AcceptButton>
            )}
          </MentorCard>
        ))}
      </WrapContainer>
      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.mentors.isCreating,
  mentors: state.mentors.mentors,
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { addMentorInvitation, loadMentors };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentorsPage)
);
