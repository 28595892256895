import styled, { css } from 'styled-components';

export const Title = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '32px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '38px')};
  color: ${(props) => props.theme.text};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};
  margin-bottom: ${(props) => props.marginBottom};
  ${(props) =>
    props.navigationTitle &&
    css`
      overflow: visible;
    `};
`;

export const Heading1 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-right: 15px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  margin-top: 0px;
  color: ${(props) => props.theme.text};
`;
export const Heading2 = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-right: 5px;
  margin-left: ${(props) => props.marginLeft};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  color: ${(props) => (props.color ? props.color : props.theme.text)};
  ${(props) =>
    props.normal &&
    css`
      font-weight: normal;
    `}
`;

export const Heading3Semibold = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: ${(props) => props.theme.text};
`;

export const Heading4Regular = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: 19px;
  margin-bottom: 0px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  height: ${(props) => props.maxHeight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};

  color: ${(props) => (props.white ? props.theme.white : props.theme.text)};

  /* ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8e8eb5;
    border-radius: 8px;
    background-clip: padding-box;
    border: 3px solid #ffffff;
  } */

  ${(props) =>
    props.noEllipsis &&
    css`
      white-space: normal;
    `}

  ${(props) =>
    props.textArea &&
    css`
      max-height: 57px;
      overflow-y: auto;
    `}

  ${(props) =>
    props.experimentPrice &&
    css`
      width: 45px;
      text-align: right;
    `}
  ${(props) =>
    props.inactive &&
    css`
      color: ${(props) => props.theme.textInactive};
    `}

  ${(props) =>
    props.twoRows &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    `}
`;

export const TextInactive = styled.h4`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.textInactive};
  text-align: justify;
`;

export const Link = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '300px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};

  ${(props) =>
    props.twoRows &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    `}

  color: ${(props) => props.theme.blueAccent} !important;
  :hover {
    color: ${(props) => props.theme.accentHover} !important;
  }
`;

export const LinkTable = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '300px')};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  color: ${(props) => props.theme.blueAccent} !important;
  :hover {
    color: ${(props) => props.theme.accentHover} !important;
  }
`;
