/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useMemo } from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router';
import NavigationBarResolver from './NavigationBarResolver';
import {
  PageContainer,
  PageBody,
  AddNewButtonDiv,
  DropDownButton,
  DropdownMenu,
  DropdownItem,
  ArrowIcon,
  PageContentWrapper,
} from './styled';
import { AddHypothesisModal } from '../../hypotheses/components/modals';
import { AddExperimentModal } from '../../experiments/components/modals';
import { AddDetailModalResolver } from './modals';
import {
  closeModal,
  getModalOpenStateSelector,
  openModal,
  currentTeamIdSelector,
} from '../ducks';
import DocumentTitle from './DocumentTitle';
import { ReactComponent as ButtonGlobalIcon } from '../assets/icons/Button_Shortcut.svg';
import { ReactComponent as HypothesisIcon } from '../assets/icons/Menu_Ipoteza_S_Hover.svg';
import { ReactComponent as ExperimentIcon } from '../assets/icons/Menu_Experiment-1.svg';
import { ReactComponent as HypothesisDetailIcon } from '../assets/icons/Menu_Detaliu_IpotezaMenu_Detaliu_Ipoteza_Small_Hover.svg';
import { ReactComponent as ExperimentDetailIcon } from '../assets/icons/Menu_Detaliu_Experiment_Small_H.svg';
import IRL from './IRL';
import matchRoute from '../../utils/services/matchRoute';

const Page = ({
  children,
  isLoading,
  isHypothesisModalOpen,
  closeModal,
  isExperimentModalOpen,
  isExperimentDetailModalOpen,
  isHypothesisModaDetaillOpen,
  openModal,
  role,
  teamId,
  isCreatingExperiment,
  isMenuOpen,
  location,
}) => {
  const openGlobalModals = (type) => {
    openModal(type);
  };

  const showIRL = useMemo(
    () =>
      !matchRoute(location.pathname, '/teams') &&
      !matchRoute(location.pathname, '/hypotheses') &&
      !matchRoute(location.pathname, '/experiments') &&
      !matchRoute(location.pathname, '/details') &&
      !matchRoute(location.pathname, '/kpis') &&
      !matchRoute(location.pathname, '/team-profile') &&
      !matchRoute(location.pathname, '/mentor-invitations') &&
      !matchRoute(location.pathname, '/mentors') &&
      !matchRoute(location.pathname, '/leaderboard'),
    [location]
  );

  return (
    <PageContainer>
      <DocumentTitle>Launchpad</DocumentTitle>

      <NavigationBarResolver />
      {!isLoading && (
        <PageBody open={isMenuOpen}>
          <PageContentWrapper>
            {(role === 'FOUNDER' || role === 'MENTOR') && teamId && showIRL && (
              <IRL teamId={teamId} />
            )}
            {role === 'FOUNDER' && teamId && (
              <AddNewButtonDiv showIRL={showIRL}>
                <Dropdown>
                  <DropDownButton id="dropdown-basic">
                    <ButtonGlobalIcon />
                  </DropDownButton>
                  <DropdownMenu>
                    <DropdownItem
                      type="button"
                      tabIndex="0"
                      role="menuitem"
                      className="dropdown-item"
                      onClick={() => openGlobalModals('hypothesis')}
                    >
                      Adaugă ipoteză
                      <HypothesisIcon style={{ marginLeft: '8px' }} />
                    </DropdownItem>
                    <DropdownItem
                      type="button"
                      tabIndex="2"
                      role="menuitem"
                      className="dropdown-item"
                      onClick={() => openGlobalModals('hypothesisDetail')}
                    >
                      Adaugă detaliu ipoteză
                      <HypothesisDetailIcon style={{ marginLeft: '8px' }} />
                    </DropdownItem>
                    <div
                      style={{
                        borderBottom: '1px solid #CCCCCC',
                        height: '1px',
                        marginRight: '11px',
                        marginLeft: '75px',
                      }}
                    />
                    <DropdownItem
                      type="button"
                      tabIndex="1"
                      role="menuitem"
                      className="dropdown-item"
                      onClick={() => openGlobalModals('experiment')}
                    >
                      Adaugă experiment
                      <ExperimentIcon style={{ marginLeft: '8px' }} />
                    </DropdownItem>
                    <DropdownItem
                      type="button"
                      tabIndex="2"
                      role="menuitem"
                      className="dropdown-item"
                      onClick={() => openGlobalModals('experimentDetail')}
                    >
                      Adaugă detaliu experiment
                      <ExperimentDetailIcon style={{ marginLeft: '8px' }} />
                    </DropdownItem>
                    <ArrowIcon />
                  </DropdownMenu>
                </Dropdown>
              </AddNewButtonDiv>
            )}
            {children}
          </PageContentWrapper>
        </PageBody>
      )}
      {role !== 'ADMIN' && (
        <AddHypothesisModal
          isModalOpen={isHypothesisModalOpen}
          closeModal={closeModal}
          global
        />
      )}
      {role !== 'ADMIN' && (
        <AddExperimentModal
          isModalOpen={isExperimentModalOpen}
          closeModal={closeModal}
          global
          isLoading={isCreatingExperiment}
        />
      )}
      {role !== 'ADMIN' && isExperimentDetailModalOpen && (
        <AddDetailModalResolver
          isModalOpenGlobal={isExperimentDetailModalOpen}
          closeModal={closeModal}
          global
          isExperiment
          isExperimentTitle
        />
      )}
      {role !== 'ADMIN' && isHypothesisModaDetaillOpen && (
        <AddDetailModalResolver
          isModalOpenGlobal={isHypothesisModaDetaillOpen}
          closeModal={closeModal}
          global
          isHypothesis
          isHypothesisTitle
        />
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.common.userTeams.isLoading,
  isCreatingExperiment: state.experiments.isCreating,
  isHypothesisModalOpen: getModalOpenStateSelector(state.common, 'hypothesis'),
  isExperimentModalOpen: getModalOpenStateSelector(state.common, 'experiment'),
  isExperimentDetailModalOpen: getModalOpenStateSelector(
    state.common,
    'experimentDetail'
  ),
  isHypothesisModaDetaillOpen: getModalOpenStateSelector(
    state.common,
    'hypothesisDetail'
  ),
  role: get(state.account.login, 'user.role'),
  teamId: currentTeamIdSelector(state.common.userTeams),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  closeModal,
  openModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
