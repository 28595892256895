import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { get, find } from 'lodash-es';
import { Formik, useField, useFormikContext } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { Dropdown } from '../../../core/components';
import { outcomeOptions } from '../../constants/resolutions';
import {
  Input,
  Heading2,
  SubmitButton,
  ButtonDiv,
  SaveIcon,
} from '../../../common/components/styled';

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderDropdownField = (props) => {
  const { label, options, name, setFieldValue, values, controlId } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        as={Dropdown}
        options={options}
        showIconOption
        name={name}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderInsightNameField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderFromThatField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        height="100px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderThereforField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        height="100px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({ handleSubmit, handleChange, values, setFieldValue }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderInsightNameField
        name="insightName"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Titlul rezoluției*"
        placeholder="Dă un titlu sugestiv concluziilor tuturor experimentelor derulate până la acest moment."
      />
    </Form.Row>
    <Form.Row />
    <Form.Row>
      <RenderFromThatField
        name="fromThat"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="În baza datelor am concluzionat că:*"
        type="textarea"
        placeholder="Descrie într-o frază succintă și cuprinzătoare ce este concluzia tuturor experimentelor derulate până la acest moment."
      />
    </Form.Row>
    <Form.Row>
      <RenderThereforField
        name="therefore"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik03"
        label="Prin urmare vom:*"
        type="textarea"
        placeholder="Menționează care sunt deciziile sau acțiunile concrete pe care le vei lua ca urmare a validării sau invalidării ipotezei."
      />
    </Form.Row>
    <Form.Row>
      <RenderDropdownField
        values={values}
        controlId="validationFormik02"
        setFieldValue={setFieldValue}
        name="outcome"
        label="Astfel, considerăm ipoteza:*"
        options={outcomeOptions}
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        {' '}
        <SaveIcon />
        Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddResolutionFormHypothesis = (props) => {
  const { onSubmit, resolution } = props;

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    insightName: Yup.string().required('Câmp obligatoriu'),
    fromThat: Yup.string().required('Câmp obligatoriu'),
    therefore: Yup.string().required('Câmp obligatoriu'),
    outcome: Yup.string().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    insightName: '',
    fromThat: '',
    therefore: '',
    outcome: '',
  };

  const defaultResolution = {
    ...resolution,
    outcome: find(outcomeOptions, {
      value: get(resolution, 'outcome'),
    }),
  };

  const initialValues = resolution ? defaultResolution : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};

export default AddResolutionFormHypothesis;
