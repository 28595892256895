import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypothesisDetail } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import HypothesisDetailPage from './HypothesisDetailPage';

const HypothesisDetailPageResolver = (props) => {
  const {
    loadHypothesisDetail,
    match: {
      params: { detailId },
    },
  } = props;
  const loadDependencies = () =>
    detailId ? loadHypothesisDetail(detailId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Detaliu Ipoteza</DocumentTitle>
      <Resolver
        successComponent={HypothesisDetailPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

HypothesisDetailPageResolver.propTypes = {
  loadHypothesisDetail: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadHypothesisDetail };

export default withRouter(
  connect(undefined, mapDispatchToProps)(HypothesisDetailPageResolver)
);
