import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadResolution } from '../ducks';
import { Resolver } from '../../core/components';
import { Loading } from '../../common/components/styled';
import { DocumentTitle } from '../../common/components';
import HypothesisResolutionPage from './HypothesisResolutionPage';

const HypothesisResolutionPageResolver = (props) => {
  const {
    loadResolution,
    match: {
      params: { resolutionId },
    },
  } = props;
  const loadDependencies = () =>
    resolutionId ? loadResolution(resolutionId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Rezolutie</DocumentTitle>
      <Resolver
        successComponent={HypothesisResolutionPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

HypothesisResolutionPageResolver.propTypes = {
  loadResolution: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadResolution };

export default withRouter(
  connect(undefined, mapDispatchToProps)(HypothesisResolutionPageResolver)
);
