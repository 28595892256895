/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { map, size, get, find } from 'lodash-es';
import { push } from 'connected-react-router';
import moment from 'moment';
import {
  Loading,
  Heading1,
  Heading2,
  Heading4Regular,
  Link,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  TagButton,
} from '../../../common/components/styled';
import { currentCohortIdSelector } from '../../ducks';
import { loadTeams } from '../../../teams/ducks';
import AvatarImage from '../../../common/components/AvatarImage';
import {
  IdeeaDescription,
  InvitationContainer,
  LabelFieldDiv,
  TitleCategoriesDiv,
  IndustryText,
  ImageFieldsDiv,
  FieldsDiv,
  WrapContainer,
  DivBorderBottom,
} from '../../../mentors/components/styled';

import { thermometerLabels } from '../../../common/components/IRLThermometer';

const CohortTeamsPage = (props) => {
  const { teams, isLoading, cohortId, loadTeams, push } = props;

  useEffect(() => {
    cohortId ? Promise.all([loadTeams(cohortId)]) : Promise.resolve();
  }, [cohortId, loadTeams]);

  const handleIrlLabels = (irl) =>
    get(find(thermometerLabels, { number: irl }), 'label');

  return (
    <>
      {' '}
      <TitleCounterDiv>
        <Title>Ideile mele</Title>
        <CounterDiv>
          <CounterText>{size(teams)}</CounterText>
        </CounterDiv>
      </TitleCounterDiv>
      <WrapContainer>
        {map(teams, (team, index) => (
          <InvitationContainer key={index}>
            <DivBorderBottom>
              <TitleCategoriesDiv>
                <Heading1
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    push(`/accelerator/${cohortId}/teams/${team._id}/canvas`)
                  }
                >
                  {team.name}
                </Heading1>
              </TitleCategoriesDiv>
              <div style={{ display: 'flex' }}>
                {map(team.ideaCategory, (category, index) => (
                  <TagButton key={index}>
                    <IndustryText>{category}</IndustryText>
                  </TagButton>
                ))}
              </div>
            </DivBorderBottom>
            <ImageFieldsDiv>
              <AvatarImage src={team.logo} medium />
              <FieldsDiv>
                <LabelFieldDiv>
                  <Link href={team.domain} target="blank">
                    {team.domain}
                  </Link>
                </LabelFieldDiv>
                <LabelFieldDiv>
                  <Heading2>Stadiu</Heading2>
                  <Heading4Regular> {team.stage}</Heading4Regular>
                </LabelFieldDiv>
                <LabelFieldDiv>
                  <Heading2>Data Infintare</Heading2>
                  <Heading4Regular>
                    {team.dateOfEstablishment &&
                      moment(team.dateOfEstablishment).format('DD/MM/YYYY')}
                  </Heading4Regular>
                </LabelFieldDiv>
                <LabelFieldDiv>
                  <Heading2>Locatie</Heading2>
                  <Heading4Regular> {team.headquarters}</Heading4Regular>
                </LabelFieldDiv>
                <LabelFieldDiv>
                  <Heading2>Cui</Heading2>
                  <Heading4Regular>{team.cui}</Heading4Regular>
                </LabelFieldDiv>
              </FieldsDiv>
            </ImageFieldsDiv>
            <LabelFieldDiv>
              <Heading2>Descriere</Heading2>
            </LabelFieldDiv>
            <IdeeaDescription className="scrollbar-modal">
              <Heading4Regular textArea noEllipsis>
                {team.description}
              </Heading4Regular>
            </IdeeaDescription>
            <LabelFieldDiv>
              <Heading2>IRL</Heading2>
              <Heading4Regular>{`${team.irl}/14 - ${handleIrlLabels(
                team.irl
              )}`}</Heading4Regular>
            </LabelFieldDiv>
          </InvitationContainer>
        ))}
      </WrapContainer>
      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  cohortId: currentCohortIdSelector(state.cohorts),
  isLoading: state.teams.isLoading,
  teams: state.teams.teams,
});

const mapDispatchToProps = {
  loadTeams,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CohortTeamsPage)
);
