export const RESOURCE_SECTION_OPTIONS = [
  {
    value: 'hypothesis',
    label: 'Ipoteză',
  },
  {
    value: 'experiment',
    label: 'Experiment',
  },
  {
    value: 'resolution',
    label: 'Concluzie',
  },
  {
    value: 'detail',
    label: 'Detaliu',
  },
];
