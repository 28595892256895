import update from 'immutability-helper';
import { push } from 'react-router-redux';
import { map, identity, get, find } from 'lodash-es';
import { createSelector } from 'reselect';
import {
  loadUserTeams as doLoadUserTeams,
  setCurrentUserTeam as doSetCurrentUserTeam,
} from '../services/userTeams';

// Actions
const START_LOAD = 'userTeams/START_LOAD';
const COMPLETE_LOAD = 'userTeams/COMPLETE_LOAD';
const FAIL_LOAD = 'userTeams/FAIL_LOAD';
const UPDATE_SELECTED_TEAM = 'userTeams/UPDATE_SELECTED_TEAM';

const RESET = 'userTeams/RESET';

// Initial state
const initialState = {
  isLoading: false,
  userTeams: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          userTeams: action.userTeams,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          userTeams: undefined,
        },
      });

    case UPDATE_SELECTED_TEAM: {
      const { userTeams } = state;
      const updatedUserTeams = map(userTeams, (team) =>
        team._id === action.teamId
          ? { ...team, selected: true }
          : { ...team, selected: false }
      );
      return update(state, {
        $merge: {
          isLoading: false,
          userTeams: updatedUserTeams,
        },
      });
    }

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (userTeams) => ({
  type: COMPLETE_LOAD,
  userTeams,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const updateSelectedTeam = (teamId) => ({
  type: UPDATE_SELECTED_TEAM,
  teamId,
});

const reset = () => ({
  type: RESET,
});

export const loadUserTeams = (userId) => (dispatch) => {
  dispatch(startLoad());
  return doLoadUserTeams(userId)
    .then((res) => dispatch(completeLoad(res)))
    .catch(() => dispatch(failLoad()));
};

export const setCurrentUserTeam = (userId, teamId) => (dispatch) => {
  doSetCurrentUserTeam(userId, teamId);
  dispatch(updateSelectedTeam(teamId));
  dispatch(push('/canvas'));
};

export const resetUserTeams = () => (dispatch) => {
  dispatch(reset());
};

const selectCurrentTeam = (state) =>
  get(
    find(state.userTeams, (team) => !!team.selected),
    '_id'
  );

export const currentTeamIdSelector = createSelector(
  selectCurrentTeam,
  identity
);

const selectCurrentTeamCohortId = (state) =>
  get(
    find(state.userTeams, (team) => !!team.selected),
    'cohortId'
  );
export const currentTeamCohortIdSelector = createSelector(
  selectCurrentTeamCohortId,
  identity
);
