import React, { useMemo, useState } from 'react';
import { map, concat, size, has, get, find } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';

import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  LinkTable,
} from '../../common/components/styled';
import { Export, SortTableHead } from '../../common/components';
import {
  ENGAGEMENT_LEVEL_OPTIONS,
  IS_EARLY_ADOPTER_OPTIONS,
  CAN_CONTACT_LATER_OPTIONS,
  CONTACT_TYPE_OPTIONS,
  DETAIL_CATEGORY_OPTIONS,
  BUYING_SIGNALS_OPTIONS,
} from '../../common/constants';
import { TitleButtonsDiv } from '../../mentors/components/styled';

const DetailsPage = ({ hypothesisDetails, experimentDetails, push }) => {
  const [details, setDetails] = useState(
    concat(hypothesisDetails, experimentDetails)
  );

  const getDownloadData = useMemo(
    () =>
      map(details, (detail, index) => ({
        '#': index + 1,
        'Titlu Detaliu': detail.title,
        Categorie: has(detail, 'experimentId')
          ? 'Detaliu experiment'
          : 'Detaliu ipoteza',
        'Referitor la': has(detail, 'experimentId')
          ? detail.experimentId.title
          : detail.hypothesisId[0].title,
        Subcategorie: get(
          find(DETAIL_CATEGORY_OPTIONS, {
            value: detail.detailCategory,
          }),
          'label',
          '-'
        ),
        'Tip Contact': get(
          find(CONTACT_TYPE_OPTIONS, {
            value: detail.contactType,
          }),
          'label',
          '-'
        ),
        'Interes soluție': get(
          find(ENGAGEMENT_LEVEL_OPTIONS, {
            value: detail.engagementLevel,
          }),
          'label',
          '-'
        ),
        'Semnal cumparare': get(
          find(BUYING_SIGNALS_OPTIONS, {
            value: detail.buyingSignals,
          }),
          'label',
          '-'
        ),
        'Disponibilitate contact': get(
          find(CAN_CONTACT_LATER_OPTIONS, {
            value: detail.canContactLater,
          }),
          'label',
          '-'
        ),
        'Early adopter': get(
          find(IS_EARLY_ADOPTER_OPTIONS, {
            value: detail.isEarlyAdopter,
          }),
          'label',
          '-'
        ),
        Linkuri: size(detail.resources) > 0 ? 'Da' : 'Nu',
      })),
    [details]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Detalii</Title>
          <CounterDiv>
            <CounterText>{size(details)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <Export data={getDownloadData} filename="detalii.csv" />
      </TitleButtonsDiv>

      <SortTableHead
        array={details}
        setArray={setDetails}
        cellSortKeys={[null, ['title']]}
      >
        <TableHeadCell alignLeft cellWidth="5%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="12%">
          <Heading2>Titlu Detaliu</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="11%">
          <Heading2>Categorie</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="11%">
          <Heading2>Referitor la</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>Subcategorie</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>Tip Contact</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>Interes soluție</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>
            Semnal <br /> cumparare
          </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>
            Disponibilitate <br /> contact
          </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>
            Early <br /> adopter
          </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="9%">
          <Heading2 center>Linkuri</Heading2>
        </TableHeadCell>
      </SortTableHead>
      {map(details, (detail, index) => (
        <TableRow key={index}>
          <TableBodyCell alignLeft cellWidth="5%">
            <Heading4Regular>{index + 1}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignLeft cellWidth="12%">
            <LinkTable
              onClick={() =>
                has(detail, 'experimentId')
                  ? push(
                      `/hypothesis/${detail.hypothesisId[0]._id}/experiment/${detail.experimentId._id}/detail/${detail._id}`
                    )
                  : push(
                      `/hypothesis/${detail.hypothesisId[0]._id}/detail/${detail._id}`
                    )
              }
            >
              {detail.title}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="11%">
            <Heading4Regular>
              {has(detail, 'experimentId')
                ? 'Detaliu experiment'
                : 'Detaliu ipoteza'}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="11%">
            <LinkTable
              onClick={() =>
                has(detail, 'experimentId')
                  ? push(
                      `/hypothesis/${detail.hypothesisId[0]._id}/experiment/${detail.experimentId._id}`
                    )
                  : push(`/hypothesis/${detail.hypothesisId[0]._id}`)
              }
            >
              {has(detail, 'experimentId')
                ? detail.experimentId.title
                : detail.hypothesisId[0].title}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {get(
                find(DETAIL_CATEGORY_OPTIONS, {
                  value: detail.detailCategory,
                }),
                'label',
                '-'
              )}
            </Heading4Regular>
          </TableBodyCell>

          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {get(
                find(CONTACT_TYPE_OPTIONS, {
                  value: detail.contactType,
                }),
                'label',
                '-'
              )}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {get(
                find(ENGAGEMENT_LEVEL_OPTIONS, {
                  value: detail.engagementLevel,
                }),
                'label',
                '-'
              )}
            </Heading4Regular>
          </TableBodyCell>

          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {get(
                find(BUYING_SIGNALS_OPTIONS, {
                  value: detail.buyingSignals,
                }),
                'label',
                '-'
              )}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {get(
                find(CAN_CONTACT_LATER_OPTIONS, {
                  value: detail.canContactLater,
                }),
                'label',
                '-'
              )}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {get(
                find(IS_EARLY_ADOPTER_OPTIONS, {
                  value: detail.isEarlyAdopter,
                }),
                'label',
                '-'
              )}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="9%">
            <Heading4Regular>
              {size(detail.resources) > 0 ? 'Da' : 'Nu'}
            </Heading4Regular>
          </TableBodyCell>
        </TableRow>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.details.isLoading,
  experimentDetails: state.details.experimentDetails,
  hypothesisDetails: state.details.hypothesisDetails,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsPage)
);
