import { toast } from 'react-toastify';
import { forEach } from 'lodash-es';

// Actions
const SUCCESS = 'core/notifications/SUCCESS';
const WARNING = 'core/notifications/WARNING';
const ERROR = 'core/notifications/ERROR';

// Initial State
const initialState = {};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      toast.success(action.message, {
        className: 'toastify toastify-type-success',
        bodyClassName: 'toastify toastify-body',
        progressClassName: 'toastify-progress',
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
      });
      return state;

    case WARNING:
      toast.warn(action.message, {
        className: 'toastify toastify-type-warning',
        bodyClassName: 'toastify-body',
        position: toast.POSITION.BOTTOM_RIGHT,
        progressClassName: 'toastify-progress',
        autoClose: 2500,
      });
      return state;

    case ERROR:
      toast.error(action.message, {
        className: 'toastify toastify-type-error',
        bodyClassName: 'toastify-body',
        progressClassName: 'toastify-progress',
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4500,
      });
      return state;

    default:
      return state;
  }
};

// Action Creators
export const notifySuccess = (message) => ({
  type: SUCCESS,
  message,
});

export const notifyWarning = (message) => ({
  type: WARNING,
  message,
});

export const notifyError = (error) => (dispatch) => {
  if (Array.isArray(error)) {
    forEach(error, (err) =>
      dispatch({
        type: ERROR,
        message: `${err.name && `${err.name}: `} ${err.message}`,
      })
    );
  } else {
    dispatch({ type: ERROR, message: error.message });
  }
};
