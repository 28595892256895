/* eslint-disable react/display-name */
import React from 'react';
import reportType from '../assets/icons/Tip-raport.svg';
import interested from '../assets/icons/Interesat.svg';
import notInterested from '../assets/icons/Neinteresat.svg';
import veryInterested from '../assets/icons/Foarte-interesat.svg';
import isEarly from '../assets/icons/Early-da.svg';
import notIsEarly from '../assets/icons/Early-nu.svg';
import interviewDetails from '../assets/icons/Detalii-interviu.svg';
import notBuying from '../assets/icons/Cumparare-nu.svg';
import buying from '../assets/icons/Cumparare-da.svg';
import notCanContact from '../assets/icons/Contact-ulterior-nu.svg';
import canContact from '../assets/icons/Contact-ulterior-da.svg';
import telephone from '../assets/icons/Contact-telefonic.svg';
import online from '../assets/icons/Contact-online.svg';
import f2f from '../assets/icons/Contact-f2f.svg';
import email from '../assets/icons/Contact-email.svg';
import other from '../assets/icons/Contact-altametoda.svg';
import notCanContactCut from '../assets/icons/notCanContactCut.svg';
import notInterestedCut from '../assets/icons/notInterestedCut.svg';
import analisys from '../assets/icons/analiza.svg';
import image from '../assets/icons/imagine.svg';
import audio from '../assets/icons/inregistrareAudio.svg';
import video from '../assets/icons/videoDetaliu.svg';
import dataLibrary from '../assets/icons/librarieDate.svg';
import discussionSummary from '../assets/icons/minutaDiscutie.svg';
import mockup from '../assets/icons/mockup.svg';
import prototype from '../assets/icons/prototip.svg';
import presentation from '../assets/icons/prezentare.svg';
import report from '../assets/icons/raport.svg';
import surveyAnswer from '../assets/icons/raspunsChestionar.svg';
import draft from '../assets/icons/schita.svg';
import study from '../assets/icons/studiu.svg';
import otherCategory from '../assets/icons/altaCategorie.svg';

import { DetailIcon } from '../components/styled';

export const DETAIL_CATEGORY_OPTIONS = [
  {
    label: 'Analiză',
    value: 0,
    icon: analisys,
    renderIcon: () => <DetailIcon icon={analisys} />,
  },
  {
    label: 'Chestionar',
    value: 1,
    icon: reportType,
    renderIcon: () => <DetailIcon icon={reportType} />,
  },
  {
    label: 'Detalii persoană de contact',
    value: 2,
    icon: interviewDetails,
    renderIcon: () => <DetailIcon icon={interviewDetails} />,
  },
  {
    label: 'Imagine',
    value: 3,
    icon: image,
    renderIcon: () => <DetailIcon icon={image} />,
  },
  {
    label: 'Înregistrare audio',
    value: 4,
    icon: audio,
    renderIcon: () => <DetailIcon icon={audio} />,
  },
  {
    label: 'Înregistrare video',
    value: 5,
    icon: video,
    renderIcon: () => <DetailIcon icon={video} />,
  },
  {
    label: 'Librărie date',
    value: 6,
    icon: dataLibrary,
    renderIcon: () => <DetailIcon icon={dataLibrary} />,
  },
  {
    label: 'Minută discuție',
    value: 7,
    icon: discussionSummary,
    renderIcon: () => <DetailIcon icon={discussionSummary} />,
  },
  {
    label: 'Mockup',
    value: 8,
    icon: mockup,
    renderIcon: () => <DetailIcon icon={mockup} />,
  },
  {
    label: 'Prototip sau MVP',
    value: 9,
    icon: prototype,
    renderIcon: () => <DetailIcon icon={prototype} />,
  },
  {
    label: 'Prezentare',
    value: 10,
    icon: presentation,
    renderIcon: () => <DetailIcon icon={presentation} />,
  },
  {
    label: 'Raport',
    value: 11,
    icon: report,
    renderIcon: () => <DetailIcon icon={report} />,
  },
  {
    label: 'Răspuns chestionar/interviu',
    value: 12,
    icon: surveyAnswer,
    renderIcon: () => <DetailIcon icon={surveyAnswer} />,
  },
  {
    label: 'Schiță',
    value: 13,
    icon: draft,
    renderIcon: () => <DetailIcon icon={draft} />,
  },
  {
    label: 'Studiu sau cercetare',
    value: 14,
    icon: study,
    renderIcon: () => <DetailIcon icon={study} />,
  },
  {
    label: 'Altă categorie',
    value: 15,
    icon: otherCategory,
    renderIcon: () => <DetailIcon icon={otherCategory} />,
  },
];

export const CONTACT_TYPE_OPTIONS = [
  {
    label: 'Față in față',
    value: 0,
    icon: f2f,
    renderIcon: () => <DetailIcon icon={f2f} />,
  },
  {
    label: 'Telefonic',
    value: 1,
    icon: telephone,
    renderIcon: () => <DetailIcon icon={telephone} />,
  },
  {
    label: 'Online',
    value: 2,
    icon: online,
    renderIcon: () => <DetailIcon icon={online} />,
  },
  {
    label: 'Email',
    value: 3,
    icon: email,
    renderIcon: () => <DetailIcon icon={email} />,
  },
  {
    label: 'Altă metodă',
    value: 4,
    icon: other,
    renderIcon: () => <DetailIcon icon={other} />,
  },
  {
    label: 'Nu e cazul',
    value: 5,
    icon: notCanContactCut,
    renderIcon: () => <DetailIcon icon={notCanContactCut} />,
  },
];

export const CAN_CONTACT_LATER_OPTIONS = [
  {
    label: 'Da',
    value: 0,
    icon: canContact,
    renderIcon: () => <DetailIcon icon={canContact} />,
  },
  {
    label: 'Nu e cazul',
    value: 1,
    icon: notCanContact,
    renderIcon: () => <DetailIcon icon={notCanContact} />,
  },
];

export const BUYING_SIGNALS_OPTIONS = [
  {
    label: 'Da',
    value: 0,
    icon: buying,
    renderIcon: () => <DetailIcon icon={buying} />,
  },
  {
    label: 'Nu e cazul',
    value: 1,
    icon: notBuying,
    renderIcon: () => <DetailIcon icon={notBuying} />,
  },
];

export const IS_EARLY_ADOPTER_OPTIONS = [
  {
    label: 'Da',
    value: 0,
    icon: isEarly,
    renderIcon: () => <DetailIcon icon={isEarly} />,
  },
  {
    label: 'Nu e cazul',
    value: 1,
    icon: notIsEarly,
    renderIcon: () => <DetailIcon icon={notIsEarly} />,
  },
];

export const ENGAGEMENT_LEVEL_OPTIONS = [
  {
    label: 'Foarte interesat/ă',
    value: 0,
    icon: veryInterested,
    renderIcon: () => <DetailIcon icon={veryInterested} />,
  },
  {
    label: 'Interesat/ă',
    value: 1,
    icon: interested,
    renderIcon: () => <DetailIcon icon={interested} />,
  },
  {
    label: 'Neinteresat/ă',
    value: 2,
    icon: notInterested,
    renderIcon: () => <DetailIcon icon={notInterested} />,
  },
  {
    label: 'Nu e cazul',
    value: 3,
    icon: notInterestedCut,
    renderIcon: () => <DetailIcon icon={notInterestedCut} />,
  },
];
