import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { resetPassword, resetResetPassword } from '../../ducks';
import { AccountPage, AccountPanel } from '../styled';
import { ResetPasswordForm } from '../forms';
import { WhiteCard } from '../../../common/components/styled';

const ResetPasswordPage = ({
  resetPassword,
  location,
  isResettingPassword,
  resetResetPassword,
}) => {
  useEffect(() => () => resetResetPassword(), [resetResetPassword]);

  const onSubmit = ({ password }) => {
    const { email, token } = parse(location.search.substr(1));
    resetPassword(token, email, password);
  };
  return (
    <>
      <AccountPage>
        <AccountPanel isLoading={isResettingPassword}>
          <WhiteCard noOverflow padding="30px" borderRadius="10px">
            <ResetPasswordForm handleSubmit={onSubmit} />
          </WhiteCard>
        </AccountPanel>
      </AccountPage>
    </>
  );
};

ResetPasswordPage.propTypes = {
  isResettingPassword: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  resetResetPassword: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapStateToProps = (state) => state.account.resetPassword;

const mapDispatchToProps = {
  resetPassword,
  resetResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
