import update from 'immutability-helper';

import { notifySuccess, notifyError } from '../../core/ducks/notifications';

import {
  loadProfile as doLoadProfile,
  editProfile as doEditProfile,
  loadHypothesesPercentage as doLoadHypothesesPercentage,
  loadExperimentsPercentage as doLoadExperimentsPercentage,
  uploadProfilePicture as doUploadProfilePicture,
} from '../services/userProfile';

// Actions
const START_LOAD = 'profile/START_LOAD';
const COMPLETE_LOAD = 'profile/COMPLETE_LOAD';
const FAIL_LOAD = 'profile/FAIL_LOAD';

const START_LOAD_HYPOTHESES_PERCENTAGE =
  'profile/START_LOAD_HYPOTHESES_PERCENTAGE';
const COMPLETE_LOAD_HYPOTHESES_PERCENTAGE =
  'profile/COMPLETE_LOAD_HYPOTHESES_PERCENTAGE';
const FAIL_LOAD_HYPOTHESES_PERCENTAGE =
  'profile/FAIL_LOAD_HYPOTHESES_PERCENTAGE';

const START_LOAD_EXPERIMENTS_PERCENTAGE =
  'profile/START_LOAD_EXPERIMENTS_PERCENTAGE';
const COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE =
  'profile/COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE';
const FAIL_LOAD_EXPERIMENTS_PERCENTAGE =
  'profile/FAIL_LOAD_EXPERIMENTS_PERCENTAGE';

const START_EDIT = 'profile/START_EDIT';
const COMPLETE_EDIT = 'profile/COMPLETE_EDIT';
const FAIL_EDIT = 'profile/FAIL_EDIT';

const START_UPLOAD_PROFILE_PICTURE = 'profile/START_UPLOAD_PROFILE_PICTURE';
const COMPLETE_UPLOAD_PROFILE_PICTURE =
  'profile/COMPLETE_UPLOAD_PROFILE_PICTURE';
const FAIL_UPLOAD_PROFILE_PICTURE = 'profile/FAIL_UPLOAD_PROFILE_PICTURE';

const RESET = 'experiments/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isEditing: false,
  userProfile: undefined,
  hypothesesPercentage: undefined,
  experimentsPercentage: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: { isLoading: false, userProfile: action.userProfile },
      });

    case FAIL_LOAD:
      return update(state, { $merge: { isLoading: false } });

    case START_LOAD_HYPOTHESES_PERCENTAGE:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_HYPOTHESES_PERCENTAGE:
      return update(state, {
        $merge: {
          isLoading: false,
          hypothesesPercentage: action.hypothesesPercentage,
        },
      });

    case FAIL_LOAD_HYPOTHESES_PERCENTAGE:
      return update(state, { $merge: { isLoading: false } });

    case START_LOAD_EXPERIMENTS_PERCENTAGE:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE:
      return update(state, {
        $merge: {
          isLoading: false,
          experimentsPercentage: action.experimentsPercentage,
        },
      });

    case FAIL_LOAD_EXPERIMENTS_PERCENTAGE:
      return update(state, { $merge: { isLoading: false } });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case START_UPLOAD_PROFILE_PICTURE:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_UPLOAD_PROFILE_PICTURE:
      return update(state, {
        $merge: { isEditing: false, userProfile: action.userProfile },
      });

    case FAIL_UPLOAD_PROFILE_PICTURE:
      return update(state, { $merge: { isEditing: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (userProfile) => ({
  type: COMPLETE_LOAD,
  userProfile,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadHypothesisPercentage = () => ({
  type: START_LOAD_HYPOTHESES_PERCENTAGE,
});

const completeLoadHypothesisPercentage = (hypothesesPercentage) => ({
  type: COMPLETE_LOAD_HYPOTHESES_PERCENTAGE,
  hypothesesPercentage,
});

const failLoadHypothesisPercentage = () => ({
  type: FAIL_LOAD_HYPOTHESES_PERCENTAGE,
});

const startLoadExperimentsPercentage = () => ({
  type: START_LOAD_EXPERIMENTS_PERCENTAGE,
});

const completeLoadExperimentsPercentage = (experimentsPercentage) => ({
  type: COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE,
  experimentsPercentage,
});

const failLoadExperimentsPercentage = () => ({
  type: FAIL_LOAD_EXPERIMENTS_PERCENTAGE,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startUploadProfilePicture = () => ({
  type: START_UPLOAD_PROFILE_PICTURE,
});

const completeUploadProfilePicture = (userProfile) => ({
  type: COMPLETE_UPLOAD_PROFILE_PICTURE,
  userProfile,
});

const failUploadProfilePicture = () => ({
  type: FAIL_UPLOAD_PROFILE_PICTURE,
});

export const loadProfile = () => (dispatch) => {
  dispatch(startLoad());
  const loadProfilePromise = doLoadProfile();
  loadProfilePromise
    .then((res) => dispatch(completeLoad(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoad());
    });
  return loadProfilePromise;
};

export const loadHypothesesPercentage = (teamId) => (dispatch) => {
  dispatch(startLoadHypothesisPercentage());
  const loadHypothesesPercentagePromise = doLoadHypothesesPercentage(teamId);
  loadHypothesesPercentagePromise
    .then((res) => dispatch(completeLoadHypothesisPercentage(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoadHypothesisPercentage());
    });
  return loadHypothesesPercentagePromise;
};

export const loadExperimentsPercentage = (teamId) => (dispatch) => {
  dispatch(startLoadExperimentsPercentage());
  const loadExperimentsPercentagePromise = doLoadExperimentsPercentage(teamId);
  loadExperimentsPercentagePromise
    .then((res) => dispatch(completeLoadExperimentsPercentage(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoadExperimentsPercentage());
    });
  return loadExperimentsPercentagePromise;
};

export const editProfile = (values) => (dispatch) => {
  dispatch(startEdit());
  const editProfilePromise = doEditProfile(values);
  editProfilePromise
    .then(() => {
      dispatch(completeEdit());
      dispatch(notifySuccess('Profilul a fost editat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEdit());
    });
  return editProfilePromise;
};

export const uploadProfilePicture = (image) => (dispatch) => {
  dispatch(startUploadProfilePicture());
  const editProfilePromise = doUploadProfilePicture(image);
  editProfilePromise
    .then((res) => {
      dispatch(completeUploadProfilePicture(res.data));
      dispatch(notifySuccess('Profilul a fost editat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failUploadProfilePicture());
    });
  return editProfilePromise;
};
