import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import { ToastContainer, Slide } from 'react-toastify';

import { AuthRoute, Account, GuestRoute } from './account/components';
import { AdminRouter } from './admin/components';

import Page from './common/components/Page';
import { LoginPage } from './account/components/pages';
import {
  HypothesesPageResolver,
  HypothesisDetailsPageResolver,
  EmpathyMapPageResolver,
} from './hypotheses/components/pages';
import {
  ExperimentsPageResolver,
  ExperimentDetailsPageResolver,
} from './experiments/components/pages';
import { LeaderboardPageResolver, MyTeamsPage } from './teams/components/pages';
import { BusinessModelCanvasPageResolver } from './canvas/components';
import { TeamProfilePageResolver } from './team/components/pages';
import { HypothesisDetailPageResolver } from './hypothesisDetails/components/pages';
import { ExperimentDetailPageResolver } from './experimentDetails/components/pages';
import {
  HypothesisResolutionPageResolver,
  ExperimentResolutionPageResolver,
} from './resolutions/pages';
import {
  MentorsPageResolver,
  MentorsInvitationsPageResolver,
} from './mentors/components/pages';
import { ProfilePageResolver } from './userProfile/components/pages';
import {
  CohortsPageResolver,
  CohortTeamsPageResolver,
  TeamCanvasPageResolver,
  TeamHypothesesPageResolver,
  TeamExperimentsPageResolver,
  TeamProfilePageResolver as CohortTeamProfilePageResolver,
  CohortLeaderboardPageResolver,
} from './accelerator/components/pages';
import { DetailsPageResolver } from './details/components';
import { KPIPageResolver } from './kpis/components';

const ProtectedRouter = () => (
  <Page>
    <AuthRoute path="/" exact component={() => <></>} />
    <AuthRoute path="/canvas" component={BusinessModelCanvasPageResolver} />
    <AuthRoute path="/hypotheses" component={HypothesesPageResolver} />
    <AuthRoute path="/experiments" component={ExperimentsPageResolver} />
    <AuthRoute path="/leaderboard" component={LeaderboardPageResolver} />
    <AuthRoute path="/team-profile" component={TeamProfilePageResolver} />
    <AuthRoute path="/teams" component={MyTeamsPage} />
    <AuthRoute path="/mentors" component={MentorsPageResolver} />
    <AuthRoute path="/profile" component={ProfilePageResolver} />
    <AuthRoute
      path="/mentor-invitations"
      component={MentorsInvitationsPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId"
      exact
      component={HypothesisDetailsPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId/resolution/:resolutionId"
      exact
      component={HypothesisResolutionPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId/experiment/:experimentId/resolution/:resolutionId"
      exact
      component={ExperimentResolutionPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId/empathy-map"
      exact
      component={EmpathyMapPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId/experiment/:experimentId"
      exact
      component={ExperimentDetailsPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId/experiment/:experimentId/detail/:detailId"
      exact
      component={ExperimentDetailPageResolver}
    />
    <AuthRoute
      path="/hypothesis/:hypothesisId/detail/:detailId"
      exact
      component={HypothesisDetailPageResolver}
    />
    <AuthRoute path="/cohorts" exact component={CohortsPageResolver} />
    <AuthRoute
      path="/accelerator/:cohortId/teams"
      exact
      component={CohortTeamsPageResolver}
    />
    <AuthRoute
      path="/accelerator/:cohortId/teams/:teamId/profile"
      exact
      component={CohortTeamProfilePageResolver}
    />
    <AuthRoute
      path="/accelerator/:cohortId/teams/:teamId/canvas"
      exact
      component={TeamCanvasPageResolver}
    />
    <AuthRoute
      path="/accelerator/:cohortId/teams/:teamId/hypotheses"
      exact
      component={TeamHypothesesPageResolver}
    />
    <AuthRoute
      path="/accelerator/:cohortId/teams/:teamId/experiments"
      exact
      component={TeamExperimentsPageResolver}
    />
    <AuthRoute
      path="/accelerator/:cohortId/leaderboard"
      exact
      component={CohortLeaderboardPageResolver}
    />
    <AuthRoute path="/details" exact component={DetailsPageResolver} />
    <AuthRoute path="/kpis" exact component={KPIPageResolver} />
  </Page>
);

const AppRouter = () => (
  <>
    <Switch>
      <GuestRoute path="/" exact component={LoginPage} />
      <Route path="/account" component={Account} />
      <AuthRoute path="/admin" component={AdminRouter} />

      <Route component={ProtectedRouter} />
    </Switch>

    <ToastContainer transition={Slide} closeButton={false} />
  </>
);

export default withRouter(AppRouter);
