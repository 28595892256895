import styled, { css } from 'styled-components';
import { ReactComponent as SegmentIcon } from '../../../common/assets/icons/Menu_Profilsegment.svg';

export const HypothesisCardContainer = styled.div`
  ${(props) =>
    props.floatRight &&
    css`
      float: right;
    `}
  min-height: 70px;
  position: relative;
  max-width: 100%;

  ${(props) =>
    props.empathy &&
    css`
      width: 100%;
    `}

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.customerSegment &&
    css`
      flex-direction: row;
    `}
  width: 100%;
  cursor: pointer;

  ${(props) =>
    props.experiment &&
    css`
      background: #f7f7f8;
      min-height: 76px;
      padding: 8px 5px;
      justify-content: space-between;
    `}
`;
export const HypothesisColorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
export const SegmentCardIcon = styled(SegmentIcon)`
  width: 17px;
  height: 17px;
  float: right;
`;
export const HypothesisColor = styled.div`
  border-radius: 2px;
  width: 20px;
  height: 5px;
  background-color: ${(props) =>
    !!props.backgroundColor && props.backgroundColor};
`;
export const ColorColumnDiv = styled.div`
  background-color: ${(props) =>
    !!props.backgroundColor && props.backgroundColor};
  height: 100%;
  width: 5px;
  border-radius: 2px;
  float: right;
  margin-left: 5px;
`;

export const HypothesisCardIconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 40px;
  height: 45px;
`;
export const HypothesisTitle = styled.p`
  width: 75%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 7px !important;
  font-size: 14px;
  line-height: 19px;
  height: 38px;
  color: ${(props) => props.theme.text};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-decoration: ${(props) =>
    props.decorationThrough ? 'line-through #2d9bf0' : null};
  :hover {
    -webkit-line-clamp: initial;
    white-space: normal;
    height: auto;
  }
`;
