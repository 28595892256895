export const evidenceOptions = [
  {
    value: 'solid',
    label: 'solide',
  },
  {
    value: 'weak',
    label: 'slabe',
  },
  {
    value: 'noProof',
    label: 'fara Dovezi',
  },
];

export const importanceOptions = [
  {
    value: 'high',
    label: 'mare',
  },
  {
    value: 'medium',
    label: 'medie',
  },
  {
    value: 'low',
    label: 'mică',
  },
];
export const categoryOptions = [
  {
    value: 'dezirabila',
    label: 'dezirabilă',
  },
  {
    value: 'viabila',
    label: 'viabilă',
  },
  {
    value: 'fezabila',
    label: 'fezabilă',
  },
];

export const colorOptions = [
  '#FF0000',
  '#FF7F00',
  '#FFFF00',
  '#00FF00',
  '#0000FF',
  '#2E2B5F',
  '#8B00FF',
];
