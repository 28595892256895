/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { Card, Button } from 'react-bootstrap';

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  padding: 6px 15px;
  padding-bottom: 14px;
  margin-bottom: 20px;
`;

export const TableRow = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  padding: 6px 15px;
  height: 50px;
  display: flex;
  align-items: center;
`;
export const TableData = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: ${(props) => props.marginLeft};
`;

export const TableHeading = styled.div`
  text-align: center;
  width: ${(props) => props.cellWidth};
`;

export const StyledCard = styled(Card)`
  width: 100%;
  margin: 0 auto;
  max-width: 1250px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  h2 {
    color: #2ad4c3;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const TableBody = styled.tbody`
  display: block;
  max-height: 500px;
  overflow-y: scroll;
  margin-right: -15px;
`;

export const TableHeadCell = styled.div`
  cursor: pointer;
  justify-content: ${(props) => (props.alignLeft ? 'flex-start' : 'center')};
  ${(props) =>
    props.alignRight &&
    css`
      justify-content: flex-end;
    `}
  &:after {
    content: '${(props) =>
      props.indicator ? (props.reverse ? '   ⇧' : '   ⇩') : ''}';
  }
  width: ${(props) => props.cellWidth};
  display: flex;
  min-width: ${(props) => props.cellWidth};
`;

export const TableBodyCell = styled.div`
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  ${(props) =>
    props.alignRight &&
    css`
      text-align: right;
    `}
  width: ${(props) => props.cellWidth};
  min-width: ${(props) => props.cellWidth};
`;

export const TableLinkButton = styled(Button)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: hidden;
`;
