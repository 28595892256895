/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from 'react';
import { map, size, get, find } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import moment from 'moment';
import { dataOptions, actionOptions } from '../../../resolutions/constants';
import { Export, SortTableHead } from '../../../common/components';
import { ReactComponent as ListViewIcon } from '../../../common/assets/icons/list-icon.svg';
import { ReactComponent as TableIcon } from '../../../common/assets/icons/table-icon.svg';

import ExperimentsListViewComponent from '../ExperimentsListViewComponent';

import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  LinkTable,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { StyledRow } from '../styled';
import { getExperimentIcon } from './ExperimentDetailsPage';

const ExperimentsPage = (props) => {
  const { experiments, push } = props;

  const [sortedExperiments, setSortedExperiments] = useState(experiments);

  const [listView, setListView] = useState(false);

  const handleDataOptions = (value, options) =>
    get(
      find(options, {
        value,
      }),
      'label'
    );

  const handleOutcome = (status) => {
    switch (status) {
      case 'like':
        return 'Valideaza ipoteza';
      case 'dislike':
        return 'Invalideaza ipoteza';
      case 'inProgress':
        return 'Neconcludent';
      default:
        return 'Neconcludent';
    }
  };

  const getExperimentIconText = (outcome) => {
    switch (outcome) {
      case 'inProgress':
        return 'In Progress';
      case 'like':
        return 'Valideaza Ipoteza';
      case 'dislike':
        return 'Invalideaza Ipoteza';
      default:
        return 'In Progress';
    }
  };

  const getDownloadData = useMemo(
    () =>
      map(sortedExperiments, (experiment, index) => ({
        '#': index + 1,
        Titlu: experiment.title,
        Stadiu: experiment.status,
        Concluzie: getExperimentIconText(
          experiment.resolutions[0] && experiment.resolutions[0].outcome
        ),
        'Cost estimat (€)': experiment.estimatedPrice,
        Termen: experiment.deadline
          ? moment(experiment.deadline).format('DD/MM/YYYY')
          : '-',
        'Încredere în date ':
          experiment.resolutions[0] && experiment.resolutions[0].dataReliability
            ? handleDataOptions(
                experiment.resolutions[0] &&
                  experiment.resolutions[0].dataReliability,
                dataOptions
              )
            : '-',
        'Tip acțiune':
          experiment.resolutions[0] && experiment.resolutions[0].actionRequired
            ? handleDataOptions(
                experiment.resolutions[0].actionRequired,
                actionOptions
              )
            : '-',
      })),
    [sortedExperiments]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Experimente</Title>
          <CounterDiv>
            <CounterText>{size(sortedExperiments)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <div style={{ marginRight: '42px', display: 'flex' }}>
          <Export
            withMargin={false}
            data={getDownloadData}
            filename="experimente.csv"
          />
          <div
            onClick={() => setListView(!listView)}
            style={{
              display: 'flex',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
          >
            {listView ? (
              <TableIcon style={{ height: '32px' }} />
            ) : (
              <ListViewIcon />
            )}
          </div>
        </div>
      </TitleButtonsDiv>
      {listView ? (
        <StyledRow>
          <ExperimentsListViewComponent
            experiments={experiments}
            filterStatus="În constructie"
          />
          <ExperimentsListViewComponent
            experiments={experiments}
            filterStatus="În derulare"
          />

          <ExperimentsListViewComponent
            experiments={experiments}
            filterStatus="Finalizat"
          />

          <ExperimentsListViewComponent
            resolutionExperiments
            experiments={experiments}
          />
        </StyledRow>
      ) : (
        <>
          <SortTableHead
            array={sortedExperiments}
            setArray={setSortedExperiments}
            cellSortKeys={[
              null,
              ['title'],
              ['status'],
              (el) => handleOutcome(el.resolution.outcome),
              ['estimatedPrice'],
              ['deadline'],
              ['resolution.dataReliability'],
              ['resolution.actionRequired'],
            ]}
            defaultSortKey={['title']}
          >
            <TableHeadCell alignLeft cellWidth="2%">
              <Heading2 center>#</Heading2>
            </TableHeadCell>
            <TableHeadCell alignLeft cellWidth="28%">
              <Heading2 center>Titlu</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Stadiu</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Concluzie</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="8%">
              <Heading2 center>Cost estimat (€)</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Termen</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="22%">
              <Heading2 center>Tip acțiune</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Încredere în date </Heading2>
            </TableHeadCell>
          </SortTableHead>
          {map(sortedExperiments, (experiment, index) => (
            <TableRow key={index}>
              <TableBodyCell alignLeft cellWidth="2%">
                <Heading4Regular>{index + 1}</Heading4Regular>
              </TableBodyCell>
              <TableBodyCell alignLeft cellWidth="28%">
                <LinkTable
                  maxWidth="95%"
                  onClick={() =>
                    push(
                      `/hypothesis/${
                        experiment.hypothesisId &&
                        experiment.hypothesisId[0] &&
                        experiment.hypothesisId[0]._id
                      }/experiment/${experiment._id}`
                    )
                  }
                >
                  {experiment.title}
                </LinkTable>
              </TableBodyCell>
              <TableBodyCell cellWidth="10%">
                <Heading4Regular noEllipsis>
                  {getExperimentIcon('', experiment.status)}
                </Heading4Regular>
              </TableBodyCell>
              <TableBodyCell cellWidth="10%">
                <Heading4Regular noEllipsis>
                  {getExperimentIcon(
                    experiment.resolutions[0]
                      ? experiment.resolutions[0].outcome
                      : 'neutral'
                  )}
                </Heading4Regular>
              </TableBodyCell>
              <TableBodyCell cellWidth="8%">
                <Heading4Regular noEllipsis>
                  {experiment.estimatedPrice}
                </Heading4Regular>
              </TableBodyCell>

              <TableBodyCell cellWidth="10%">
                <Heading4Regular noEllipsis>
                  {experiment.deadline
                    ? moment(experiment.deadline).format('DD/MM/YYYY')
                    : '-'}
                </Heading4Regular>
              </TableBodyCell>
              <TableBodyCell cellWidth="22%">
                <Heading4Regular noEllipsis>
                  {experiment.resolutions[0] &&
                    experiment.resolutions[0].actionRequired &&
                    handleDataOptions(
                      experiment.resolutions[0] &&
                        experiment.resolutions[0].actionRequired,
                      actionOptions
                    )}
                </Heading4Regular>
              </TableBodyCell>
              <TableBodyCell cellWidth="10%">
                <Heading4Regular noEllipsis>
                  {experiment.resolutions[0] &&
                    experiment.resolutions[0].dataReliability &&
                    handleDataOptions(
                      experiment.resolutions[0].dataReliability,
                      dataOptions
                    )}
                </Heading4Regular>
              </TableBodyCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  experiments: state.experiments.experiments,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExperimentsPage)
);
