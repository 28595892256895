import React from 'react';
import { Switch } from 'react-router';
import AuthRoute from './AuthRoute';
import GuestRoute from './GuestRoute';
import {
  LoginPage,
  RequestPasswordResetPage,
  PasswordResetEmailSentPage,
  ResetPasswordPage,
  LogoutPage,
  RegisterPage,
} from './pages';

const Account = () => (
  <Switch>
    <GuestRoute exact path="/account/login" component={LoginPage} />
    <GuestRoute exact path="/account/register" component={RegisterPage} />
    <GuestRoute
      exact
      path="/account/forgot-password"
      component={RequestPasswordResetPage}
    />
    <GuestRoute
      exact
      path="/account/password-reset-email-sent"
      component={PasswordResetEmailSentPage}
    />
    <GuestRoute
      exact
      path="/account/password-reset"
      component={ResetPasswordPage}
    />
    <AuthRoute exact path="/account/logout" component={LogoutPage} />
  </Switch>
);

export default Account;
