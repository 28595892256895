import { get, filter } from 'lodash-es';
import { http } from '../../core/services/http';

export const addExperimentsDetailComment = (values, experimentDetailId) => {
  const registerData = {
    ...values,
    experimentDetailId,
  };
  return http.post('/experiment-detail-comment/add', registerData);
};

export const deleteExperimentDetailComment = (experimentDetailCommentId) =>
  http.delete(`/experiment-detail-comment/delete/${experimentDetailCommentId}`);

export const editExperimentDetailComment = (
  values,
  experimentDetailCommentId
) =>
  http.put(
    `/experiment-detail-comment/edit/${experimentDetailCommentId}`,
    values
  );

export const deleteExperimentDetail = (experimentDetailId) =>
  http.delete(`/experiment-detail/delete/${experimentDetailId}`);

export const addExperimentDetail = (values, createdBy, parentId) => {
  const registerValues = {
    ...values,
    resources: filter(values.resources, (elem) => elem !== ''),
    createdBy,
    experimentId: parentId,
  };

  return http.post('/experiment-detail/add', registerValues);
};

export const editExperimentDetail = (
  values,
  entityId,
  parent,
  personResponsible
) => {
  const registerValues = {
    ...values,
    resources: filter(values.resources, (elem) => elem !== ''),
  };
  const registerDataResolution = {
    ...parent,
    resolution: {
      ...values.resolution,
      personResponsible,
      actionRequired:
        values.resolution && get(values.resolution.actionRequired, 'value'),
      dataReliability:
        values.resolution && get(values.resolution.dataReliability, 'value'),
    },
  };
  const registerData =
    parent === undefined ? registerValues : registerDataResolution;

  return http.put(`/experiment-detail/edit/${entityId}`, registerData);
};

export const loadExperimentDetail = (experimentDetailId) =>
  http.get(`/experiment-detail/get/${experimentDetailId}`);

export const addExperimentDetailCommentReply = (
  values,
  experimentDetailCommentId
) =>
  http.post(
    `/experiment-detail-comment/reply/add/${experimentDetailCommentId}`,
    values
  );

export const editExperimentDetailCommentReply = (
  values,
  experimentDetailCommentId,
  replyId
) =>
  http.put(
    `/experiment-detail-comment/reply/edit/${experimentDetailCommentId}/${replyId}`,
    values
  );

export const deleteExperimentDetailCommentReply = (
  experimentDetailCommentId,
  replyId
) =>
  http.delete(
    `/experiment-detail-comment/reply/delete/${experimentDetailCommentId}/${replyId}`
  );
