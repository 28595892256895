import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Modal, Row } from 'react-bootstrap';
import { map, size, find, get } from 'lodash-es';
import moment from 'moment';
import { push } from 'connected-react-router';
import EditIconLarge from '../../common/assets/icons/Button_Edit_Large.svg';
import EditIconLargeHover from '../../common/assets/icons/Button_Edit_Large_Hover.svg';
import { isMentorSelector, isAcceleratorSelector } from '../../account/ducks';
import { ReactComponent as ReplyIconMessage } from '../../common/assets/icons/Reply.svg';
import { dataOptions, actionOptions } from '../constants';

import {
  TextInactive,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  Loading,
  PlaceHolderInput,
  DeleteButton,
  AddCommentButton,
  DeleteCommentButton,
  WhiteCard,
  RightSection,
  EditButtonLarge,
  LeftSection,
  Link,
  RowDiv,
} from '../../common/components/styled';
import { AddResolutionModal } from '../components/modals';
import {
  loadExperimentResolution,
  editExperimentResolution,
  deleteExperimentResolution,
  addExperimentResolutionComment,
  editExperimentResolutionComment,
  deleteExperimentResolutionComment,
  editExperimentResolutionCommentReply,
  deleteExperimentResolutionCommentReply,
  addExperimentResolutionCommentReply,
} from '../ducks';

import {
  AcceptButton,
  TitleButtonsDiv,
  DivBorderBottom,
  ButtonText,
} from '../../mentors/components/styled';
import { AddCommentModal } from '../../common/components/modals';
import { CommentRenderComponent } from '../../common/components';
import { getExperimentIcon } from '../../experiments/components/pages/ExperimentDetailsPage';
import { sizeComments } from '../../common/functions';

const AlertMessage = ({ show, setShow, onSubmit }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit();
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere concluzie</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea concluziei?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HypothesisResolutionPage = (props) => {
  const {
    resolution,
    isMentor,
    isAccelerator,
    loadExperimentResolution,
    editExperimentResolution,
    addExperimentResolutionComment,
    editExperimentResolutionComment,
    isCreating,
    isEditing,
    deleteExperimentResolution,
    isLoading,
    isDeleting,
    deleteExperimentResolutionComment,
    editExperimentResolutionCommentReply,
    deleteExperimentResolutionCommentReply,
    addExperimentResolutionCommentReply,
    push,
    match: {
      params: { hypothesisId, resolutionId },
    },
  } = props;

  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isEditModeComment, setIsEditModeComment] = useState(false);
  const [comment, setComment] = useState(undefined);

  const handleDataOptions = (value, options) =>
    get(
      find(options, {
        value,
      }),
      'label'
    );

  const [hovered, setHovered] = useState(false);
  const handleHookSet = (type) => {
    switch (type) {
      case 'resolution':
        setIsResolutionModalOpen(true);
        break;
      case 'comment':
        setIsCommentModalOpen(true);
        break;
      default:
        setIsResolutionModalOpen(false);
        setIsCommentModalOpen(false);
    }
  };

  const handleOpenEditCommentModal = (type, commentId) => {
    handleHookSet(type);
    setComment(commentId);
    setIsEditModeComment(true);
  };

  const handleDeleteComment = async (commentId) => {
    await deleteExperimentResolutionComment(commentId);
    await loadExperimentResolution(resolutionId);
  };

  const handleCardClick = (url) => push(url);

  const handleOutcomeText = (outcome) => {
    switch (outcome) {
      case 'like':
        return 'Considerăm că experimentul confirmă ipoteza.';
      case 'dislike':
        return 'Considerăm că experimentul infirmă ipoteza.';
      default:
        return '';
    }
  };

  const handleDeleteResolution = async () => {
    await deleteExperimentResolution(resolution._id);
    push(`/hypothesis/${hypothesisId}`);
  };

  return (
    <>
      <div>
        <div>
          <TitleButtonsDiv paddingRight marginBottom="15px">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {getExperimentIcon(resolution.outcome)}
              <Title marginLeft="15px" width="80%">
                {resolution.conclusion}{' '}
              </Title>
            </div>
            <div>
              {!isMentor && !isAccelerator && (
                <>
                  <EditButtonLarge
                    src={hovered ? EditIconLargeHover : EditIconLarge}
                    onClick={() => handleHookSet('resolution')}
                    onMouseEnter={() => setHovered(!hovered)}
                    onMouseLeave={() => setHovered(!hovered)}
                  />
                  <DeleteButton onClick={() => setOpenAlertMessage(true)} />
                </>
              )}
            </div>
          </TitleButtonsDiv>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', width: '70%' }}
            >
              <Heading2>Ipoteze:</Heading2>
              {map(resolution.experimentId[0].hypothesisId, (hypothesis) => (
                <PlaceHolderInput
                  key={hypothesis._id}
                  hypothesisCard
                  maxWidth="30%"
                  onClick={() =>
                    handleCardClick(`/hypothesis/${hypothesis._id}`)
                  }
                >
                  <Heading4Regular>{hypothesis.title}</Heading4Regular>
                </PlaceHolderInput>
              ))}
            </div>
            <Heading4Regular inactive>{`${
              resolution.personResponsible
            } ${moment(resolution.createdAt).format(
              'DD/MM/YYYY  H:mm'
            )}`}</Heading4Regular>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '30px',
              paddingLeft: '30px',
            }}
          >
            <ReplyIconMessage />
            <Heading2 marginLeft="15px">Experiment:</Heading2>
            <AcceptButton
              dashed
              maxWidth="100%"
              onClick={() =>
                handleCardClick(
                  `/hypothesis/${hypothesisId}/experiment/${resolution.experimentId[0]._id}`
                )
              }
            >
              <Heading4Regular>
                {resolution.experimentId[0].title}
              </Heading4Regular>
            </AcceptButton>
          </div>
        </div>

        <RowDiv>
          <LeftSection>
            <WhiteCard height="auto" borderRadius="3px" padding="20px">
              <Title marginBottom="20px" fontSize="24px" lineHeight="28px">
                Concluzie experiment
              </Title>
              <Heading2 marginBottom="20px">Sumar concluzie</Heading2>

              <Heading4Regular marginBottom="30px" textArea noEllipsis>
                {resolution.conclusion}
              </Heading4Regular>
              <Heading2 marginBottom="20px">Observatii*</Heading2>

              <Heading4Regular marginBottom="30px" textArea noEllipsis>
                {resolution.observations}
              </Heading4Regular>
              <Row>
                <Col md={6}>
                  <Heading2 marginBottom="20px">
                    Considerăm datele obținute ca fiind:
                  </Heading2>
                  <Heading4Regular marginBottom="30px" textArea noEllipsis>
                    {handleDataOptions(resolution.dataReliability, dataOptions)}
                  </Heading4Regular>
                </Col>
                <Col md={6}>
                  <Heading2 marginBottom="20px">
                    În baza concluziei estimăm o acțiune:
                  </Heading2>
                  {handleDataOptions(resolution.actionRequired, actionOptions)}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Link-uri
                  </Heading2>

                  {size(resolution.nextSteps) !== 0 ? (
                    map(resolution.nextSteps, (link, index) => (
                      <Link
                        style={{ display: 'block', marginBottom: '10px' }}
                        key={index}
                        maxWidth="100%"
                        href={link}
                        target="_blank"
                      >
                        {link}
                      </Link>
                    ))
                  ) : (
                    <Heading4Regular inactive>https://</Heading4Regular>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Heading2 marginBottom="20px" marginTop="30px">
                    Concluzie
                  </Heading2>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getExperimentIcon(resolution.outcome)}
                    {resolution.outcome !== 'inProgress' && (
                      <Heading4Regular marginLeft="10px">
                        {handleOutcomeText(resolution.outcome)}
                      </Heading4Regular>
                    )}
                  </div>
                </Col>
              </Row>
            </WhiteCard>
          </LeftSection>
          <RightSection>
            <WhiteCard
              noOverflow
              height="100%"
              borderRadius="3px"
              padding="20px"
              style={{ height: '413px' }}
            >
              <TitleButtonsDiv marginBottom="39px">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title fontSize="24px" lineHeight="28px">
                    Comentarii
                  </Title>
                  <CounterDiv>
                    <CounterText>
                      {sizeComments(resolution.comments)}
                    </CounterText>
                  </CounterDiv>
                </div>
                <AddCommentButton onClick={() => handleHookSet('comment')} />
              </TitleButtonsDiv>
              {!size(resolution.comments) > 0 && (
                <TextInactive>
                  Momentan nu există niciun comentariu pentru această secțiune.
                  Poți adăuga un comentariu făcând click pe butonul „+” din
                  partea dreaptă a secțiunii.
                </TextInactive>
              )}
              <div
                style={{
                  maxHeight: '85%',
                  overflow: 'auto',
                  paddingRight: '4px',
                }}
                className="scrollbar-modal"
              >
                {map(resolution.comments, (comment, index) => (
                  <CommentRenderComponent
                    key={index}
                    comment={comment}
                    handleDeleteComment={handleDeleteComment}
                    handleEdit={() =>
                      handleOpenEditCommentModal('comment', comment)
                    }
                    handleAddReply={addExperimentResolutionCommentReply}
                    handleEditReply={(values, replyId) =>
                      editExperimentResolutionCommentReply(
                        values,
                        comment._id,
                        replyId
                      )
                    }
                    handleDeleteReply={(replyId) =>
                      deleteExperimentResolutionCommentReply(
                        comment._id,
                        replyId
                      )
                    }
                    loadData={() => loadExperimentResolution(resolutionId)}
                    isLoading={isEditModeComment ? isEditing : isCreating}
                  />
                ))}
              </div>
            </WhiteCard>
          </RightSection>
        </RowDiv>
      </div>

      <AddResolutionModal
        isModalOpen={isResolutionModalOpen}
        setIsModalOpen={setIsResolutionModalOpen}
        isLoading={isEditing}
        isExperiment
        loadData={() => loadExperimentResolution(resolutionId)}
        entityId={resolution._id}
        resolution={resolution}
        onSubmitResolution={editExperimentResolution}
        isEditingResolution
      />

      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteResolution}
      />
      <AddCommentModal
        isModalOpen={isCommentModalOpen}
        setIsModalOpen={setIsCommentModalOpen}
        parentId={resolution._id}
        onSubmit={addExperimentResolutionComment}
        isLoading={isEditModeComment ? isEditing : isCreating}
        loadData={() => loadExperimentResolution(resolutionId)}
        isEditModeComment={isEditModeComment}
        comment={isEditModeComment ? comment : undefined}
        setIsEditModeComment={setIsEditModeComment}
        editComment={editExperimentResolutionComment}
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.resolution.isDeleting,
  isCreating: state.resolution.isCreating,
  isEditing: state.resolution.isEditing,
  isLoading: state.resolution.isLoading,
  resolution: state.resolution.resolution,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});

const mapDispatchToProps = {
  loadExperimentResolution,
  editExperimentResolution,
  deleteExperimentResolution,
  addExperimentResolutionComment,
  editExperimentResolutionComment,
  deleteExperimentResolutionComment,
  editExperimentResolutionCommentReply,
  deleteExperimentResolutionCommentReply,
  addExperimentResolutionCommentReply,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HypothesisResolutionPage)
);
