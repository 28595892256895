import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { AddIndustryForm } from '../forms';
import { currentTeamIdSelector } from '../../../common/ducks';
import {
  addOpportunityAnalysis,
  editOpportunityAnalysis,
  loadOpportunityAnalysis,
} from '../../ducks';

import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddIndustryModal = (props) => {
  const {
    isModalOpen,
    isCreating,
    isEditing,
    setIsModalOpen,
    teamId,
    opportunityAnalysis,
    addOpportunityAnalysis,
    editOpportunityAnalysis,
    loadOpportunityAnalysis,
  } = props;
  const onSubmit = async (values) => {
    if (Object.keys(opportunityAnalysis).length < 2) {
      await addOpportunityAnalysis(values);
    } else {
      await editOpportunityAnalysis(values, opportunityAnalysis._id);
    }

    setIsModalOpen(false);
    await loadOpportunityAnalysis(teamId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">Analiza de oportunitate – industria</Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <AddIndustryForm onSubmit={onSubmit} />
      </Modal.Body>

      <Loading isLoading={isCreating || isEditing} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  opportunityAnalysis: state.opportunityAnalysis.opportunityAnalysis,
  isCreating: state.opportunityAnalysis.isCreating,
  isEditing: state.opportunityAnalysis.isEditing,
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  addOpportunityAnalysis,
  editOpportunityAnalysis,
  loadOpportunityAnalysis,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddIndustryModal)
);
