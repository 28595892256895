import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { get, filter, map, includes, find, isObject, size } from 'lodash-es';
import { Formik, useField, FieldArray, useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { Col, Form } from 'react-bootstrap';
import { Dropdown } from '../../../core/components';
import {
  colorOptions,
  categoryOptions,
  importanceOptions,
} from '../../constants';
import { BMC_OPTIONS } from '../../../canvas/constants';
import {
  Input,
  Heading2,
  SubmitButton,
  AddCommentButton,
  MinusButton,
  ButtonDiv,
  SaveIcon,
  LabelButtonContainer,
  PositionParent,
} from '../../../common/components/styled';

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderTitleField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDescriptionFieldField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="100px"
        as={type}
        ref={fieldRef}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderImportanceField = (props) => {
  const { values, setFieldValue, controlId, name, label, placeholder } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={Dropdown}
        fieldRef={fieldRef}
        placeholder={placeholder}
        name={name}
        options={importanceOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('importance', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderColumnBmcField = (props) => {
  const {
    values,
    setFieldValue,
    controlId,
    name,
    label,
    setAddColorProperty,
    setShowSegmentField,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  const handleCategoryFieldValue = (type) => {
    let value;
    switch (type) {
      case 'customerSegments':
      case 'customerRelationships':
      case 'valuePropositions':
      case 'channels':
      case 'revenueStreams':
        value = 'dezirabila';
        break;
      case 'keyResources':
      case 'keyActivities':
      case 'keyPartners':
        value = 'viabila';
        break;
      case 'costStructure':
        value = 'fezabila';
        break;
      default:
        value = '';
    }
    const returnedValue = find(categoryOptions, {
      value,
    });

    return returnedValue;
  };

  useEffect(() => {
    setFieldValue(
      'hypothesisCategory',
      handleCategoryFieldValue(fieldValue.value)
    );
  }, [fieldValue, setFieldValue]);

  useEffect(() => {
    if (fieldValue.value === 'customerSegments') {
      setAddColorProperty(true);
      setShowSegmentField(false);
    } else {
      setAddColorProperty(false);
      setShowSegmentField(true);
    }
  }, [fieldValue, setAddColorProperty, setShowSegmentField]);

  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        as={Dropdown}
        fieldRef={fieldRef}
        placeholder={placeholder}
        name={name}
        options={BMC_OPTIONS}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('columnBMC', v);
          setFieldValue(
            'hypothesisCategory',
            handleCategoryFieldValue(v.value)
          );
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderHypothesisCategoryField = (props) => {
  const { values, setFieldValue, controlId, name, label } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  return (
    <Form.Group
      style={{ display: 'none' }}
      as={Col}
      md="6"
      controlId={controlId}
    >
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        placeholder={label}
        name={name}
        disabled
        options={categoryOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('hypothesisCategory', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderSegmentField = (props) => {
  const {
    values,
    setFieldValue,
    controlId,
    name,
    label,
    segmentOptions,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });

  const fieldValue = get(values, name);

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue('segment', []);
    }
  };
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        placeholder={placeholder}
        name={name}
        isMulti
        options={segmentOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('segment', v);
          handleNullValue(v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderKPIsField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,
    showPlusButton,
    setShowPlusButton,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    if (size(values.KPIIndicators) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.KPIIndicators, setShowPlusButton]);
  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <LabelButtonContainer style={{ width: '100%' }} marginBottom="10px">
              <Heading2>Indicatori și valoare țintă</Heading2>
              {showPlusButton && (
                <AddCommentButton onClick={() => arrayHelpers.push('')} />
              )}
            </LabelButtonContainer>
            {values.KPIIndicators.map((KPI, index) => (
              <Form.Row
                key={index}
                style={{ width: '100%', margin: '0 0 10px 0' }}
              >
                <PositionParent style={{ display: 'flex' }} width="93%">
                  <Form.Group
                    as={Col}
                    md="7"
                    style={{ display: 'flex', paddingLeft: '0' }}
                  >
                    <Input
                      link
                      type={type}
                      placeholder={placeholder}
                      name={`KPIIndicators.${index}.title`}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={get(values.KPIIndicators[index], 'title')}
                    />
                  </Form.Group>

                  <Form.Group
                    style={{ display: 'flex', paddingRight: '0' }}
                    as={Col}
                    md="5"
                  >
                    <Input
                      type={type}
                      placeholder="Valoarea indicatorului"
                      name={`KPIIndicators.${index}.targetValue`}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={get(values.KPIIndicators[index], 'targetValue')}
                    />
                  </Form.Group>
                </PositionParent>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '7%',
                  }}
                >
                  {values.KPIIndicators.length > 1 && (
                    <MinusButton
                      marginTop="10px"
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  )}
                </div>
              </Form.Row>
            ))}
          </>
        )}
      />
    </>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  setFieldValue,
  setAddColorProperty,
  segmentOptions,
  showPlusButton,
  setShowPlusButton,
  showSegmentField,
  setShowSegmentField,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderTitleField
        placeholder="Titlul ipotezei"
        name="title"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Titlul ipotezei*"
      />
      <RenderHypothesisCategoryField
        values={values}
        setFieldValue={setFieldValue}
        name="hypothesisCategory"
        label="Categorie ipoteză"
      />
    </Form.Row>
    <Form.Row>
      <RenderDescriptionFieldField
        placeholder="Descrie pe scurt ipoteza pe care vrei să o testezi"
        name="description"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Descriere*"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <RenderColumnBmcField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik03"
        name="columnBMC"
        label="Se referă la*"
        placeholder="Alege minim o secțiune din BMC"
        setAddColorProperty={setAddColorProperty}
        setShowSegmentField={setShowSegmentField}
      />

      <RenderImportanceField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik04"
        name="importance"
        label="Importanța*"
        placeholder="Alege importanța ipotezei"
      />
    </Form.Row>
    <Form.Row>
      {showSegmentField && (
        <RenderSegmentField
          values={values}
          setFieldValue={setFieldValue}
          controlId="validationFormik05"
          name="segment"
          label="Segmente vizate*"
          placeholder="Segmente vizate"
          segmentOptions={segmentOptions}
        />
      )}
    </Form.Row>
    <Form.Row>
      <Col md="12">
        <RenderKPIsField
          values={values}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
          md="12"
          name="KPIIndicators"
          placeholder="Cum putem măsura valoarea și succesul?"
          handleChange={handleChange}
          controlId={`validationFormik0${showSegmentField ? '6' : '5'}`}
        />
      </Col>
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddHypothesisForm = (props) => {
  const { onSubmit, hypotheses, isEditMode, hypothesis, defaultColumnBMC } =
    props;
  const [addColorProperty, setAddColorProperty] = useState(false);
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [showSegmentField, setShowSegmentField] = useState(false);

  const segmentHypothesis = filter(hypotheses, {
    columnBMC: 'customerSegments',
  });
  const segmentOptions = map(segmentHypothesis, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
  }));

  const defaultHypothesis = {
    ...hypothesis,
    segment:
      hypothesis &&
      filter(segmentOptions, (elem) =>
        includes(map(hypothesis.segment), elem.value)
      ),
    hypothesisCategory:
      hypothesis &&
      find(categoryOptions, {
        value: hypothesis.hypothesisCategory,
      }),
    importance:
      hypothesis &&
      find(importanceOptions, {
        value: hypothesis.importance,
      }),
    KPIIndicators:
      hypothesis &&
      hypothesis.KPIIndicators &&
      size(hypothesis.KPIIndicators) !== 0
        ? hypothesis.KPIIndicators
        : [{ title: undefined, targetValue: undefined }],
    columnBMC: hypothesis && find(BMC_OPTIONS, { value: hypothesis.columnBMC }),
  };

  const getRandomColor = (currentColors) => {
    const randomColors = filter(
      colorOptions,
      (el) => !includes(currentColors, el)
    );
    const randomColor =
      size(randomColors) === 0
        ? colorOptions[Math.floor(Math.random() * colorOptions.length)]
        : randomColors[Math.floor(Math.random() * randomColors.length)];

    return randomColor;
  };

  const currentColors = map(hypotheses, 'color');

  const handleSubmit = (values, { resetForm }) => {
    const newValues = {
      ...values,
      color: addColorProperty ? getRandomColor(currentColors) : '',
      segment: values.segment ? values.segment : '',
    };
    const valuesToSubmit = isEditMode ? values : newValues;
    onSubmit(valuesToSubmit);
  };

  const schema = Yup.object({
    title: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    hypothesisCategory: Yup.string().required('Câmp obligatoriu'),
    importance: Yup.string().required('Câmp obligatoriu'),
    columnBMC: Yup.string().required('Câmp obligatoriu'),
    segment: showSegmentField
      ? Yup.string().required('Camp Obligatoriu')
      : Yup.string(),
    KPIIndicators: Yup.array().of(
      Yup.object({
        title: Yup.string(),
        targetValue: Yup.string(),
      })
    ),
  });

  const defaultValues = {
    title: '',
    hypothesisCategory: '',
    description: '',
    columnBMC: defaultColumnBMC
      ? find(BMC_OPTIONS, { value: defaultColumnBMC })
      : '',
    segment: '',
    importance: '',
    KPIIndicators: [{ title: undefined, targetValue: undefined }],
  };

  const initialValues = isEditMode ? defaultHypothesis : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          setAddColorProperty={setAddColorProperty}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
          segmentOptions={segmentOptions}
          isEditMode={isEditMode}
          showSegmentField={showSegmentField}
          setShowSegmentField={setShowSegmentField}
          {...props}
        />
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
  defaultColumnBMC:
    isObject(state.common.modal.props) &&
    state.common.modal.props.initialValues.columnBMC,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddHypothesisForm);
